import { useTranslation } from "react-i18next"
import { Flex, Link } from "ui/ui"
import * as AllClientsPage from "../../AllClientsPage/path"
import * as AllInviteApplicationsPage from "../applications/AllInviteApplicationsPage/path"

export function Title() {
    const [t] = useTranslation()

    return <Flex direction="row"
                 justify="end"
                 gap="32px">
        <Flex direction="row"
              width="fit-content">
            <Link text={t("sections.clients.link")}
                  to={AllClientsPage.PATH}
                  whiteSpace="nowrap"
                  fontSize="16px"/>

            <Link text={t("sections.clients.invites.applications.link")}
                  to={AllInviteApplicationsPage.PATH}
                  whiteSpace="nowrap"
                  fontSize="16px"/>
        </Flex>
    </Flex>
}
