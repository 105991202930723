import { t } from "i18next"

// Types

export type NotificationType =
    | NewChatMessagesNotificationType
    | NewInviteApplicationNotificationType
    | NewInviteNotificationType
    | NewTransferNotificationType
    | NewUserNotificationType
    | NewProviderNotificationType
    | TransferStatusChangedNotificationType

export type NewChatMessagesNotificationType = typeof NEW_CHAT_MESSAGES_NOTIFICATION_TYPE
export type NewInviteApplicationNotificationType = typeof NEW_INVITE_APPLICATION_NOTIFICATION_TYPE
export type NewInviteNotificationType = typeof NEW_INVITE_NOTIFICATION_TYPE
export type NewTransferNotificationType = typeof NEW_TRANSFER_NOTIFICATION_TYPE
export type NewUserNotificationType = typeof NEW_USER_NOTIFICATION_TYPE
export type NewProviderNotificationType = typeof NEW_PROVIDER_NOTIFICATION_TYPE
export type TransferStatusChangedNotificationType = typeof TRANSFER_STATUS_CHANGED_NOTIFICATION_TYPE

// Consts

export const NEW_CHAT_MESSAGES_NOTIFICATION_TYPE = "new-chat-messages"
export const NEW_INVITE_APPLICATION_NOTIFICATION_TYPE = "new-invite-application"
export const NEW_INVITE_NOTIFICATION_TYPE = "new-invite"
export const NEW_TRANSFER_NOTIFICATION_TYPE = "new-transfer"
export const NEW_USER_NOTIFICATION_TYPE = "new-user"
export const NEW_PROVIDER_NOTIFICATION_TYPE = "new-provider"
export const TRANSFER_STATUS_CHANGED_NOTIFICATION_TYPE = "transfer-status-changed"

// Name

export function getNotificationTypeName(type: NotificationType): string {
    return t(`notifications.types.${type}`)
}

// Is-check

export function isNotificationType(s: string): s is NotificationType {
    switch (s) {
        case NEW_CHAT_MESSAGES_NOTIFICATION_TYPE:
        case NEW_INVITE_APPLICATION_NOTIFICATION_TYPE:
        case NEW_INVITE_NOTIFICATION_TYPE:
        case NEW_TRANSFER_NOTIFICATION_TYPE:
        case NEW_USER_NOTIFICATION_TYPE:
        case NEW_PROVIDER_NOTIFICATION_TYPE:
        case TRANSFER_STATUS_CHANGED_NOTIFICATION_TYPE:
            s satisfies NotificationType
            return true

        default:
            return false
    }
}
