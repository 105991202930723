import { ForwardedRef, forwardRef, useMemo } from "react"
import { Money } from "model"
import { Flex } from "ui/ui/layout"
import { DecimalOutput } from "ui/ui/numeric"
import { Output, Information, Label } from "ui/ui/output"

export namespace MoneyOutput {
    export interface Props {
        value: Money | Money.CreationOptions | null
        precision?: number

        width?: string
        currencyWidth?: string
        amountWidth?: string
        gap?: string

        label?: string
        information?: string
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MoneyOutput = forwardRef((
    {
        value, precision,
        width, currencyWidth, amountWidth, gap,
        label, information,
    }: Readonly<MoneyOutput.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    // State

    const innerValue = useMemo(() => Money.createOrPass(value), [value])

    // Render

    return <Flex width={width}
                 gap="4px"
                 ref={ref}>
        <Label text={label}/>

        <Flex gap={gap ?? "8px"}
              direction="row">
            <Output width={currencyWidth ?? "fit-content"}>
                {innerValue.currency}
            </Output>

            <DecimalOutput value={innerValue.amount}
                           precision={precision}
                           width={amountWidth}/>
        </Flex>

        <Information text={information}/>
    </Flex>
})

MoneyOutput.displayName = "MoneyOutput"
