import { arrayOfNonNull } from "my-util/array"

// Nullable

export function makeNullableName(
    firstname?: string | null,
    lastname?: string | null,
    patronymic?: string | null,
): string | null {
    const parts = arrayOfNonNull(firstname, lastname, patronymic)

    return parts.length > 0
        ? parts.join(" ")
        : null
}

export function makeNullableFormalName(
    firstname?: string | null,
    patronymic?: string | null,
): string | null {
    const parts = arrayOfNonNull(firstname, patronymic)

    return parts.length > 0
        ? parts.join(" ")
        : null
}

// Non-nullable

export function makeName(firstname: string, lastname: string, patronymic: string | null): string {
    return patronymic != null
        ? `${firstname} ${lastname} ${patronymic}`
        : `${firstname} ${lastname}`
}

export function makeFormalName(firstname: string, patronymic: string | null): string {
    return patronymic != null
        ? `${firstname} ${patronymic}`
        : firstname
}
