import { joinSubpaths } from "my-util"
import * as AllInvitesPage from "../AllInvitesPage/path"

export function createPath(inviteId: string) {
    return PATH.replace(`:${INVITE_ID_PARAM}`, inviteId)
}

export const INVITE_ID_PARAM = "inviteId"

export const PATH = joinSubpaths([AllInvitesPage.PATH, `/:${INVITE_ID_PARAM}`])
