import { ForwardedRef, forwardRef, ReactNode } from "react"
import { DeepReadonly } from "my-util"

export namespace FlexItem {
    export interface Props {
        children?: ReactNode

        grow?: number
        shrink?: number

        width?: string
        height?: string
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FlexItem = forwardRef((
    {
        children,
        grow, shrink,
        width, height,
    }: DeepReadonly<FlexItem.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) =>
    <div
        style={{
            flexGrow: grow,
            flexShrink: shrink,
            width, height,
        }}
        ref={ref}
    >
        {children}
    </div>
)

FlexItem.displayName = "FlexItem"
