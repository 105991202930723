import { ForwardedRef, forwardRef } from "react"
import { User } from "model"
import { DeepReadonly } from "my-util"
import { createPath } from "ui/page/sections/users/UserPage/path"
import { Link } from "ui/ui"

export namespace UserLink {
    export interface Props extends Omit<Link.Props, "to"> {
        user: User

        showCompany?: boolean
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserLink = forwardRef((
    props: DeepReadonly<UserLink.Props>,
    ref: ForwardedRef<HTMLAnchorElement>,
) => {
    const {
        user,
        showCompany,
        text, children,
    } = props

    return <Link { ...props }
                 to={createPath(user.id)}
                 ref={ref}>
        {children ?? text ?? (showCompany ? user.company.anyName ?? user.name : user.name)}
    </Link>
})

UserLink.displayName = "UserLink"
