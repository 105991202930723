import EventEmitter from "events"
import { NotificationResponse, NotificationResponseSchema } from "api/responses"

import { NewAnonymousChatMessagesNotification,
         Notification, NewChatMessagesNotification,
         NewInviteNotification, NewTransferNotification,
         NewInviteApplicationNotification, NewUserNotification,
         TransferStatusChangedNotification, NewProviderNotification,

         NEW_CHAT_MESSAGES_NOTIFICATION_TYPE,
         NEW_INVITE_APPLICATION_NOTIFICATION_TYPE,
         TRANSFER_STATUS_CHANGED_NOTIFICATION_TYPE,
         NEW_INVITE_NOTIFICATION_TYPE, NEW_USER_NOTIFICATION_TYPE,
         NEW_TRANSFER_NOTIFICATION_TYPE, NEW_PROVIDER_NOTIFICATION_TYPE } from "model"

import { DeepReadonly, UuidSchema } from "my-util"

export namespace NotificationSource {
    export type ReadyState =
        | "closed"
        | "connecting"
        | "open"

    export interface CreationOptions {
        open?: boolean

        url: string | URL
        withCredentials?: boolean

        captureRejections?: boolean
    }
}

export class NotificationSource extends EventEmitter<{
    "old": [Notification[]],
    "new": [Notification],

    "delete": [id: string],
    "clear": [],

    "open": [],
    "error": [error: unknown],
    "close": [],
}> {
    // Fields

    readonly url: string | URL
    readonly withCredentials: boolean

    private eventSource: EventSource | null = null

    // Constructor

    constructor(
        {
            open,
            url, withCredentials,
            captureRejections,
        }: Readonly<NotificationSource.CreationOptions>,
    ) {
        super({ captureRejections })

        this.url = url
        this.withCredentials = withCredentials ?? false

        if (open)
            this.open()
    }

    // Ready state

    get readyState(): NotificationSource.ReadyState {
        switch (this.eventSource?.readyState) {
            case EventSource.CLOSED:
            default:
                return "closed"

            case EventSource.CONNECTING:
                return "connecting"

            case EventSource.OPEN:
                return "open"
        }
    }

    // Open

    open() {
        this.eventSource = new EventSource(this.url, { withCredentials: this.withCredentials })

        // Status events

        this.eventSource.addEventListener("open", () => this.emit("open"))
        this.eventSource.addEventListener("error", () => this.emit("error", undefined))

        // Notification receiving events

        this.eventSource.addEventListener("old", event => {
            if (this.listenerCount("old") === 0)
                return

            try {
                const json = JSON.parse(event.data)
                const parsedJson = NotificationResponseSchema.array().parse(json)
                const notifications = parsedJson.map(notificationResponseToNotification)

                this.emit("old", notifications)
            } catch (error) {
                this.emit("error", error)
            }
        })

        this.eventSource.addEventListener("new", event => {
            if (this.listenerCount("new") === 0)
                return

            try {
                const json = JSON.parse(event.data)
                const parsedJson = NotificationResponseSchema.parse(json)
                const notification = notificationResponseToNotification(parsedJson)

                this.emit("new", notification)
            } catch (error) {
                this.emit("error", error)
            }
        })

        // Notification deletion events

        this.eventSource.addEventListener("delete-all", () => this.emit("clear"))

        this.eventSource.addEventListener("delete", event => {
            if (this.listenerCount("delete") === 0)
                return

            try {
                const json = JSON.parse(event.data)
                const id = UuidSchema.parse(json)

                this.emit("delete", id)
            } catch (error) {
                this.emit("error", error)
            }
        })

        // Util

        function notificationResponseToNotification(
            response: DeepReadonly<NotificationResponse>,
        ): Notification {
            switch (response.type) {
                case NEW_CHAT_MESSAGES_NOTIFICATION_TYPE:
                    return "fromId" in response
                        ? new NewChatMessagesNotification(response)
                        : new NewAnonymousChatMessagesNotification(response)

                case NEW_INVITE_APPLICATION_NOTIFICATION_TYPE:
                    return new NewInviteApplicationNotification(response)

                case NEW_INVITE_NOTIFICATION_TYPE:
                    return new NewInviteNotification(response)

                case NEW_PROVIDER_NOTIFICATION_TYPE:
                    return new NewProviderNotification(response)

                case NEW_TRANSFER_NOTIFICATION_TYPE:
                    return new NewTransferNotification(response)

                case NEW_USER_NOTIFICATION_TYPE:
                    return new NewUserNotification(response)

                case TRANSFER_STATUS_CHANGED_NOTIFICATION_TYPE:
                    return new TransferStatusChangedNotification(response)
            }
        }
    }

    // Close

    close() {
        if (this.eventSource == null)
            return

        this.eventSource.close()
        this.emit("close")

        this.eventSource = null
    }
}
