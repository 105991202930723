import { ForwardedRef, forwardRef, ReactElement } from "react"
import { Flex } from "ui/ui"
import { Logo } from "../Logo"
import { Info } from "./Info"
import { LogoutButton } from "./LogoutButton"
import { NotificationsButton } from "./NotificationsButton"
import { ProfileButton } from "./ProfileButton"
import style from "./style.module.css"

export namespace Header {
    export interface Props {
        type?: Type
    }

    export type Type =
        | "auth"
        | "main"
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Header = forwardRef((
    { type }: Readonly<Header.Props>,
    ref: ForwardedRef<HTMLHeadingElement>,
) => {
    // Render

    return type === "main"
        ? renderMain()
        : renderAuth()

    function renderMain(): ReactElement {
        return <header ref={ref}>
            <Flex align="start">
                <Flex justify="space-between"
                      direction="row">
                    <Logo height="fit-content"
                          width="168px"
                          full/>

                    <Flex direction="row"
                          width="fit-content">
                        <NotificationsButton/>
                        <ProfileButton/>
                        <LogoutButton/>
                    </Flex>
                </Flex>

                <div className={style.info}>
                    <Info/>
                </div>
            </Flex>
        </header>
    }

    function renderAuth(): ReactElement {
        return <header>
            <Logo refetchUserOnClick/>
        </header>
    }
})

Header.displayName = "Header"
