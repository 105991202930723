import { getLang } from "i18n"
import { NamedFields, NullableNamed } from "model/interfaces"
import { Constructor, makeNullableFormalName, makeNullableName, Nullable } from "my-util"

export function NullableNamedMixin<BaseConstructor extends Constructor<Nullable<NamedFields>>>(
    Base: BaseConstructor,
) {
    return class extends Base implements NullableNamed {
        private cachedEnName?: string | null = undefined
        private cachedRuName?: string | null = undefined

        private cachedEnFormalName?: string | null = undefined
        private cachedRuFormalName?: string | null = undefined

        // En

        get enName(): string | null {
            if (this.cachedEnName === undefined)
                this.cachedEnName = makeNullableName(
                    this.enFirstname,
                    this.enLastname,
                    this.enPatronymic,
                )

            return this.cachedEnName
        }

        get enFormalName(): string | null {
            if (this.cachedEnFormalName === undefined)
                this.cachedEnFormalName = makeNullableFormalName(
                    this.enFirstname,
                    this.enPatronymic,
                )

            return this.cachedEnFormalName
        }

        // Ru

        get ruName(): string | null {
            if (this.cachedRuName === undefined)
                this.cachedRuName = makeNullableName(
                    this.ruFirstname,
                    this.ruLastname,
                    this.ruPatronymic,
                )

            return this.cachedRuName
        }

        get ruFormalName(): string | null {
            if (this.cachedRuFormalName === undefined)
                this.cachedRuFormalName = makeNullableFormalName(
                    this.ruFirstname,
                    this.ruPatronymic,
                )

            return this.cachedRuFormalName
        }

        // Any

        get anyFirstname(): string | null {
            return getLang() === "ru"
                ? this.ruFirstname ?? this.enFirstname
                : this.enFirstname ?? this.ruFirstname
        }

        get anyLastname(): string | null {
            return getLang() === "ru"
                ? this.ruLastname ?? this.enLastname
                : this.enLastname ?? this.ruLastname
        }

        get anyPatronymic(): string | null {
            return getLang() === "ru"
                ? this.ruPatronymic ?? this.enPatronymic
                : this.enPatronymic ?? this.ruPatronymic
        }

        get anyName(): string | null {
            return getLang() === "ru"
                ? this.ruName ?? this.enName
                : this.enName ?? this.ruName
        }

        get anyFormalName(): string | null {
            return getLang() === "ru"
                ? this.ruFormalName ?? this.enFormalName
                : this.enFormalName ?? this.ruFormalName
        }

        // Current locale

        get firstname(): string | null {
            return getLang() === "ru"
                ? this.ruFirstname
                : this.enFirstname
        }

        get lastname(): string | null {
            return getLang() === "ru"
                ? this.ruLastname
                : this.enLastname
        }

        get patronymic(): string | null {
            return getLang() === "ru"
                ? this.ruPatronymic
                : this.enPatronymic
        }

        get name(): string | null {
            return getLang() === "ru"
                ? this.ruName
                : this.enName
        }

        get formalName(): string | null {
            return getLang() === "ru"
                ? this.ruFormalName
                : this.enFormalName
        }
    }
}
