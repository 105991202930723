import { ForwardedRef, forwardRef, useMemo } from "react"
import { getLang } from "i18n"
import { Transfer, User } from "model"

import { getWeekDayIndex, getWeekDayName, normalizeDayOrGetCurrent,
         normalizeMonthIndexOrGetCurrent, HOUR_STRINGS, normalizeYearOrGetCurrent } from "my-util"

import { Flex } from "ui/ui/layout"
import { SmallTransferCard } from "ui/ui/domain"
import style from "./style.module.css"

export namespace DayCalendar {
    export interface Props {
        dayIndex?: number
        monthIndex?: number
        year?: number

        onTransferClick?: (transfer: Transfer) => void
        transfers?: Transfer[]

        users?: Map<string, User> | Iterable<User>

        width?: string
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DayCalendar = forwardRef((
    {
        dayIndex, monthIndex, year,
        onTransferClick, transfers,
        users,
        width,
    }: Readonly<DayCalendar.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    // State

    const now = useMemo(() => new Date(), [])

    const innerYear = useMemo(
        () => normalizeYearOrGetCurrent(year, now),
        [year, now],
    )

    const innerMonthIndex = useMemo(
        () => normalizeMonthIndexOrGetCurrent(monthIndex, now),
        [monthIndex, now],
    )

    const innerDayIndex = useMemo(
        () => normalizeDayOrGetCurrent(dayIndex, now),
        [dayIndex, now],
    )

    const dayOfWeekIndex = useMemo(
        () => getWeekDayIndex(innerYear, innerMonthIndex, innerDayIndex),
        [innerYear, innerMonthIndex, innerDayIndex],
    )

    const dayOfWeekName = useMemo(
        () => getWeekDayName(dayOfWeekIndex, true),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dayOfWeekIndex, getLang()],
    )

    const innerTransfers = useMemo(
        () => transfers?.filter(({ moment }) =>
            moment != null &&
            moment.getFullYear() === innerYear &&
            moment.getMonth() === innerMonthIndex &&
            moment.getDate() === innerDayIndex + 1
        ) ?? [],

        [transfers, innerDayIndex, innerMonthIndex, innerYear],
    )

    // Render

    return <Flex width={width}
                 gap="8px"
                 ref={ref}>
        <div className={style.dayOfWeek}
             style={{ width }}
             ref={ref}>
            <span className={style.dayOfWeekName}>
                {dayOfWeekName}
            </span>

            {" "}

            {innerDayIndex + 1}
        </div>

        <table className={style.table}>
            <tbody>
                {HOUR_STRINGS.map((hour, i) => {
                    const hourTransfers = innerTransfers.filter(({ moment }) => {
                        const transferHour = moment!.getHours()

                        return transferHour >= i
                            && transferHour < i + 1
                    })

                    return <tr key={hour}>
                        <th className={style.hour}>
                            {i !== HOUR_STRINGS.length - 1 && hour}
                        </th>

                        <td className={style.transfersCell}>
                            <div className={style.transfers}>
                                <Flex gap="4px">
                                    {hourTransfers.map(transfer =>
                                        <SmallTransferCard onClick={onTransferClick}
                                                           transfer={transfer}
                                                           users={users}
                                                           key={transfer.id}/>
                                    )}
                                </Flex>
                            </div>
                        </td>
                    </tr>
                })}
            </tbody>
        </table>
    </Flex>
})

DayCalendar.displayName = "DayCalendar"
