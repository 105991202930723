import { forwardRef, ForwardedRef } from "react"
import { getUserStatusName, getUserStatusColors } from "model"
import { UserStatus } from "model"
import { ColorLegend } from "ui/ui/output"

export namespace UserStatusColorLegend {
    export interface Props {
        onClick?: (status: UserStatus) => void
        status: UserStatus

        showLegend?: boolean

        fontSize?: string
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserStatusColorLegend = forwardRef((
    {
        onClick, status,
        showLegend,
        fontSize
    }: Readonly<UserStatusColorLegend.Props>,
    ref: ForwardedRef<HTMLSpanElement>
) => {
    const onInnerClick = onClick != null
        ? () => onClick(status)
        : undefined

    const legend = showLegend
        ? getUserStatusName(status)
        : undefined

    const { color } = getUserStatusColors(status)

    return <ColorLegend onClick={onInnerClick}
                        fontSize={fontSize}
                        legend={legend}
                        color={color}
                        ref={ref}/>
});

UserStatusColorLegend.displayName = "UserStatusColorLegend"
