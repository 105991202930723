import { t } from "i18next"
import { capitalized, joinGrammatically } from "my-util"

// Types

export type MessageTargetType =
    | PhoneMessageTargetType
    | EmailMessageTargetType

export type PhoneMessageTargetType = typeof PHONE_MESSAGE_TARGET_TYPE
export type EmailMessageTargetType = typeof EMAIL_MESSAGE_TARGET_TYPE

export type MessageTargetObject =
    | { phone: string }
    | { email: string }

// Consts

export const PHONE_MESSAGE_TARGET_TYPE = "phone"
export const EMAIL_MESSAGE_TARGET_TYPE = "email"

// Determination

export function determineNullableMessageTargetType(s?: string | null): MessageTargetType | null {
    return s != null
        ? determineMessageTargetType(s)
        : null
}

export function determineMessageTargetType(s: string): MessageTargetType {
    return s.match(/[.@\p{L}]/ui)
         ? EMAIL_MESSAGE_TARGET_TYPE
         : PHONE_MESSAGE_TARGET_TYPE
}

// To string conversion

export function getMessageTargetTypeName(type: MessageTargetType): string {
    return t(`messageTargets.labels.${type}`)
}

export function getMessageTargetTypeWord(type: MessageTargetType): string {
    return t(`messageTargets.placeholders.words.${type}`)
}

export function getMessageTargetTypeMfaPlaceholder(type?: MessageTargetType | null): string {
    switch (type) {
        case PHONE_MESSAGE_TARGET_TYPE:
            return t("auth.mfa.placeholders.smsCode")

        case EMAIL_MESSAGE_TARGET_TYPE:
            return t("auth.mfa.placeholders.emailCode")

        case null:
        case undefined:
            return t("auth.mfa.placeholders.code")
    }
}

// Is-check

export function isMessageTargetType(s: string): s is MessageTargetType {
    switch (s) {
        case PHONE_MESSAGE_TARGET_TYPE:
        case EMAIL_MESSAGE_TARGET_TYPE:
            s satisfies MessageTargetType
            return true

        default:
            return false
    }
}

// To object

export function messageTargetToObject(
    messageTarget: string,
    messageTargetType: MessageTargetType = determineMessageTargetType(messageTarget),
): MessageTargetObject {
    switch (messageTargetType) {
        case PHONE_MESSAGE_TARGET_TYPE:
            return { phone: messageTarget }

        case EMAIL_MESSAGE_TARGET_TYPE:
            return { email: messageTarget }
    }
}

// Input

export function createMessageTargetInputLabel(types: readonly MessageTargetType[]): string {
    if (types.length === 0)
        return t("messageTargets.messages.noneIsAllowed")

    const words = types.map(getMessageTargetTypeWord)
    const joinedWords = joinGrammatically(words)
    const label = capitalized(joinedWords)

    return label
}

export function createMessageTargetInputPlaceholder(types: readonly MessageTargetType[]): string {
    const words = types.map(getMessageTargetTypeWord)
    const joinedWords = joinGrammatically(words)
    const placeholder = capitalized(t("misc.words.enter") + " " + joinedWords)

    return placeholder
}
