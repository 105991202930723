import { del, tryDeleteEntity } from "api/http/util"
import { joinSubpaths } from "my-util"

export const NOTIFICATIONS_SUBPATH = "/notifications"

// Delete many

export async function clearMyNotifications(signal?: AbortSignal | null): Promise<null> {
    return del({
        subpath: joinSubpaths([NOTIFICATIONS_SUBPATH, "/my"]),
        signal,
    })
}

export async function clearNotifications(signal?: AbortSignal | null): Promise<null> {
    return del({
        subpath: NOTIFICATIONS_SUBPATH,
        signal,
    })
}

// Delete one

export async function deleteNotificationById(
    id: string,
    signal?: AbortSignal | null,
): Promise<void> {
    return del({
        subpath: joinSubpaths([NOTIFICATIONS_SUBPATH, id]),
        signal,
    })
}

export async function tryDeleteNotificationById(
    id: string,
    signal?: AbortSignal | null,
): Promise<boolean> {
    return tryDeleteEntity(joinSubpaths([NOTIFICATIONS_SUBPATH, id]), signal)
}
