import { ForwardedRef, forwardRef } from "react"
import { Named } from "model"
import { DeepReadonly } from "my-util"
import style from "./style.module.css"

export namespace Name {
    export interface Props {
        value: Named
        formal?: boolean
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Name = forwardRef((
    { value, formal }: DeepReadonly<Name.Props>,
    ref: ForwardedRef<HTMLSpanElement>,
) =>
    <span className={style.Name}
          ref={ref}>
        {
            formal
                ? value.formalName
                : value.name
        }
    </span>
)

Name.displayName = "Name"
