import { ForwardedRef, forwardRef } from "react"
import { Flex } from "ui/ui/layout"
import { ACTIVE_INVITE_STATUS, BLOCKED_USER_STATUS, UNVERIFIED_USER_STATUS, UserStatus } from "model"
import { UserStatusColorLegend } from "../UserStatusColorLegend"

export namespace UserStatusColorLegendList {
    export interface Props extends Omit<Flex.Props, "children"> {
        onClick?: (status: UserStatus) => void

        hideActive?: boolean
        hideUnverified?: boolean
        hideBlocked?: boolean

        showLegend?: boolean

        fontSize?: string
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserStatusColorLegendList = forwardRef((
    props: Readonly<UserStatusColorLegendList.Props>,
    ref: ForwardedRef<HTMLDivElement>
) => {
    const {
        onClick,
        hideActive, hideUnverified, hideBlocked,
        showLegend,
        fontSize,
    } = props

    return <Flex width="fit-content"
                 direction="row"
                 { ...props }
                 ref={ref}>
        {!hideActive &&
            <UserStatusColorLegend status={ACTIVE_INVITE_STATUS}
                                   showLegend={showLegend}
                                   fontSize={fontSize}
                                   onClick={onClick}/>
        }

        {!hideUnverified &&
            <UserStatusColorLegend status={UNVERIFIED_USER_STATUS}
                                   showLegend={showLegend}
                                   fontSize={fontSize}
                                   onClick={onClick}/>
        }

        {!hideBlocked &&
            <UserStatusColorLegend status={BLOCKED_USER_STATUS}
                                   showLegend={showLegend}
                                   fontSize={fontSize}
                                   onClick={onClick}/>
        }
    </Flex>
})

UserStatusColorLegendList.displayName = "UserStatusColorLegendList"
