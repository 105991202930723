import assert from "assert"
import Decimal from "decimal.js"
import { EventEmitter } from "events"

import { forwardRef, useMemo, ForwardedRef, useRef,
         ReactNode, useContext, useState, useEffect } from "react"

import { useTranslation } from "react-i18next"
import { arrowsLeftRightIconUrl, minusIconUrl, plusIconUrl } from "images"
import { getLang } from "i18n"
import { CompanyResponse, getAllCompanies, getAllUsers, getUserById } from "api"

import { Money, TransferDirection, Product, User,
         PAID_TRANSFER_STATUS, //WAITING_MOMENT_TRANSFER_STATUS,
         REJECTED_TRANSFER_STATUS, PAYMENT_EXPIRED_TRANSFER_STATUS,
         WAITING_PAYMENT_TRANSFER_STATUS, IMPORT_TRANSFER_DIRECTION,
         DONE_TRANSFER_STATUS, EXPORT_TRANSFER_DIRECTION, NEW_TRANSFER_STATUS } from "model"

import { PAYMENT_ACCOUNT_INPUT_REGEX, removeWhiteSpace,
         collapseWhiteSpace, DeepReadonly, isNullOrBlank,
         collapseWhiteSpaceToNull, removeWhiteSpaceToNull,
         SWIFT_INPUT_REGEX,  CORRESPONDENT_ACCOUNT_INPUT_REGEX,
         dateWithDateSet, dateWithTimeSet, withFirstUpper, map, tryCopyDate,
         arrayOfNonNull, collapseSpacesToNull, IBAN_INPUT_REGEX, mergedMaps } from "my-util"

import { MAX_SHORT_TEXT_LENGTH, LEGAL_ITN_LENGTH,
         PAYMENT_ACCOUNT_LENGTH, MAX_SWIFT_LENGTH,
         validateCorrespondentAccount, validateLegalItn,
         CORRESPONDENT_ACCOUNT_LENGTH, MAX_MEDIUM_TEXT_LENGTH, MAX_IBAN_LENGTH,
         validatePaymentAccount, validateSwift, validateIban, validateShortText } from "validation"

import { copyTransferFields, copyRoutePointFields,
         TransferFields, copySignableDocumentFields } from "ui/fields"

import { useStateWithDeps, useUsers } from "ui/hook"
import { UserContext } from "ui/context"

import { copyUiDocument, UiDocument, isFieldDocument,
         SignableDocumentListEditor, DocumentListUpload } from "ui/component/document"

import { RoutePointListEditor, RoutePointListViolations } from "ui/component/route-point"
import { ProductListEditor, ProductListViolations } from "ui/component/product"

import { Input, MoneyInput, DateInput, Required, Information, Subheader,
         TimeInput, DecimalInput, TextArea, TransferDirectionRadio, Button,
         Form, Flex, FormControls, Icon, LoadingIndicator, Label, Select, ErrorText } from "ui/ui"

import { TransferProviderListEditor } from "../provider"
import style from "./style.module.css"

export namespace TransferFillInForm {
    export interface Props {
        onSubmit?: (fields: TransferFields) => void
        onReset?: () => void
        onChange?: (fields: TransferFields) => void

        fields?: TransferFields
        buttons?: FormControls.Button[]

        noDocumentsDelete?: boolean

        loading?: boolean
        disabled?: boolean
        error?: unknown

        width?: string
        height?: string

        exportDisabled?: boolean
        importDisabled?: boolean
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransferFillInForm = forwardRef((
    {
        onSubmit, onReset, onChange,
        fields, buttons,
        noDocumentsDelete,
        loading, disabled, error,
        width, height,
        exportDisabled, importDisabled,
    }: DeepReadonly<TransferFillInForm.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const [t] = useTranslation()

    const usersStorage = useUsers()

    // State

    // - Local user

    const [localUser] = useContext(UserContext)

    const localUserHasRightToManageTransfers = useMemo(
        () => localUser?.hasRightToManageTransfers ?? false,
        [localUser],
    )

    // - Users

    const [innerUsersById, setInnerUsersById] = useState(new Map<string, User>())

    const [loadedAllUsers, setLoadedAllUsers] = useState(false)
    const [loadingAllUsers, setLoadingAllUsers] = useState(false)
    const [allUsersLoadingError, setAllUsersLoadingError] = useState(undefined as unknown)

    const allUsersEventEmitterRef = useRef(new EventEmitter<{
        startedLoading: [],
        loadingFailed: [unknown],
        loaded: [User[]],
    }>())

    // - Companies

    const [companies, setCompanies] = useState(null as CompanyResponse[] | null)
    const [loadingCompanies, setLoadingCompanies] = useState(localUserHasRightToManageTransfers)
    const [companiesLoadingError, setCompaniesLoadingError] = useState(undefined as unknown)

    // - Status

    const status = fields?.status ?? NEW_TRANSFER_STATUS

    const isNew = status === NEW_TRANSFER_STATUS

    // const isWaitingMoment = status === WAITING_MOMENT_TRANSFER_STATUS
    const isWaitingPayment = status === WAITING_PAYMENT_TRANSFER_STATUS
    // const isWaiting = isWaitingMoment || isWaitingPayment

    const isPaid = status === PAID_TRANSFER_STATUS
    const isDone = status === DONE_TRANSFER_STATUS

    const isRejected = status === REJECTED_TRANSFER_STATUS
    const isPaymentExpired = status === PAYMENT_EXPIRED_TRANSFER_STATUS
    const isDeclined = isRejected || isPaymentExpired

    // - Direction

    const [direction, setDirection] = useStateWithDeps<TransferDirection>(
        () => {
            if (fields?.direction != null)
                return fields.direction

            if (exportDisabled && !importDisabled)
                return IMPORT_TRANSFER_DIRECTION

            return EXPORT_TRANSFER_DIRECTION
        },

        [fields?.direction],
    )

    const directionDisabled = useMemo(
        () => direction === EXPORT_TRANSFER_DIRECTION
            ? exportDisabled
            : importDisabled,

        [direction, exportDisabled, importDisabled],
    )

    // - Moment

    const [moment, setMoment] = useStateWithDeps(
        () => fields?.moment != null
            ? new Date(fields.moment.getTime())
            : null,

        [fields?.moment],
    )

    // - Country

    const [country, setCountry] = useStateWithDeps(() => fields?.country, [fields?.country])
    const [touchedCountry, setTouchedCountry] = useState(false)
    const countryInvalid = useMemo(() => isNullOrBlank(country), [country])

    // - Recipient

    // -- Name

    const [recipientName, setRecipientName] = useStateWithDeps(
        () => fields?.recipient?.name,
        [fields?.recipient?.name],
    )

    const [touchedRecipientName, setTouchedRecipientName] = useState(false)

    const recipientNameInvalid = useMemo(() => isNullOrBlank(recipientName), [recipientName])

    // -- ITN

    const [recipientItn, setRecipientItn] = useStateWithDeps(
        () => fields?.recipient?.itn ?? undefined,
        [fields?.recipient?.itn],
    )

    const [touchedRecipientItn, setTouchedRecipientItn] = useState(false)

    const recipientItnInvalid = useMemo(
        () => !isNullOrBlank(recipientItn) && validateLegalItn(recipientItn!) != null,
        [recipientItn],
    )

    // -- Correspondent account

    const [recipientCorrespondentAccount, setRecipientCorrespondentAccount] = useStateWithDeps(
        () => fields?.recipient?.correspondentAccount ?? undefined,
        [fields?.recipient?.correspondentAccount],
    )

    const [touchedRecipientCorrespondentAccount, setTouchedRecipientCorrespondentAccount] = useState(false)

    const recipientCorrespondentAccountInvalid = useMemo(
        () =>
            !isNullOrBlank(recipientCorrespondentAccount) &&
            validateCorrespondentAccount(recipientCorrespondentAccount!) != null,

        [recipientCorrespondentAccount],
    )

    // -- Payment account

    const [recipientPaymentAccount, setRecipientPaymentAccount] = useStateWithDeps(
        () => fields?.recipient?.paymentAccount ?? undefined,
        [fields?.recipient?.paymentAccount],
    )

    const [touchedRecipientPaymentAccount, setTouchedRecipientPaymentAccount] = useState(false)

    const recipientPaymentAccountInvalid = useMemo(
        () =>
            !isNullOrBlank(recipientPaymentAccount) &&
            validatePaymentAccount(recipientPaymentAccount!) != null,

        [recipientPaymentAccount],
    )

    // -- IBAN

    const [recipientIban, setRecipientIban] = useStateWithDeps(
        () => fields?.recipient?.iban ?? undefined,
        [fields?.recipient?.paymentAccount],
    )

    const [touchedRecipientIban, setTouchedRecipientIban] = useState(false)

    const recipientIbanInvalid = useMemo(
        () =>
            !isNullOrBlank(recipientIban) &&
            validateIban(recipientIban!) != null,

        [recipientIban],
    )

    // -- Bank

    // --- Name

    const [recipientBankName, setRecipientBankName] = useStateWithDeps(
        () => fields?.recipient?.bank?.name,
        [fields?.recipient?.bank?.name],
    )

    const [touchedRecipientBankName, setTouchedRecipientBankName] = useState(false)

    const recipientBankNameInvalid = useMemo(
        () =>
            isNullOrBlank(recipientBankName) ||
            validateShortText(recipientBankName!) != null,

        [recipientBankName],
    )

    // --- Address

    const [recipientBankAddress, setRecipientBankAddress] = useStateWithDeps(
        () => fields?.recipient?.bank?.address,
        [fields?.recipient?.bank?.address],
    )

    const [touchedRecipientBankAddress, setTouchedRecipientBankAddress] = useState(false)

    const recipientBankAddressInvalid = useMemo(
        () =>
            isNullOrBlank(recipientBankAddress) ||
            validateShortText(recipientBankAddress!) != null,

        [recipientBankAddress],
    )

    // --- SWIFT

    const [recipientBankSwift, setRecipientBankSwift] = useStateWithDeps(
        () => fields?.recipient?.bank?.swift,
        [fields?.recipient?.bank?.swift],
    )

    const [touchedRecipientBankSwift, setTouchedRecipientBankSwift] = useState(false)

    const recipientBankSwiftInvalid = useMemo(
        () =>
            isNullOrBlank(recipientBankSwift) ||
            validateSwift(recipientBankSwift!) != null,

        [recipientBankSwift],
    )

    // -- Number

    const [number, setNumber] = useStateWithDeps(
        () => (
            fields != null
                ? fields?.number
                : localUser?.nextTransferNumber
        )?.toString(),

        [fields?.number],
    )

    const numberInvalid = useMemo(
        () => {
            try {
                if (isNullOrBlank(number))
                    return false

                const normalizedNumber = removeWhiteSpace(number!)
                const parsedNumber = Number(normalizedNumber)

                return !Number.isInteger(parsedNumber)
                    || parsedNumber <= 0
            } catch {
                return true
            }
        },

        [number],
    )

    // - Money

    const [money, setMoney] = useStateWithDeps(
        () => fields?.money ?? new Money(),
        [fields?.money],
    )

    // - Products

    const [products, setProducts] = useStateWithDeps(
        () => [...fields?.products ?? []],
        [fields?.products],
    )

    const [productsViolations, setProductsViolations] = useState(new ProductListViolations())

    // - Invoice

    // -- Number

    const [invoiceNumber, setInvoiceNumber] = useStateWithDeps(
        () => fields?.invoice?.number,
        [fields?.invoice?.number],
    )

    const [touchedInvoiceNumber, setTouchedInvoiceNumber] = useState(false)

    const invoiceNumberInvalid = useMemo(() => isNullOrBlank(invoiceNumber), [invoiceNumber])

    // -- Date

    const [invoiceDate, setInvoiceDate] = useStateWithDeps(
        () => new Date(fields?.invoice?.date.getTime() ?? Date.now()),
        [fields?.invoice?.date],
    )

    // -- Document

    const [invoiceDocument, setInvoiceDocument] = useStateWithDeps<UiDocument | undefined>(
        () => fields?.invoice?.document != null
            ? copyUiDocument(fields.invoice.document)
            : undefined,

        [fields?.contract?.document],
    )

    const invoiceDocumentNotReady = invoiceDocument?.status !== "ready"

    // - Contract

    // -- Number

    const [contractNumber, setContractNumber] = useStateWithDeps(
        () => fields?.contract?.number,
        [fields?.contract?.number],
    )

    const [touchedContractNumber, setTouchedContractNumber] = useState(false)

    const contractNumberInvalid = useMemo(() => isNullOrBlank(contractNumber), [contractNumber])

    // -- Date

    const [contractDate, setContractDate] = useStateWithDeps(
        () => new Date(fields?.contract?.date.getTime() ?? Date.now()),
        [fields?.contract?.date],
    )

    // -- Document

    const [contractDocument, setContractDocument] = useStateWithDeps<UiDocument | undefined>(
        () => fields?.contract?.document != null
            ? copyUiDocument(fields.contract.document)
            : undefined,

        [fields?.contract?.document],
    )

    const contractDocumentNotReady = contractDocument?.status !== "ready"

    // - Route points

    const [routePoints, setRoutePoints] = useStateWithDeps(
        () => fields?.routePoints?.map(copyRoutePointFields) ?? [],
        [fields?.routePoints],
    )

    const [routePointsViolations, setRoutePointsViolations] = useState(new RoutePointListViolations())

    // - Currency rate

    const isCurrencyRateStatus = isWaitingPayment || isPaid || isDone

    // -- Money

    const [currencyRateMoney, setCurrencyRateMoney] = useStateWithDeps(
        () => fields?.currencyRate?.money ?? null,
        [fields?.currencyRate?.money],
    )

    // -- Moment

    const [currencyRateMoment, setCurrencyRateMoment] = useStateWithDeps(
        () => fields?.currencyRate?.moment != null
            ? new Date(fields?.currencyRate.moment.getTime())
            : null,

        [fields?.currencyRate?.moment],
    )

    // -- Evidence

    const [currencyRateEvidence, setCurrencyRateEvidence] = useStateWithDeps<UiDocument | undefined>(
        () => fields?.currencyRate?.evidence != null
            ? copyUiDocument(fields?.currencyRate?.evidence)
            : undefined,

        [fields?.currencyRate?.evidence],
    )

    const currencyRateEvidenceNotReady =
        currencyRateEvidence != null &&
        currencyRateEvidence.status !== "ready"

    // -- Evidence link

    const [currencyRateEvidenceLink, setCurrencyRateEvidenceLink] = useStateWithDeps(
        () => fields?.currencyRate?.evidenceLink ?? undefined,
        [fields?.currencyRate?.evidenceLink],
    )

    const [touchedCurrencyRateEvidenceLink, setTouchedCurrencyRateEvidenceLink] = useState(false)

    const currencyRateEvidenceLinkInvalid = useMemo(
        () => isNullOrBlank(currencyRateEvidenceLink),
        [currencyRateEvidenceLink],
    )

    // - Shares and expenses

    // -- Attorney fee

    const [attorneyFee, setAttorneyFee] = useStateWithDeps(
        () => fields?.attorneyFee ?? null,
        [fields?.attorneyFee],
    )

    // -- Agent percent

    const [agentPercent, setAgentPercent] = useStateWithDeps(
        () => fields?.agentPercent ?? null,
        [fields?.agentPercent],
    )

    // -- Expenses

    const [expenses, setExpenses] = useStateWithDeps(
        () => fields?.expenses ?? null,
        [fields?.expenses],
    )

    const expensesInvalid = useMemo(
        () => (expenses?.amount?.isZero() && !localUser?.isAdmin) ?? false,
        [expenses, localUser],
    )

    // - Signable documents

    const [signableDocuments, setSignableDocuments] = useStateWithDeps(
        () => map(fields?.signableDocuments ?? [], copySignableDocumentFields),
        [fields?.signableDocuments],
    )

    // - Transfer providers

    const [transferProviders, setTransferProviders] = useStateWithDeps(
        () => [...fields?.providers ?? []],
        [fields?.providers],
    )

    const someTransferProviderNotReady = useMemo(
        () => transferProviders?.some(
            ({ userId, providerId }) => userId == null && providerId == null
        ) ?? false,

        [transferProviders],
    )

    const [transferProviderSelectionModal, setTransferProviderSelectionModal] = useState<ReactNode>(null)

    // - Documents

    const [documents, setDocuments] = useStateWithDeps<UiDocument[]>(
        () => map(fields?.documents ?? [], copyUiDocument),
        [fields?.documents],
    )

    const documentsUploading = documents.some(({ status }) => status === "uploading" )

    // - Comments

    // -- Admin's

    const [adminComment, setAdminComment] = useStateWithDeps(
        () => fields?.adminComment ?? "",
        [fields?.adminComment],
    )

    // -- User's

    const [comment, setComment] = useStateWithDeps(
        () => fields?.comment ?? "",
        [fields?.comment],
    )

    // -- Lawyers's

    const [lawyerComment, setLawyerComment] = useStateWithDeps(
        () => fields?.lawyerComment ?? "",
        [fields?.lawyerComment],
    )

    // -- Lawyers's

    const [accountantComment, setAccountantComment] = useStateWithDeps(
        () => fields?.accountantComment ?? "",
        [fields?.accountantComment],
    )

    // - Company ITN

    const [companyItn, setCompanyItn] = useStateWithDeps(
        () => fields?.companyItn ?? null,
        [fields?.companyItn],
    )

    // Effects

    // - All users events emitting

    useEffect(() => {
        if (allUsersLoadingError != null)
            allUsersEventEmitterRef.current.emit("loadingFailed", allUsersLoadingError)
    }, [allUsersLoadingError])

    useEffect(() => {
        if (loadingAllUsers)
            allUsersEventEmitterRef.current.emit("startedLoading")
    }, [loadingAllUsers])

    useEffect(() => {
        if (loadedAllUsers)
            allUsersEventEmitterRef.current.emit("loaded", [...innerUsersById.values()])
    }, [innerUsersById, loadedAllUsers])

    // - All users loading

    useEffect(() => {
        if (!loadingAllUsers)
            return

        const controller = new AbortController()

        getAllUsers(controller.signal)
            .then(loadedUsers => {
                usersStorage.addAll(loadedUsers)

                setInnerUsersById(oldInnerUsersById => mergedMaps(
                    oldInnerUsersById,
                    User.groupById(loadedUsers),
                ))

                setLoadedAllUsers(true)
            })
            .catch(error => {
                if (!controller.signal.aborted)
                    setAllUsersLoadingError(error)
            })
            .finally(() => {
                if (!controller.signal.aborted)
                    setLoadingAllUsers(false)
            })

        return () => controller.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingAllUsers])

    // - All companies loading

    useEffect(() => {
        if (!loadingCompanies)
            return

        const controller = new AbortController()

        getAllCompanies(controller.signal)
            .then(setCompanies)
            .catch(error => {
                if (!controller.signal.aborted)
                    setCompaniesLoadingError(error)
            })
            .finally(() => {
                if (!controller.signal.aborted)
                    setLoadingCompanies(false)
            })

        return () => controller.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingCompanies])

    // Render

    const submitDisabled =
        disabled ||
        directionDisabled ||

        countryInvalid ||
        numberInvalid ||

        recipientNameInvalid ||
        recipientItnInvalid ||
        recipientCorrespondentAccountInvalid ||
        recipientPaymentAccountInvalid ||
        recipientIbanInvalid ||

        recipientBankNameInvalid ||
        recipientBankAddressInvalid ||
        recipientBankSwiftInvalid ||

        invoiceNumberInvalid ||
        invoiceDocumentNotReady ||

        contractNumberInvalid ||
        contractDocumentNotReady ||

        (isCurrencyRateStatus && (
            currencyRateEvidenceNotReady ||
            currencyRateEvidenceLinkInvalid
        )) ||

        expensesInvalid ||

        documentsUploading ||
        someTransferProviderNotReady ||
        !productsViolations.isValid ||
        !routePointsViolations.isValid

    return <div style={{ width, height }}
                ref={ref}>
        <Form onSubmit={onInnerSubmit}
              onReset={onReset}
              loading={loading}
              height="100%">
            <Flex justify="space-between"
                  height="100%">
                <div className={style.fields}>
                    {renderDirection()}
                    {renderMoment()}
                    {renderNumber()}
                    {renderRequisitesSection()}
                    {renderRecipientSection()}
                    {renderProductsSection()}
                    {renderRouteSection()}
                    {renderCurrencyRateSection()}
                    {renderSharesAndExpensesSection()}
                    {renderProvidersSection()}
                    {renderDocumentsSection()}
                    {renderSignableDocumentsSection()}
                    {renderCommentsSection()}
                </div>

                {renderControls()}
            </Flex>
        </Form>

        {transferProviderSelectionModal}
    </div>

    // - Direction

    function renderDirection(): ReactNode {
        return <>
            <div className={style.column1Label}>
                <Required>
                    <Label text={t("domain.transfers.labels.direction")}/>
                </Required>
            </div>

            <div className={style.column2To4Value}>
                <TransferDirectionRadio onChange={setDirection}
                                        checked={direction}

                                        loading={loading}
                                        disabled={disabled}

                                        exportDisabled={exportDisabled}
                                        importDisabled={importDisabled}/>
            </div>
        </>
    }

    // - Moment

    function renderMoment(): ReactNode {
        if (isNew || isDeclined)
            return

        return <>
            <div className={style.column1Label}>
                <Required>
                    <Label text={t("domain.transfers.labels.date")}/>
                </Required>
            </div>

            <div className={style.column2To4Value}>
                <Flex direction="row"
                      width="fit-content">
                    <DateInput onChange={({ date }) => onDateChange(date)}
                               value={moment}

                               loading={loading}
                               disabled={disabled}

                               width="min"/>

                    <Required>
                        <Label text={t("domain.transfers.labels.time")}/>
                    </Required>

                    <TimeInput onChange={({ date }) => onTimeChange(date)}
                               value={moment}

                               loading={loading}
                               disabled={disabled}

                               width="min"/>
                </Flex>
            </div>
        </>

        function onDateChange(date: Date) {
            setMoment(oldMoment =>
                oldMoment != null
                    ? dateWithDateSet(oldMoment, date)
                    : date
            )
        }

        function onTimeChange(time: Date) {
            setMoment(oldMoment =>
                oldMoment != null
                    ? dateWithTimeSet(oldMoment, time)
                    : time
            )
        }
    }

    // - Number

    function renderNumber(): ReactNode {
        return <>
            <div className={style.column1Label}>
                <Label text={t("domain.transfers.labels.number")}/>
            </div>

            <div className={style.column2Value}>
                <Input onChange={setNumber}
                       value={number ?? undefined}

                       placeholder={t("domain.transfers.placeholders.number")}

                       invalid={numberInvalid}
                       disabled={disabled}
                       loading={loading}

                       max={MAX_SHORT_TEXT_LENGTH}
                       regex={/^[\s\d]*$/g}/>
            </div>
        </>
    }

    // - Recipient

    function renderRecipientSection(): ReactNode {
        return <>
            {renderRecipientSubheader()}
            {renderCountry()}
            {renderRecipient()}
            {renderMoney()}
            {renderRecipientRequisites()}
        </>
    }

    function renderRecipientSubheader(): ReactNode {
        return <div className={style.subheader}>
            <Subheader text={t("domain.transfers.subheaders.recipient")}/>
        </div>
    }

    function renderCountry(): ReactNode {
        return <>
            <div className={style.column1Label}>
                <Required>
                    <Label text={t("domain.transfers.labels.country")}/>
                </Required>
            </div>

            <div className={style.column2To4Value}>
                <Input onChange={setCountry}
                       value={country}

                       onFocus={() => setTouchedCountry(true)}

                       placeholder={t("domain.transfers.placeholders.country")}

                       invalid={touchedCountry && countryInvalid}
                       loading={loading}
                       disabled={disabled}

                       max={MAX_SHORT_TEXT_LENGTH}/>
            </div>
        </>
    }

    function renderRecipient(): ReactNode {
        return <>
            {/* Name */}

            <div className={style.column1Label}>
                <Required>
                    <Label text={t("domain.transfers.labels.recipient.name.full")}/>
                </Required>
            </div>

            <div className={style.column2Value}>
                <Input onChange={setRecipientName}
                       value={recipientName}

                       onFocus={() => setTouchedRecipientName(true)}

                       placeholder={t("domain.transfers.placeholders.recipient.name")}

                       invalid={touchedRecipientName && recipientNameInvalid}
                       loading={loading}
                       disabled={disabled}

                       max={MAX_SHORT_TEXT_LENGTH}/>
            </div>

            {/* ITN */}

            <div className={style.column3Label}>
                <Label text={t("domain.transfers.labels.recipient.itn")}/>
            </div>

            <div className={style.column4Value}>
                <Input onChange={setRecipientItn}
                       value={recipientItn}

                       onFocus={() => setTouchedRecipientItn(true)}

                       placeholder={t("domain.transfers.placeholders.recipient.itn")}

                       invalid={touchedRecipientItn && recipientItnInvalid}
                       loading={loading}
                       disabled={disabled}

                       max={LEGAL_ITN_LENGTH}/>
            </div>
        </>
    }

    function renderMoney(): ReactNode {
        return <>
            <div className={style.column1Label}>
                <Required>
                    <Label text={t("domain.transfers.labels.money")}/>
                </Required>
            </div>

            <div className={style.column2To4Value}>
                <MoneyInput onChange={setMoney}
                            value={money}

                            placeholder={t("domain.transfers.placeholders.money")}

                            loading={loading}
                            disabled={disabled}/>
            </div>
        </>
    }

    function renderRecipientRequisites(): ReactNode {
        return <>
            {/* Bank name */}

            <div className={style.column1Label}>
                <Required>
                    <Label text={t("domain.transfers.labels.recipient.bank.name")}/>
                </Required>
            </div>

            <div className={style.column2Value}>
                <Input onChange={setRecipientBankName}
                       value={recipientBankName}

                       onFocus={() => setTouchedRecipientBankName(true)}

                       placeholder={t("domain.transfers.placeholders.recipient.bank.name")}

                       invalid={touchedRecipientBankName && recipientBankNameInvalid}
                       loading={loading}
                       disabled={disabled}

                       max={MAX_SHORT_TEXT_LENGTH}/>
            </div>

            {/* Bank SWIFT */}

            <div className={style.column3Label}>
                <Required>
                    <Label text={t("domain.transfers.labels.recipient.bank.swift")}/>
                </Required>
            </div>

            <div className={style.column4Value}>
                <Input onChange={setRecipientBankSwift}
                       value={recipientBankSwift}

                       onFocus={() => setTouchedRecipientBankSwift(true)}

                       placeholder={t("domain.transfers.placeholders.recipient.bank.swift")}

                       invalid={touchedRecipientBankSwift && recipientBankSwiftInvalid}
                       loading={loading}
                       disabled={disabled}

                       postProcess={text => text.toUpperCase()}

                       regex={SWIFT_INPUT_REGEX}
                       max={MAX_SWIFT_LENGTH}/>
            </div>

            {/* Bank address */}

            <div className={style.column1Label}>
                <Required>
                    <Label text={t("domain.transfers.labels.recipient.bank.address")}/>
                </Required>
            </div>

            <div className={style.column2Value}>
                <Input onChange={setRecipientBankAddress}
                       value={recipientBankAddress}

                       onFocus={() => setTouchedRecipientBankAddress(true)}

                       placeholder={t("domain.transfers.placeholders.recipient.bank.address")}

                       invalid={touchedRecipientBankAddress && recipientBankAddressInvalid}
                       loading={loading}
                       disabled={disabled}

                       max={MAX_SHORT_TEXT_LENGTH}/>
            </div>

            {/* IBAN */}

            <div className={style.column3Label}>
                <Label text={t("domain.transfers.labels.recipient.iban")}/>
            </div>

            <div className={style.column4Value}>
                <Input onChange={setRecipientIban}
                       value={recipientIban}

                       onFocus={() => setTouchedRecipientIban(true)}

                       placeholder={t("domain.transfers.placeholders.recipient.iban")}

                       invalid={touchedRecipientIban && recipientIbanInvalid}
                       loading={loading}
                       disabled={disabled}

                       regex={IBAN_INPUT_REGEX}
                       max={MAX_IBAN_LENGTH}/>
            </div>

            {/* Correspondent account */}

            <div className={style.column1Label}>
                <Label text={t("domain.transfers.labels.recipient.correspondentAccount.short")}/>
            </div>

            <div className={style.column2Value}>
                <Input onChange={setRecipientCorrespondentAccount}
                       value={recipientCorrespondentAccount}

                       onFocus={() => setTouchedRecipientCorrespondentAccount(true)}

                       placeholder={t("domain.transfers.placeholders.recipient.correspondentAccount")}

                       invalid={touchedRecipientCorrespondentAccount && recipientCorrespondentAccountInvalid}
                       loading={loading}
                       disabled={disabled}

                       regex={CORRESPONDENT_ACCOUNT_INPUT_REGEX}
                       max={CORRESPONDENT_ACCOUNT_LENGTH}/>
            </div>

            {/* Payment account */}

            <div className={style.column3Label}>
                <Label text={t("domain.transfers.labels.recipient.paymentAccount.short")}/>
            </div>

            <div className={style.column4Value}>
                <Input onChange={setRecipientPaymentAccount}
                       value={recipientPaymentAccount}

                       onFocus={() => setTouchedRecipientPaymentAccount(true)}

                       placeholder={t("domain.transfers.placeholders.recipient.paymentAccount")}

                       invalid={touchedRecipientPaymentAccount && recipientPaymentAccountInvalid}
                       loading={loading}
                       disabled={disabled}

                       regex={PAYMENT_ACCOUNT_INPUT_REGEX}
                       max={PAYMENT_ACCOUNT_LENGTH}/>
            </div>
        </>
    }

    // - Products

    function renderProductsSection(): ReactNode {
        return <>
            {renderProductsSubheader()}
            {renderProducts()}
        </>
    }

    function renderProductsSubheader(): ReactNode {
        return <div className={style.subheader}>
            <Subheader text={t("domain.transfers.subheaders.products")}/>
        </div>
    }

    function renderProducts(): ReactNode {
        return <div className={style.column1To4Value}>
            <ProductListEditor onValidate={setProductsViolations}
                               onChange={setProducts}
                               values={products}
                               required/>
        </div>
    }

    // - Requisites

    function renderRequisitesSection(): ReactNode {
        if (!localUserHasRightToManageTransfers)
            return null

        return <>
            {renderRequisitesSubheader()}
            {renderRequisitesSource()}
        </>
    }

    function renderRequisitesSubheader(): ReactNode {
        return <div className={style.subheader}>
            <Subheader text={t("domain.transfers.subheaders.requisites")}/>
        </div>
    }

    function renderRequisitesSource(): ReactNode {
        return <>
            <div className={style.column1Label}>
                <Label text={t("domain.transfers.labels.requisitesSource")}/>
            </div>

            <div className={style.column2To4Value}>
                {renderValue()}
            </div>
        </>

        function renderValue(): ReactNode {
            if (companiesLoadingError != null)
                return <ErrorText error={companiesLoadingError}/>

            if (companies == null)
                return <LoadingIndicator/>


            return <Select
                onSelect={onSelect}
                selected={companyItn ?? "no"}
                options={{
                    ...Object.fromEntries(
                        companies.map(company => [
                            company.itn,

                            getLang() === "ru"
                                ? company.ruName ?? company.enName ?? t("misc.words.unknown")
                                : company.enName ?? company.ruName ?? t("misc.words.unknown"),
                        ])
                    ),

                    "no": t("misc.words.no"),
                }}
            />

            function onSelect(key: string) {
                setCompanyItn(
                    key === "no"
                        ? null
                        : key
                )
            }
        }
    }

    // - Route

    function renderRouteSection(): ReactNode {
        return <>
            {renderRouteSubheader()}
            {renderRoute()}
        </>
    }

    function renderRouteSubheader(): ReactNode {
        return <div className={style.subheader}>
            <Subheader text={t("domain.transfers.subheaders.route")}/>
        </div>
    }

    function renderRoute(): ReactNode {
        return <>
            <div className={style.column1To4Value}>
                <Information text={t("domain.transfers.messages.routeNote")}
                             whiteSpace="pre-line"/>
            </div>

            <div className={style.column1To4Value}>
                <RoutePointListEditor onValidate={setRoutePointsViolations}
                                      onChange={setRoutePoints}
                                      values={routePoints}

                                      noDocumentDelete={noDocumentsDelete}

                                      loading={loading}
                                      disabled={disabled}
                                      required/>
            </div>
        </>
    }

    // - CurrencyRate

    function renderCurrencyRateSection(): ReactNode {
        if (!localUserHasRightToManageTransfers || !isCurrencyRateStatus)
            return null

        return <>
            {renderCurrencyRateSubheader()}
            {renderCurrencyRateMoney()}
            {renderCurrencyRateMoment()}
            {renderCurrencyRateEvidence()}
            {renderCurrencyRateEvidenceLink()}
        </>
    }

    function renderCurrencyRateSubheader(): ReactNode {
        return <div className={style.subheader}>
            <Subheader text={t("domain.transfers.subheaders.currencyRate")}/>
        </div>
    }

    function renderCurrencyRateMoney(): ReactNode {
        return <>
            <div className={style.column1Label}>
                <Required>
                    <Label text={t("domain.transfers.labels.currencyRate")}/>
                </Required>
            </div>

            <div className={style.column2To4Value}>
                <Flex direction="row">
                    <MoneyInput onChange={setCurrencyRateMoney}
                                value={currencyRateMoney}
                                precision={4}

                                loading={loading}
                                disabled={disabled}/>

                    <Icon src={arrowsLeftRightIconUrl}
                          alt="Left and right arrows"
                          filter="brightness(0) invert(50%)"/>

                    <MoneyInput onChange={({ currency }) => onMoneyCurrencyChange(currency)}
                                value={new Money({ currency: money.currency, amount: 1 })}
                                precision={4}

                                readonlyAmount/>
                </Flex>
            </div>
        </>

        function onMoneyCurrencyChange(currency: string) {
            setTimeout(() => setMoney(money.copy({ currency })), 0)
        }
    }

    function renderCurrencyRateMoment(): ReactNode {
        return <>
            <div className={style.column1Label}>
                <Required>
                    <Label text={t("domain.currencyRates.labels.date")}/>
                </Required>
            </div>

            <div className={style.column2To4Value}>
                <Flex direction="row"
                      width="fit-content">
                    <DateInput onChange={({ date }) => onDateChange(date)}
                               value={currencyRateMoment}

                               loading={loading}
                               disabled={disabled}

                               width="min"/>

                    <Label text={t("datetime.labels.time")}/>

                    <TimeInput onChange={({ date }) => onTimeChange(date)}
                               value={currencyRateMoment}


                               loading={loading}
                               disabled={disabled}

                               width="min"/>
                </Flex>
            </div>
        </>

        function onDateChange(date: Date) {
            setCurrencyRateMoment(oldCurrencyRateMoment =>
                oldCurrencyRateMoment != null
                    ? dateWithDateSet(oldCurrencyRateMoment, date)
                    : date,
            )
        }

        function onTimeChange(time: Date) {
            setCurrencyRateMoment(oldCurrencyRateMoment =>
                oldCurrencyRateMoment != null
                    ? dateWithTimeSet(oldCurrencyRateMoment, time)
                    : time,
            )
        }
    }

    function renderCurrencyRateEvidence(): ReactNode {
        return <>
            <div className={style.column1Label}>
                <Label text={t("domain.currencyRates.labels.evidence")}/>
            </div>

            <div className={style.column2To4Value}>
                <DocumentListUpload onChange={documents => setCurrencyRateEvidence(documents[0])}
                                    documents={arrayOfNonNull(currencyRateEvidence)}

                                    disabled={loading || disabled}
                                    noDelete={noDocumentsDelete}/>
            </div>
        </>
    }

    function renderCurrencyRateEvidenceLink(): ReactNode {
        return <>
            <div className={style.column1Label}>
                <Required>
                    <Label text={t("domain.currencyRates.labels.evidenceLink")}/>
                </Required>
            </div>

            <div className={style.column2Value}>
                <Input onChange={setCurrencyRateEvidenceLink}
                       value={currencyRateEvidenceLink}

                       onFocus={() => setTouchedCurrencyRateEvidenceLink(true)}

                       placeholder={t("domain.currencyRates.placeholders.evidenceLink")}

                       invalid={touchedCurrencyRateEvidenceLink && currencyRateEvidenceLinkInvalid}
                       loading={loading}
                       disabled={disabled}

                       max={MAX_SHORT_TEXT_LENGTH}/>
            </div>
        </>
    }

    // - Shares and expenses

    function renderSharesAndExpensesSection(): ReactNode {
        if (!localUserHasRightToManageTransfers)
            return null

        return <>
            {renderSharesAndExpensesSubheader()}
            {renderExpenses()}
            {renderAttorneyFee()}
            {renderAgentPercent()}
        </>
    }

    function renderSharesAndExpensesSubheader(): ReactNode {
        return <div className={style.subheader}>
            <Subheader text={t("domain.transfers.subheaders.sharesAndExpenses")}/>
        </div>
    }

    function renderExpenses(): ReactNode {
        return <>
            <div className={style.column1Label}>
                <Label text={t("domain.transfers.labels.expenses")}/>
            </div>

            <div className={style.column2Value}>
                {(() => {
                    if (expenses == null)
                        return <Button onClick={() => setExpenses(new Money({ amount: 5_000 }))}
                                       iconSrc={plusIconUrl}
                                       iconAlt="Plus icon"
                                       width="32px"/>

                        return <Flex direction="row"
                                     gap="32px">
                            <MoneyInput onChange={setExpenses}
                                        value={expenses}

                                        placeholder={t("domain.transfers.placeholders.expenses")}

                                        loading={loading}
                                        disabled={disabled}/>

                            <Button onClick={() => setExpenses(null)}
                                    iconSrc={minusIconUrl}
                                    iconAlt="Minus icon"
                                    width="32px"/>
                        </Flex>
                })()}
            </div>
        </>
    }

    function renderAttorneyFee(): ReactNode {
        return <>
            <div className={style.column1Label}>
                <Label text={t("domain.transfers.labels.attorneyFee")}/>
            </div>

            <div className={style.column2Value}>
                {(() => {
                    if (attorneyFee == null)
                        return <Button onClick={() => setAttorneyFee(new Decimal(100))}
                                       iconSrc={plusIconUrl}
                                       iconAlt="Plus icon"
                                       width="32px"/>

                        return <Flex direction="row"
                                     gap="32px">
                            <DecimalInput onChange={setAttorneyFee}
                                          value={attorneyFee}

                                          placeholder={t("domain.transfers.placeholders.attorneyFee")}
                                          right="₽"

                                          loading={loading}
                                          disabled={disabled}/>

                            <Button onClick={() => setAttorneyFee(null)}
                                    iconSrc={minusIconUrl}
                                    iconAlt="Minus icon"
                                    width="32px"/>
                        </Flex>
                })()}
            </div>
        </>
    }

    function renderAgentPercent(): ReactNode {
        return <>
            <div className={style.column1Label}>
                <Label text={t("domain.transfers.labels.agentPercent")}/>
            </div>

            <div className={style.column2Value}>
                {(() => {
                    if (agentPercent == null)
                        return <Button onClick={() => setAgentPercent(new Decimal(2.5))}
                                       iconSrc={plusIconUrl}
                                       iconAlt="Plus icon"
                                       width="32px"/>

                        return <Flex direction="row"
                                     gap="32px">

                            <DecimalInput onChange={setAgentPercent}
                                          value={agentPercent}
                                          precision={4}

                                          placeholder={t("domain.transfers.placeholders.agentPercent")}
                                          right="%"

                                          loading={loading}
                                          disabled={disabled}/>

                            <Button onClick={() => setAgentPercent(null)}
                                    iconSrc={minusIconUrl}
                                    iconAlt="Minus icon"
                                    width="32px"/>
                        </Flex>
                })()}
            </div>
        </>
    }

    // - Providers

    function renderProvidersSection(): ReactNode {
        if (!localUserHasRightToManageTransfers)
            return null

        return <>
            {renderProvidersSubheader()}
            {renderProviders()}
        </>
    }

    function renderProvidersSubheader(): ReactNode {
        return <div className={style.subheader}>
            <Subheader text={t("domain.transfers.subheaders.providers")}/>
        </div>
    }

    function renderProviders(): ReactNode {
        return <div className={style.column1To4Value}>
            <TransferProviderListEditor onChange={setTransferProviders}
                                        values={transferProviders}

                                        onUsersChange={users => setInnerUsersById(User.groupById(users))}
                                        users={innerUsersById}
                                        getAllUsers={innerGetAllUsers}
                                        getUserById={innerGetUserById}

                                        onRenderModal={setTransferProviderSelectionModal}

                                        allowEmpty/>
        </div>
    }

    // - Documents

    function renderDocumentsSection(): ReactNode {
        return <>
            {renderDocumentsSubheader()}
            {renderInvoice()}
            {renderContract()}
            {renderDocuments()}
        </>
    }

    function renderDocumentsSubheader(): ReactNode {
        return <div className={style.subheader}>
            <Subheader text={t("domain.transfers.subheaders.documents")}/>
        </div>
    }

    function renderInvoice(): ReactNode {
        return renderSpecialDocument({
            type: "invoice",

            setTouchedNumber: setTouchedInvoiceNumber,
            touchedNumber: touchedInvoiceNumber,

            setNumber: setInvoiceNumber,
            number: invoiceNumber,

            numberInvalid: invoiceNumberInvalid,

            setDate: setInvoiceDate,
            date: invoiceDate,

            setDocument: setInvoiceDocument,
            document: invoiceDocument,
        })
    }

    function renderContract(): ReactNode {
        return renderSpecialDocument({
            type: "contract",

            setTouchedNumber: setTouchedContractNumber,
            touchedNumber: touchedContractNumber,

            setNumber: setContractNumber,
            number: contractNumber,

            numberInvalid: contractNumberInvalid,

            setDate: setContractDate,
            date: contractDate,

            setDocument: setContractDocument,
            document: contractDocument,
        })
    }

    interface RenderSpecialDocumentOptions {
        type: "invoice" | "contract"

        setTouchedNumber: (touched: boolean) => void
        touchedNumber: boolean

        setNumber: (number: string) => void
        number?: string

        numberInvalid: boolean

        setDate: (date: Date) => void
        date: Date

        setDocument: (document?: UiDocument) => void
        document?: UiDocument
    }

    function renderSpecialDocument(options: RenderSpecialDocumentOptions): ReactNode {
        const { type } = options

        return <>
            <div className={style.column1Label}>
                <Required>
                    <Label text={t(`domain.transfers.labels.${type}.number`)}/>
                </Required>
            </div>

            <div className={style.column2To4Value}>
                <Flex direction="row"
                      width="fit-content">
                    <Input onChange={options.setNumber}
                           value={options.number}

                           onFocus={() => options.setTouchedNumber(true)}

                           placeholder={t(`domain.transfers.placeholders.${type}.number`)}

                           invalid={options.touchedNumber && options.numberInvalid}
                           loading={loading}
                           disabled={disabled}

                           max={MAX_SHORT_TEXT_LENGTH}/>

                    <Required>
                        <Label text={t(`domain.transfers.labels.${type}.date`)}/>
                    </Required>

                    <div>
                        <DateInput onChange={({ date }) => options.setDate(date)}
                                   value={options.date}

                                   loading={loading}
                                   disabled={disabled}

                                   width="min"/>
                    </div>
                </Flex>
            </div>

            <div className={style.column1Label}>
                <Required>
                    <Label text={t(`domain.transfers.labels.${type}.document`)}/>
                </Required>
            </div>

            <div className={style.column2To4Value}>
                <DocumentListUpload onChange={documents => options.setDocument(documents[0])}
                                    documents={arrayOfNonNull(options.document)}

                                    buttonText={t(`domain.transfers.actions.add${withFirstUpper(type)}`)}

                                    disabled={disabled || loading}
                                    noDelete={noDocumentsDelete}/>
            </div>
        </>
    }

    function renderDocuments(): ReactNode {
        return <>
            <div className={style.column1Label}>
                <Label text={t("domain.transfers.labels.documents")}/>
            </div>

            <div className={style.column2To4Value}>
                <DocumentListUpload onChange={onDocumentsChange}
                                    documents={documents}

                                    buttonText={t("domain.transfers.actions.addDocuments")}

                                    noDelete={noDocumentsDelete}
                                    disabled={loading || disabled}
                                    multiple/>
            </div>
        </>

        function onDocumentsChange(newDocuments: UiDocument[]) {
            setDocuments(newDocuments)

            if (fields == null || onChange == null)
                return

            const newFields = copyTransferFields(fields)

            newFields.documents = newDocuments.filter(isFieldDocument)

            onChange?.(newFields)
        }
    }

    // - Signable documents

    function renderSignableDocumentsSection(): ReactNode {
        if (!localUserHasRightToManageTransfers)
            return null

        return <>
            {renderSignableDocumentsSubheader()}
            {renderSignableDocuments()}
        </>
    }

    function renderSignableDocumentsSubheader(): ReactNode {
        return <div className={style.subheader}>
            <Subheader text={t("domain.transfers.subheaders.signableDocuments")}/>
        </div>
    }

    function renderSignableDocuments(): ReactNode {
        return <div className={style.column1To4Value}>
            <SignableDocumentListEditor onChange={setSignableDocuments}
                                        values={signableDocuments}

                                        allowEmpty

                                        width="fit-content"/>
        </div>
    }

    // - Comments

    function renderCommentsSection(): ReactNode {
        return <>
            {renderCommentsSubheader()}
            {renderComment()}
            {renderAdminComment()}
            {renderLawyerComment()}
            {renderAccountantComment()}
        </>
    }

    function renderCommentsSubheader(): ReactNode {
        return <div className={style.subheader}>
            <Subheader text={t("domain.transfers.subheaders.comments")}/>
        </div>
    }

    function renderComment(): ReactNode {
        return <>
            <div className={style.column1Label}>
                <Label text={t("domain.transfers.labels.comment")}/>
            </div>

            <div className={style.column2To4Value}>
                <TextArea onChange={setComment}
                          value={comment}

                          placeholder={t("domain.transfers.placeholders.comment")}

                          resize="vertical"

                          loading={loading}
                          disabled={disabled}

                          max={MAX_MEDIUM_TEXT_LENGTH}
                          canSubmit
                          showMax/>
            </div>
        </>
    }

    function renderAdminComment(): ReactNode {
        if (!localUserHasRightToManageTransfers)
            return null

        return <>
            <div className={style.column1Label}>
                <Label text={t("domain.transfers.labels.adminComment")}
                       wrap/>
            </div>

            <div className={style.column2To4Value}>
                <TextArea onChange={setAdminComment}
                          value={adminComment}

                          placeholder={t("domain.transfers.placeholders.comment")}

                          resize="vertical"

                          loading={loading}
                          disabled={disabled}

                          max={MAX_MEDIUM_TEXT_LENGTH}
                          canSubmit
                          showMax/>
            </div>
        </>
    }

    function renderLawyerComment(): ReactNode {
        if (!localUser?.isLawyer)
            return null

        return <>
            <div className={style.column1Label}>
                <Label text={t("domain.transfers.labels.lawyerComment")}
                       wrap/>
            </div>

            <div className={style.column2To4Value}>
                <TextArea onChange={setLawyerComment}
                          value={lawyerComment}

                          placeholder={t("domain.transfers.placeholders.comment")}

                          resize="vertical"

                          loading={loading}
                          disabled={disabled}

                          max={MAX_MEDIUM_TEXT_LENGTH}
                          canSubmit
                          showMax/>
            </div>
        </>
    }

    function renderAccountantComment(): ReactNode {
        if (!localUser?.isAccountant)
            return null

        return <>
            <div className={style.column1Label}>
                <Label text={t("domain.transfers.labels.accountantComment")}
                       wrap/>
            </div>

            <div className={style.column2To4Value}>
                <TextArea onChange={setAccountantComment}
                          value={accountantComment}

                          placeholder={t("domain.transfers.placeholders.comment")}

                          resize="vertical"

                          loading={loading}
                          disabled={disabled}

                          max={MAX_MEDIUM_TEXT_LENGTH}
                          canSubmit
                          showMax/>
            </div>
        </>
    }

    // - Controls

    function renderControls(): ReactNode {
        const localButtons: FormControls.Button[] = buttons?.map(button =>
            button.type === "submit"
                ? { ...button, disabled: submitDisabled }
                : button
        ) ?? []

        if (process.env.NODE_ENV === "development")
            localButtons.push({
                onClick: fillWithTestData,
                text: t("test.actions.fillWithTestData"),
                buttonStyle: "outline",
                position: "right",
                disabled,
                loading,
            })

        return <FormControls buttons={localButtons}
                             loading={loading}
                             disabled={disabled}
                             error={error}/>
    }

    // Events

    function onInnerSubmit() {
        if (!onSubmit)
            return

        assert(
            country != null &&

            recipientName != null &&

            recipientBankName != null &&
            recipientBankAddress != null &&
            recipientBankSwift != null &&

            invoiceNumber != null &&
            contractNumber != null
        )

        const newFields: TransferFields = {
            // Model object

            id: fields?.id,

            createdAt: fields?.createdAt != null
                ? new Date(fields.createdAt.getTime())
                : null,

            modifiedAt: fields?.modifiedAt != null
                ? new Date(fields.modifiedAt.getTime())
                : null,

            // Special user IDs

            creatorId: fields?.id ?? localUser?.id ?? null,

            stopperId: fields?.stopperId ?? null,
            isStopped: fields?.isStopped ?? false,

            // Common

            status,
            direction,
            country: collapseWhiteSpace(country),

            recipient: {
                name: collapseWhiteSpace(recipientName),

                itn: recipientItn != null
                    ? removeWhiteSpaceToNull(recipientItn)
                    : null,

                paymentAccount: recipientPaymentAccount != null
                    ? removeWhiteSpaceToNull(recipientPaymentAccount)?.toUpperCase() ?? null
                    : null,

                correspondentAccount: recipientCorrespondentAccount != null
                    ? removeWhiteSpaceToNull(recipientCorrespondentAccount)?.toUpperCase() ?? null
                    : null,

                iban: recipientIban != null
                    ? removeWhiteSpaceToNull(recipientIban)?.toUpperCase() ?? null
                    : null,

                bank: {
                    name: collapseWhiteSpace(recipientBankName),
                    address: collapseWhiteSpace(recipientBankAddress),
                    swift: removeWhiteSpace(recipientBankSwift).toUpperCase(),
                },
            },

            number: (() => {
                const normalizedNumber = removeWhiteSpaceToNull(number ?? "")

                if (normalizedNumber == null)
                    return null

                try {
                    return Number(normalizedNumber)
                } catch (error) {
                    console.error(error)
                    return null
                }
            })(),

            // Moment

            moment,

            // Money

            money,

            currencyRate: currencyRateMoney != null && currencyRateMoment != null
                ? {
                    money: currencyRateMoney,
                    moment: currencyRateMoment,

                    evidence: isFieldDocument(currencyRateEvidence)
                        ? currencyRateEvidence
                        : null,

                    evidenceLink: currencyRateEvidenceLink != null
                        ? collapseWhiteSpaceToNull(currencyRateEvidenceLink)
                        : null,
                }
                : null,

            // Special documents

            invoice: {
                number: collapseWhiteSpace(invoiceNumber),
                date: invoiceDate,

                document: isFieldDocument(invoiceDocument)
                    ? invoiceDocument
                    : null,
            },

            contract: {
                number: collapseWhiteSpace(contractNumber),
                date: contractDate,

                document: isFieldDocument(contractDocument)
                    ? contractDocument
                    : null,
            },

            // Payments

            cost: fields?.cost ?? null,
            expenses,
            agentPercent,
            attorneyFee,
            agentPayedAt: tryCopyDate(fields?.agentPayedAt),

            // Comment

            comment: collapseSpacesToNull(comment),
            adminComment: collapseSpacesToNull(adminComment),
            lawyerComment: collapseSpacesToNull(lawyerComment,),
            accountantComment: collapseSpacesToNull(accountantComment),

            // Requisites

            companyItn,

            // Collections

            documents: documents.filter(isFieldDocument),
            signableDocuments: signableDocuments.filter(({ initial, signed }) => initial != null || signed != null),
            products,
            routePoints,
            providers: transferProviders,
        }

        onSubmit(newFields)
    }

    async function innerGetAllUsers(): Promise<User[]> {
        if (loadedAllUsers)
            return [...innerUsersById.values()]

        if (allUsersLoadingError != null)
            throw allUsersLoadingError

        setLoadingAllUsers(true)

        return new Promise((resolve, reject) => {
            allUsersEventEmitterRef.current
                .once("loaded", resolve)
                .once("loadingFailed", reject)
        })
    }

    async function innerGetUserById(id: string, signal?: AbortSignal | null): Promise<User> {
        const oldUser = innerUsersById.get(id)

        if (oldUser != null)
            return oldUser

        const loadedUser = await getUserById(id, signal)

        usersStorage.add(loadedUser)

        setInnerUsersById(oldUsersById => {
            const newUsersById = new Map(oldUsersById)

            newUsersById.set(loadedUser.id, loadedUser)

            return newUsersById
        })

        return loadedUser
    }

    // Util

    function fillWithTestData() {
        setDirection("import")
        setCountry("Турция")

        setMoney(new Money({
            currency: "USD",
            amount: new Decimal(1_000_000),
        }))

        setRecipientName("Horns and Hooves - Turkey LLC")
        setRecipientCorrespondentAccount("30101810600000000957")
        setRecipientPaymentAccount("40817810099910004312")

        setRecipientBankName("DenizBanko")
        setRecipientBankAddress("Büyükdere Cad. No:141 34394 Esentepe-İSTANBUL")
        setRecipientBankSwift("DENITRISXXX")

        setInvoiceNumber("001")
        setContractNumber("002")

        setProducts([
            new Product({
                name: "Золотые слитки",
                cnfeaCodes: ["7108200001"],
            }),
        ])

        setRoutePoints([
            {
                country: "Россия",
                type: "water",
                documents: [],
            },
        ])

        setComment("Сделка серая. Нужно как можно быстрее")
    }
})

TransferFillInForm.displayName = "TransferFillInForm"
