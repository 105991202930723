import { ApiError } from "./ApiError"

export namespace ForbiddenApiError {
    export interface CreationOptions extends Omit<ApiError.CreationOptions, "statusCode"> {}
}

export class ForbiddenApiError extends ApiError {
    static readonly STATUS_CODE = 403

    constructor(options: Readonly<ForbiddenApiError.CreationOptions> = {}) {
        super({
            ...options,
            statusCode: ForbiddenApiError.STATUS_CODE,
        })
    }
}
