import { ReactNode, useState } from "react"
import { Center, Pane } from "ui/ui"
import { Message } from "./Message"
import { CreationForm } from "./CreationForm"
import { SuccessNotification } from "./SuccessNotification"

type State =
    | "show-message"
    | "phone-mfa"
    | "success"

export function InviteApplicationPane() {
    const [state, setState] = useState<State>("show-message")

    return <Pane>{renderContent()}</Pane>

    function renderContent(): ReactNode {
        switch (state) {
            case "show-message":
                return <Center>
                    <Message onCreateInviteApplication={() => setState("phone-mfa")}/>
                </Center>

            case "phone-mfa":
                return <CreationForm onSuccess={() => setState("success")}/>

            case "success":
                return <SuccessNotification/>
        }
    }
}
