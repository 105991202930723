import { ForwardedRef, forwardRef, ReactNode } from "react"
import { DeepReadonly } from "my-util"
import style from "./style.module.css"

export namespace Group {
    export interface Props {
        children?: ReactNode

        width?: string
        height?: string
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Group = forwardRef((
    {
        children,
        width, height,
    }: DeepReadonly<Group.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) =>
    <div style={{ width, height }}
         className={style.Group}
         ref={ref}>
        {children}
    </div>
)

Group.displayName = "Group"
