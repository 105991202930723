import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { UserContext } from "ui/context"
import { Page } from "ui/component"
import { Pane } from "ui/ui"
import style from "./style.module.css"

export function Component() {
    const [t] = useTranslation()

    const [localUser] = useContext(UserContext)

    return <Page type={localUser == null ? "auth" : "main"}>
        <Pane header={t("sections.contacts.header")}>
            <table className={style.table}>
                <tbody>
                    <tr>
                        <th>{t("sections.contacts.name")}</th>
                        <td>ООО «СИСТЕМАФОРМ»</td>
                    </tr>

                    <tr>
                        <th>{t("sections.contacts.legalAddress")}</th>
                        <td>"127247, г.Москва, Вн.Тер.гор. Муниципальный округ Восточное Дегунино, ул. 800-летия Москвы, д. 22 к. 2, этаж 1, помещ./ком. I/26"</td>
                    </tr>

                    <tr>
                        <th>{t("sections.contacts.actualAddress")}</th>
                        <td>"127247, г.Москва, Вн.Тер.г. Муниципальный округ Восточное Дегунино, ул. 800-летия Москвы, д. 22 к. 2, этаж 1, помещ./ком. I/26"</td>
                    </tr>

                    <tr>
                        <th>{t("sections.contacts.itn")}</th>
                        <td>9709061465</td>
                    </tr>

                    <tr>
                        <th>{t("sections.contacts.msrnie")}</th>
                        <td>1207700152791</td>
                    </tr>

                    <tr>
                        <th>{t("sections.contacts.crr")}</th>
                        <td>771301001</td>
                    </tr>

                    <tr>
                        <th>{t("sections.contacts.paymentAccount")}</th>
                        <td>40702810638000061544</td>
                    </tr>

                    <tr>
                        <th>{t("sections.contacts.correspondentAccount")}</th>
                        <td>30101810400000000225</td>
                    </tr>

                    <tr>
                        <th>{t("sections.contacts.bank")}</th>
                        <td>ПАО СБЕРБАНК</td>
                    </tr>

                    <tr>
                        <th>{t("sections.contacts.bankItn")}</th>
                        <td>7707083893</td>
                    </tr>

                    <tr>
                        <th>{t("sections.contacts.bic")}</th>
                        <td>044525225</td>
                    </tr>
                </tbody>
            </table>
        </Pane>
    </Page>
}
