import { NamedFields, WithCompanyNameFields, WithOneMessageTargetFields } from "model/interfaces"

import { NullableNamedMixin,
         WithOneMessageTargetMixin,
         WithNullableCompanyNameMixin } from "model/mixins"

import { AbstractModelObject } from "model/AbstractModelObject"

import { mixin, DeepReadonly, Nullish, capitalized,
         removeWhiteSpace, Nullable, collapseWhiteSpaceToNull } from "my-util"

export namespace InviteApplication {
    export interface CreationOptions
        extends
            AbstractModelObject.CreationOptions,
            WithOneMessageTargetFields,
            Nullish<WithCompanyNameFields>,
            Nullish<NamedFields>
    {}

    export interface CopyOptions
        extends
            AbstractModelObject.CopyOptions,
            Nullish<WithOneMessageTargetFields>,
            Nullish<WithCompanyNameFields>,
            Nullish<NamedFields>
    {}
}

class InviteApplicationBase
    extends
        AbstractModelObject<InviteApplication.CopyOptions>

    implements
        Readonly<Nullable<NamedFields>>,
        Readonly<Nullable<WithCompanyNameFields>>,
        Readonly<WithOneMessageTargetFields>
{
    // Fields

    // - Message target

    readonly messageTarget: string

    // - Company name

    readonly enCompany: string | null
    readonly ruCompany: string | null

    // - Name

    // -- En

    readonly enFirstname: string | null
    readonly enLastname: string | null
    readonly enPatronymic: string | null

    // -- Ru

    readonly ruFirstname: string | null
    readonly ruLastname: string | null
    readonly ruPatronymic: string | null

    // Constructor

    constructor(options: DeepReadonly<InviteApplication.CreationOptions>) {
        super(options)

        // Message target

        this.messageTarget = removeWhiteSpace(options.messageTarget)

        // Company

        this.enCompany = collapseWhiteSpaceToNull(options.enCompany ?? "")
        this.ruCompany = collapseWhiteSpaceToNull(options.ruCompany ?? "")

        // Name

        // - En

        this.enFirstname = collapseWhiteSpaceToNull(capitalized(options.enFirstname ?? ""))
        this.enLastname = collapseWhiteSpaceToNull(capitalized(options.enLastname ?? ""))
        this.enPatronymic = collapseWhiteSpaceToNull(capitalized(options.enPatronymic ?? ""))

        // - Ru

        this.ruFirstname = collapseWhiteSpaceToNull(capitalized(options.ruFirstname ?? ""))
        this.ruLastname = collapseWhiteSpaceToNull(capitalized(options.ruLastname ?? ""))
        this.ruPatronymic = collapseWhiteSpaceToNull(capitalized(options.ruPatronymic ?? ""))
    }

    // Copy

    protected override createCopy(
        options: DeepReadonly<InviteApplication.CopyOptions> = {},
    ): InviteApplicationBase {
        return new InviteApplicationBase({
            // Message target

            messageTarget: options.messageTarget ?? this.messageTarget,

            // Company

            enCompany: "enCompany" in options
                ? options.enCompany
                : options.enCompany,

            ruCompany: "ruCompany" in options
                ? options.ruCompany
                : options.ruCompany,

            // Name

            // - En

            enFirstname: "enFirstname" in options
                ? options.enFirstname
                : this.enFirstname,

            enLastname: "enLastname" in options
                ? options.enLastname
                : this.enLastname,

            enPatronymic: "enPatronymic" in options
                ? options.enPatronymic
                : this.enPatronymic,

            // - Ru

            ruFirstname: "ruFirstname" in options
                ? options.ruFirstname
                : this.ruFirstname,

            ruLastname: "ruLastname" in options
                ? options.ruLastname
                : this.ruLastname,

            ruPatronymic: "ruPatronymic" in options
                ? options.ruPatronymic
                : this.ruPatronymic,

            // Basic

            id: options.id ?? this.id,
            createdAt: options.createdAt ?? this.createdAt,
            modifiedAt: options.modifiedAt ?? this.modifiedAt,
        })
    }
}

export class InviteApplication extends mixin(InviteApplicationBase, [
    WithNullableCompanyNameMixin,
    WithOneMessageTargetMixin,
    NullableNamedMixin,
]) {
    static createOrPass(
        arg: InviteApplication | DeepReadonly<InviteApplication.CreationOptions>,
    ): InviteApplication {
        return arg instanceof InviteApplication
            ? arg
            : new InviteApplication(arg)
    }
}
