import { z } from "zod"
import { NewAnonymousChatMessagesNotificationResponseSchema } from "./new-anonymous-chat-messages"
import { NewChatMessagesNotificationResponseSchema } from "./new-chat-messages"
import { NewInviteApplicationNotificationResponseSchema } from "./new-invite-application"
import { NewInviteNotificationResponseSchema } from "./new-invite"
import { NewProviderNotificationResponseSchema } from "./new-provider"
import { NewTransferNotificationResponseSchema } from "./new-transfer"
import { NewUserNotificationResponseSchema } from "./new-user"
import { TransferStatusChangedNotificationResponseSchema } from "./transfer-status-changed"

export const NotificationResponseSchema = NewAnonymousChatMessagesNotificationResponseSchema
    .or(NewChatMessagesNotificationResponseSchema)
    .or(NewInviteApplicationNotificationResponseSchema)
    .or(NewInviteNotificationResponseSchema)
    .or(NewProviderNotificationResponseSchema)
    .or(NewTransferNotificationResponseSchema)
    .or(NewUserNotificationResponseSchema)
    .or(TransferStatusChangedNotificationResponseSchema)

export type NotificationResponse = z.infer<typeof NotificationResponseSchema>
