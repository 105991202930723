import { ReactNode, useContext, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { plusIconUrl } from "images"
import { getAllMyTransfers, getAllTransfers, getAllUsersByIds } from "api"

import { TransferGroup, TransferSortBy, DATE_TRANSFER_SORT_BY,
         EXPORT_TRANSFER_DIRECTION, Transfer, User, getTransferGroup,
         IMPORT_TRANSFER_DIRECTION, TransferDirection, DONE_TRANSFER_STATUS } from "model"

import { uniqueArray } from "my-util"
import { useTransfers, useUsers } from "ui/hook"
import { UserContext } from "ui/context"
import { SessionExpiredErrorPage } from "ui/page/error"
import * as TransferCreationPage from "ui/page/sections/transfers/TransferCreationPage/path"
import * as TransferPage from "ui/page/sections/transfers/TransferPage/path"
import { Page, SmallTransferTable } from "ui/component"

import { TransferDirectionRadio, Button, Padding,
         TransferGroupColorLegendList, ErrorDisplay,
         Subheader, ExpandButton, Information, Flex,
         TransferSortBySelect, Label, CheckBox, Loading } from "ui/ui"

export function Component() {
    const [t] = useTranslation()

    const [localUser] = useContext(UserContext)

    const storedUsers = useUsers()
    const storedTransfers = useTransfers()

    const navigate = useNavigate()

    // State

    // - Users

    const [creators, setCreators] = useState<User[]>([])
    const [loadingCreators, setLoadingCreators] = useState(false)

    const [agents, setAgents] = useState<User[]>([])
    const [loadingAgents, setLoadingAgents] = useState(false)

    const usersById = useMemo(
        () => User.groupById([...creators, ...agents]),
        [creators, agents],
    )

    // - Transfers

    const [transfers, setTransfers] = useState<Transfer[]>([])
    const [loadingTransfers, setLoadingTransfers] = useState(localUser != null)

    const [showDoneTransfers, setShowDoneTransfers] = useState(false)

    // -- Direction filtering

    const importTransfers = useMemo(
        () => transfers.filter(({ direction }) => direction === IMPORT_TRANSFER_DIRECTION),
        [transfers],
    )

    const exportTransfers = useMemo(
        () => transfers.filter(({ direction }) => direction === EXPORT_TRANSFER_DIRECTION),
        [transfers],
    )

    const [transferDirection, setTransferDirection] = useState<TransferDirection>(
        IMPORT_TRANSFER_DIRECTION
    )

    const directionTransfers = useMemo(
        () => {
            switch (transferDirection) {
                case IMPORT_TRANSFER_DIRECTION:
                    return importTransfers

                case EXPORT_TRANSFER_DIRECTION:
                    return exportTransfers
            }
        },

        [importTransfers, exportTransfers, transferDirection],
    )

    // -- By status grouping

    const activeTransfers = useMemo(
        () => directionTransfers.filter(({ status }) => status !== DONE_TRANSFER_STATUS),
        [directionTransfers],
    )

    const doneTransfers = useMemo(
        () => directionTransfers.filter(({ status }) => status === DONE_TRANSFER_STATUS),
        [directionTransfers],
    )

    // -- Group filtering

    const [activeTransferGroup, setActiveTransferGroup] = useState<TransferGroup | undefined>(undefined)

    const groupActiveTransfers = useMemo(
        () => activeTransferGroup != null
            ? activeTransfers.filter(transfer => activeTransferGroup === getTransferGroup(transfer))
            : activeTransfers,

        [activeTransfers, activeTransferGroup],
    )

    // -- Sorting

    // --- Active

    const [sortActiveTransfersBy, setSortActiveTransferBy] = useState<TransferSortBy>(
        DATE_TRANSFER_SORT_BY
    )

    const [reverseActiveTransferSorting, setReverseActiveTransferSorting] = useState(false)

    // --- Done

    const [sortDoneTransfersBy, setSortDoneTransferBy] = useState<TransferSortBy>(
        DATE_TRANSFER_SORT_BY
    )

    const [reverseDoneTransferSorting, setReverseDoneTransferSorting] = useState(false)

    // - Loading

    const loading =
        loadingTransfers ||
        loadingCreators ||
        loadingAgents

    const [error, setError] = useState<unknown>(undefined)

    // Effects

    // - Transfers loading

    useEffect(() => {
        if (!loadingTransfers)
            return

        const controller = new AbortController()

        const promise = localUser?.isManager
            ? getAllTransfers(controller.signal)
            : getAllMyTransfers(controller.signal)

        promise
            .then(transfers => {
                storedTransfers.addAll(transfers)
                setTransfers(transfers)
                setLoadingCreators(localUser?.isManager ?? false)
            })
            .catch(error => {
                if (!controller.signal.aborted)
                    setError(error)
            })
            .finally(() => {
                if (!controller.signal.aborted)
                    setLoadingTransfers(false)
            })

        return () => controller.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingTransfers])

    // - Creators loading

    useEffect(() => {
        if (!loadingCreators)
            return

        const creatorIds = uniqueArray(
            transfers
                .map(({ creatorId }) => creatorId)
                .filter(id => id != null) as string[]
        )

        if (creatorIds.length === 0) {
            setLoadingCreators(false)
            return
        }

        const controller = new AbortController()

        getAllUsersByIds({ ids: creatorIds }, controller.signal)
            .then(creators => {
                storedUsers.addAll(creators)
                setCreators(creators)
                setLoadingAgents(true)
            })
            .catch(error => {
                if (!controller.signal.aborted)
                    setError(error)
            })
            .finally(() => {
                if (!controller.signal.aborted)
                    setLoadingCreators(false)
            })

        return () => controller.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingCreators])

    // - Agents loading

    useEffect(() => {
        if (!loadingAgents)
            return

        const agentIds = uniqueArray(
            creators
                .map(({ creatorId }) => creatorId)
                .filter(id => id != null) as string[]
        )

        if (agentIds.length === 0) {
            setLoadingAgents(false)
            return
        }

        const controller = new AbortController()

        getAllUsersByIds({ ids: agentIds }, controller.signal)
            .then(agents => {
                storedUsers.addAll(agents)
                setAgents(agents)
            })
            .catch(error => {
                if (!controller.signal.aborted)
                    setError(error)
            })
            .finally(() => {
                if (!controller.signal.aborted)
                    setLoadingAgents(false)
            })

        return () => controller.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingAgents])

    // Render

    if (localUser == null)
        return <SessionExpiredErrorPage/>

    return <Page compactTitle={renderCompactTitle}
                 title={renderTitle()}
                 type="main">
        {renderContent()}
    </Page>

    function renderCompactTitle(compact?: boolean): ReactNode {
        const content = renderContent()

        return compact || loading || transfers.length === 0
            ? content

            : <Padding paddingLeft="32px">
                {content}
            </Padding>

        function renderContent(): ReactNode {
            return <Flex direction="row"
                         gap="8px">
                <Button onClick={() => navigate(TransferCreationPage.PATH)}
                        iconSrc={plusIconUrl}
                        iconAlt="Plus icon"
                        width="32px"/>

                <div style={{ height: "1em" }}>
                    {(
                        compact
                            ? t("sections.transfers.header.short")
                            : t("sections.transfers.header.full")
                    ).toLocaleUpperCase()}
                </div>
            </Flex>
        }
    }

    function renderTitle(): ReactNode {
        if (loading || transfers.length === 0)
            return null

        return <Padding paddingLeft="14px">
            <TransferDirectionRadio onChange={setTransferDirection}
                                    checked={transferDirection}
                                    width="fit-content"/>
        </Padding>
    }

    function renderContent(): ReactNode {
        if (loading)
            return <Loading/>

        if (error != null)
            return <ErrorDisplay error={error}/>

        return <Flex gap="44px">
            {renderActiveTransfers()}
            {renderDoneTransfers()}
        </Flex>
    }

    function renderActiveTransfers(): ReactNode {
        return <Flex align="start"
                     gap="20px">
            <Padding paddingLeft="32px"
                     paddingTop="16px">
                <Flex direction="row"
                      gap="32px">
                    <TransferGroupColorLegendList onClick={onTransferGroupClick}
                                                  active={activeTransferGroup}

                                                  fontStyle="italic"
                                                  fontSize="12px"

                                                  showLegend
                                                  hideDone/>

                    {localUser?.isManager &&
                        <Flex width="fit-content"
                              direction="row"
                              gap="8px">
                            <Label text={t("misc.labels.sortBy")}/>

                            <TransferSortBySelect onSelect={setSortActiveTransferBy}
                                                  selected={sortActiveTransfersBy}
                                                  width="min"/>

                            <CheckBox onChange={setReverseActiveTransferSorting}
                                      checked={reverseActiveTransferSorting}
                                      label={t("misc.labels.reverseSortingOrder")}/>
                        </Flex>
                    }
                </Flex>
            </Padding>

            {groupActiveTransfers.length === 0
                ? <Padding paddingLeft="56px"
                           paddingTop="8px">
                    <Information text={renderNoActiveTransfersMessage()}
                                 fontSize="18px"/>
                </Padding>

                : <SmallTransferTable onTransferClick={onTransferClick}
                                      transfers={groupActiveTransfers}

                                      users={usersById}

                                      showAgents={localUser?.isManager}
                                      showGroups

                                      sortBy={sortActiveTransfersBy}
                                      reverseSorting={reverseActiveTransferSorting}/>
            }
        </Flex>
    }

    function renderNoActiveTransfersMessage(): string {
        return activeTransferGroup != null
            ? t(`domain.transfers.messages.no.groups.${activeTransferGroup}`)
            : t("domain.transfers.messages.no.active")
    }

    function renderDoneTransfers(): ReactNode {
        return <Flex align="start"
                     gap="20px">
            <Flex direction="row"
                  height="32px"
                  gap="32px">
                <Flex width="fit-content"
                      direction="row"
                      gap="8px">
                    <Subheader text={t("sections.main.transfers.archiveSubheader") + ":"}/>

                    <ExpandButton onClick={() => setShowDoneTransfers(old => !old)}
                                  expand={showDoneTransfers}/>
                </Flex>

                {showDoneTransfers && localUser?.isManager &&
                    <Flex width="fit-content"
                          direction="row"
                          gap="8px">
                        <Label text={t("misc.labels.sortBy")}/>

                        <TransferSortBySelect onSelect={setSortDoneTransferBy}
                                              selected={sortDoneTransfersBy}
                                              width="min"/>

                        <CheckBox onChange={setReverseDoneTransferSorting}
                                  checked={reverseDoneTransferSorting}
                                  label={t("misc.labels.reverseSortingOrder")}/>
                    </Flex>
                }
            </Flex>

            {showDoneTransfers
                ? doneTransfers.length === 0
                    ? <Padding paddingLeft="56px"
                               paddingTop="8px">
                        <Information text={t("domain.transfers.messages.no.groups.done")}
                                     fontSize="18px"/>
                    </Padding>

                    : <SmallTransferTable onTransferClick={onTransferClick}
                                          transfers={doneTransfers}

                                          showAgents={localUser?.isManager}
                                          users={usersById}

                                          sortBy={sortDoneTransfersBy}
                                          reverseSorting={reverseDoneTransferSorting}/>

                : null
            }
        </Flex>
    }

    // Events

    function onTransferGroupClick(group: TransferGroup) {
        setActiveTransferGroup(oldGroup =>
            group === oldGroup
                ? undefined
                : group
        )
    }

    function onTransferClick(transfer: Transfer) {
        navigate(TransferPage.createPath(transfer.id))
    }
}
