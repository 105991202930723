import { filesize } from "filesize"
import { ForwardedRef, forwardRef } from "react"
import style from "./style.module.css"

export namespace FileSize {
    export interface Props {
        bytes: number

        fontSize?: string
        fontWeight?: string
        color?: string
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FileSize = forwardRef((
    {
        bytes,
        fontSize, fontWeight, color,
    }: Readonly<FileSize.Props>,
    ref: ForwardedRef<HTMLSpanElement>,
) =>
    <span style={{ fontSize, fontWeight, color }}
          className={style.FileSize}
          ref={ref}>
        {filesize(bytes)}
    </span>
)

FileSize.displayName = "FileSize"
