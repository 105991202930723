import { ForwardedRef, forwardRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { patchTransferById } from "api"
import { Transfer, TransferStatus } from "model"
import { isNullOrBlank } from "my-util"
import { MAX_MEDIUM_TEXT_LENGTH } from "validation"
import { ErrorDisplay, Label, Flex, Modal, Required, TextArea } from "ui/ui"

export namespace TransferRejectionModal {
    export interface Props {
        onClose?: () => void
        onRejected?: (transfer: Transfer) => void

        transferId: string

        width?: string
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransferRejectionModal = forwardRef((
    {
        onClose, onRejected,
        transferId,
        width,
    }: Readonly<TransferRejectionModal.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const [t] = useTranslation()

    // State

    const [reason, setReason] = useState("")
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(undefined as any)

    // Render

    return <Modal
        header={t("domain.transfers.headers.reject")}
        loading={loading}
        onClose={onInnerClose}
        width={width}
        buttons={[
            {
                onClick: onInnerClose,
                text: t("misc.actions.cancel"),
                buttonStyle: "outline",
            },

            {
                onClick: onReject,
                text: t("misc.actions.ok"),
                type: "submit",
                disabled: isNullOrBlank(reason),
            },
        ]}
        ref={ref}
    >
        <Flex gap="8px">
            <Flex align="start"
                  gap="8px">
                <Required>
                    <Label text={t("domain.transfers.labels.rejectionReason")}/>
                </Required>

                <TextArea placeholder={t("domain.transfers.placeholders.rejectionReason")}

                          onChange={setReason}
                          value={reason}

                          loading={loading}

                          resize="vertical"

                          max={MAX_MEDIUM_TEXT_LENGTH}
                          showMax

                          canSubmit/>
            </Flex>

            <ErrorDisplay centerType="flex"
                          error={error}/>
        </Flex>
    </Modal>

    // Events

    function onInnerClose() {
        if (!loading)
            onClose?.()
    }

    async function onReject() {
        setLoading(true)

        try {
            const transfer = await patchTransferById(transferId, [
                {
                    op: "replace",
                    path: "/status",
                    value: "rejected" satisfies TransferStatus,
                },

                {
                    op: "replace",
                    path: "/adminComment",
                    value: reason,
                },

                {
                    op: "replace",
                    path: "/moment",
                    value: null,
                },

                {
                    op: "replace",
                    path: "/currencyRate",
                    value: null,
                },
            ])

            onRejected?.(transfer)
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }
})

TransferRejectionModal.displayName = "TransferRejectionModal"
