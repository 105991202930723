import { ForwardedRef, forwardRef } from "react"
import { createDocumentDownloadUrlById } from "api"
import { Document } from "model"
import { DeepReadonly } from "my-util"
import { Link } from "ui/ui"

export namespace DocumentLink {
    export interface Props extends Omit<Link.Props, "to"> {
        document: Document
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DocumentLink = forwardRef((
    props: DeepReadonly<DocumentLink.Props>,
    ref: ForwardedRef<HTMLAnchorElement>,
) => {
    const {
        document,
        text, children,
        target, download,
    } = props

    return <Link { ...props }

                 to={createDocumentDownloadUrlById(document.id)}
                 target={target ?? "_blank"}
                 download={download ?? document.name}

                 ref={ref}>
        {children ?? text ?? document.name}
    </Link>
})

DocumentLink.displayName = "DocumentLink"
