import assert from "assert"
import React from "react"
import ReactDOM from "react-dom/client"
import { App } from "./ui/App"
import "./i18n"
import './index.css'

const rootElement = document.getElementById("root")

assert(rootElement != null)

const root = ReactDOM.createRoot(rootElement)

root.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>
)
