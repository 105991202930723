import { useTranslation } from "react-i18next"
import { ReactNode, useMemo, useState } from "react"
import { createInviteApplication, MfaResponse, InviteApplicationRequest } from "api"
import { messageTargetToObject, MessageTargetType } from "model"
import { capitalizedText, EN_NAME_INPUT_REGEX, isBlank, RU_NAME_INPUT_REGEX } from "my-util"
import { MAX_SHORT_TEXT_LENGTH, validateEnName, validateRuName } from "validation"
import { MessageTargetMfaForm } from "ui/component"
import { Button, DiLangInput, Flex, Limit, PhaseIndicator } from "ui/ui"

export namespace CreationForm {
    export interface Props {
        onSuccess?: () => void
    }
}

export function CreationForm({ onSuccess }: Readonly<CreationForm.Props>) {
    const [t] = useTranslation()

    // State

    // - Name

    const [name, setName] = useState({ en: "", ru: "" })
    const [touchedName, setTouchedName] = useState(false)

    const nameInvalid = useMemo(
        () => ({
            en: validateEnName(name.en) != null,
            ru: validateRuName(name.ru) != null,
        }),

        [name],
    )

    // - Company

    const [company, setCompany] = useState({ en: "", ru: "" })
    const [touchedCompany, setTouchedCompany] = useState(false)

    const companyInvalid = useMemo(
        () => ({
            en: isBlank(company.en),
            ru: isBlank(company.ru),
        }),

        [company],
    )

    // - MFA

    const [messageTarget, setMessageTarget] = useState("")
    const [mfa, setMfa] = useState(false)
    const [mfaState, setMfaState] = useState<MessageTargetMfaForm.State>("message-target")

    // Render

    return <Flex align="start">
        {renderPhaseIndicator()}
        {renderContent()}
    </Flex>

    function renderPhaseIndicator(): ReactNode {
        const phase: PhaseIndicator.Phase = {
            style: {
                current: {
                    size: "big",
                }
            },
        }

        return <PhaseIndicator current={getCurrentPhase()}
                               phases={[phase, phase, phase]}/>

        function getCurrentPhase(): number {
            if (mfa)
                switch (mfaState) {
                    case "message-target":
                        return 1

                    case "otp":
                    case "success":
                        return 2
                }

            return 0
        }
    }

    function renderContent(): ReactNode {
        const MAX_WIDTH = "300px"

        if (mfa)
            return <Limit maxWidth={MAX_WIDTH}>
                <MessageTargetMfaForm messageTargetPlaceholder={t("messageTargets.placeholders.messageTarget")}

                                      performMfa={performMfa}

                                      onMessageTargetChange={setMessageTarget}
                                      messageTarget={messageTarget}

                                      onSuccess={onSuccess}
                                      onStateChange={setMfaState}
                                      onBack={() => setMfa(false)}

                                      hidePhaseIndicator/>
            </Limit>

        const nextDisabled =
            nameInvalid.en ||
            nameInvalid.ru ||

            companyInvalid.en ||
            companyInvalid.ru

        return <>
            <DiLangInput onChange={(en, ru) => setName({ en, ru })}
                         enValue={name.en}
                         ruValue={name.ru}

                         enInvalid={touchedName && nameInvalid.en}
                         ruInvalid={touchedName && nameInvalid.ru}

                         postProcess={capitalizedText}

                         enRegex={EN_NAME_INPUT_REGEX}
                         ruRegex={RU_NAME_INPUT_REGEX}

                         onFocus={() => setTouchedName(true)}

                         label={t("domain.inviteApplications.labels.name")}
                         placeholder={t("domain.inviteApplications.placeholders.name")}


                        maxWidth={MAX_WIDTH}/>

            <DiLangInput onChange={(en, ru) => setCompany({ en, ru })}
                         enValue={company.en}
                         ruValue={company.ru}

                         enInvalid={touchedCompany && companyInvalid.en}
                         ruInvalid={touchedCompany && companyInvalid.ru}

                         onFocus={() => setTouchedCompany(true)}

                         label={t("domain.inviteApplications.labels.company")}
                         placeholder={t("domain.inviteApplications.placeholders.company")}

                         max={MAX_SHORT_TEXT_LENGTH}

                         maxWidth={MAX_WIDTH}/>

            <Limit maxWidth={MAX_WIDTH}>
                <Button onClick={() => setMfa(true)}
                        text={t("misc.actions.next")}
                        disabled={nextDisabled}
                        width="50%"/>
            </Limit>
        </>
    }

    // Util

    async function performMfa(
        messageTarget: string,
        messageTargetType: MessageTargetType,
    ): Promise<MfaResponse> {
        const [enFirstname, enLastname, enPatronymic] = name.en.trim().split(/\s+/).filter(Boolean)
        const [ruFirstname, ruLastname, ruPatronymic] = name.ru.trim().split(/\s+/).filter(Boolean)

        const request: InviteApplicationRequest = {
            ...messageTargetToObject(messageTarget, messageTargetType),

            enCompany: company.en,
            ruCompany: company.ru,

            enFirstname, enLastname, enPatronymic,
            ruFirstname, ruLastname, ruPatronymic,
        }

        return createInviteApplication(request as InviteApplicationRequest)
    }
}
