import { z } from "zod"

export const NullableNamedResponseSchema = z.object({
    enFirstname: z.string().nullish(),
    enLastname: z.string().nullish(),
    enPatronymic: z.string().nullish(),

    ruFirstname: z.string().nullish(),
    ruLastname: z.string().nullish(),
    ruPatronymic: z.string().nullish(),
})

export type NullableNamedResponse = z.infer<typeof NullableNamedResponseSchema>
