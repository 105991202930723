export function getOrComputeMapValue<K, V>(
    map: Map<K, V>,
    key: K,
    compute: (key: K) => V,
): V {
    let value = map.get(key)

    if (value == null)
        map.set(key, value = compute(key))

    return value
}

export function mergedMaps<K, V>(...maps: ReadonlyMap<K, V>[]): Map<K, V> {
    const merged = new Map<K, V>()

    for (const map of maps)
        for (const [key, value] of map.entries())
            merged.set(key, value)

    return merged
}

export function mapWithSet<K, V>(map: ReadonlyMap<K, V>, key: K, value: V): Map<K, V> {
    const withSet = new Map(map)

    withSet.set(key, value)

    return withSet
}

export function mapWithAllSet<K, V>(map: ReadonlyMap<K, V>, ...entries: [K, V][]): Map<K, V> {
    const withSet = new Map(map)

    for (const [key, value] of entries)
        withSet.set(key, value)

    return withSet
}

export function mapWitDeleted<K, V>(map: ReadonlyMap<K, V>, ...keys: K[]): Map<K, V> {
    const withDeleted = new Map(map)

    for (const key of keys)
        withDeleted.delete(key)

    return withDeleted
}
