import { ForwardedRef, forwardRef, ReactNode } from "react"
import { DeepReadonly } from "my-util"

export namespace Required {
    export interface Props {
        children?: ReactNode
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Required = forwardRef((
    { children }: DeepReadonly<Required.Props>,
    ref: ForwardedRef<HTMLSpanElement>,
) =>
    <span ref={ref}>
        {children}

        <span style={{ color: "#E53E3E" }}>
            *
        </span>
    </span>
)

Required.displayName = "Required"
