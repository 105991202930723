import { useTranslation } from "react-i18next"
import { ErrorPage } from "../ErrorPage"

export namespace Error403Page {
    export interface Props {
        message?: string
    }
}

export function Error403Page ({ message }: Readonly<Error403Page.Props>) {
    const [t] = useTranslation()

    return <ErrorPage
        header={{
            text: t("errors.pages.403.header"),
        }}

        message={{
            text: message ?? t("errors.pages.403.defaultMessage"),
        }}
    />
}
