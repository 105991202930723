import { DeepReadonly, Immutable, createOrCopyDate, tryNormalizeNullableUuid,
         Copyable, collapseWhiteSpaceToNull, IsImmutable, ReadonlyDate, Nullish } from "my-util"

import { Money } from "./Money"

export namespace CurrencyRate {
    export interface OptionsBase extends Nullish<{
        moment: Date
        evidenceId: string
        evidenceLink: string
    }> {}

    export interface CreationOptions extends OptionsBase, Nullish<{
        money: Money | Money.CreationOptions
    }> {}

    export interface CopyOptions extends OptionsBase, Nullish<{
        money: Money | Money.CopyOptions
    }> {}
}

export class CurrencyRate
    extends
        Copyable<CurrencyRate.CopyOptions>

    implements
        Immutable
{
    static createOrPass(
        arg?: CurrencyRate
            | DeepReadonly<CurrencyRate.CreationOptions>
            | null,
    ): CurrencyRate {
        return arg instanceof CurrencyRate
            ? arg
            : new CurrencyRate(arg ?? {})
    }

    // Fields

    readonly [IsImmutable] = true

    readonly moment: ReadonlyDate
    readonly money: Money
    readonly evidenceId: string | null
    readonly evidenceLink: string | null

    // Constructor

    constructor(options: DeepReadonly<CurrencyRate.CreationOptions> = {}) {
        super()

        this.moment = createOrCopyDate(options.moment)
        this.money = Money.createOrPass(options.money)
        this.evidenceId = tryNormalizeNullableUuid(options.evidenceId)
        this.evidenceLink = collapseWhiteSpaceToNull(options.evidenceLink ?? "")
    }

    // Copy

    protected override createCopy(
        options: DeepReadonly<CurrencyRate.CopyOptions> = {},
    ): CurrencyRate {
        return new CurrencyRate({
            moment: options.moment ?? this.moment,
            money: this.money.copyOrPass(options.money),

            evidenceId: "evidenceId" in options
                ? options.evidenceId
                : this.evidenceId,

            evidenceLink: "evidenceLink" in options
                ? options.evidenceLink
                : this.evidenceLink,
        })
    }
}
