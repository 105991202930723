import countryCodes, { CountryProperty } from "country-codes-list"
import { ForwardedRef, forwardRef } from "react"
import { Select } from "ui/ui/input"

const OPTIONS = Object.fromEntries(
    Object.entries(
        countryCodes.customList(
            "countryCallingCode" as CountryProperty,
            "{flag} {countryNameLocal} ({countryNameEn}) +{countryCallingCode}",
        )
    ).map(([code, text]) => [code, [text, `+${code}`]])
) as { [key: string]: [string, string] }

export namespace PhoneCodeSelect {
    export interface Props {
        onSelect?: (code: number) => void
        selected?: number

        label?: string
        information?: string

        disabled?: boolean
        loading?: boolean
        readonly?: boolean

        autoFocus?: boolean
        width?: string
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PhoneCodeSelect = forwardRef((
    {
        onSelect, selected,
        label, information,
        disabled, loading, readonly,
        autoFocus, width,
    }: Readonly<PhoneCodeSelect.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    return <Select options={OPTIONS}
                   onSelect={onInnerSelect}
                   selected={String(selected ?? 7)}
                   label={label}
                   information={information}
                   disabled={disabled}
                   readonly={readonly}
                   loading={loading}
                   autoFocus={autoFocus}
                   width={width}
                   ref={ref}/>

    function onInnerSelect(code: string) {
        return onSelect?.(Number(code))
    }
})

PhoneCodeSelect.displayName = "PhoneCodeSelect"
