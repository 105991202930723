import { get } from "api/http/util"
import { CompanyResponse, CompanyResponseSchema } from "api/responses"
import { joinSubpaths } from "my-util"

export const COMPANIES_SUBPATH = "/companies"

export async function getAllCompanies(signal?: AbortSignal | null): Promise<CompanyResponse[]> {
    return get({
        subpath: COMPANIES_SUBPATH,
        schema: CompanyResponseSchema.array(),
        signal,
    })
}

export async function getCompanyByItn(
    itn: string,
    signal?: AbortSignal | null,
): Promise<CompanyResponse> {
    return get({
        subpath: joinSubpaths([COMPANIES_SUBPATH, itn]),
        schema: CompanyResponseSchema,
        signal,
    })
}
