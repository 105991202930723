import { allMandatoryNamedFieldsPresent, MandatoryNamedFields, NamedFields } from "model/interfaces"
import { NamedMixin } from "model/mixins"

import { mixin, Copyable, capitalized, collapseWhiteSpace,
         collapseWhiteSpaceToNull, Immutable, Nullish, IsImmutable } from "my-util"

export namespace AnonymousUser {
    export interface CreationOptions extends MandatoryNamedFields {}

    export interface CopyOptions extends Nullish<NamedFields> {}
}

class AnonymousUserBase
    extends
        Copyable<AnonymousUser.CopyOptions>

    implements
        NamedFields,
        Immutable
{
    // Fields

    readonly [IsImmutable] = true

    // - En

    readonly enFirstname: string
    readonly enLastname: string
    readonly enPatronymic: string | null

    // - Ru

    readonly ruFirstname: string
    readonly ruLastname: string
    readonly ruPatronymic: string | null

    // Constructor

    constructor(options: Readonly<AnonymousUser.CreationOptions>) {
        super()

        this.enFirstname = collapseWhiteSpace(capitalized(options.enFirstname))
        this.enLastname = collapseWhiteSpace(capitalized(options.enLastname))
        this.enPatronymic = collapseWhiteSpaceToNull(capitalized(options.enPatronymic ?? ""))

        this.ruFirstname = collapseWhiteSpace(capitalized(options.ruFirstname))
        this.ruLastname = collapseWhiteSpace(capitalized(options.ruLastname))
        this.ruPatronymic = collapseWhiteSpaceToNull(capitalized(options.ruPatronymic ?? ""))
    }

    // Copy

    protected override createCopy(
        options: Readonly<AnonymousUser.CopyOptions> = {}
    ): AnonymousUserBase {
        return new AnonymousUserBase({
            // En

            enFirstname: options.enFirstname ?? this.enFirstname,
            enLastname: options.enLastname ?? this.enLastname,

            enPatronymic: "enPatronymic" in options
                ? options.enPatronymic
                : this.enPatronymic,

            // Ru

            ruFirstname: options.ruFirstname ?? this.ruFirstname,
            ruLastname: options.ruLastname ?? this.ruLastname,

            ruPatronymic: "ruPatronymic" in options
                ? options.ruPatronymic
                : this.ruPatronymic,
        })
    }
}

export class AnonymousUser extends mixin(AnonymousUserBase, [NamedMixin]) {
    // Create

    static createOrPass(
        arg: AnonymousUser | Readonly<AnonymousUser.CreationOptions>,
    ): AnonymousUser {
        return arg instanceof AnonymousUser
            ? arg
            : new AnonymousUser(arg)
    }

    // Copy

    static copyOrPass(
        object?: AnonymousUser | null,
        arg?: AnonymousUser | Readonly<AnonymousUser.CopyOptions> | null,
    ): AnonymousUser | AnonymousUser.CreationOptions | null {
        if (object != null)
            return object.copyOrPass(arg)

        if (arg != null && allMandatoryNamedFieldsPresent(arg))
            return arg

        return null
    }
}
