import { ForwardedRef, forwardRef, Fragment, ReactNode, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { trashCanIconUrl } from "images"
import { Invite, User } from "model"
import { dateToDateString, DeepReadonly, map } from "my-util"
import { useRerender } from "ui/hook"
import * as InvitePage from "ui/page/sections/clients/invites/InvitePage/path"
import { UserLink } from "ui/component/user"
import { Button, Flex, MessageTarget, Timer, UserRoleAbbr } from "ui/ui"
import style from "./style.module.css"

export namespace InviteTable {
    export interface Props {
        onExpired?: (invite: Invite) => void
        onDelete?: (invite: Invite) => void

        invites: Iterable<Invite>
        users?: Iterable<User> | Map<string, User>

        loading?: boolean
        disabled?: boolean

        width?: string
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InviteTable = forwardRef((
    {
        onExpired, onDelete,
        invites, users,
        loading, disabled,
        width,
    }: DeepReadonly<InviteTable.Props>,
    ref: ForwardedRef<HTMLTableElement>,
) => {
    const [t] = useTranslation()

    const navigate = useNavigate()
    const rerender = useRerender()

    // State

    const innerUsersById = useMemo(
        () => User.groupByIdOrPassOrCreate(users),
        [users],
    )

    // Render

    return <div className={style.InviteTable}
                style={{ width }}>
        {map(invites, renderInvite)}
    </div>

    function renderInvite(invite: Invite, index: number = 0): ReactNode {
        const BUTTON_SIZE = "16px"

        const baseGridRow = 4 * index + 1
        const onClick = () => onInviteClick(invite)

        return <Fragment key={invite.id}>
            {index > 0 &&
                <div style={{ gridRow: baseGridRow - 1 }}
                     className={style.gap}/>
            }

            <div className={invite.isExpired ? style.expiredBackground : style.background}
                 style={{ gridRow: `${baseGridRow}/${baseGridRow + 3}` }}
                 onClick={onClick}/>

            <div style={{ gridRow: baseGridRow }}
                 className={style.date}
                 onClick={onClick}>
                {dateToDateString(invite.createdAt)}
            </div>

            <div style={{ gridRow: baseGridRow }}
                 className={style.company}
                 onClick={onClick}>
                {invite.anyCompany ?? t("domain.companies.messages.notSet")}
            </div>

            <div style={{ gridRow: baseGridRow }}
                 className={style.messageTarget}>
                <MessageTarget messageTarget={invite.messageTarget}/>
            </div>

            <div style={{ gridRow: baseGridRow + 1 }}
                 className={style.name}
                 onClick={onClick}>
                {invite.anyName ?? t("domain.invites.messages.nameNotSet")}
            </div>

            <div style={{ gridRow: baseGridRow }}
                 className={style.buttons}>
                <Flex direction="row"
                      justify="end"
                      gap="8px">
                    {onDelete != null &&
                        <Button onClick={() => onDelete?.(invite)}

                                iconSrc={trashCanIconUrl}
                                iconAlt="Trash can icon"

                                loading={loading}
                                disabled={disabled}

                                buttonStyle="text"

                                fontSize="inherit"

                                width={BUTTON_SIZE}
                                height={BUTTON_SIZE}

                                critical/>
                    }
                </Flex>
            </div>

            <div style={{ gridRow: baseGridRow + 2 }}
                 className={style.creator}>
                {(() => {
                    const creator = invite.creatorId != null
                        ? innerUsersById.get(invite.creatorId)
                        : undefined

                    if (creator == null)
                        return null

                    return <Flex direction="row"
                                 gap="8px">
                        <div className={style.role}>
                            <UserRoleAbbr role={creator.role}
                                          colorful/>
                        </div>

                        <UserLink user={creator}/>
                    </Flex>
                })()}
            </div>

            <div style={{ gridRow: baseGridRow + 2 }}
                 className={style.expiresIn}>
                {(() => {
                    if (invite.isExpired)
                        return <span className={style.expired}>
                            {t("domain.invites.statuses.expired")}
                        </span>

                    return <>
                        {t("datetime.labels.expiresIn") + ": "}

                        <span className={style.timer}>
                            <Timer onExpired={() => onInviteExpired(invite)}
                                   expiresAt={invite.expiresAt}/>
                        </span>
                    </>
                })()}
            </div>
        </Fragment>
    }

    // Events

    function onInviteClick(invite: Invite) {
        if (!invite.isExpired)
            navigate(InvitePage.createPath(invite.id))
    }

    function onInviteExpired(invite: Invite) {
        rerender()
        onExpired?.(invite)
    }
})

InviteTable.displayName = "InviteTable"
