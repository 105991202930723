import { DeepReadonly, Nullish, tryNormalizeNullableUuid } from "my-util"
import { AbstractNotification } from "./AbstractNotification"

import { NotificationType,
         NewTransferNotificationType,
         NEW_TRANSFER_NOTIFICATION_TYPE } from "./NotificationType"

export namespace NewTransferNotification {
    export interface OptionsBase extends AbstractNotification.OptionsBase, Nullish<{
        transferId: string
    }> {}

    export interface CreationOptions
        extends
            Omit<AbstractNotification.CreationOptions<NotificationType>, "type">,
            OptionsBase
    {}

    export interface CopyOptions
        extends
            Omit<AbstractNotification.CopyOptions, "type">,
            Nullish<OptionsBase>
    {}
}

export class NewTransferNotification extends AbstractNotification<
    NewTransferNotificationType,
    NewTransferNotification.CopyOptions
> {
    static createOrPass(
        arg?: NewTransferNotification
            | DeepReadonly<NewTransferNotification.CreationOptions>
            | null
    ): NewTransferNotification {
        return arg instanceof NewTransferNotification
            ? arg
            : new NewTransferNotification(arg ?? {})
    }

    // Fields

    readonly transferId: string | null

    // Constructor

    constructor(options: DeepReadonly<NewTransferNotification.CreationOptions> = {}) {
        super({ ...options, type: NEW_TRANSFER_NOTIFICATION_TYPE })

        this.transferId = tryNormalizeNullableUuid(options.transferId)
    }

    // Copy

    protected override createCopy(
        options: DeepReadonly<NewTransferNotification.CopyOptions> = {},
    ): NewTransferNotification {
        return new NewTransferNotification({
            // Recipient ID

            recipientId: "recipientId" in options
                ? options.recipientId
                : this.recipientId,

            // Content

            transferId: "transferId" in options
                ? options.transferId
                : this.transferId,

            // Basic

            id: options.id ?? this.id,
            createdAt: options.createdAt ?? this.createdAt,
            modifiedAt: options.modifiedAt ?? this.modifiedAt,
        })
    }
}
