import { post } from "api/http/util"
import { joinSubpaths } from "my-util"
import { AUTH_SUBPATH } from "./subpath"

export const LOGOUT_SUBPATH = joinSubpaths([AUTH_SUBPATH, "logout"])

export async function logout(signal?: AbortSignal | null) {
    return post({
        subpath: LOGOUT_SUBPATH,
        signal,
    })
}
