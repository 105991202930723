import { z } from "zod"
import { BankResponseSchema } from "./bank"

export const CompanyResponseSchema = z.object({
    itn: z.string().nullish(),

    ruCeo: z.string().nullish(),
    enCeo: z.string().nullish(),

    ruName: z.string().nullish(),
    enName: z.string().nullish(),

    ruAddress: z.string().nullish(),
    enAddress: z.string().nullish(),

    correspondentAccount: z.string().nullish(),
    paymentAccount: z.string().nullish(),

    bank: BankResponseSchema,
})

export type CompanyResponse = z.infer<typeof CompanyResponseSchema>
