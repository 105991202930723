import { ForwardedRef, forwardRef } from "react"
import { getNullableUserSpecializationName, UserSpecialization } from "model"
import { Output } from "ui/ui/output"
import { DeepReadonly } from "my-util"

export namespace UserSpecializationOutput {
    export interface Props extends Omit<Output.Props, "children"> {
        specialization?: UserSpecialization | null
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserSpecializationOutput = forwardRef((
    props: DeepReadonly<UserSpecializationOutput.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) =>
    <Output { ...props as UserSpecializationOutput.Props }
            ref={ref}>
        {getNullableUserSpecializationName(props.specialization)}
    </Output>
)

UserSpecializationOutput.displayName = "UserSpecializationOutput"
