import { t } from "i18next"

// Types

export type RoutePointType =
    | EarthRoutePointType
    | AirRoutePointType
    | WaterRoutePointType

export type EarthRoutePointType = typeof EARTH_ROUTE_POINT_TYPE
export type AirRoutePointType = typeof AIR_ROUTE_POINT_TYPE
export type WaterRoutePointType = typeof WATER_ROUTE_POINT_TYPE

// Consts

export const EARTH_ROUTE_POINT_TYPE = "earth"
export const AIR_ROUTE_POINT_TYPE = "air"
export const WATER_ROUTE_POINT_TYPE = "water"

// Name

export function getRoutePointTypeName(type: RoutePointType): string {
    return t(`domain.routePoints.types.${type}`)
}

// Is-check

export function isRoutePointType(s: string): s is RoutePointType {
    switch (s) {
        case EARTH_ROUTE_POINT_TYPE:
        case AIR_ROUTE_POINT_TYPE:
        case WATER_ROUTE_POINT_TYPE:
            s satisfies RoutePointType
            return true

        default:
            return false
    }
}
