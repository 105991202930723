import { useContext } from "react"
import { UserContext } from "ui/context"
import { Private } from "./Private"
import { Public } from "./Public"

export function Component() {
    const [localUser] = useContext(UserContext)

    return localUser != null
        ? <Private/>
        : <Public/>
}
