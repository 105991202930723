import { Component } from "./Component"

import { PathCreationOptions as LocalPathCreationOptions, createPath,

         Scope as LocalScope, BASIC_INFO_SCOPE, LEGAL_INFO_SCOPE,
         NOTIFICATIONS_SCOPE, PASSWORD_SCOPE, RIGHTS_SCOPE, isScope,

         USER_ID_PARAM, SCOPE_SEARCH_PARAM, PATH } from "./path"

export namespace UserPage {
    export interface PathCreationOptions extends LocalPathCreationOptions {}

    export type Scope = LocalScope
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserPage = Object.assign(Component, {
    createPath,
    BASIC_INFO_SCOPE, LEGAL_INFO_SCOPE, NOTIFICATIONS_SCOPE, PASSWORD_SCOPE, RIGHTS_SCOPE, isScope,
    USER_ID_PARAM, SCOPE_SEARCH_PARAM, PATH,
} as const)
