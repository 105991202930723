import { ForwardedRef, forwardRef, ReactNode, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { countInviteApplications, countMyUnreadChatMessages } from "api"
import * as InviteApplicationsPage from "ui/page/sections/clients/invites/applications/AllInviteApplicationsPage/path"
import * as MessengerPage from "ui/page/sections/messenger/MessengerPage/path"
import { Clickable, CountDataRow, ErrorDisplay, Flex, Loading } from "ui/ui"
import { Widget } from "../Widget"
import { useStateWithDeps } from "ui/hook"

export namespace MessagesWidget {
    export interface Props {
        showUnreadMessageCount?: boolean
        showInviteApplicationCount?: boolean

        maxHeaderWidth?: string
        maxContentWidth?: string

        headerWidth?: string
        width?: string
        height?: string

        colorful?: boolean
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MessagesWidget = forwardRef((
    {
        showUnreadMessageCount, showInviteApplicationCount,
        maxHeaderWidth, maxContentWidth,
        headerWidth, width, height,
        colorful,
    }: Readonly<MessagesWidget.Props>,
    ref: ForwardedRef<Widget.Ref>,
) => {
    const [t] = useTranslation()

    const navigate = useNavigate()

    // State

    // - Unread messages

    const [unreadMessageCount, setUnreadMessageCount] = useState(0)

    const [loadingUnreadMessageCount, setLoadingUnreadMessageCount] = useStateWithDeps(
        () => showUnreadMessageCount ?? false,
        [showUnreadMessageCount],
    )

    const [unreadMessageCountLoadingError, setUnreadMessageCountLoadingError] = useState<unknown>(undefined)

    // - Invite applications

    const [inviteApplicationCount, setInviteApplicationCount] = useState(0)

    const [loadingInviteApplicationCount, setLoadingInviteApplicationCount] = useStateWithDeps(
        () => showInviteApplicationCount ?? false,
        [showInviteApplicationCount],
    )

    const [inviteApplicationCountLoadingError, setInviteApplicationCountLoadingError] = useState<unknown>(undefined)

    // - Common

    const loading = loadingUnreadMessageCount
    const error = unreadMessageCountLoadingError ?? inviteApplicationCountLoadingError

    // Effects

    // - Unread message counting

    useEffect(
        () => {
            if (!loadingUnreadMessageCount)
                return

            const controller = new AbortController()

            countMyUnreadChatMessages(controller.signal)
                .then(setUnreadMessageCount)
                .catch(error => {
                    if (!controller.signal.aborted)
                        setUnreadMessageCountLoadingError(error)
                })
                .finally(() => {
                    if (!controller.signal.aborted)
                        setLoadingUnreadMessageCount(false)
                })

            return () => controller.abort()
        },

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [loadingUnreadMessageCount],
    )

    // - Invite application counting

    useEffect(
        () => {
            if (!loadingInviteApplicationCount)
                return

            const controller = new AbortController()

            countInviteApplications(controller.signal)
                .then(setInviteApplicationCount)
                .catch(error => {
                    if (!controller.signal.aborted)
                        setInviteApplicationCountLoadingError(error)
                })
                .finally(() => {
                    if (!controller.signal.aborted)
                        setLoadingInviteApplicationCount(false)
                })

            return () => controller.abort()
        },

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [loadingInviteApplicationCount],
    )

    // Render

    const [contentBackgroundColor, contentBorderColor] = colorful
        ? ["#ABD6CF", "#FFE2D7"]
        : ["white", "#D5D5D5"]

    return <Widget onHeaderClick={navigateToMessengerPage}
                   header={t("widgets.messages.header") + ":"}

                   headerBackgroundColor="#8ACED6"
                   headerBorderColor="rgba(0, 0, 0, 0)"

                   contentBackgroundColor={contentBackgroundColor}
                   contentBorderColor={contentBorderColor}

                   maxContentWidth={maxContentWidth}
                   headerWidth={headerWidth}
                   height={height}
                   width={width}

                   ref={ref}>
        {renderContent()}
    </Widget>

    function renderContent(): ReactNode {
        if (loading)
            return <Loading centerType="flex"/>

        if (error != null)
            return <ErrorDisplay centerType="flex"
                                 error={error}/>

        return <Flex>
            {showUnreadMessageCount &&
                <Clickable onClick={navigateToMessengerPage}
                           cursor="pointer"
                           width="100%">
                    <CountDataRow label={t("widgets.messages.labels.new")}
                                  value={unreadMessageCount}
                                  importance="medium"
                                  underline/>
                </Clickable>
            }

            {showInviteApplicationCount &&
                <Clickable onClick={navigateToInviteApplicationsPage}
                           cursor="pointer"
                           width="100%">
                    <CountDataRow label={t("widgets.messages.labels.inviteApplications")}
                                  value={inviteApplicationCount}
                                  importance="high"
                                  underline/>
                </Clickable>
            }
        </Flex>
    }

    // Util

    function navigateToMessengerPage() {
        navigate(MessengerPage.PATH)
    }

    function navigateToInviteApplicationsPage() {
        navigate(InviteApplicationsPage.PATH)
    }
})

MessagesWidget.displayName = "MessagesWidget"
