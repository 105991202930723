import { ForwardedRef, forwardRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Duration } from "my-util"
import { useStateWithDeps } from "ui/hook"
import { Input } from "ui/ui/input"
import { Flex } from "ui/ui/layout"
import { Information, Label } from "ui/ui/output"

const DURATION_PART_REGEX = /^\d{0,2}$/g

export namespace DurationInput {
    export interface Props extends Duration.CreationOptions {
        onChange?: (duration: Duration) => void

        label?: string
        information?: string

        hideDays?: boolean
        hideHours?: boolean
        hideMinutes?: boolean
        showSeconds?: boolean

        invalid?: boolean
        disabled?: boolean
        loading?: boolean
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DurationInput = forwardRef((
    props: Readonly<DurationInput.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const [t] = useTranslation()

    // State

    const {
        onChange,
        label, information,
        hideDays, hideHours, hideMinutes, showSeconds,
        invalid, disabled, loading
    } = props

    const [daysFocused, setDaysFocused] = useState(false)
    const [hoursFocused, setHoursFocused] = useState(false)
    const [minutesFocused, setMinutesFocused] = useState(false)
    const [secondsFocused, setSecondsFocused] = useState(false)

    const focused =
        daysFocused ||
        hoursFocused ||
        minutesFocused ||
        secondsFocused

    const [duration, setDuration] = useStateWithDeps<Duration>(
        oldDuration =>
            focused && oldDuration != null
                ? oldDuration
                : new Duration(props).normalized().truncated(),

        [props],
    )

    // Render

    return <Flex width="fit-content"
                 align="start"
                 gap="4px"
                 ref={ref}>
        <Label text={label}/>

        <Flex width="fit-content"
              direction="row"
              gap="8px"
              ref={ref}>
            {/* Days */}

            {!hideDays && <>
                <Input width="40px"
                       loading={loading}
                       disabled={disabled}
                       regex={DURATION_PART_REGEX}
                       value={getDaysValue()}
                       onChange={onDaysChange}
                       onFocus={onDaysFocus}
                       onBlur={onDaysBlur}/>

                <span>{t("datetime.duration.days.short")}</span>
            </>}

            {/* Hours */}

            {!hideHours && <>
                <Input width="40px"
                       loading={loading}
                       disabled={disabled}
                       regex={DURATION_PART_REGEX}
                       value={getHoursValue()}
                       onChange={onHoursChange}
                       onFocus={onHoursFocus}
                       onBlur={onHoursBlur}/>

                <span>{t("datetime.duration.hours.short")}</span>
            </>}

            {/* Minutes */}

            {!hideMinutes && <>
                <Input width="40px"
                       loading={loading}
                       disabled={disabled}
                       regex={DURATION_PART_REGEX}
                       value={getMinutesValue()}
                       onChange={onMinutesChange}
                       onFocus={onMinutesFocus}
                       onBlur={onMinutesBlur}/>

                <span>{t("datetime.duration.minutes.short")}</span>
            </>}

            {/* Seconds */}

            {showSeconds && <>
                <Input width="40px"
                       loading={loading}
                       disabled={disabled}
                       regex={DURATION_PART_REGEX}
                       value={getSecondsValue()}
                       onChange={onSecondsChange}
                       onFocus={onSecondsFocus}
                       onBlur={onSecondsBlur}/>

                <span>{t("datetime.duration.seconds.short")}</span>
            </>}
        </Flex>

        <Information type={invalid ? "error" : "regular"}
                     text={information}/>
    </Flex>

    // Events

    function onDaysChange(text: string) {
        updateDuration(duration.copy({
            days: Number(text),
        }))
    }

    function onHoursChange(text: string) {
        updateDuration(duration.copy({
            hours: Number(text),
        }))
    }

    function onMinutesChange(text: string) {
        updateDuration(duration.copy({
            minutes: Number(text),
        }))
    }

    function onSecondsChange(text: string) {
        updateDuration(duration.copy({
            seconds: Number(text),
        }))
    }

    // - Focus

    function onDaysFocus() {
        setDaysFocused(true)
    }

    function onHoursFocus() {
        setHoursFocused(true)
    }

    function onMinutesFocus() {
        setMinutesFocused(true)
    }

    function onSecondsFocus() {
        setSecondsFocused(true)
    }

    // - Blur

    function onDaysBlur(): string | undefined {
        setDaysFocused(false)
        onBlur()
        return
    }

    function onHoursBlur(): string | undefined {
        setHoursFocused(false)
        onBlur()
        return
    }

    function onMinutesBlur(): string | undefined {
        setMinutesFocused(false)
        onBlur()
        return
    }

    function onSecondsBlur(): string | undefined {
        setSecondsFocused(false)
        onBlur()
        return
    }

    function onBlur() {
        setDuration(duration.normalized().truncated())
    }

    // Util

    // - Part values

    function getDaysValue(): string | undefined {
        return daysFocused
            ? undefined
            : formatDays(String(duration.days))
    }

    function getHoursValue(): string | undefined {
        return hoursFocused
            ? undefined
            : padWithZeroes(String(duration.hours))
    }

    function getMinutesValue(): string | undefined {
        return minutesFocused
            ? undefined
            : padWithZeroes(String(duration.minutes))
    }

    function getSecondsValue(): string | undefined {
        return secondsFocused
            ? undefined
            : padWithZeroes(String(duration.seconds))
    }

    // - Format

    function formatDays(text: string): string {
        return text.length !== 0
            ? text
            : "0"
    }

    function padWithZeroes(text: string): string {
        switch (text.length) {
            case 0:
                return "00"

            case 1:
                return "0" + text

            default:
                return text
        }
    }

    // - Update

    function updateDuration(newDuration: Duration) {
        setDuration(newDuration)
        onChange?.(newDuration)
    }
})

DurationInput.displayName = "DurationInput"
