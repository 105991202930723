import { trimToNull } from "my-util"
import { ApiError } from "./ApiError"
import { ForbiddenApiError } from "./ForbiddenApiError"
import { NotFoundApiError } from "./NotFoundApiError"

export async function throwIfResponseNotOk(response: Response) {
    if (response.ok)
        return

    throw createApiError(
        response.status,
        response.statusText,
        await response.text(),
    )
}

export function createApiError(statusCode: number, statusText: string, text: string = ""): ApiError {
    let message = text.trim()

    if (message.length === 0)
        message = statusText.trim()

    if (message.length === 0)
        message = String(statusCode)

    const options: ApiError.CreationOptions = {
        statusText: trimToNull(statusText),
        statusCode,
        message,
    }

    switch (statusCode) {
        case ForbiddenApiError.STATUS_CODE:
            return new ForbiddenApiError(options)

        case NotFoundApiError.STATUS_CODE:
            return new NotFoundApiError(options)

        default:
            return new ApiError(options)
    }
}
