import { determineMessageTargetType, MessageTargetType } from "model/enums"
import { WithOneMessageTarget, WithOneMessageTargetFields } from "model/interfaces"
import { Constructor } from "my-util"

export function WithOneMessageTargetMixin<
    BaseConstructor extends Constructor<WithOneMessageTargetFields>
>(Base: BaseConstructor) {
    return class extends Base implements WithOneMessageTarget {
        private cachedMessageTargetType: MessageTargetType | null  = null

        get messageTargetType(): MessageTargetType {
            if (this.cachedMessageTargetType == null)
                this.cachedMessageTargetType = determineMessageTargetType(this.messageTarget)

            return this.cachedMessageTargetType
        }
    }
}
