import { CSSProperties, ForwardedRef, ReactNode } from "react"
import { forwardRefAndSetProperties, satAs } from "my-util"
import style from "./style.module.css"

export namespace Arrow {
    export interface Props {
        color?: string

        arrowStyle?: Style

        angle?: string

        width?: string
        height?: string
    }

    export type Style =
        | "stickless"
        | "with-stick"
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Arrow = forwardRefAndSetProperties(
    {
        DEFAULT_STYLE: satAs<Arrow.Style>("stickless"),
    } as const,

    (
        {
            color,
            arrowStyle,
            angle,
            width, height,
        }: Readonly<Arrow.Props>,

        ref: ForwardedRef<HTMLDivElement>,
    ) => {
        const innerArrowStyle = arrowStyle ?? Arrow.DEFAULT_STYLE

        switch (innerArrowStyle) {
            case "stickless":
                return renderStickless()

            case "with-stick":
                return renderWithStick()
        }

        function renderStickless(): ReactNode {
            return <div className={style.SticklessArrow}
                        style={renderStyle()}
                        ref={ref}/>

            function renderStyle(): CSSProperties {
                const halfHeight = height != null
                    ? `calc(.5 * ${height})`
                    : undefined

                return {
                    borderLeftColor: color,
                    borderLeftWidth: width,
                    borderTopWidth: halfHeight,
                    borderBottomWidth: halfHeight,

                    transform: renderTransform(),
                }
            }
        }

        function renderWithStick(): ReactNode {
            return <div className={style.ArrowWithStick}
                        style={renderStyle()}
                        ref={ref}>
                <div className={style.stick}
                     style={renderStickStyle()}/>

                <div className={style.SticklessArrow}
                     style={renderCapStyle()}/>
            </div>

            function renderStyle(): CSSProperties {
                return {
                    transform: renderTransform()
                }
            }

            function renderCapStyle(): CSSProperties {
                const halfHeight = height != null
                    ? `calc(.5 * ${height})`
                    : undefined

                return {
                    borderLeftColor: color,
                    borderLeftWidth: height,
                    borderTopWidth: halfHeight,
                    borderBottomWidth: halfHeight,
                }
            }

            function renderStickStyle(): CSSProperties {
                return {
                    backgroundColor: color,

                    width: width != null
                        ? `calc(${width} - ${height ?? 0})`
                        : undefined,

                    height: height != null
                        ? `calc(.5 * ${height})`
                        : undefined,
                }
            }
        }

        function renderTransform(): string | undefined {
            return angle != null
                ? `rotate(${angle})`
                : undefined
        }
    },
)

Arrow.displayName = "Arrow"
