import { DeepReadonly, Nullish, tryNormalizeNullableUuid } from "my-util"
import { AbstractNotification } from "./AbstractNotification"

import { NotificationType,
         NewInviteNotificationType,
         NEW_INVITE_NOTIFICATION_TYPE } from "./NotificationType"

export namespace NewInviteNotification {
    export interface OptionsBase extends AbstractNotification.OptionsBase, Nullish<{
        inviteId: string
    }> {}

    export interface CreationOptions
        extends
            Omit<AbstractNotification.CreationOptions<NotificationType>, "type">,
            OptionsBase
    {}

    export interface CopyOptions
        extends
            Omit<AbstractNotification.CopyOptions, "type">,
            Nullish<OptionsBase>
    {}
}

export class NewInviteNotification extends AbstractNotification<
    NewInviteNotificationType,
    NewInviteNotification.CopyOptions
> {
    static createOrPass(
        arg?: NewInviteNotification
            | DeepReadonly<NewInviteNotification.CreationOptions>
            | null
    ): NewInviteNotification {
        return arg instanceof NewInviteNotification
            ? arg
            : new NewInviteNotification(arg ?? {})
    }

    // Fields

    readonly inviteId: string | null

    // Constructor

    constructor(options: DeepReadonly<NewInviteNotification.CreationOptions> = {}) {
        super({ ...options, type: NEW_INVITE_NOTIFICATION_TYPE })

        this.inviteId = tryNormalizeNullableUuid(options.inviteId)
    }

    // Copy

    protected override createCopy(
        options: DeepReadonly<NewInviteNotification.CopyOptions> = {},
    ): NewInviteNotification {
        return new NewInviteNotification({
            // Recipient ID

            recipientId: "recipientId" in options
                ? options.recipientId
                : this.recipientId,

            // Content

            inviteId: "inviteId" in options
                ? options.inviteId
                : this.inviteId,

            // Basic

            id: options.id ?? this.id,
            createdAt: options.createdAt ?? this.createdAt,
            modifiedAt: options.modifiedAt ?? this.modifiedAt,
        })
    }
}
