import { i18next } from "./i18next"

export type Lang =
    | "ru"
    | "en"

export function getLang(): Lang {
    return i18next.language.match(/ru/gi) != null
        ? "ru"
        : "en"
}

export function setLang(lang: Lang) {
    i18next.changeLanguage(lang)
}
