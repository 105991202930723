import { WithCreatorId } from "model/interfaces"
import { DeepReadonly, Nullable, tryNormalizeNullableUuid } from "my-util"
import { ChatMessageBase } from "./ChatMessageBase"

export namespace ChatMessage {
    export interface OptionsBase {
        creatorId?: string | null
        recipientId?: string | null
    }

    export interface CreationOptions
        extends
            ChatMessageBase.CreationOptions,
            OptionsBase
    {}

    export interface CopyOptions
        extends
            ChatMessageBase.CopyOptions,
            OptionsBase
    {}
}

export class ChatMessage
    extends
        ChatMessageBase<ChatMessage.CopyOptions>

    implements
        Readonly<Nullable<WithCreatorId>>
{
    static createOrPass(arg: ChatMessage | DeepReadonly<ChatMessage.CreationOptions>): ChatMessage {
        return arg instanceof ChatMessage
            ? arg
            : new ChatMessage(arg)
    }

    // Fields

    readonly creatorId: string | null
    readonly recipientId: string | null

    // Constructor

    constructor(options: DeepReadonly<ChatMessage.CreationOptions>) {
        super(options)

        this.creatorId = tryNormalizeNullableUuid(options.creatorId)
        this.recipientId = tryNormalizeNullableUuid(options.recipientId)
    }

    // Copy

    protected override createCopy(options: DeepReadonly<ChatMessage.CopyOptions> = {}): ChatMessage {
        return new ChatMessage({
            // IDs

            creatorId: "creatorId" in options
                ? options.creatorId
                : this.creatorId,

            recipientId: "recipientId" in options
                ? options.recipientId
                : this.recipientId,

            // Payload

            text: "text" in options
                ? options.text
                : this.text,

            documentIds: options.documentIds ?? this.documentIds,

            // Meta

            readByIds: options.readByIds ?? this.readByIds,
            edited: options.edited ?? this.edited,

            // Basic

            id: options.id ?? this.id,
            createdAt: options.createdAt ?? this.createdAt,
            modifiedAt: options.modifiedAt ?? this.modifiedAt,
        })
    }
}
