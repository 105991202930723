import { getOrComputeMapValue } from "my-util/map/func"
import { Comparator } from "my-util/type"

// Join

export function join<T>(iterable: Iterable<T>, sep: string = ""): string {
    return [...iterable].join(sep)
}

// Unique

export function unique<T>(
    iterable: Iterable<T>,
    getProperty: (item: T, index: number) => unknown = item => item,
): T[] {
    return [
        ...new Map(
            map(iterable, (item, index) => [
                getProperty(item, index),
                item,
            ]),
        ).values(),
    ]
}

// Map

export function map<T, R>(
    iterable: Iterable<T>,
    map: (value: T, i: number, iterable: Iterable<T>) => R,
): Array<R> {
    const result = new Array<R>()

    let i = 0

    for (const value of iterable) {
        const mappedValue = map(value, i++, iterable)
        result.push(mappedValue)
    }

    return result
}

// Group

export function group<T, P>(
    iterable: Iterable<T>,
    getProperty: (item: T, index: number) => P,
): Map<P, T[]> {
    const groups = new Map<P, T[]>()

    let i = 0

    for (const item of iterable) {
        const property = getProperty(item, i++)
        const group = getOrComputeMapValue(groups, property, () => [])

        group.push(item)
    }

    return groups
}

// Filter

export function filter<T>(iterable: Iterable<T>, predicate: (item: T) => boolean): T[] {
    const result: T[] = []

    for (const item of iterable)
        if (predicate(item))
            result.push(item)

    return result
}

// Includes

export function includes<T>(iterable: Iterable<T>, value: T): boolean {
    for (const item of iterable)
        if (item === value)
            return true

    return false
}

// Some/Every

export function some<T>(iterable: Iterable<T>, predicate: (item: T) => boolean): boolean {
    for (const item of iterable)
        if (predicate(item))
            return true

    return false
}

export function every<T>(iterable: Iterable<T>, predicate: (item: T) => boolean): boolean {
    for (const item of iterable)
        if (!predicate(item))
            return false

    return true
}

// Find

// - Exact

export function find<T>(iterable: Iterable<T>, predicate: (item: T) => boolean): T | undefined {
    for (const item of iterable)
        if (predicate(item))
            return item

    return undefined
}

// - Min

export function findMin<T>(iterable: Iterable<T>, cmp: Comparator<T>): T | undefined {
    let min: T | undefined = undefined

    for (const item of iterable)
        if (min == null || cmp(min, item) > 0)
            min = item

    return min
}

// - Max

export function findMax<T>(iterable: Iterable<T>, cmp: Comparator<T>): T | undefined {
    let max: T | undefined = undefined

    for (const item of iterable)
        if (max == null || cmp(max, item) < 0)
            max = item

    return max
}

// Count

export function count<T>(iterable: Iterable<T>, predicate: (item: T) => boolean): number {
    let count = 0

    for (const item of iterable)
        if (predicate(item))
            ++count

    return count
}
