import { ForwardedRef } from "react"
import { arrowUpIconUrl } from "images"
import { forwardRefAndSetProperties } from "my-util"
import { Icon } from "../Icon"

export namespace ExportIcon {
    export interface Props extends Omit<Icon.Props, "src" | "alt" | "filter"> {}
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExportIcon = forwardRefAndSetProperties(
    {
        SRC: arrowUpIconUrl as string,
        FILTER: "brightness(0) saturate(100%) invert(33%) sepia(92%) saturate(1574%) hue-rotate(203deg) brightness(87%) contrast(82%)" as string,
        ALT: "Export icon" as string,
    } as const,

    (
        props: Readonly<ExportIcon.Props>,
        ref: ForwardedRef<HTMLImageElement>,
    ) =>
        <Icon { ...props }

              src={ExportIcon.SRC}
              filter={ExportIcon.FILTER}
              alt={ExportIcon.ALT}

              ref={ref}/>
)

ExportIcon.displayName = "ExportIcon"
