import i18next from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import Backend from "i18next-http-backend"
import { initReactI18next } from "react-i18next"
import { DateTime } from "luxon"

const DEFAULT_LANGUAGE = "ru"
const FALLBACK_LANGUAGE = "ru"

i18next
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        returnEmptyString: false,
        lng: DEFAULT_LANGUAGE,
        fallbackLng: FALLBACK_LANGUAGE,
    });

i18next.services.formatter?.add("DATE_SHORT", (value: Date, language) => {
    return DateTime
        .fromJSDate(value)
        .setLocale(language ?? FALLBACK_LANGUAGE)
        .toFormat("d MMMM yyyy")
})

export { i18next }
