import { z } from "zod"
import { TRANSFER_STATUS_CHANGED_NOTIFICATION_TYPE } from "model"
import { UuidSchema } from "my-util"
import { TransferStatusResponseSchema } from "../domain"
import { NotificationResponseBaseSchema } from "./base"
import { NotificationTypeResponse } from "./type"

export const TransferStatusChangedNotificationResponseSchema = NotificationResponseBaseSchema.extend({
    type: z.literal(TRANSFER_STATUS_CHANGED_NOTIFICATION_TYPE satisfies NotificationTypeResponse),

    transferId: UuidSchema.nullish(),
    from: TransferStatusResponseSchema,
    to: TransferStatusResponseSchema,
})

export type TransferStatusChangedNotificationResponse = z.infer<typeof TransferStatusChangedNotificationResponseSchema>
