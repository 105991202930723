import stringHash from "string-hash"
import uniqolor from "uniqolor"

export function getStringColor(s: string, opacity?: number | null): string {
    const hash = stringHash(s)

    let color = uniqolor(hash, { format: "rgb" }).color

    color = color.replace("rgb", "rgba")
    color = color.replace(")", `, ${opacity ?? 1})`)

    return color
}
