import { arrayOfNonNull, DeepReadonly, Nullish, tryNormalizeNullableUuid } from "my-util"
import { AbstractNotification } from "./AbstractNotification"

import { NotificationType,
         NewUserNotificationType,
         NEW_USER_NOTIFICATION_TYPE } from "./NotificationType"

export namespace NewUserNotification {
    export interface OptionsBase extends AbstractNotification.OptionsBase, Nullish<{
        userId: string
    }> {}

    export interface CreationOptions
        extends
            Omit<AbstractNotification.CreationOptions<NotificationType>, "type">,
            OptionsBase
    {}

    export interface CopyOptions
        extends
            Omit<AbstractNotification.CopyOptions, "type">,
            Nullish<OptionsBase>
    {}
}

export class NewUserNotification extends AbstractNotification<
    NewUserNotificationType,
    NewUserNotification.CopyOptions
> {
    static createOrPass(
        arg?: NewUserNotification
            | DeepReadonly<NewUserNotification.CreationOptions>
            | null,
    ): NewUserNotification {
        return arg instanceof NewUserNotification
            ? arg
            : new NewUserNotification(arg ?? {})
    }

    // Fields

    readonly userId: string | null

    // Constructor

    constructor(options: DeepReadonly<NewUserNotification.CreationOptions> = {}) {
        super({ ...options, type: NEW_USER_NOTIFICATION_TYPE })

        this.userId = tryNormalizeNullableUuid(options.userId)
    }

    // User IDs

    override get userIds(): string[] {
        return arrayOfNonNull(this.recipientId, this.userId)
    }

    // Copy

    protected override createCopy(
        options: DeepReadonly<NewUserNotification.CopyOptions> = {},
    ): NewUserNotification {
        return new NewUserNotification({
            // Recipient ID

            recipientId: "recipientId" in options
                ? options.recipientId
                : this.recipientId,

            // Content

            userId: "userId" in options
                ? options.userId
                : this.userId,

            // Basic

            id: options.id ?? this.id,
            createdAt: options.createdAt ?? this.createdAt,
            modifiedAt: options.modifiedAt ?? this.modifiedAt,
        })
    }
}
