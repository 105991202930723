import getSymbolFromCurrency from "currency-symbol-map"
import Decimal from "decimal.js"

import { Copyable, Immutable, IsImmutable,
         formatDecimal, createOrPassDecimal, Nullish } from "my-util"

export namespace Money {
    export interface OptionsBase extends Nullish<{
        amount: Decimal.Value
        currency: string
    }> {}

    export interface CreationOptions extends OptionsBase {}

    export interface CopyOptions extends OptionsBase {}
}

export class Money
    extends
        Copyable<Money.CopyOptions>

    implements
        Immutable
{
    static createOrPass(arg?: Money | Readonly<Money.CreationOptions> | null): Money {
        return arg instanceof Money
            ? arg
            : new Money(arg ?? {})
    }

    // Fields

    readonly [IsImmutable] = true

    readonly amount: Decimal
    readonly currency: string

    // Constructor

    constructor({ amount, currency }: Readonly<Money.CreationOptions> = {}) {
        super()

        this.amount = createOrPassDecimal(amount)
        this.currency = currency ?? "USD"
    }

    // Copy

    protected override createCopy(options: Readonly<Money.CopyOptions> = {}): Money {
        return new Money({
            amount: options.amount ?? this.amount,
            currency: options.currency ?? this.currency,
        })
    }

    // To string

    toString(precision?: number): string {
        return getSymbolFromCurrency(this.currency)
            + " "
            + formatDecimal(this.amount, precision)
    }
}
