import { Nullish, ReadonlyDate, removeWhiteSpace,
         Copyable, DeepReadonly, Immutable, IsImmutable } from "my-util"

export namespace LegalDocument {
    export interface OptionsBase {
        number: string
        date: Date
        documentId?: string | null
    }

    export interface CreationOptions extends OptionsBase {}

    export interface CopyOptions extends Nullish<OptionsBase> {}
}

export class LegalDocument
    extends
        Copyable<LegalDocument.CopyOptions>

    implements
        Immutable
{
    static createOrPass(
        arg: LegalDocument | DeepReadonly<LegalDocument.CreationOptions>,
    ): LegalDocument {
            return arg instanceof LegalDocument
                ? arg
                : new LegalDocument(arg)
        }

    // Fields

    readonly [IsImmutable] = true

    readonly number: string
    readonly date: ReadonlyDate
    readonly documentId: string | null

    // Constructor

    constructor(options: DeepReadonly<LegalDocument.CreationOptions>) {
        super()

        this.number = removeWhiteSpace(options.number)
        this.date = new Date(options.date.getTime())
        this.documentId = options.documentId ?? null
    }

    // Copy

    protected override createCopy(
        options: DeepReadonly<LegalDocument.CopyOptions> = {}
    ): LegalDocument {
        return new LegalDocument({
            number: options.number ?? this.number,
            date: options.date ?? this.date,

            documentId: options.documentId !== undefined
                ? options.documentId
                : this.documentId,
        })
    }
}
