import { useTranslation } from "react-i18next"
import { ForwardedRef, forwardRef } from "react"
import { Invite, InviteApplication, Notification, Provider, Transfer, User } from "model"
import { DeepReadonly, map } from "my-util"
import { Button, Center, Flex } from "ui/ui"
import { NotificationCard } from "../NotificationCard"
import style from "./style.module.css"

export namespace NotificationList {
    export interface Props {
        onClear?: () => void
        onDelete?: (notification: Notification) => void
        onClick?: (Notification: Notification) => void

        notifications: Notification[]

        inviteApplications?: InviteApplication[] | Map<string, InviteApplication>
        invites?: Invite[] | Map<string, Invite>
        users?: User[] | Map<string, User>
        transfers?: Transfer[] | Map<string, Transfer>
        providers?: Provider[] | Map<string, Provider>

        width?: string
        height?: string

        loading?: boolean
        disabled?: boolean
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NotificationList = forwardRef((
    {
        onClear, onDelete, onClick,
        notifications,
        inviteApplications, invites, users, transfers, providers,
        width, height,
        loading, disabled,
    }: DeepReadonly<NotificationList.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const [t] = useTranslation()

    return <div className={style.NotificationList}
                style={{ width, height }}
                ref={ref}>
        <div className={style.header}>
            {t("notifications.headers.notifications")}
        </div>

        <div className={style.content}>
            {notifications.length > 0
                ? <Flex gap="8px">
                    {map(notifications, notification =>
                        <NotificationCard notification={notification}
                                          inviteApplications={inviteApplications}
                                          invites={invites}
                                          users={users}
                                          transfers={transfers}
                                          providers={providers}

                                          onDelete={onDelete}
                                          onClick={onClick}

                                          key={notification.id}/>
                    )}
                </Flex>

                : <Center>
                    {t("notifications.messages.youHaveNoNew")}
                </Center>
            }
        </div>

        {notifications.length > 0 &&
            <div className={style.footer}>
                <Button onClick={onClear}

                        text={t("misc.actions.clear")}

                        buttonStyle="text"
                        fontSize="12px"
                        height="24px"

                        loading={loading}
                        disabled={disabled}/>
            </div>
        }
    </div>
})

NotificationList.displayName = "NotificationList"
