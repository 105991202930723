import { ForwardedRef, forwardRef } from "react"
import { useTranslation } from "react-i18next"
import style from "./style.module.css"

export namespace Count {
    export interface Props {
        value?: number
        important?: boolean
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Count = forwardRef((
    { value, important }: Readonly<Count.Props>,
    ref: ForwardedRef<HTMLSpanElement>,
) => {
    const [t] = useTranslation()

    const zero = value === 0

    return <span className={important && !zero ? style.importantValue : style.value}
                 ref={ref}>
        {zero
            ? t("misc.words.zero")
            : value
        }
    </span>
})

Count.displayName = "Count"
