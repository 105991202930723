import { DependencyList, useEffect } from "react"
import { Nullish } from "my-util"

export namespace NClicksHook {
    export interface Options extends Nullish<{
        count: number
        delay: number
        depsOnCallback: boolean
        extraDeps: DependencyList
    }> {}
}

export function useNClicks(
    callback: () => void,
    { count, delay, depsOnCallback, extraDeps }: Readonly<NClicksHook.Options> = {},
) {
    const innerCount = count ?? 5
    const innerDelay = delay ?? 250

    useEffect(
        () => {
            let clicked = 0
            let clickedResetTimer: number | null = null

            window.addEventListener("click", handleClick)

            return () => window.removeEventListener("click", handleClick)

            function handleClick() {
                if (clickedResetTimer != null) {
                    window.clearTimeout(clickedResetTimer)
                    clickedResetTimer = null
                }

                if (++clicked >= innerCount) {
                    callback()
                    clicked = 0
                    return
                }

                clickedResetTimer = window.setTimeout(
                    () => {
                        clicked = 0
                        clickedResetTimer = null
                    },

                    innerDelay,
                )
            }
        },

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [innerCount, innerDelay, depsOnCallback ? callback : null, ...extraDeps ?? []],
    )
}
