import { ForwardedRef, forwardRef, useMemo } from "react"
import { sandClockIconUrl, tickIconUrl } from "images"
import { getLang } from "i18n"

import { DONE_TRANSFER_STATUS, NEW_TRANSFER_STATUS,
         PAID_TRANSFER_STATUS, PAYMENT_EXPIRED_TRANSFER_STATUS,
         REJECTED_TRANSFER_STATUS, TransferStatus, getTransferStatusName,
         WAITING_MOMENT_TRANSFER_STATUS, WAITING_PAYMENT_TRANSFER_STATUS } from "model"

import { DeepReadonly } from "my-util"
import { Flex } from "ui/ui/layout"
import { PhaseIndicator } from "ui/ui/output"

export namespace TransferPhaseIndicator {
    export interface Props {
        status: TransferStatus

        indicatorStyle?: Style
        mobile?: boolean

        size?: PhaseIndicator.PhaseSize
        sepLength?: string

        direction?: Flex.Direction

        label?: string
        labelFontSize?: string
    }

    export type Style =
        | "mini"
        | "full"
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransferPhaseIndicator = forwardRef((
    props: Readonly<TransferPhaseIndicator.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const {
        status,
        indicatorStyle,
        size, sepLength,
    } = props

    // State

    const miniStyle = useMemo<PhaseIndicator.PhaseStateStyles>(() => ({
        fallback: {
            backgroundColor: "#97C776",
            borderColor: "#97C776",
            color: "white",
            fontSize: "16px",
            size,
        },

        future: {
            backgroundColor: "#F84235",
            borderColor: "#F84235",
        },
    }), [size])

    const fullStyle = useMemo<PhaseIndicator.PhaseStateStyles>(() => ({
        fallback: {
            fontSize: "16px",
            size,
        },

        current: {
            fontSize: "18px",
        },
    }), [size])

    const phases = useMemo<DeepReadonly<PhaseIndicator.Phase[]>>(
        () => indicatorStyle === "mini"
            ? [
                {
                    label: getTransferStatusName(NEW_TRANSFER_STATUS),
                    icon: renderMiniIcon,
                    style: miniStyle,
                    text: "",
                },

                {
                    label: getTransferStatusName(WAITING_MOMENT_TRANSFER_STATUS),
                    icon: renderMiniIcon,
                    style: miniStyle,
                    text: "",
                },

                {
                    label: getTransferStatusName(WAITING_PAYMENT_TRANSFER_STATUS),
                    icon: renderMiniIcon,
                    style: miniStyle,
                    text: "",
                },

                {
                    label: getTransferStatusName(PAID_TRANSFER_STATUS),
                    icon: renderMiniIcon,
                    style: miniStyle,
                    text: "",
                },

                {
                    label: getTransferStatusName(DONE_TRANSFER_STATUS),
                    icon: renderMiniIcon,
                    style: miniStyle,
                    text: "",
                },
            ]

            : [
                {
                    text: getTransferStatusName(NEW_TRANSFER_STATUS),
                    style: fullStyle,
                },

                {
                    text: getTransferStatusName(WAITING_MOMENT_TRANSFER_STATUS),
                    style: fullStyle,
                },

                {
                    text: getTransferStatusName(WAITING_PAYMENT_TRANSFER_STATUS),
                    style: fullStyle,
                },

                {
                    text: getTransferStatusName(PAID_TRANSFER_STATUS),
                    style: fullStyle,
                },

                {
                    text: getTransferStatusName(DONE_TRANSFER_STATUS),
                    style: fullStyle,
                },
            ],

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [fullStyle, indicatorStyle, miniStyle, getLang()],
    )

    // Render

    return <PhaseIndicator { ...props }
                           phases={phases}
                           current={getCurrent()}
                           sepLength={sepLength}
                           ref={ref}/>

    function renderMiniIcon(type: PhaseIndicator.PhaseType): PhaseIndicator.PhaseIcon {
        return type === "future"
            ? {
                src: sandClockIconUrl,
                alt: "Sand clock icon",
            }

            : {
                src: tickIconUrl,
                alt: "Tick icon",
            }
    }

    // Util

    function getCurrent(): number {
        switch (status) {
            case NEW_TRANSFER_STATUS:
                return 0

            case WAITING_MOMENT_TRANSFER_STATUS:
                return 1

            case WAITING_PAYMENT_TRANSFER_STATUS:
                return 2

            case PAID_TRANSFER_STATUS:
                return 3

            case DONE_TRANSFER_STATUS:
                return 4

            case PAYMENT_EXPIRED_TRANSFER_STATUS:
            case REJECTED_TRANSFER_STATUS:
                return -1
        }
    }
})

TransferPhaseIndicator.displayName = "TransferPhaseIndicator"
