import { ForwardedRef, forwardRef, useContext, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { patchTransferById } from "api"
import { Transfer } from "model"
import { DeepReadonly } from "my-util"
import { useStateWithDeps } from "ui/hook"
import { UserContext } from "ui/context"

import { TransferFields,
         copySignableDocumentFields,
         signableDocumentFieldsToRequest } from "ui/fields"

import { SignableDocumentListEditor } from "ui/component/document"
import { ActionModal } from "ui/ui"

export namespace TransferSignableDocumentsEditingModal {
    export interface Props {
        onClose?: () => void
        onSaved?: (transfer: Transfer) => void
        closeOnSuccess?: boolean

        fields?: TransferFields

        noDocumentDelete?: boolean

        width?: string
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransferSignableDocumentsEditingModal = forwardRef((
    {
        onClose, onSaved, closeOnSuccess,
        fields,
        noDocumentDelete,
        width,
    }: DeepReadonly<TransferSignableDocumentsEditingModal.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const [t] = useTranslation()

    const [localUser] = useContext(UserContext)

    const localUserHasRightToManageTransfers = useMemo(
        () => localUser?.hasRightToManageTransfers ?? false,
        [localUser],
    )

    // State

    const [signableDocuments, setSignableDocuments] = useStateWithDeps(
        () => fields?.signableDocuments?.map(copySignableDocumentFields) ?? [],
        [fields?.signableDocuments],
    )

    const [loading, setLoading] = useState(false)

    // Render

    console.log(signableDocuments)

    return <ActionModal onYes={onInnerSave}
                        onNo={onClose}
                        closeOnSuccess={closeOnSuccess}

                        header={t("domain.transfers.headers.signableDocumentsEditing")}
                        yes={t("misc.actions.save")}
                        no={t("misc.actions.cancel")}

                        width={width}

                        ref={ref}>
        <SignableDocumentListEditor onChange={setSignableDocuments}
                                    values={signableDocuments}

                                    loading={loading}

                                    readonlyCount={!localUserHasRightToManageTransfers}
                                    readonlyInitial={!localUserHasRightToManageTransfers}
                                    readonlyLocked={!localUserHasRightToManageTransfers}
                                    readonlyIfLocked={!localUserHasRightToManageTransfers}

                                    noDocumentDelete={noDocumentDelete}/>
    </ActionModal>

    // Events

    async function onInnerSave() {
        const id = fields?.id

        if (id == null)
            return

        setLoading(true)

        try {
            const transfer = await patchTransferById(id, [{
                op: "replace",
                path: "/signableDocuments",
                value: signableDocuments.map(signableDocumentFieldsToRequest) as any, // ???
            }])

            onSaved?.(transfer)
        } finally {
            setLoading(false)
        }
    }
})

TransferSignableDocumentsEditingModal.displayName = "TransferSignableDocumentsEditingModal"
