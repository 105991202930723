import { t } from "i18next"

// Types

export type UserSpecialization =
    | LawyerUserSpecialization
    | AccountantUserSpecialization

export type LawyerUserSpecialization = typeof LAWYER_USER_SPECIALIZATION
export type AccountantUserSpecialization = typeof ACCOUNTANT_USER_SPECIALIZATION

// Consts

export const LAWYER_USER_SPECIALIZATION = "lawyer"
export const ACCOUNTANT_USER_SPECIALIZATION = "accountant"

// Name

export function getNullableUserSpecializationName(specialization?: UserSpecialization | null): string {
    return specialization != null
        ? getUserSpecializationName(specialization)
        : t("misc.words.no")
}

export function getUserSpecializationName(specialization: UserSpecialization): string {
    return t(`domain.users.specializations.${specialization}`)
}

// Is-check

export function isUserSpecialization(s: string): s is UserSpecialization {
    switch (s) {
        case LAWYER_USER_SPECIALIZATION:
        case ACCOUNTANT_USER_SPECIALIZATION:
            s satisfies UserSpecialization
            return true

        default:
            return false
    }
}
