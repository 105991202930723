import Decimal from "decimal.js"
import { ForwardedRef, forwardRef, useMemo } from "react"
import { formatDecimal } from "my-util"
import { Output } from "ui/ui/output"

export namespace DecimalOutput {
    export interface Props extends Omit<Output.Props, "children"> {
        value: Decimal.Value
        precision?: number
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DecimalOutput = forwardRef((
    props: Readonly<DecimalOutput.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const value = useMemo(
        () => formatDecimal(new Decimal(props.value), props.precision),
        [props.precision, props.value],
    )

    return <Output whiteSpace="nowrap"
                   { ...props }
                   ref={ref}>
        {value}
    </Output>
})

DecimalOutput.displayName = "DecimalOutput"
