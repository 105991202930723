import { ForwardedRef, forwardRef } from "react"

import { determineMessageTargetType,
         EMAIL_MESSAGE_TARGET_TYPE,
         PHONE_MESSAGE_TARGET_TYPE } from "model"

import { Phone } from "../phone"
import { Email } from "../email"

export namespace MessageTarget {
    export interface Props
        extends
            Omit<Phone.Props, "phone">,
            Omit<Email.Props, "email">
    {
        messageTarget: string
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MessageTarget = forwardRef((
    props: Readonly<MessageTarget.Props>,
    ref: ForwardedRef<HTMLElement>,
) => {
    const { messageTarget } = props

    switch (determineMessageTargetType(messageTarget)) {
        case PHONE_MESSAGE_TARGET_TYPE:
            return <Phone { ...props }
                          phone={messageTarget}
                          ref={ref}/>

        case EMAIL_MESSAGE_TARGET_TYPE:
            return <Email { ...props }
                          email={messageTarget}
                          ref={ref}/>
    }
})

MessageTarget.displayName = "MessageTarget"
