import { joinSubpaths } from "my-util"
import * as AllTransfersPage from "../AllTransfersPage/path"

export function createPath(transferId: string): string {
    return PATH.replace(`:${TRANSFER_ID_PARAM}`, transferId)
}

export const TRANSFER_ID_PARAM = "transferId"

export const PATH = joinSubpaths([AllTransfersPage.PATH, `/:${TRANSFER_ID_PARAM}`])
