import { ForwardedRef, forwardRef } from "react"
import { useTranslation } from "react-i18next"
import { EXPORT_TRANSFER_DIRECTION, IMPORT_TRANSFER_DIRECTION, TransferDirection } from "model"
import { ImportIcon, ExportIcon } from "ui/ui/icon"
import { Radio } from "ui/ui/input"

export namespace TransferDirectionRadio {
    export interface Props {
        onChange?: (type: TransferDirection) => void
        checked?: TransferDirection

        readonly?: boolean
        loading?: boolean
        disabled?: boolean

        name?: string

        importHidden?: boolean
        importDisabled?: boolean

        exportHidden?: boolean
        exportDisabled?: boolean

        hideButton?: boolean

        width?: string
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransferDirectionRadio = forwardRef((
    {
        onChange, checked,
        readonly, loading, disabled,
        name,
        importHidden, importDisabled,
        exportHidden, exportDisabled,
        hideButton,
        width,
    }: Readonly<TransferDirectionRadio.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const [t] = useTranslation()

    return <Radio
        onChange={onChange as (value: string) => void}
        checked={checked ?? EXPORT_TRANSFER_DIRECTION}

        readonly={readonly}
        loading={loading}
        disabled={disabled}

        name={name}

        hideButton={hideButton}

        width={width}

        items={{
            [IMPORT_TRANSFER_DIRECTION]: {
                hidden: importHidden,
                disabled: importDisabled,

                label: t("domain.transfers.directions.import"),

                iconSrc: ImportIcon.SRC,
                iconAlt: ImportIcon.ALT,
                iconFilter: ImportIcon.FILTER,
            },

            [EXPORT_TRANSFER_DIRECTION]: {
                hidden: exportHidden,
                disabled: exportDisabled,

                label: t("domain.transfers.directions.export"),

                iconSrc: ExportIcon.SRC,
                iconAlt: ExportIcon.ALT,
                iconFilter: ExportIcon.FILTER,
            },
        }}

        ref={ref}
    />
})

TransferDirectionRadio.displayName = "TransferDirectionRadio"
