import { ForwardedRef, forwardRef } from "react"
import { DeepReadonly } from "my-util"
import { None } from "ui/ui/layout"
import { ErrorText } from "../ErrorText"
import style from "./style.module.css"

export namespace ErrorBlock {
    export interface Props extends ErrorText.Props {
        width?: string
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ErrorBlock = forwardRef((
    props: DeepReadonly<ErrorBlock.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) =>
    props.error
        ? <div style={{ width: props.width }}
               className={style.ErrorBlock}
               ref={ref}>
            <ErrorText {...props}/>
        </div>

        : <None ref={ref}/>
)

ErrorBlock.displayName = "ErrorBlock"
