import { z } from "zod"

export const BankResponseSchema = z.object({
    itn: z.string().nullish(),

    ruName: z.string().nullish(),
    enName: z.string().nullish(),

    ruAddress: z.string().nullish(),
    enAddress: z.string().nullish(),

    swift: z.string().nullish(),
    bic: z.string().nullish(),
})

export type BankResponse = z.infer<typeof BankResponseSchema>
