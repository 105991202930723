export namespace ApiError {
    export interface CreationOptions {
        statusCode: number
        statusText?: string | null
        message?: string | null
        cause?: unknown
    }
}

export class ApiError extends Error {
    readonly statusCode: number
    readonly statusText: string | null

    constructor(options: Readonly<ApiError.CreationOptions>) {
        super(options.message ?? undefined, { cause: options.cause })

        this.statusCode = options.statusCode
        this.statusText = options.statusText ?? null
    }
}
