import assert from "assert"
import { ReactNode, useContext, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Navigate, useNavigate } from "react-router-dom"
import { createTransfer, getTransfersConfig, TransfersConfigResponse } from "api"
import { TransferFields, transferFieldsToTransferRequest, transferToTransferFields } from "ui/fields"
import { UserContext } from "ui/context"
import { SessionExpiredErrorPage } from "ui/page/error"
import * as UserPage from "ui/page/sections/users/UserPage/path"
import { TransferFillInForm, Page, TransferViewForm } from "ui/component"
import { Loading, Pane, ErrorDisplay, PhaseIndicator, Padding, Center, Flex, Link } from "ui/ui"
import * as TransferPage from "../TransferPage/path"

type State =
    | "missing-legal-info"
    | "session-expired"

    | "loading-config"
    | "config-loading-failed"

    | "filling-in"
    | "verifying"

    | "creating"
    | "created"

export function Component() {
    const [t] = useTranslation()

    const [localUser, setLocalUser] = useContext(UserContext)

    const navigate = useNavigate()

    // State

    const [state, setState] = useState<State>(() => {
        if (localUser == null)
            return "session-expired"

        if (!localUser.isManager && localUser.company.anyName == null)
            return "missing-legal-info"

        return "loading-config"
    })

    const [config, setConfig] = useState(undefined as TransfersConfigResponse | undefined)
    const [error, setError] = useState(undefined as unknown)

    // Refs

    const fieldsRef = useRef(undefined as TransferFields | undefined)

    // Effects

    // - Config loading

    useEffect(() => {
        if (state !== "loading-config")
            return

        const controller = new AbortController()

        getTransfersConfig(controller.signal)
            .then(config => {
                setConfig(config)
                setState("filling-in")
            })
            .catch(error => {
                if (controller.signal.aborted)
                    return

                setError(error)
                setState("config-loading-failed")
            })

        return () => controller.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state])

    // - Creation

    useEffect(() => {
        if (state !== "creating")
            return

        const fields = fieldsRef.current

        if (fields == null) {
            setState("filling-in")
            setError("fieldsRef.current == null")
            return
        }

        const request = transferFieldsToTransferRequest(fields)
        const controller = new AbortController()

        createTransfer(request, controller.signal)
            .then(transfer => {
                fieldsRef.current = transferToTransferFields(transfer)

                if (localUser != null)
                    setLocalUser(localUser.copy({
                        nextTransferNumber: localUser.nextTransferNumber + 1,
                    }))

                setState("created")
            })
            .catch(error => {
                if (controller.signal.aborted)
                    return

                setError(error)
                setState("verifying")
            })

        return () => controller.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state])

    // Render

    if (state === "session-expired")
        return <SessionExpiredErrorPage/>

    return <Page title={t("sections.transfers.new.header").toUpperCase()}
                 type="main">
        {renderContent()}
    </Page>

    function renderContent() {
        assert(state !== "session-expired")

        if (state === "missing-legal-info") {
            const path = UserPage.createPath(localUser!.id, { scope: UserPage.LEGAL_INFO_SCOPE })

            return <Center>
                <Flex>
                    {t("domain.users.messages.fillInLegalInfoToCreateTransfers")}

                    <Link text={t("domain.users.actions.editLegalInfo")}
                          to={path}/>
                </Flex>
            </Center>
        }

        if (state === "loading-config")
            return <Loading/>

        if (state === "config-loading-failed")
            return <ErrorDisplay error={error}/>

        if (state === "created")
            return <Navigate to={TransferPage.createPath(fieldsRef.current!.id!)}
                             replace/>

        return renderPane()
    }

    function renderPane(): ReactNode {
        return <Pane>
            {renderPaddedPhaseIndicator()}
            {renderForm()}
        </Pane>
    }

    function renderPaddedPhaseIndicator(): ReactNode {
        return <Padding paddingBottom="16px">
            {renderPhaseIndicator()}
        </Padding>
    }

    function renderPhaseIndicator(): ReactNode {
        assert(
            state !== "session-expired" &&
            state !== "missing-legal-info" &&
            state !== "loading-config" &&
            state !== "config-loading-failed" &&
            state !== "created"
        )

        let current: number
        let label: string
        let labelFontSize: string | undefined

        switch (state) {
            case "filling-in":
                current = 0
                label = t("misc.messages.correctlyFillInRequired")
                labelFontSize = undefined
                break

            case "verifying":
            case "creating":
                current = 1
                label = t("sections.transfers.new.messages.checkFilled")
                labelFontSize = "14px"
                break

            default:
                return state satisfies never
        }

        return <PhaseIndicator phases={[{}, {}]}
                               current={current}
                               label={label}
                               labelFontSize={labelFontSize}/>
    }

    function renderForm(): ReactNode {
        assert(
            state !== "loading-config" &&
            state !== "config-loading-failed" &&
            state !== "created"
        )

        switch (state) {
            case "filling-in":
                assert(config != null)

                return <TransferFillInForm
                    fields={fieldsRef.current}

                    onSubmit={newFields => {
                        fieldsRef.current = newFields
                        setState("verifying")
                    }}

                    exportDisabled={!config.isExportEnabled}
                    importDisabled={!config.isImportEnabled}

                    buttons={[
                        {
                            onClick() { navigate(-1) },
                            text: t("misc.actions.back"),
                            buttonStyle: "outline",
                            position: "right",
                        },
                        {
                            text: t("misc.actions.save"),
                            type: "submit",
                            position: "left",
                        }
                    ]}
                />

            case "verifying":
            case "creating":
                assert(fieldsRef.current != null)

                const creating = state === "creating"

                return <TransferViewForm
                    fields={fieldsRef.current}
                    error={error}
                    buttons={[
                        {
                            text: t("misc.actions.back"),
                            buttonStyle: "outline",
                            position: "right",
                            onClick() { setState("filling-in") },
                            disabled: creating,
                        },

                        {
                            text: t("misc.actions.save"),
                            position: "left",
                            onClick() { setState("creating") },
                            loading: creating,
                        }
                    ]}
                />
        }
    }
}
