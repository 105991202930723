import { joinSubpaths, Nullish, createPath as createAnyPath, arrayOfNonNull } from "my-util"
import * as MainPage from "ui/page/sections/main/MainPage/path"

// Creation

export interface PathCreationOptions extends Nullish<{
    scope: Scope
}> {}

export function createPath(userId: string, options: Readonly<PathCreationOptions> = {}): string {
    return createAnyPath({
        subpaths: [PATH.replace(`:${USER_ID_PARAM}`, userId)],
        encodeSubpaths: false,

        params: arrayOfNonNull(
            options.scope != null
                ? [SCOPE_SEARCH_PARAM, options.scope]
                : null,
        ),
    })
}

// Scope

export function isScope(s: unknown): s is Scope {
    switch (s) {
        case BASIC_INFO_SCOPE:
        case LEGAL_INFO_SCOPE:
        case NOTIFICATIONS_SCOPE:
        case PASSWORD_SCOPE:
        case RIGHTS_SCOPE:
            s satisfies Scope
            return true

        default:
            return false
    }
}

export type Scope =
    | typeof BASIC_INFO_SCOPE
    | typeof LEGAL_INFO_SCOPE
    | typeof NOTIFICATIONS_SCOPE
    | typeof PASSWORD_SCOPE
    | typeof RIGHTS_SCOPE

export const BASIC_INFO_SCOPE = "basic-info"
export const LEGAL_INFO_SCOPE = "legal-info"
export const NOTIFICATIONS_SCOPE = "notifications-info"
export const PASSWORD_SCOPE = "password"
export const RIGHTS_SCOPE = "rights"

// Params

export const USER_ID_PARAM = "userId"

// Search params

export const SCOPE_SEARCH_PARAM = "scope"

// Path

export const PATH = joinSubpaths([MainPage.PATH, `/users/:${USER_ID_PARAM}`])
