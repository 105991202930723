import { ForwardedRef, forwardRef, ReactNode } from "react"
import { DeepReadonly } from "my-util"
import style from "./style.module.css"

export namespace Padding {
    export interface Props {
        padding?: string
        paddingLeft?: string
        paddingRight?: string
        paddingTop?: string
        paddingBottom?: string

        width?: string
        height?: string

        children?: ReactNode
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Padding = forwardRef((
    {
        padding, paddingLeft, paddingRight, paddingTop, paddingBottom,
        width, height,
        children,
    }: DeepReadonly<Padding.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) =>
    <div
        style={{
            paddingLeft: paddingLeft ?? padding,
            paddingRight: paddingRight ?? padding,
            paddingTop: paddingTop ?? padding,
            paddingBottom: paddingBottom ?? padding,

            width, height,
        }}
        className={style.Padding}
        ref={ref}
    >
        {children}
    </div>
)

Padding.displayName = "Padding"
