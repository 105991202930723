import { z } from "zod"
import { NEW_PROVIDER_NOTIFICATION_TYPE } from "model"
import { UuidSchema } from "my-util"
import { NotificationResponseBaseSchema } from "./base"
import { NotificationTypeResponse } from "./type"

export const NewProviderNotificationResponseSchema = NotificationResponseBaseSchema.extend({
    type: z.literal(NEW_PROVIDER_NOTIFICATION_TYPE satisfies NotificationTypeResponse),

    providerId: UuidSchema.nullish(),
})

export type NewProviderNotificationResponse = z.infer<typeof NewProviderNotificationResponseSchema>
