import { DependencyList, useLayoutEffect } from "react"
import { Nullish } from "my-util"

export namespace ResizeHook {
    export interface Options extends Nullish<{
        depsOnCallback: boolean
        extraDeps: DependencyList
    }> {}

    export type ElementArg =
        | (() => Element)
        | Element

    export type Element = HTMLElement | undefined | null
}

export function useResize(
    element: ResizeHook.ElementArg,
    callback: (element: HTMLElement) => void,
    { depsOnCallback, extraDeps }: Readonly<ResizeHook.Options> = {},
) {
    useLayoutEffect(
        () => {
            const innerElement = typeof element === "function"
                ? element()
                : element

            if (innerElement == null)
                return

            const observer = new ResizeObserver(() => callback(innerElement))

            observer.observe(innerElement)

            return () => observer.disconnect()
        },

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [element, depsOnCallback ? callback : null, ...extraDeps ?? []],
    )
}
