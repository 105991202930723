import { z } from "zod"
import { AIR_ROUTE_POINT_TYPE, EARTH_ROUTE_POINT_TYPE, WATER_ROUTE_POINT_TYPE } from "model"

export const RoutePointTypeResponseSchema = z.enum([
    AIR_ROUTE_POINT_TYPE,
    EARTH_ROUTE_POINT_TYPE,
    WATER_ROUTE_POINT_TYPE,
])

export type RoutePointTypeResponse = z.infer<typeof RoutePointTypeResponseSchema>
