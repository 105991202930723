import { useTranslation } from "react-i18next"
import { envelopeIconUrl } from "images"
import { Button, Flex } from "ui/ui"
import style from "./style.module.css"

export namespace Message {
    export interface Props {
        onCreateInviteApplication?: () => void
    }
}

export function Message(props: Readonly<Message.Props>) {
    const [t] = useTranslation()

    return <Flex>
        <span className={style.message}>
            {t("auth.reg.messages.errors.invalidAddress")}
        </span>

        <Button width="300px"
                fontSize="20px"
                onClick={props.onCreateInviteApplication}
                iconSrc={envelopeIconUrl}
                iconAlt="Envelope icon"/>
    </Flex>
}
