import { ForwardedRef, forwardRef, ReactNode } from "react"
import { DeepReadonly } from "my-util"
import style from "./style.module.css"

export namespace Table {
    export interface Props {
        children?: ReactNode

        width?: string
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Table = forwardRef((
    {
        children,
        width,
    }: DeepReadonly<Table.Props>,
    ref: ForwardedRef<HTMLTableElement>,
) =>
    <table className={style.Table}
           style={{ width }}
           ref={ref}>
        {children}
    </table>
)

Table.displayName = "Table"
