import { useEffect, useImperativeHandle, ReactNode,
         ForwardedRef, forwardRef, useRef, useState } from "react"

import { Dim } from "ui/ui/appearance"
import style from "./style.module.css"

export namespace Sidebar {
    export interface Props {
        onClose?: () => void
        open?: boolean

        children?: ReactNode
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Sidebar = forwardRef((
    {
        onClose, open,
        children,
    }: Readonly<Sidebar.Props>,
    ref: ForwardedRef<HTMLDivElement>
) => {
    // State

    const [visible, setVisible] = useState(false)

    // Refs

    const innerRef = useRef(null as HTMLDivElement | null)

    useImperativeHandle(ref, () => innerRef.current!, [])

    // Animation

    useEffect(() => {
        if (open) {
            setVisible(true)
            return
        }

        const sidebar = innerRef.current!
        const allAnimations = sidebar.getAnimations({ subtree: true })

        let finishedAnimations = 0

        for (const animation of allAnimations)
            animation.onfinish = onFinish

        function onFinish() {
            if (++finishedAnimations === allAnimations.length)
                setVisible(false)
        }
    }, [open])

    // Render

    const visibility = visible ? "visible" : "hidden"

    return <div className={style.Sidebar}
                style={{ visibility }}
                ref={innerRef}>
        <Dim opacity={open ? undefined : 0}
             onClick={onClose}/>

        <div className={renderContentClassName()}
             ref={ref}>
            {children}
        </div>
    </div>

    function renderContentClassName(): string {
        return [
            style.content,
            open ? style.openAnimation : style.closeAnimation,
        ].join(" ")
    }
})

Sidebar.displayName = "Sidebar"
