import { Component } from "./Component"
import { createPath, USER_ID_SEARCH_PARAM, PATH } from "./path"

import { UnsentMessage as LocalUnsentMessage,
         UnsentMessageToUiOptions as LocalUnsentMessageToUiOptions,
         LAST_USER_ID_STORAGE_KEY, getLastUserId, setLastUserId,
         UNSENT_MESSAGES_STORAGE_KEY, getUnsentMessages,
         pushUnsentMessage, deleteUnsentMessageById,
         setUnsentMessages,clearUnsentMessages, unsentMessageToUi } from "./storage"

export namespace MessengerPage {
    export interface UnsentMessage extends LocalUnsentMessage {}
    export interface UnsentMessageToUiOptions extends LocalUnsentMessageToUiOptions {}
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MessengerPage = Object.assign(Component, {
    createPath, USER_ID_SEARCH_PARAM, PATH,
    LAST_USER_ID_STORAGE_KEY, getLastUserId, setLastUserId,
    UNSENT_MESSAGES_STORAGE_KEY,  setUnsentMessages,
    pushUnsentMessage, getUnsentMessages, deleteUnsentMessageById,
    clearUnsentMessages, unsentMessageToUi,
})
