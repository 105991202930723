import { forwardRef, ForwardedRef } from "react"
import { InviteStatus, getInviteStatusColors, getInviteStatusName } from "model"
import { ColorLegend } from "ui/ui/output"

export namespace InviteStatusColorLegend {
    export interface Props {
        onClick?: (status: InviteStatus) => void
        status: InviteStatus

        fontSize?: string

        showLegend?: boolean
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InviteStatusColorLegend = forwardRef((
    {
        onClick, status,
        fontSize,
        showLegend,
    }: Readonly<InviteStatusColorLegend.Props>,
    ref: ForwardedRef<HTMLSpanElement>
) => {
    const onInnerClick = onClick != null
        ? () => onClick(status)
        : undefined

    const legend = showLegend
        ? getInviteStatusName(status)
        : undefined

    const { color } = getInviteStatusColors(status)

    return <ColorLegend onClick={onInnerClick}
                        fontSize={fontSize}
                        legend={legend}
                        color={color}
                        ref={ref}/>
});

InviteStatusColorLegend.displayName = "InviteStatusColorLegend"
