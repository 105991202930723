import { ForwardedRef, forwardRef, ReactNode, useMemo } from "react"
import { getLang } from "i18n"
import { Transfer, User } from "model"

import { getAllWeekDayNames, getWeekDayIndexes,
         HOUR_STRINGS, normalizeDayOrGetCurrent, WEEK_DAY_INDEXES,
         normalizeMonthIndexOrGetCurrent, normalizeYearOrGetCurrent } from "my-util"

import { Flex } from "ui/ui/layout"
import { SmallTransferCard } from "ui/ui/domain"
import style from "./style.module.css"

export namespace WeekCalendar {
    export interface Props {
        dayIndex?: number
        monthIndex?: number
        year?: number

        onTransferClick?: (transfer: Transfer) => void
        transfers?: Transfer[]

        users?: Map<string, User> | Iterable<User>

        width?: string
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WeekCalendar = forwardRef((
    {
        dayIndex, monthIndex, year,
        onTransferClick, transfers,
        users,
        width,
    }: Readonly<WeekCalendar.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    // State

    const now = useMemo(() => new Date(), [])

    const innerYear = useMemo(
        () => normalizeYearOrGetCurrent(year, now),
        [now, year],
    )

    const innerMonthIndex = useMemo(
        () => normalizeMonthIndexOrGetCurrent(monthIndex, now),
        [monthIndex, now],
    )

    const innerDayIndex = useMemo(
        () => normalizeDayOrGetCurrent(dayIndex, now),
        [dayIndex, now],
    )

    const dateWeekDayIndexes = useMemo(
        () => getWeekDayIndexes(innerYear, innerMonthIndex, innerDayIndex),
        [innerYear, innerMonthIndex, innerDayIndex],
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const weekDayNames = useMemo(() => getAllWeekDayNames(true), [getLang()])

    const innerTransfers = useMemo(() =>
        transfers?.filter(({ moment }) =>
            moment != null &&
            moment.getFullYear() === innerYear &&
            moment.getMonth() === innerMonthIndex &&
            dateWeekDayIndexes.includes(moment.getDate() - 1)
        ) ?? [],

        [dateWeekDayIndexes, innerMonthIndex, innerYear, transfers],
    )

    // Render

    return <div className={style.tableWrapper}
                style={{ width }}
                ref={ref}>
        {renderTable()}
    </div>

    function renderTable(): ReactNode {
        return <table className={style.table}>
            {renderTableHeader()}
            {renderTableBody()}
        </table>
    }

    function renderTableHeader(): ReactNode {
        return <thead>
            <tr>
                <td className={style.topLeft}/>

                {dateWeekDayIndexes.map((dayIndex, i) =>
                    <th className={style.weekDay}
                         key={dayIndex}>
                        <span className={style.weekDayName}>
                            {weekDayNames[i]}
                        </span>

                        {" "}

                        {dayIndex + 1}
                    </th>
                )}
            </tr>
        </thead>
    }

    function renderTableBody(): ReactNode {
        return <tbody>
            {HOUR_STRINGS.map((hourString, hourIndex) => {
                const hourTransfers = innerTransfers.filter(({ moment }) => {
                    const transferHour = moment!.getHours()

                    return transferHour >= hourIndex
                        && transferHour < hourIndex + 1
                })

                return <tr key={hourString}>
                    <th className={style.hour}>
                        {hourIndex !== HOUR_STRINGS.length - 1 && hourString}
                    </th>

                    {WEEK_DAY_INDEXES.map(weekDayIndex => {
                        const weekDayHourTransfers = hourTransfers.filter(({ moment }) =>
                            moment!.getDate() - 1 === dateWeekDayIndexes[weekDayIndex]
                        )

                        return <td className={style.transfersCell}
                                   key={weekDayIndex}>
                            <div className={style.transfers}>
                                <Flex gap="4px">
                                    {weekDayHourTransfers.map(transfer =>
                                        <SmallTransferCard onClick={onTransferClick}
                                                           transfer={transfer}
                                                           users={users}
                                                           key={transfer.id}/>
                                    )}
                                </Flex>
                            </div>
                        </td>
                    })}
                </tr>
            })}
        </tbody>
    }
})

WeekCalendar.displayName = "WeekCalendar"
