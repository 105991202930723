import { ForwardedRef, forwardRef } from "react"
import style from "./style.module.css"

export namespace LoadingIndicator {
    export interface Props {
        size?: string
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LoadingIndicator = forwardRef((
    { size }: Readonly<LoadingIndicator.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) =>
    <div className={style.LoadingIndicator}
         style={{ fontSize: size }}
         ref={ref}>
        <div/>
        <div/>
        <div/>
    </div>
)

LoadingIndicator.displayName = "LoadingIndicator"
