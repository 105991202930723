import { ForwardedRef, forwardRef, Fragment, useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { patchTransferById } from "api"
import { AGENT_USER_ROLE, CLIENT_USER_ROLE, TransferAgentPayment, User } from "model"
import { dateToDateTimeString, DeepReadonly, splicedArray } from "my-util"
import { useStateWithDeps } from "ui/hook"
import { UserLink } from "ui/component/user"
import { Button, Clickable, UserRoleAbbr } from "ui/ui"
import style from "./style.module.css"

export namespace TransferAgentPaymentTable {
    export interface Props {
        onPaymentClick?: (payment: TransferAgentPayment) => void
        onPaymentsChange?: (payments: TransferAgentPayment[]) => void
        payments?: Iterable<TransferAgentPayment>

        users?: Map<string, User> | Iterable<User>

        onError?: (error: unknown) => void

        showAgents?: boolean
        showButtons?: boolean

        width?: string
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransferAgentPaymentTable = forwardRef((
    {
        onPaymentClick, onPaymentsChange, payments,
        users,
        onError,
        showAgents, showButtons,
        width,
    }: DeepReadonly<TransferAgentPaymentTable.Props>,
    ref: ForwardedRef<HTMLTableElement>,
) => {
    const [t] = useTranslation()

    // Refs

    const changedRef = useRef(false)

    // State

    const [innerPayments, setInnerPayments] = useStateWithDeps(
        () => [...payments ?? []],
        [payments],
    )

    const [paymentsLoadingByIndex, setPaymentsLoadingByIndex] = useState(new Map<number, boolean>())

    const innerUsersById = useMemo(() => User.groupByIdOrPassOrCreate(users), [users])

    // Effects

    useEffect(() => {
        if (changedRef.current) {
            changedRef.current = false
            onPaymentsChange?.(innerPayments)
        }
    }, [onPaymentsChange, innerPayments])

    // Render

    return <table className={style.table}
                  width={width}
                  ref={ref}>
        <tbody>
            {innerPayments.map((payment, index) => {
                const key = payment.transferId ?? payment.agentId ?? payment.creatorId ?? index
                const onClick = () => onPaymentClick?.(payment)

                const showThisButtons = showButtons && payment.agentPayment != null

                const statusButton = payment.isPaid
                    ? {
                        text: t("domain.transferAgentPayments.labels.inWork"),
                        onClick() { onInWork(index) },
                    }

                    : {
                        text: t("domain.transferAgentPayments.labels.paid"),
                        onClick() { onPaid(index) },
                    }

                const creator = payment.creatorId != null
                    ? innerUsersById.get(payment.creatorId)
                    : undefined

                const agent = payment.agentId != null
                    ? innerUsersById.get(payment.agentId)
                    : undefined

                const status = payment.isPaid
                    ? {
                        text: t("domain.transferAgentPayments.labels.paid"),
                        className: style.payed,
                    }

                    : {
                        text: t("domain.transferAgentPayments.labels.inWork"),
                        className: style.inWork,
                    }

                return <Fragment key={key}>
                    <tr className={style.firstRow}
                        onClick={onClick}>
                        <td colSpan={2}>
                            {
                                creator?.company?.anyName != null
                                    ? <UserLink stopClickPropagation
                                                user={creator}
                                                showCompany/>

                                    : t("domain.companies.messages.notSet")
                            }
                        </td>

                        <td className={status.className}>
                            {status.text}
                        </td>

                        {showThisButtons &&
                            <td className={style.noBackground}>
                                <Clickable stopPropagation>
                                    <Button onClick={statusButton.onClick}
                                            text={statusButton.text}
                                            loading={paymentsLoadingByIndex.get(index)}
                                            buttonStyle="text"/>
                                </Clickable>
                            </td>
                        }
                    </tr>

                    <tr onClick={onClick}>
                        <td className={style.client + " " + style.bigText}>
                            <UserRoleAbbr role={CLIENT_USER_ROLE}
                                          colorful/>
                        </td>

                        <td className={style.client}>
                            {creator != null
                                ? <UserLink stopClickPropagation
                                            user={creator}/>

                                : "-"
                            }
                        </td>

                        <td>{payment.agentPayment?.toString() ?? ""}</td>

                        {showThisButtons &&
                            <td/>
                        }
                    </tr>

                    <tr onClick={onClick}>
                        {showAgents
                            ? <>
                                <td className={style.agent + " " + style.bigText}>
                                    <UserRoleAbbr role={AGENT_USER_ROLE}
                                                colorful/>
                                </td>

                                <td className={style.agent}>
                                    {agent != null
                                        ? <UserLink stopClickPropagation
                                                    user={agent}/>
                                        : "-"
                                    }
                                </td>
                            </>

                            : <td className={style.client}
                                  colSpan={2}/>
                        }

                        <td>
                            {payment.agentPayedAt != null
                                ? dateToDateTimeString(payment.agentPayedAt)
                                : ""
                            }
                        </td>

                        {showThisButtons &&
                            <td/>
                        }
                    </tr>

                    <tr className={style.gap}/>
                </Fragment>
            })}
        </tbody>
    </table>

    // Events

    async function onPaid(index: number) {
        onStatusChange(index, async () => {
            const payment = innerPayments[index]

            if (payment?.transferId == null)
                return

            const agentPayedAt = new Date()

            await patchTransferById(payment.transferId, [{
                op: "replace",
                path: "/agentPayedAt",
                value: agentPayedAt,
            }])

            setInnerPayments(old => splicedArray(old, index, 1, payment.copy({ agentPayedAt })))
            changedRef.current = true
        })
    }

    async function onInWork(index: number) {
        onStatusChange(index, async () => {
            const payment = innerPayments[index]

            if (payment?.transferId == null)
                return

            await patchTransferById(payment.transferId, [{
                op: "replace",
                path: "/agentPayedAt",
                value: null,
            }])

            setInnerPayments(old => splicedArray(old, index, 1, payment.copy({ agentPayedAt: null })))
            changedRef.current = true
        })
    }

    async function onStatusChange(index: number, action: () => (Promise<void> | void)) {
        setLoadingByIndex(index, true)

        try {
            await action()
            onError?.(undefined)
        } catch (error) {
            onError?.(error)
        } finally {
            setLoadingByIndex(index, false)
        }
    }

    // Util

    function setLoadingByIndex(index: number, loading: boolean) {
        setPaymentsLoadingByIndex(old => {
            const changed = new Map(old)

            if (loading)
                changed.set(index, true)
            else
                changed.delete(index)

            return changed
        })
    }
})

TransferAgentPaymentTable.displayName = "TransferAgentPaymentTable"
