import { z } from "zod"

export const NamedResponseSchema = z.object({
    enFirstname: z.string(),
    enLastname: z.string(),
    enPatronymic: z.string().nullish(),

    ruFirstname: z.string(),
    ruLastname: z.string(),
    ruPatronymic: z.string().nullish(),
})

export type NamedResponse = z.infer<typeof NamedResponseSchema>
