import { ForwardedRef, forwardRef, useEffect, useRef } from "react"

import { EMAIL_MESSAGE_TARGET_TYPE, MessageTargetType,
        PHONE_MESSAGE_TARGET_TYPE, determineNullableMessageTargetType } from "model"

import { DeepReadonly, formatPhoneAsYouType,
         removeWhiteSpace, fixLayout, trimNullableToNull } from "my-util"

import { useStateWithDeps } from "ui/hook"
import { Input } from "ui/ui/input"

const DEFAULT_COUNTRY = "RU"

export namespace MessageTargetInput {
    export interface Props extends Omit<Input.Props, "onChange" | "value" | "postProcess" | "left"> {
        onChange?: OnChange
        value?: string
    }

    export type OnChange = (
        messageTarget: string,
        messageTargetType: MessageTargetType | null,
    ) => void
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MessageTargetInput = forwardRef((
    props: DeepReadonly<MessageTargetInput.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const { value,  onChange } = props

    // Refs

    const changedRef = useRef(false)
    const layoutFixTimerRef = useRef(undefined as number | undefined)

    // State

    const [innerValue, setInnerValue] = useStateWithDeps<string>(
        oldValue => changedRef.current && oldValue != null
            ? oldValue
            : value != null
                ? postProcess(value)
                : "",

        [value],
    )

    const [messageTargetType, setMessageTargetType] = useStateWithDeps(
        () => determineNullableMessageTargetTypeTrimming(value),
        [value],
    )

    // Effects

    // - State propagation

    useEffect(() => {
        if (changedRef.current) {
            onChange?.(innerValue, messageTargetType)
            changedRef.current = false
        }
    }, [innerValue, messageTargetType, onChange])

    // Render

    return <Input { ...props }

                  onChange={onInnerChange}
                  value={innerValue}

                  postProcess={postProcess}

                  left={renderLeft()}

                  ref={ref}/>

    function renderLeft(): string | undefined {
        return messageTargetType === PHONE_MESSAGE_TARGET_TYPE
            ? "+"
            : undefined
    }

    // Events

    function onInnerChange(newValue: string) {
        if (layoutFixTimerRef.current != null) {
            window.clearTimeout(layoutFixTimerRef.current)
            layoutFixTimerRef.current = undefined
        }

        const newMessageTargetType = determineNullableMessageTargetTypeTrimming(newValue)

        switch (newMessageTargetType) {
            case PHONE_MESSAGE_TARGET_TYPE:
                newValue = "+" + newValue.replaceAll(/\s+/g, "")
                break

            case EMAIL_MESSAGE_TARGET_TYPE:
                layoutFixTimerRef.current = window.setTimeout(
                    () => {
                        updateInnerValue(
                            fixLayout(newValue, { whitelist: "\"" }),
                            EMAIL_MESSAGE_TARGET_TYPE,
                        )

                        layoutFixTimerRef.current = undefined
                    },

                    200,
                )

                break
        }

        updateInnerValue(newValue, newMessageTargetType)
    }

    // Util

    function postProcess(value: string): string {
        switch (determineNullableMessageTargetTypeTrimming(value)) {
            case PHONE_MESSAGE_TARGET_TYPE:
                return formatPhoneAsYouType(value, DEFAULT_COUNTRY)

            case EMAIL_MESSAGE_TARGET_TYPE:
                return removeWhiteSpace(value)

            default:
                return value
        }
    }

    function determineNullableMessageTargetTypeTrimming(
        value?: string | null,
    ): MessageTargetType | null {
        return determineNullableMessageTargetType(trimNullableToNull(value))
    }

    function updateInnerValue(newValue: string, newMessageTargetType: MessageTargetType | null) {
        setInnerValue(newValue)
        setMessageTargetType(newMessageTargetType)

        changedRef.current = true
    }
})

MessageTargetInput.displayName = "MessageTargetInput"
