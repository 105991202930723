import { ForwardedRef, forwardRef } from "react"
import style from "./style.module.css"

export namespace Circle {
    export interface Props {
        radius?: string

        borderWidth?: string

        borderColor?: string
        color?: string
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Circle = forwardRef((
    {
        radius,
        borderWidth,
        borderColor, color,
    }: Readonly<Circle.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) =>
    <div
        style={{
            fontSize: radius != null
                ? `calc(2 * (${radius}))`
                : undefined,

            backgroundColor: color,
            borderColor,
            borderWidth
        }}
        className={style.Circle}
        ref={ref}
    />
)
