import { get, post, del, countEntities, isEntityExists, tryDeleteEntity } from "api/http/util"
import { InviteRequest } from "api/requests"
import { InviteResponseSchema } from "api/responses"
import { Invite } from "model"
import { joinSubpaths } from "my-util"

const INVITES_SUBPATH = "/invites"

// Count

export async function countInvites(signal?: AbortSignal | null): Promise<number> {
    return countEntities(joinSubpaths([INVITES_SUBPATH, "/count"]), signal)
}

export async function countMyInvites(signal?: AbortSignal | null): Promise<number> {
    return countEntities(joinSubpaths([INVITES_SUBPATH, "/my/count"]), signal)
}

export async function isInviteExistsById(id: string, signal?: AbortSignal | null): Promise<boolean> {
    return isEntityExists(joinSubpaths([INVITES_SUBPATH, id, "/exists"]), signal)
}

// Get many

export async function getAllInvites(signal?: AbortSignal | null): Promise<Invite[]> {
    return getAllInvitesAt("", signal)
}

export async function getAllMyInvites(signal?: AbortSignal | null): Promise<Invite[]> {
    return getAllInvitesAt("/my", signal)
}

async function getAllInvitesAt(
    subpath: string,
    signal?: AbortSignal | null,
): Promise<Invite[]> {
    return (await get({
        subpath: joinSubpaths([INVITES_SUBPATH, subpath]),
        schema: InviteResponseSchema.array(),
        signal,
    })).map(response => new Invite(response))
}

// Get one

export async function getInviteById(id: string, signal?: AbortSignal | null): Promise<Invite> {
    return new Invite(await get({
        subpath: joinSubpaths([INVITES_SUBPATH, id]),
        schema: InviteResponseSchema,
        signal,
    }))
}

// Delete many

export async function clearInvites(signal?: AbortSignal | null) {
    return del({
        subpath: INVITES_SUBPATH,
        signal,
    })
}

export async function clearMyInvites(signal?: AbortSignal | null) {
    return del({
        subpath: joinSubpaths([INVITES_SUBPATH, "/my"]),
        signal,
    })
}

// Delete one

export async function deleteInviteById(id: string, signal?: AbortSignal | null) {
    return del({
        subpath: joinSubpaths([INVITES_SUBPATH, id]),
        signal,
    })
}

export async function tryDeleteInviteById(id: string, signal?: AbortSignal | null): Promise<boolean> {
    return tryDeleteEntity(joinSubpaths([INVITES_SUBPATH, id]), signal)
}

// Resend

export async function resendInviteById(id: string, signal?: AbortSignal | null): Promise<void> {
    return post({
        subpath: joinSubpaths([INVITES_SUBPATH, id, "/resend"]),
        signal,
    })
}

// Create

export async function createInvite(
    request: Readonly<InviteRequest>,
    signal?: AbortSignal | null,
): Promise<Invite> {
    return new Invite(await post({
        subpath: INVITES_SUBPATH,
        schema: InviteResponseSchema,
        body: request,
        signal,
    }))
}
