import { ForwardedRef, useLayoutEffect, useRef, ReactNode,
         useState, useContext, forwardRef, MutableRefObject } from "react"

import { useTranslation } from "react-i18next"
import { getUserRoleName } from "model"
import { UserContext } from "ui/context"
import { Flex } from "ui/ui"
import style from "./style.module.css"

export const Info = forwardRef((_, ref: ForwardedRef<HTMLDivElement>) => {
    const [t] = useTranslation()

    const [localUser] = useContext(UserContext)

    // State

    const [mobile, setMobile] = useState(false)

    // Refs

    const desktopDateAndUserVariantRef = useRef(null as HTMLDivElement | null)

    // Effects

    useLayoutEffect(() => {
        const variant = desktopDateAndUserVariantRef.current

        if (variant == null)
            return

        const observer = new ResizeObserver(handleResize)

        observer.observe(variant)

        return () => observer.disconnect()

        function handleResize(any: any) {
            if (variant != null)
                setMobile(variant.scrollWidth > variant.offsetWidth)
        }
    }, [])

    // Render

    return <div className={style.Info}
                ref={ref}>
        {renderDateAndUserVariant(mobile, true)}
        {renderDateAndUserVariant(!mobile, false, desktopDateAndUserVariantRef)}
    </div>


    function renderDateAndUserVariant(
        visible: boolean = true,
        mobile: boolean = false,
        ref?: MutableRefObject<HTMLDivElement | null>,
    ): ReactNode {
        return <div style={{ visibility: visible ? "visible" : "hidden" }}
                    className={style.dateAndUserVariant}
                    ref={ref}>
            <Flex direction="row">
                {renderDate(mobile)}
                {renderUser(mobile)}
            </Flex>
        </div>
    }

    function renderDate(mobile: boolean = false): ReactNode {
        return <span>
            {!mobile && <>
                {t("datetime.labels.today")}
                {": "}
            </>}

            {t("datetime.values.shortDate", { date: new Date() })}
        </span>
    }

    function renderUser(mobile: boolean = false): ReactNode {
        if (localUser == null)
            return

        return <span>
            {localUser.name}

            {!mobile && <>
                {", "}
                {getUserRoleName(localUser.role)}
            </>}
        </span>
    }
})

Info.displayName = "Info"
