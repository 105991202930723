import { z } from "zod"
import { UuidSchema } from "my-util"
import { ModelObjectResponseSchema } from "../../model-object"
import { RoutePointTypeResponseSchema } from "./route-point-type"

export const RoutePointResponseSchema = ModelObjectResponseSchema.extend({
    country: z.string(),
    type: RoutePointTypeResponseSchema,
    documentIds: UuidSchema.array(),
})

export type ShortRoutePointResponse = z.infer<typeof RoutePointResponseSchema>
