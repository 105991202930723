import { z } from "zod"

import { WithSentAtResponseSchema,
         NullableNamedResponseSchema,
         WithMessageTargetResponseSchema } from "api/responses/common"

import { UuidSchema } from "my-util"
import { ExpirableModelObjectResponseSchema } from "../expirable-model-object"
import { WithCreatorIdResponseSchema } from "./with-creator-id"
import { WithUserRightsResponseSchema } from "./with-user-rights"
import { WithCompanyNameResponseSchema } from "./with-company-name"

export const InviteResponseSchema = ExpirableModelObjectResponseSchema.extend({
    providerId: UuidSchema.nullish(),

    reference: z.string().nullish(),
    text: z.string().nullish(),
    comment: z.string().nullish(),
    link: z.string().nullish(),
})
    .merge(NullableNamedResponseSchema)
    .merge(WithCreatorIdResponseSchema)
    .merge(WithMessageTargetResponseSchema)
    .merge(WithSentAtResponseSchema)
    .merge(WithCompanyNameResponseSchema)
    .merge(WithUserRightsResponseSchema)

export type InviteResponse = z.infer<typeof InviteResponseSchema>
