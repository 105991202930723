import { ForwardedRef, forwardRef, ReactNode } from "react"
import { DeepReadonly } from "my-util"
import style from "./style.module.css"

export namespace Badge {
    export interface Props {
        backgroundColor?: string
        borderColor?: string
        color?: string

        children?: ReactNode
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Badge = forwardRef((
    {
        backgroundColor, borderColor, color,
        children,
    }: DeepReadonly<Badge.Props>,
    ref: ForwardedRef<HTMLSpanElement>,
) =>
    <span style={{ backgroundColor, borderColor, color }}
          className={style.Badge}
          ref={ref}>
        {children}
    </span>
)

Badge.displayName = "Badge"
