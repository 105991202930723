import { ForwardedRef, forwardRef, ReactElement } from "react"
import { formatEmail } from "my-util"
import { Link } from "ui/ui/output"
import style from "./style.module.css"

export namespace Email {
    export interface Props {
        email: string

        noFormat?: boolean
        noLink?: boolean

        stopLinkClickPropagation?: boolean
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Email = forwardRef((
    {
        email,
        noFormat, noLink,
        stopLinkClickPropagation,
    }: Readonly<Email.Props>,
    ref: ForwardedRef<HTMLElement>,
) => {
    // Render

    return noLink
        ? renderSpan()
        : renderLink()

    function renderSpan(): ReactElement {
        return <span className={style.Email}
                     ref={ref}>
            {prepareEmail(email)}
        </span>
    }

    function renderLink(): ReactElement {
        return <Link stopClickPropagation={stopLinkClickPropagation}
                     to={`mailto:${email}`}
                     text={prepareEmail(email)}
                     ref={ref as any}/>
    }

    // Util

    function prepareEmail(email: string): string {
        return noFormat
            ? email
            : formatEmail(email)
    }
})

Email.displayName = "Email"
