import { z } from "zod"
import { SuccessMfaResponseSchema, VerificationNeededMfaResponseSchema } from "api/responses/auth"
import { UserResponseSchema } from "./user"

// Verification needed

export const VerificationNeededUserUpdateResponseSchema = VerificationNeededMfaResponseSchema

export type VerificationNeededUserUpdateResponse = z.infer<typeof VerificationNeededUserUpdateResponseSchema>

// Success

export const SuccessUserUpdateResponseSchema = SuccessMfaResponseSchema.extend({
    body: UserResponseSchema,
})

// United

export const UserUpdateResponseSchema = z.never()
    .or(VerificationNeededUserUpdateResponseSchema)
    .or(SuccessUserUpdateResponseSchema)

export type UserUpdateResponse = z.infer<typeof UserUpdateResponseSchema>
