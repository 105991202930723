import { z } from "zod"
import { Iso8601DurationSchema } from "my-util"

import { ExpirableResponseSchema,
         WithSentAtResponseSchema,
         WithMessageTargetResponseSchema } from "../common"

export const OtpResponseSchema = z.object({
    resendCoolDown: Iso8601DurationSchema,
})
    .merge(WithMessageTargetResponseSchema)
    .merge(ExpirableResponseSchema)
    .merge(WithSentAtResponseSchema)

export type OtpResponse = z.infer<typeof OtpResponseSchema>
