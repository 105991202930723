import { ForwardedRef, forwardRef } from "react"
import { Dim } from "ui/ui/appearance"
import { Center } from "ui/ui/layout"
import { LoadingIndicator } from "../LoadingIndicator"
import style from "./style.module.css"

export namespace Loading {
    export interface Props {
        opacity?: number

        centerType?: Center.Type
        fixed?: boolean

        indicatorSize?: string
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Loading = forwardRef((
    {
        opacity,
        fixed, centerType,
        indicatorSize,
    }: Readonly<Loading.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) =>
    <div className={fixed ? style.FixedLoading : style.Loading}
         ref={ref}>
        <Center type={centerType ?? "absolute-fill"}>
            <Dim opacity={opacity ?? 0}/>
            <LoadingIndicator size={indicatorSize}/>
        </Center>
    </div>
)

Loading.displayName = "Loading"
