import { EMAIL_MESSAGE_TARGET_TYPE, PHONE_MESSAGE_TARGET_TYPE,
         determineMessageTargetType, Identifiable, WithManyMessageTargets } from "model"

import { Nullish } from "my-util"

export type LoginRequest =
    | LoginByPhoneRequest
    | LoginByEmailRequest

export interface LoginByPhoneRequest extends LoginRequestBase {
    phone: string
}

export interface LoginByEmailRequest extends LoginRequestBase {
    email: string
}

export interface LoginRequestBase
    extends
        Nullish<WithManyMessageTargets>,
        Identifiable
{
    id: string
    password: string
}

export function createLoginRequestFromMessageTarget(
    id: string,
    username: string,
    password: string,
): LoginRequest {
    switch (determineMessageTargetType(username)) {
        case PHONE_MESSAGE_TARGET_TYPE:
            return { id, password, phone: username }

        case EMAIL_MESSAGE_TARGET_TYPE:
            return { id, password, email: username }
    }
}
