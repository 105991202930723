import { BASE_URL, countEntities, patchEntity, get, put, del,
         NetworkError, post, throwIfResponseNotOk, tryDeleteEntity } from "api/http/util"

import { IdsRequest, JsonPatch, TransferRequest } from "api/requests"

import { TransfersConfigResponse, TransfersConfigResponseSchema,
         TransferAgentPaymentResponseSchema, TransferResponseSchema } from "api/responses"

import { Transfer, TransferAgentPayment, TransferStatus } from "model"
import { DeepReadonly, joinSubpaths } from "my-util"

export const TRANSFERS_SUBPATH = "/transfers"

// Report

export async function getTransfersReport(
    request: DeepReadonly<IdsRequest>,
    signal?: AbortSignal | null,
): Promise<Blob> {
    let response: Response

    try {
        const url = BASE_URL + joinSubpaths([TRANSFERS_SUBPATH, "/report"])
        const options: RequestInit = {
            method: "POST",
            body: JSON.stringify(request),
            headers: { "Content-Type": "application/json" },
            signal,
        }

        response = await fetch(url, options)
    } catch (error) {
        throw new NetworkError("Network error", { cause: error })
    }

    await throwIfResponseNotOk(response)

    return await response.blob()
}

// Act-Report

export function createTransferActReportDownloadUrlById(id: string): string {
    return BASE_URL + joinSubpaths([TRANSFERS_SUBPATH, id, "/act-report"])
}

// Order

export function createTransferOrderDownloadUrlById(id: string): string {
    return BASE_URL + joinSubpaths([TRANSFERS_SUBPATH, id, "/order"])
}

// Treaty

export function createTransferTreatyDownloadUrlById(id: string): string {
    return BASE_URL + joinSubpaths([TRANSFERS_SUBPATH, id, "/treaty"])
}

// Agent payments

export async function getAllTransferAgentPayments(
    signal?: AbortSignal | null,
): Promise<TransferAgentPayment[]> {
    return getAllTransferAgentPaymentsAt(["/payments"], signal)
}

export async function getAllMyTransferAgentPayments(
    signal?: AbortSignal | null,
): Promise<TransferAgentPayment[]> {
    return getAllTransferAgentPaymentsAt(["/payments", "/my"], signal)
}

export async function getAllTransferAgentPaymentsAt(
    subpaths: string[],
    signal?: AbortSignal | null,
): Promise<TransferAgentPayment[]> {
    return (await get({
        subpath: joinSubpaths([TRANSFERS_SUBPATH, ...subpaths]),
        schema: TransferAgentPaymentResponseSchema.array(),
        signal,
    })).map(response => new TransferAgentPayment(response))
}

// Count

// - All

export async function countTransfers(signal?: AbortSignal | null): Promise<number> {
    return countEntities(joinSubpaths([TRANSFERS_SUBPATH, "/count"]), signal)
}

export async function countTransfersByStatusNot(
    status: TransferStatus,
    signal?: AbortSignal | null,
): Promise<number> {
    return countEntities(joinSubpaths([TRANSFERS_SUBPATH, `/not-${status}/count`]), signal)
}

export async function countTransfersByStatus(
    status: TransferStatus,
    signal?: AbortSignal | null,
): Promise<number> {
    return countEntities(joinSubpaths([TRANSFERS_SUBPATH, status, "/count"]), signal)
}

export async function countTransfersInWork(signal?: AbortSignal | null): Promise<number> {
    return countEntities(joinSubpaths([TRANSFERS_SUBPATH, "/in-work", "/count"]), signal)
}

// - My

export async function countMyTransfers(signal?: AbortSignal | null): Promise<number> {
    return countEntities(joinSubpaths([TRANSFERS_SUBPATH, "/my", "/count"]), signal)
}

export async function countMyTransfersByStatusNot(
    status: TransferStatus,
    signal?: AbortSignal | null,
): Promise<number> {
    return countEntities(joinSubpaths([TRANSFERS_SUBPATH, "/my", `/not-${status}/count`]), signal)
}

export async function countMyTransfersByStatus(
    status: TransferStatus,
    signal?: AbortSignal | null,
): Promise<number> {
    return countEntities(joinSubpaths([TRANSFERS_SUBPATH, "/my", status, "/count"]), signal)
}

export async function countMyTransfersInWork(signal?: AbortSignal | null): Promise<number> {
    return countEntities(joinSubpaths([TRANSFERS_SUBPATH, "/my", "/in-work", "/count"]), signal)
}

// Get one

export async function getTransferById(id: string, signal?: AbortSignal | null): Promise<Transfer> {
    return new Transfer(await get({
        subpath: joinSubpaths([TRANSFERS_SUBPATH, id]),
        schema: TransferResponseSchema,
        signal,
    }))
}

// Get many

// - All

export async function getAllTransfers(signal?: AbortSignal | null): Promise<Transfer[]> {
    return getAllTransferAt([], signal)
}

export async function getAllTransfersByStatusNot(
    status: TransferStatus,
    signal?: AbortSignal | null,
): Promise<Transfer[]> {
    return getAllTransferAt([`/not-${status}`], signal)
}

export async function getAllTransfersByStatus(
    status: TransferStatus,
    signal?: AbortSignal | null,
): Promise<Transfer[]> {
    return getAllTransferAt([status], signal)
}

export async function getAllTransfersInWork(signal?: AbortSignal | null): Promise<Transfer[]> {
    return getAllTransferAt(["/in-work"], signal)
}

// - My

export async function getAllMyTransfers(signal?: AbortSignal | null): Promise<Transfer[]> {
    return getAllTransferAt(["/my"], signal)
}

export async function getAllMyTransfersByStatusNot(
    status: TransferStatus,
    signal?: AbortSignal | null,
): Promise<Transfer[]> {
    return getAllTransferAt(["/my", `/not-${status}`], signal)
}

export async function getAllMyTransfersByStatus(
    status: TransferStatus,
    signal?: AbortSignal | null,
): Promise<Transfer[]> {
    return getAllTransferAt(["/my", status], signal)
}

export async function getAllMyTransfersInWork(signal?: AbortSignal | null): Promise<Transfer[]> {
    return getAllTransferAt(["/my", "/in-work"], signal)
}

async function getAllTransferAt(
    subpaths: string[],
    signal?: AbortSignal | null,
): Promise<Transfer[]> {
    return (await get({
        subpath: joinSubpaths([TRANSFERS_SUBPATH, ...subpaths]),
        schema: TransferResponseSchema.array(),
        signal,
    })).map(response => new Transfer(response))
}

// Delete many

// - All

export async function clearTransfers(signal?: AbortSignal | null) {
    return clearTransferAt([], signal)
}

export async function clearTransfersByStatusNot(
    status: TransferStatus,
    signal?: AbortSignal | null,
) {
    return clearTransferAt([`/not-${status}`], signal)
}

export async function clearTransfersByStatus(status: TransferStatus, signal?: AbortSignal | null) {
    return clearTransferAt([status], signal)
}

export async function clearTransfersInWork(signal?: AbortSignal | null) {
    return clearTransferAt(["/in-work"], signal)
}

// - My

export async function clearMyTransfers(signal?: AbortSignal | null) {
    return clearTransferAt(["/my"], signal)
}

export async function clearMyTransfersByStatusNot(
    status: TransferStatus,
    signal?: AbortSignal | null,
) {
    return clearTransferAt(["/my", `/not-${status}`], signal)
}

export async function clearMyTransfersByStatus(
    status: TransferStatus,
    signal?: AbortSignal | null,
) {
    return clearTransferAt(["/my", status], signal)
}

export async function clearMyTransfersInWork(signal?: AbortSignal | null) {
    return clearTransferAt(["/my", "/in-work"], signal)
}

async function clearTransferAt(
    subpaths: string[],
    signal?: AbortSignal | null,
) {
    return del({
        subpath: joinSubpaths([TRANSFERS_SUBPATH, ...subpaths]),
        signal,
    })
}

// Delete one

export async function deleteTransferById(id: string, signal?: AbortSignal | null) {
    return del({
        subpath: joinSubpaths([TRANSFERS_SUBPATH, id]),
        signal,
    })
}

export async function tryDeleteTransferById(
    id: string,
    signal?: AbortSignal | null,
): Promise<boolean> {
    return tryDeleteEntity(joinSubpaths([TRANSFERS_SUBPATH, id]), signal)
}

// Patch

export async function patchTransferById(
    id: string,
    patches: DeepReadonly<JsonPatch[]>,
    signal?: AbortSignal | null
): Promise<Transfer> {
    return new Transfer(await patchEntity({
        subpath: joinSubpaths([TRANSFERS_SUBPATH, id]),
        schema: TransferResponseSchema,
        patches,
        signal,
    }))
}

// Put

export async function putTransferById(
    id: string,
    request: DeepReadonly<TransferRequest>,
    signal?: AbortSignal | null,
): Promise<Transfer> {
    return new Transfer(await put({
        subpath: joinSubpaths([TRANSFERS_SUBPATH, id]),
        schema: TransferResponseSchema,
        body: request,
        signal,
    }))
}

// Create

export async function createTransfer(
    request: DeepReadonly<TransferRequest>,
    signal?: AbortSignal | null,
): Promise<Transfer> {
    return new Transfer(await post({
        subpath: TRANSFERS_SUBPATH,
        schema: TransferResponseSchema,
        body: request,
        signal,
    }))
}

// Get config

export async function getTransfersConfig(
    signal?: AbortSignal | null,
): Promise<TransfersConfigResponse> {
    return get({
        subpath: joinSubpaths([TRANSFERS_SUBPATH, "/config"]),
        schema: TransfersConfigResponseSchema,
        signal,
    })
}
