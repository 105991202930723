import { ForwardedRef, forwardRef, Fragment, ReactNode, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { getLang } from "i18n"

import { AGENT_TRANSFER_SORT_BY, COMPANY_TRANSFER_SORT_BY,
         DATE_TRANSFER_SORT_BY, User, getTransferGroup, TransferSortBy,
         Money, AGENT_USER_ROLE, getTransferSortByComparator, Transfer } from "model"

import { dateToDateString, dateWithTimeNulled, DeepReadonly, ReadonlyDate } from "my-util"
import { UserLink } from "ui/component/user"
import { Flex, TransferDirectionIcon, TransferGroupColorLegend, UserRoleAbbr } from "ui/ui"
import style from "./style.module.css"

export namespace SmallTransferTable {
    export interface Props {
        onTransferClick?: (transfer: Transfer) => void

        transfers: Iterable<Transfer>
        users?: Iterable<User> | Map<string, User>

        showGroups?: boolean
        showAgents?: boolean

        max?: number

        sortBy?: TransferSortBy
        reverseSorting?: boolean

        width?: string
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SmallTransferTable = forwardRef((
    {
        onTransferClick,
        transfers, users,
        showGroups, showAgents,
        max,
        sortBy, reverseSorting,
        width,
    }: DeepReadonly<SmallTransferTable.Props>,

    ref: ForwardedRef<HTMLTableElement>,
) => {
    const innerSortBy = sortBy ?? COMPANY_TRANSFER_SORT_BY

    const [t] = useTranslation()

    // State

    const innerTransfers = useMemo(
        () => [...transfers].sort(getTransferSortByComparator(innerSortBy, {
            reverse: reverseSorting,
        })),

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [innerSortBy, reverseSorting, transfers, getLang()],
    )

    const innerUsersById = useMemo(
        () => User.groupByIdOrPassOrCreate(users),
        [users],
    )

    // Render

    return <table className={style.table}
                  style={{ width }}
                  ref={ref}>
        <tbody>
            {renderTransfers(innerTransfers)}
        </tbody>
    </table>

    function renderTransfers(transfers: Transfer[]): ReactNode {
        switch (innerSortBy) {
            case COMPANY_TRANSFER_SORT_BY:
                return renderSortedByCompany(transfers)

            case AGENT_TRANSFER_SORT_BY:
                return renderSortedByAgent(transfers)

            case DATE_TRANSFER_SORT_BY:
                return renderSortedByDate(transfers)
        }
    }

    // - Sorted by

    function renderSortedByCompany(transfers: Transfer[]): ReactNode {
        return transfers.map((transfer, i) => {
            const prevTransfer = transfers[i - 1] as Transfer | undefined

            const creator = transfer.creatorId != null
                ? innerUsersById.get(transfer.creatorId)
                : undefined

            return <Fragment key={transfer.id}>
                {prevTransfer?.creatorId !== transfer.creatorId &&
                    <tr className={style.header}>
                        {showGroups &&
                            <th className={style.group}/>
                        }

                        <th colSpan={showAgents ? 4 : 3}>
                            {renderCompany(creator)}
                        </th>
                    </tr>
                }

                <tr onClick={() => onTransferClick?.(transfer)}>
                    {showGroups &&
                        <td className={style.group}>
                            <TransferGroupColorLegend group={getTransferGroup(transfer)}/>
                        </td>
                    }

                    <td>{renderDirectionWithCountry(transfer)}</td>
                    <td>{renderCost(transfer.cost)}</td>
                    <td>{renderMoment(transfer.moment)}</td>

                    {showAgents &&
                        <td>{renderAgentById(creator?.creatorId)}</td>
                    }
                </tr>
            </Fragment>
        })
    }

    function renderSortedByAgent(transfers: Transfer[]): ReactNode {
        return transfers.map((transfer, i) => {
            const prevTransfer = transfers[i - 1] as Transfer | undefined

            const prevCreator = prevTransfer?.creatorId != null
                ? innerUsersById.get(prevTransfer.creatorId)
                : undefined

            const creator = transfer.creatorId != null
                ? innerUsersById.get(transfer.creatorId)
                : undefined

            return <Fragment key={transfer.id}>
                {prevCreator?.creatorId !== creator?.creatorId &&
                    <tr className={style.header}>
                        {showGroups &&
                            <th className={style.group}/>
                        }

                        <th colSpan={4}>
                            {renderAgentById(creator?.creatorId)}
                        </th>
                    </tr>
                }

                <tr onClick={() => onTransferClick?.(transfer)}>
                    {showGroups &&
                        <td className={style.group}>
                            <TransferGroupColorLegend group={getTransferGroup(transfer)}/>
                        </td>
                    }

                    <td>{renderCompany(creator)}</td>
                    <td>{renderDirectionWithCountry(transfer)}</td>
                    <td>{renderCost(transfer.cost)}</td>
                    <td>{renderMoment(transfer.moment)}</td>
                </tr>
            </Fragment>
        })
    }

    function renderSortedByDate(transfers: Transfer[]): ReactNode {
        return transfers.map((transfer, i) => {
            const prevTransfer = transfers[i - 1] as Transfer | undefined

            const prevTime = prevTransfer != null
                ? dateWithTimeNulled(prevTransfer.createdAt).getTime()
                : null

            const time = dateWithTimeNulled(transfer.createdAt).getTime()

            const creator = transfer.creatorId != null
                ? innerUsersById.get(transfer.creatorId)
                : undefined

            return <Fragment key={transfer.id}>
                {time !== prevTime &&
                    <tr className={style.header}>
                        {showGroups &&
                            <th className={style.group}/>
                        }

                        <th colSpan={showAgents ? 4 : 3}>
                            {dateToDateString(transfer.createdAt)}
                        </th>
                    </tr>
                }

                <tr onClick={() => onTransferClick?.(transfer)}>
                    {showGroups &&
                        <td className={style.group}>
                            <TransferGroupColorLegend group={getTransferGroup(transfer)}/>
                        </td>
                    }

                    <td>{renderCompany(creator)}</td>
                    <td>{renderDirectionWithCountry(transfer)}</td>
                    <td>{renderCost(transfer.cost)}</td>

                    {showAgents &&
                        <td>{renderAgentById(creator?.creatorId)}</td>
                    }
                </tr>
            </Fragment>
        })
    }

    // - Parts

    function renderCompany(user?: User | null): ReactNode {
        if (user == null)
            return t("domain.companies.messages.notSet")

        return <UserLink stopClickPropagation
                         user={user}
                         showCompany/>
    }

    function renderDirectionWithCountry(transfer: Transfer): ReactNode {
        return <Flex direction="row"
                     gap="4px">
            <TransferDirectionIcon direction={transfer.direction}
                                   width="8px"
                                   height="20px"/>

            {transfer.country}
        </Flex>
    }

    function renderCost(cost?: Money | null): ReactNode {
        return cost?.toString() ?? t("domain.transfers.messages.no.cost")
    }

    function renderMoment(moment?: ReadonlyDate | null): ReactNode {
        return moment != null
            ? dateToDateString(moment)
            : t("domain.transfers.messages.dateNotSet")
    }

    function renderAgentById(agentId?: string | null): ReactNode {
        return agentId != null
            ? renderAgent(innerUsersById.get(agentId))
            : t("domain.transfers.messages.no.agent")
    }

    function renderAgent(agent?: User | null): ReactNode {
        if (agent == null)
            return t("domain.transfers.messages.no.agent")

        return <Flex direction="row"
                     gap="8px">
            <UserRoleAbbr role={AGENT_USER_ROLE}
                          colorful/>

            <UserLink stopClickPropagation
                      user={agent}/>
        </Flex>
    }
})

SmallTransferTable.displayName = "SmallTransferTable"
