import { HOUR_MINUTES, MINUTE_SECONDS, SECOND_MILLIS } from "my-util"
import { ForwardedRef, forwardRef, useEffect, useState } from "react"

export const Stopwatch = forwardRef((_, ref: ForwardedRef<HTMLDivElement>) => {
    // State

    const [totalSeconds, setTotalSeconds] = useState(0)

    // Timer

    useEffect(() => {
        const timer = setInterval(
            () => setTotalSeconds(oldTotalSeconds => oldTotalSeconds + 1),
            SECOND_MILLIS,
        )

        return () => clearInterval(timer)
    }, [])

    // Render

    const totalMinutes = totalSeconds / MINUTE_SECONDS
    const totalHours = totalMinutes / HOUR_MINUTES

    const seconds = Math.floor(totalSeconds % MINUTE_SECONDS)
    const minutes = Math.floor(totalMinutes % HOUR_MINUTES)
    const hours = Math.floor(totalHours)

    return <span ref={ref}>
        {formatPart(hours)}:{formatPart(minutes)}:{formatPart(seconds)}
    </span>

    // Util

    function formatPart(part: number): string {
        const stringPart = String(part)

        return stringPart.length < 2
            ? "0" + stringPart
            : stringPart
    }
})

Stopwatch.displayName = "Stopwatch"
