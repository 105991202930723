import { ChangeEvent, ForwardedRef, forwardRef,
         KeyboardEvent, useImperativeHandle, useRef } from "react"

import { computeTextWidthForElement } from "my-util"
import { useStateWithDeps } from "ui/hook"
import { Information, Label, OptionallyRequired } from "ui/ui/output"
import style from "./style.module.css"

export namespace TextArea {
    export interface Props {
        onChange?: (text: string) => void
        value?: string

        postProcess?: (text: string) => string
        test?: (text: string) => boolean
        regex?: RegExp
        max?: number

        label?: string
        placeholder?: string
        information?: string
        showMax?: boolean

        resize?: Resize
        autoFocus?: boolean
        rows?: number
        maxRows?: number

        canSubmit?: boolean

        width?: string
        height?: string

        invalid?: boolean
        disabled?: boolean
        readonly?: boolean
        loading?: boolean
        required?: boolean
    }

    export type Resize =
        | "none"
        | "auto"
        | "vertical"
        | "horizontal"
        | "both"
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TextArea = forwardRef((
    {
        onChange, value,
        postProcess, test, regex, max,
        label, placeholder, information, showMax,
        width, height,
        resize, autoFocus, rows, maxRows,
        canSubmit,
        invalid, disabled, readonly, loading, required,
    }: Readonly<TextArea.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    // Refs

    const elementRef = useRef(null as HTMLDivElement | null)
    const textAreaElementRef = useRef(null as HTMLTextAreaElement | null)

    useImperativeHandle(ref, () => elementRef.current!, [])

    // State

    const [innerRows, setInnerRows] = useStateWithDeps(
        () => clampRows(rows ?? 2),
        [rows],
    )

    const [innerValue, setInnerValue] = useStateWithDeps(
        () => {
            const newValue = value ?? ""

            if (resize === "auto")
                updateInnerRows(newValue)

            return newValue
        },
        [value],
    )

    const innerDisabled = disabled || loading || readonly
    const innerInvalid = invalid && !innerDisabled
    const innerShowMax = max != null && showMax
    const innerResize = resize === "auto"
        ? "none"
        : resize

    // Render

    return <div className={renderTextAreaClassName()}
                style={{ width, height }}
                ref={elementRef}>
        <OptionallyRequired required={required}>
            <Label text={label}/>
        </OptionallyRequired>

        <div className={style.htmlTextAreaWrapper}>
            <textarea className={renderHtmlTextAreaClassName()}
                      style={{ resize: innerResize }}

                      value={innerValue}
                      onChange={onInnerChange}
                      onKeyDown={onKeyDown}

                      placeholder={placeholder}

                      disabled={innerDisabled}

                      rows={innerRows}
                      autoFocus={autoFocus}

                      ref={textAreaElementRef}/>

            {innerShowMax &&
                <div className={style.max}>
                    {innerValue.length}/{max}
                </div>
            }
        </div>

        <Information type={innerInvalid ? "error" : "regular"}
                     text={information}/>
    </div>

    function renderTextAreaClassName(): string {
        if (readonly)
            return style.ReadonlyTextArea

        if (loading)
            return style.LoadingTextArea

        return style.TextArea
    }

    function renderHtmlTextAreaClassName(): string {
        return innerInvalid
            ? style.invalidHtmlTextArea
            : style.htmlTextArea
    }

    // Events

    function onKeyDown(event: KeyboardEvent<HTMLTextAreaElement>) {
        if (disabled || !canSubmit || event.key !== "Enter" || event.shiftKey)
            return

        event.preventDefault()

        let element: HTMLElement | null = elementRef.current

        if (element == null)
            return

        do {
            element = element.parentElement
        } while (element != null && element.tagName !== "FORM")

        if (element == null)
            return

        const form = element as HTMLFormElement
        const buttons = form.querySelectorAll("button")

        for (const button of buttons)
            if (!button.disabled && button.type === "submit") {
                button.click()
                break
            }
    }

    function onInnerChange(event: ChangeEvent<HTMLTextAreaElement>) {
        let newValue = event.currentTarget.value

        if (postProcess)
            newValue = postProcess(newValue)

        if (max != null && newValue.length > max)
            newValue = newValue.substring(0, max)

        if (regex != null && newValue.match(regex) == null)
            return

        if (test?.(newValue) === false)
            return

        setInnerValue(newValue)

        if (resize === "auto")
            updateInnerRows(newValue)

        onChange?.(newValue)
    }

    // Util

    function updateInnerRows(value: string) {
        const lineCount = countLines(value)
        const newInnerRows = clampRows(lineCount)

        if (newInnerRows !== innerRows)
            setInnerRows(newInnerRows)
    }

    function countLines(value: string) {
        let count = 0

        const textAreaElement = textAreaElementRef.current

        for (const line of value.split("\n")) {
            if (textAreaElement != null) {
                const lineWidth = computeTextWidthForElement(line, textAreaElement)
                const textAreaMaxLineWidth = textAreaElement.scrollWidth - 16 // Padding

                if (lineWidth > textAreaMaxLineWidth)
                    ++count
            }

            ++count
        }

        return count
    }

    function clampRows(rows: number): number {
        return Math.min(rows, maxRows ?? Number.MAX_SAFE_INTEGER)
    }
})

TextArea.displayName = "TextArea"
