import { AbstractModelObject } from "model/AbstractModelObject"
import { collapseWhiteSpace, DeepReadonly, map, Nullish, tryNormalizeUuid } from "my-util"
import type { RoutePointType } from "./RoutePointType"

export namespace RoutePoint {
    export interface OptionsBase {
        country: string
        type: RoutePointType
        documentIds?: Iterable<string> | null
    }

    export interface CreationOptions
        extends
            AbstractModelObject.CreationOptions,
            OptionsBase
    {}

    export interface CopyOptions
        extends
            AbstractModelObject.CopyOptions,
            Nullish<OptionsBase>
    {}
}

export class RoutePoint extends AbstractModelObject<RoutePoint.CopyOptions> {
    static createOrPass(arg: RoutePoint | DeepReadonly<RoutePoint.CreationOptions>): RoutePoint {
        return arg instanceof RoutePoint
            ? arg
            : new RoutePoint(arg)
    }

    // Fields

    readonly country: string
    readonly type: RoutePointType
    readonly documentIds: string[]

    // Constructor

    constructor(options: DeepReadonly<RoutePoint.CreationOptions>) {
        super(options)

        this.country = collapseWhiteSpace(options.country)
        this.type = options.type
        this.documentIds = map(options.documentIds ?? [], tryNormalizeUuid)
    }

    // Copy

    protected override createCopy(options: DeepReadonly<RoutePoint.CopyOptions> = {}): RoutePoint {
        return new RoutePoint({
            // Point

            country: options.country ?? this.country,
            type: options.type ?? this.type,
            documentIds: options.documentIds ?? this.documentIds,

            // Basic

            id: options.id ?? this.id,
            createdAt: options.createdAt ?? this.createdAt,
            modifiedAt: options.modifiedAt ?? this.modifiedAt,
        })
    }
}
