import { useTranslation } from "react-i18next"
import { linkIconUrl } from "images"
import { InviteStatus } from "model"
import * as RegistrationPage from "ui/page/auth/RegistrationPage/path"
import { Flex, Copy, InviteStatusBadge } from "ui/ui"

export namespace InviteHeader {
    export interface Props {
        text: string
        status?: InviteStatus
        inviteId: string
    }
}

export function InviteHeader({ text, status, inviteId }: Readonly<InviteHeader.Props>) {
    const [t] = useTranslation()

    const registrationLink = window.location.origin + RegistrationPage.createPath(inviteId)

    return <Flex direction="row"
                 justify="space-between">
        <Flex direction="row"
              width="fit-content">
            {text}

            {status != null &&
                <InviteStatusBadge status={status}/>
            }
        </Flex>

        <Copy text={t("misc.actions.copyLink")}
              copiedText={t("misc.messages.linkCopied")}

              fontSize="16px"

              value={registrationLink}

              iconSrc={linkIconUrl}
              iconAlt="Link icon"
              iconFilter="brightness(0.4)"/>
    </Flex>
}
