import { useState } from "react"

export function useRerender(): () => void {
    const [, setValue] = useState(Number.MIN_SAFE_INTEGER)

    return () => setValue(oldValue => {
        return oldValue >= Number.MAX_SAFE_INTEGER
            ? Number.MIN_SAFE_INTEGER
            : oldValue + 1
    })
}
