import { ForwardedRef, forwardRef } from "react"
import { getTransferGroupColors, getTransferGroupName, TransferGroup } from "model"
import { Badge } from "ui/ui/output"

export namespace TransferGroupBadge {
    export interface Props {
        group: TransferGroup
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransferGroupBadge = forwardRef((
    { group }: Readonly<TransferGroupBadge.Props>,
    ref: ForwardedRef<HTMLSpanElement>,
) =>
    <Badge { ...getTransferGroupColors(group) }
           ref={ref}>
        {getTransferGroupName(group)}
    </Badge>
)

TransferGroupBadge.displayName = "TransferGroupBadge"
