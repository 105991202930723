import { ForwardedRef, forwardRef } from "react"
import { getLang } from "i18n"
import { Flex, Limit } from "ui/ui/layout"
import { Output } from "ui/ui/output"

export namespace DiLangOutput {
    export interface Props {
        enValue?: string
        ruValue?: string

        label?: string

        maxWidth?: string
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiLangOutput = forwardRef((
    {
        enValue, ruValue,
        label,
        maxWidth,
    }: Readonly<DiLangOutput.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const ru = getLang() === "ru"

    return <Flex direction="row"
                 align="end"
                 ref={ref}
                 wrap>
        <Limit maxWidth={maxWidth}>
            <Output whiteSpace="nowrap"
                    label={label}>
                {ru ? ruValue : enValue}
            </Output>
        </Limit>

        <Limit maxWidth={maxWidth}>
            <Output backgroundColor="rgba(0, 0, 0, 0)"
                    borderColor="rgba(0, 0, 0, 0)"
                    whiteSpace="nowrap">
                {ru ? enValue : ruValue}
            </Output>
        </Limit>
    </Flex>
})

DiLangOutput.displayName = "DiLangOutput"
