import { z } from "zod"
import { NullableNamedResponseSchema, WithMessageTargetResponseSchema } from "api/responses/common"
import { ModelObjectResponseSchema } from "../model-object"
import { WithCompanyNameResponseSchema } from "./with-company-name"

export const InviteApplicationResponseSchema = ModelObjectResponseSchema
    .merge(WithMessageTargetResponseSchema)
    .merge(NullableNamedResponseSchema)
    .merge(WithCompanyNameResponseSchema)

export type InviteApplicationResponse = z.infer<typeof InviteApplicationResponseSchema>
