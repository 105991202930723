import { ForwardedRef, forwardRef, ReactNode, useEffect, useMemo, useRef, useState } from "react"
import { ZodType } from "zod"
import { MfaResponse } from "api"
import { determineMessageTargetType, MessageTargetType } from "model"
import { useStateWithDeps } from "ui/hook"
import { Flex, None, PhaseIndicator } from "ui/ui"
import { MessageTargetForm } from "./MessageTargetForm"
import { OtpForm } from "./OtpForm"

export namespace MessageTargetMfaForm {
    export interface Props {
        performMfa: PerformMfa
        responseSchema?: ZodType,

        onMessageTargetChange?: OnMessageTargetChange
        messageTarget?: string
        messageTargetPlaceholder?: string

        onSuccess?: OnSuccess
        onBack?: OnBack

        onStateChange?: OnStateChange

        hidePhaseIndicator?: boolean

        width?: string
    }

    export type PerformMfa = (
        messageTarget: string,
        messageTargetType: MessageTargetType,
        responseSchema?: ZodType,
    ) => Promise<MfaResponse>

    export type OnMessageTargetChange = (
        messageTarget: string,
        messageTargetType: MessageTargetType,
    ) => void

    export type OnSuccess = (
        messageTarget: string,
        messageTargetType: MessageTargetType,
        response: unknown,
    ) => void

    export type OnBack = () => void

    export type OnStateChange = (state: State) => void

    export type State =
        | "message-target"
        | "otp"
        | "success"
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MessageTargetMfaForm = forwardRef((
    {
        performMfa, responseSchema,
        onMessageTargetChange, messageTarget, messageTargetPlaceholder,
        onSuccess, onBack,
        onStateChange,
        hidePhaseIndicator,
        width,
    }: Readonly<MessageTargetMfaForm.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    // State

    const [state, setState] = useState<MessageTargetMfaForm.State>("message-target")

    const [innerMessageTarget, setInnerMessageTarget] = useStateWithDeps(
        () => messageTarget ?? "",
        [messageTarget],
    )

    const messageTargetType = useMemo(
        () => determineMessageTargetType(innerMessageTarget),
        [innerMessageTarget],
    )

    const responseRef = useRef(undefined as unknown)

    // Effects

    useEffect(
        () => onStateChange?.(state),
        [onStateChange, state],
    )

    useEffect(
        () => onMessageTargetChange?.(innerMessageTarget ?? "", messageTargetType),
        [innerMessageTarget, messageTargetType, onMessageTargetChange],
    )

    useEffect(
        () => {
            if (state === "success") {
                onSuccess?.(innerMessageTarget, messageTargetType, responseRef.current)
            }
        },

        [state, innerMessageTarget, messageTargetType, onSuccess],
    )

    // Render

    switch (state) {
        case "message-target":
            return <Flex align="start"
                         width={width}>
                {!hidePhaseIndicator &&
                    renderPhaseIndicator(0)
                }

                <MessageTargetForm performMfa={performMfa}
                                   responseSchema={responseSchema}

                                   onMessageTargetChange={setInnerMessageTarget}
                                   messageTarget={innerMessageTarget}
                                   messageTargetPlaceholder={messageTargetPlaceholder}

                                   onSuccess={(_0, _1, response) => onInnerSuccess(response)}
                                   onVerificationNeeded={() => setState("otp")}
                                   onBack={onBack}/>
            </Flex>

        case "otp":
            return <Flex align="start"
                         width={width}>
                {!hidePhaseIndicator &&
                    renderPhaseIndicator(1)
                }

                <OtpForm onSuccess={onInnerSuccess}
                         onBack={() => setState("message-target")}

                         responseSchema={responseSchema}

                         messageTarget={innerMessageTarget}/>
            </Flex>

        case "success":
            return <None ref={ref}/>
    }

    function renderPhaseIndicator(phase: 0 | 1): ReactNode {
        return <PhaseIndicator
            current={phase}
            phases={[
                {
                    style: {
                        current: { size: "big" },
                        fallback: {
                            borderColor: "transperant",
                            backgroundColor: "#3182CE",
                            color: "white",
                        },
                    },
                },

                {
                    style: {
                        current: { size: "big" },
                        fallback: {
                            borderColor: "transperant",
                            backgroundColor: "#D69E2E",
                            color: "white",
                        },
                    },
                },
            ]}
        />
    }

    // Events

    function onInnerSuccess(response: unknown) {
        responseRef.current = response
        setState("success")
    }
})

MessageTargetMfaForm.displayName = "MessageTargetMfaForm"
