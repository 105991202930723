import { SignableDocumentRequest } from "api"
import { SignableDocument } from "model"
import { DeepReadonly } from "my-util"
import { copyUiDocument, FieldDocument } from "ui/component"

export interface SignableDocumentFields {
    id?: string | null
    createdAt?: Date | null
    modifiedAt?: Date | null

    initial: FieldDocument | null
    signed: FieldDocument | null
    locked: boolean
}

export function copySignableDocumentFields(fields: DeepReadonly<SignableDocumentFields>): SignableDocumentFields {
    return {
        id: fields.id,

        createdAt: fields.createdAt != null
            ? new Date(fields.createdAt.getTime())
            : null,

        modifiedAt: fields.modifiedAt != null
            ? new Date(fields.modifiedAt.getTime())
            : null,

        initial: fields.initial != null
            ? copyUiDocument(fields.initial)
            : null,

        signed: fields.signed != null
            ? copyUiDocument(fields.signed)
            : null,

        locked: fields.locked,
    }
}

export function signableDocumentFromFields(fields: DeepReadonly<SignableDocumentFields>): SignableDocument {
    return new SignableDocument({
        id: fields.id,
        createdAt: fields.createdAt,
        modifiedAt: fields.modifiedAt,

        initialId: fields.initial?.document?.id ?? fields.initial?.id,
        signedId: fields.signed?.document?.id ?? fields.signed?.id,
        locked: fields.locked,
    })
}

export function signableDocumentToFields(document: SignableDocument): SignableDocumentFields {
    return {
        id: document.id,
        createdAt: new Date(document.createdAt.getTime()),
        modifiedAt: new Date(document.modifiedAt.getTime()),

        initial: document.initialId != null
            ? {
                id: document.initialId,
                status: "loading",
            }
            : null,

        signed: document.signedId != null
            ? {
                id: document.signedId,
                status: "loading",
            }
            : null,

        locked: document.locked,
    }
}

export function signableDocumentFieldsToRequest(fields: DeepReadonly<SignableDocumentFields>): SignableDocumentRequest {
    return {
        initialId: fields.initial?.document?.id ?? fields.initial?.id,
        signedId: fields.signed?.document?.id ?? fields.signed?.id,
        locked: fields.locked,
    }
}
