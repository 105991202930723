export type Schema =
    | HttpsSchema
    | HttpSchema

export type HttpsSchema = typeof HTTPS_SCHEMA
export type HttpSchema = typeof HTTP_SCHEMA

export const HTTPS_SCHEMA = "https"
export const HTTP_SCHEMA = "http"

export const SCHEMA_SEP = "://"

export const HTTPS_SCHEMA_WITH_SEP = HTTPS_SCHEMA + SCHEMA_SEP
export const HTTP_SCHEMA_WITH_SEP = HTTP_SCHEMA + SCHEMA_SEP

export function isSchema(s: string): s is Schema {
    switch (s) {
        case HTTPS_SCHEMA:
        case HTTP_SCHEMA:
            s satisfies Schema
            return true

        default:
            return false
    }
}

export function startsWithSchema(uri: string): boolean {
    return uri.startsWith(HTTPS_SCHEMA_WITH_SEP)
        || uri.startsWith(HTTP_SCHEMA_WITH_SEP)
}
