import Decimal from "decimal.js"
import { ForwardedRef, forwardRef } from "react"
import { Money } from "model"
import { DeepReadonly } from "my-util"
import { useStateWithDeps } from "ui/hook"
import { Flex } from "ui/ui/layout"
import { DecimalInput } from "ui/ui/numeric"
import { Information, Label } from "ui/ui/output"
import { CurrencySelect } from "../CurrencySelect"
import style from "./style.module.css"

export namespace MoneyInput {
    export interface Props {
        onChange?: (money: Money) => void
        value?: Money | Money.CreationOptions | null
        precision?: number

        width?: string
        currencyWidth?: string
        amountWidth?: string
        gap?: string

        invalid?: boolean
        disabled?: boolean
        readonly?: boolean
        readonlyCurrency?: boolean
        readonlyAmount?: boolean
        loading?: boolean

        autoFocus?: boolean

        label?: string
        placeholder?: string
        information?: string

        onIconClick?: () => void
        iconSrc?: string
        iconAlt?: string
        iconFilter?: string
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MoneyInput = forwardRef((
    {
        onChange, value, precision,
        width, currencyWidth, amountWidth, gap,
        invalid, disabled, readonly, readonlyCurrency, readonlyAmount, loading,
        autoFocus,
        label, placeholder, information,
        onIconClick, iconSrc, iconAlt, iconFilter,
    }: DeepReadonly<MoneyInput.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    // State

    const [innerValue, setInnerValue] = useStateWithDeps(() => Money.createOrPass(value), [value])

    // Render

    return <div className={style.MoneyInput}
                style={{ width }}
                ref={ref}>
        <Flex gap="4px">
            <Label text={label}/>

            <div className={style.inputContainer}>
                <CurrencySelect onSelect={onCurrencyChange}
                                selected={innerValue.currency}

                                width={currencyWidth ?? "100px"}

                                disabled={disabled}
                                readonly={readonly || readonlyCurrency}
                                loading={loading}/>

                <div
                    className={style.amountInputContainer}
                    style={{
                        width: amountWidth,
                        marginLeft: gap,
                    }}
                >
                    <DecimalInput onChange={onAmountChange}
                                  value={innerValue.amount}
                                  precision={precision}

                                  invalid={invalid}
                                  disabled={disabled}
                                  readonly={readonly || readonlyAmount}
                                  loading={loading}

                                  autoFocus={autoFocus}

                                  placeholder={placeholder}

                                  onIconClick={onIconClick}
                                  iconSrc={iconSrc}
                                  iconAlt={iconAlt}
                                  iconFilter={iconFilter}/>
                </div>
            </div>

            <Information type={invalid ? "error" : "regular"}
                         text={information}/>
        </Flex>
    </div>

    // Events

    function onCurrencyChange(newCurrency: string) {
        setInnerValue(oldInnerMoney => {
            const newInnerMoney = oldInnerMoney.copy({
                currency: newCurrency,
            })

            onChange?.(newInnerMoney)

            return newInnerMoney
        })
    }

    function onAmountChange(newAmount: Decimal) {
        setInnerValue(oldInnerMoney => {
            const newInnerMoney = oldInnerMoney.copy({
                amount: newAmount,
            })

            onChange?.(newInnerMoney)

            return newInnerMoney
        })
    }
})

MoneyInput.displayName = "MoneyInput"
