import { useTranslation } from "react-i18next"
import { ErrorPage } from "../ErrorPage"

export namespace SessionExpiredErrorPage {
    export interface Props {
        message?: string
    }
}

export function SessionExpiredErrorPage({ message }: Readonly<SessionExpiredErrorPage.Props>) {
    const [t] = useTranslation()

    return <ErrorPage
        header={{
            text: t("errors.pages.sessionExpired.header"),
            fontSize: "36px",
        }}

        message={{
            text: message ?? t("errors.pages.sessionExpired.defaultMessage"),
            fontSize: "16px",
        }}

        linkType="relogin"
    />
}
