import { CSSProperties, ForwardedRef, forwardRef } from "react"
import { useTranslation } from "react-i18next"
import { ApiError, NetworkError } from "api"
import { DeepReadonly } from "my-util"
import style from "./style.module.css"

export namespace ErrorText {
    export interface Props {
        error?: unknown

        textAlign?: CSSProperties["textAlign"]
        fontSize?: string
        fontWeight?: string

        apiErrorMessageMapping?: ApiErrorMessageMapping
        doNotUseDefaultApiErrorMessageMapping?: boolean
    }

    export interface ApiErrorMessageMapping {
        [statusCode: number]: string | undefined
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ErrorText = forwardRef((
    {
        error,
        textAlign, fontSize, fontWeight,
        apiErrorMessageMapping, doNotUseDefaultApiErrorMessageMapping,
    }: DeepReadonly<ErrorText.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const [t] = useTranslation()

    if (error instanceof ApiError)
        error = apiErrorMessageMapping?.[error.statusCode]
            ?? (
                doNotUseDefaultApiErrorMessageMapping
                    ? error
                    : t([`errors.${error.statusCode}` as any, "errors.unspecific"])
            )

    if (error instanceof NetworkError)
        error = t("errors.networkError")

    const display = error
        ? undefined
        : "none"

    const message = error instanceof Error
        ? error.message
        : String(error)

    return <span className={style.ErrorText}
                 style={{ display, textAlign, fontSize, fontWeight }}
                 ref={ref}>
        {message}
    </span>
})

ErrorText.displayName = "ErrorText"
