import { WithCreatorId } from "model/interfaces"
import { AbstractModelObject } from "model/AbstractModelObject"

import { collapseWhiteSpace, DeepReadonly, Nullable,
         Nullish, removeWhiteSpace, tryNormalizeNullableUuid } from "my-util"

export namespace Document {
    export interface OptionsBase
        extends
            Nullish<WithCreatorId>,
            Nullish<{
                mimeType: string
                size: number
                blob: Blob
            }>
    {
        name: string
    }

    export interface CreationOptions
        extends
            AbstractModelObject.CreationOptions,
            OptionsBase
    {}

    export interface CopyOptions
        extends
            AbstractModelObject.CopyOptions,
            Nullish<OptionsBase>
    {}
}

export class Document
    extends
        AbstractModelObject<Document.CopyOptions>

    implements
        Readonly<Nullable<WithCreatorId>>
{
    static createOrPass(arg: Document | DeepReadonly<Document.CreationOptions>): Document {
        return arg instanceof Document
            ? arg
            : new Document(arg)
    }

    // Fields

    // - Creator ID

    readonly creatorId: string | null

    // - File

    readonly name: string
    readonly mimeType: string
    readonly size: number
    readonly blob: Blob | null

    // Constructor

    constructor(options: DeepReadonly<Document.CreationOptions>) {
        super(options)

        this.creatorId = tryNormalizeNullableUuid(options.creatorId)

        this.name = collapseWhiteSpace(options.name)
        this.mimeType = removeWhiteSpace(options.mimeType ?? "text/plain")
        this.size = options.size ?? 0
        this.blob = options.blob ?? null
    }

    // Copy

    protected override createCopy(options: DeepReadonly<Document.CopyOptions> = {}): Document {
        return new Document({
            // Creator ID

            creatorId: "creatorId" in options
                ? options.creatorId
                : this.creatorId,

            // File

            name: options.name ?? this.name,
            mimeType: options.mimeType ?? this.mimeType,
            size: options.size ?? this.size,

            blob: "blob" in options
                ? options.blob
                : this.blob,

            // Basic

            id: options.id ?? this.id,
            createdAt: options.createdAt ?? this.createdAt,
            modifiedAt: options.modifiedAt ?? this.modifiedAt,
        })
    }
}
