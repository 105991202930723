import { CSSProperties, ForwardedRef, forwardRef, ReactNode } from "react"
import style from "./style.module.css"

export namespace Pane {
    export interface Props {
        onHeaderClick?: () => void

        header?: ReactNode
        children?: ReactNode

        width?: string
        height?: string

        padding?: string
        headerPadding?: string
        contentPadding?: string

        overflow?: CSSProperties["overflow"]
        headerOverflow?: CSSProperties["overflow"]
        contentOverflow?: CSSProperties["overflow"]

        backgroundColor?: string

        paneStyle?: Style
    }

    export type Style =
        | "page"
        | "widget"
}


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Pane = forwardRef((
    props: Readonly<Pane.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const {
        onHeaderClick,
        header, children,
        padding, headerPadding, contentPadding,
        overflow, headerOverflow, contentOverflow,
        backgroundColor,
    } = props

    const paneStyle = props.paneStyle ?? "page"
    const page = paneStyle === "page"

    const width = props.width ?? (page ? "100%" : "fit-content")
    const height = props.height ?? (page ? "100%" : "fit-content")

    return <div style={{ width, height, backgroundColor }}
                className={style.Pane}
                ref={ref}>
        {renderHeader()}
        {renderContent()}
    </div>

    function renderHeader(): ReactNode {
        if (!header)
            return null

        return <div onClick={onHeaderClick}
                    className={style.header}
                    style={{
                        padding: headerPadding ?? padding,
                        overflow: headerOverflow ?? overflow,
                        cursor: onHeaderClick ? "pointer" : undefined,
                    }}>
            {header}
        </div>
    }

    function renderContent(): ReactNode {
        return <div className={style.content}
                    style={{
                        padding: contentPadding ?? padding,
                        overflow: contentOverflow ?? overflow,
                    }}>
            {children}
        </div>
    }
})

Pane.displayName = "Pane"
