import { joinSubpaths, MonthIndex, Nullish,
         createPath as createAnyPath, arrayOfNonNull } from "my-util"

import * as MainPage from "ui/page/sections/main/MainPage/path"

// Creation

export interface PathCreationOptions extends Nullish<{
    scope: Scope
    year: number
    monthIndex: MonthIndex

    dayIndex: number
}> {}

export function createPath(
    { scope, year, monthIndex, dayIndex }: Readonly<PathCreationOptions> = {},
): string {
    return createAnyPath({
        subpaths: [PATH],
        encodeSubpaths: false,

        params: arrayOfNonNull(
            scope != null
                ? [SCOPE_SEARCH_PARAM, scope]
                : null,

            year != null
                ? [YEAR_SEARCH_PARAM, year.toFixed(0)]
                : null,

            monthIndex != null
                ? [MONTH_SEARCH_PARAM, (monthIndex + 1).toFixed(0)]
                : null,

            dayIndex != null
                ? [DAY_SCOPE, (dayIndex + 1).toFixed(0)]
                : null,
        ),
    })
}

// Scope

export function isScope(s: unknown): s is Scope {
    switch (s) {
        case YEAR_SCOPE:
        case MONTH_SCOPE:
        case WEEK_SCOPE:
        case DAY_SCOPE:
            s satisfies Scope
            return true

        default:
            return false
    }
}

export type Scope =
    | typeof YEAR_SCOPE
    | typeof MONTH_SCOPE
    | typeof WEEK_SCOPE
    | typeof DAY_SCOPE

export const YEAR_SCOPE = "year"
export const MONTH_SCOPE = "month"
export const WEEK_SCOPE = "week"
export const DAY_SCOPE = "day"

// Search params

export const SCOPE_SEARCH_PARAM = "scope"
export const YEAR_SEARCH_PARAM = "year"
export const MONTH_SEARCH_PARAM = "month"
export const DAY_SEARCH_PARAM = "day"

// Path

export const PATH = joinSubpaths([MainPage.PATH, "/calendar"])
