import { ForwardedRef, forwardRef } from "react"
import { DeepReadonly } from "my-util"
import { Button as ButtonComponent } from "ui/ui/input"
import { ErrorText } from "ui/ui/output"
import { Flex } from "ui/ui/layout"
import style from "./style.module.css"

export namespace FormControls {
    export interface Props {
        buttons?: (Button | undefined | null)[]

        loading?: boolean
        disabled?: boolean

        width?: string
        mobile?: boolean
        wrap?: boolean

        error?: unknown
        apiErrorMessageMapping?: ErrorText.ApiErrorMessageMapping
    }

    export interface Button extends ButtonComponent.Props {
        position: Button.Position
        hidden?: boolean
    }

    export namespace Button {
        export type Position =
            | "left"
            | "right"
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FormControls = forwardRef((
    {
        buttons,
        loading, disabled,
        width, mobile, wrap,
        error, apiErrorMessageMapping,
    }: DeepReadonly<FormControls.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    return <div className={mobile ? style.MobileFormControls : style.FormControls}
                style={{ width }}
                ref={ref}>
        <div className={style.leftButtons}>
            {renderButtons("left")}
        </div>

        <div className={style.error}>
            <ErrorText apiErrorMessageMapping={apiErrorMessageMapping}
                       error={error}/>
        </div>

        <div className={style.rightButtons}>
            {renderButtons("right")}
        </div>
    </div>

    function renderButtons(position: FormControls.Button.Position) {
        const filteredButtons = buttons?.filter(button =>
            button != null &&
            !button.hidden &&
            button.position === position
        ) ?? [] as DeepReadonly<FormControls.Button[]>

        return <Flex width="fit-content"
                     justify="start"
                     direction="row"
                     gap="8px"
                     wrap={wrap}>
            {
                filteredButtons.map((button, i) =>
                    <ButtonComponent width="fit-content"
                                     loading={loading}
                                     disabled={disabled}
                                     {...button}
                                     key={i}/>
                )
            }
        </Flex>
    }
})

FormControls.displayName = "FormControls"
