import { z } from "zod"
import { OtpResponseSchema } from "./otp"

// Status

export type MfaResponseStatus =
    | SuccessMfaResponseStatus
    | VerificationNeededMfaResponseStatus

export type SuccessMfaResponseStatus = typeof SUCCESS_MFA_RESPONSE_STATUS
export type VerificationNeededMfaResponseStatus = typeof VERIFICATION_NEEDED_MFA_RESPONSE_STATUS

export const SUCCESS_MFA_RESPONSE_STATUS = "success"
export const VERIFICATION_NEEDED_MFA_RESPONSE_STATUS = "verification-needed"

// Success

export const SuccessMfaResponseSchema = z.object({
    status: z.literal(SUCCESS_MFA_RESPONSE_STATUS),
    body: z.any(),
})

export type SuccessMfaResponse = z.infer<typeof SuccessMfaResponseSchema>

// Verification needed

export const VerificationNeededMfaResponseSchema = OtpResponseSchema.extend({
    status: z.literal(VERIFICATION_NEEDED_MFA_RESPONSE_STATUS),
})

export type VerificationNeededMfaResponse = z.infer<typeof VerificationNeededMfaResponseSchema>

// United

export const MfaResponseSchema = z.never()
    .or(SuccessMfaResponseSchema)
    .or(VerificationNeededMfaResponseSchema)

export type MfaResponse = z.infer<typeof MfaResponseSchema>
