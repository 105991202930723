import { ForwardedRef, forwardRef } from "react"
import { UserRole, getUserRoleName } from "model"
import { DeepReadonly } from "my-util"
import { Output } from "ui/ui/output"

export namespace UserRoleOutput {
    export interface Props extends Omit<Output.Props, "children"> {
        role: UserRole
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserRoleOutput = forwardRef((
    props: DeepReadonly<UserRoleOutput.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) =>
    <Output {...props as UserRoleOutput.Props}
            ref={ref}>
        {getUserRoleName(props.role)}
    </Output>
)

UserRoleOutput.displayName = "UserRoleOutput"
