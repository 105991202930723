import assert from "assert"
import { ReactNode, useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { VerificationNeededMfaResponse,
         cancelPasswordChange, completePasswordChange,
         getPasswordChangeConfig, startPasswordChange } from "api"

import { validatePassword } from "validation"
import { UserContext } from "ui/context"
import * as LoginPage from "ui/page/auth/LoginPage/path"
import * as MainPage from "ui/page/sections/main/MainPage/path"
import { Button, CapsLockDetector, Flex, Form, FormControls, Input, Limit, Loading } from "ui/ui"
import { MfaModal } from "ui/component"

export namespace PasswordTab {
    export interface Props {
        mobile?: boolean
    }
}

export function PasswordTab({ mobile }: Readonly<PasswordTab.Props>) {
    const [t] = useTranslation()

    const [localUser] = useContext(UserContext)

    const navigate = useNavigate()

    // State

    const [oldPassword, setOldPassword] = useState(undefined as string | undefined)
    const oldPasswordInputInvalid = oldPassword != null && validatePassword(oldPassword) != null
    const oldPasswordInvalid = oldPassword == null || oldPasswordInputInvalid

    const [newPassword, setNewPassword] = useState(undefined as string | undefined)
    const newPasswordInputInvalid = newPassword != null && validatePassword(newPassword) != null
    const newPasswordInvalid = newPassword == null || newPasswordInputInvalid

    const [loginRequiredAfter, setLoginRequiredAfter] = useState(true)

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(undefined as unknown)

    const [mfaResponse, setMfaResponse] = useState(
        undefined as VerificationNeededMfaResponse | undefined
    )

    // Config loading

    useEffect(() => {
        const controller = new AbortController()

        getPasswordChangeConfig(controller.signal)
            .then(({ isLoginRequiredAfter }) => setLoginRequiredAfter(isLoginRequiredAfter) )
            .catch(error => {
                if (!controller.signal.aborted)
                    console.error(error)
            })

        return () => controller.abort()
    }, [])

    // Render

    return <>
        <Form onSubmit={onSubmit}
              loading={loading}
              height="100%">
            <Flex justify="space-between"
                  align="start"
                  height="100%">
                {renderFields()}
                {renderControls()}
            </Flex>
        </Form>

        {renderLoadingIfNeeded()}
        {renderMfaModalIfNeeded()}
    </>

    function renderFields(): ReactNode {
        return <Limit maxWidth="400px">
            <Flex direction="column"
                  align="start">
                <Input onChange={setOldPassword}
                       value={oldPassword}

                       label={t("misc.labels.oldPassword")}
                       placeholder={t("misc.placeholders.oldPassword")}

                       invalid={oldPasswordInputInvalid}
                       loading={loading}

                       autoComplete="off"
                       type="password"/>

                <Input onChange={setNewPassword}
                       value={newPassword}

                       label={t("misc.labels.newPassword")}
                       placeholder={t("misc.placeholders.newPassword")}

                       invalid={newPasswordInputInvalid}
                       loading={loading}

                       autoComplete="off"
                       type="password"/>

                <Button text={t("misc.actions.changePassword")}

                        disabled={oldPasswordInvalid || newPasswordInvalid}
                        loading={loading}

                        width="max(50%, 140px)"

                        type="submit"/>

                <CapsLockDetector/>
            </Flex>
        </Limit>
    }

    function renderControls(): ReactNode {
        return <FormControls
            error={error}
            apiErrorMessageMapping={{
                422: t("errors.invalidPassword"),
            }}

            buttons={[{
                onClick() { navigate(-1) },
                text: t("misc.actions.back"),
                buttonStyle: "outline",
                position: "right",
            }]}

            mobile={mobile}
        />
    }

    function renderLoadingIfNeeded(): ReactNode {
        return loading
            ? <Loading opacity={.4}
                       fixed/>

            : null
    }

    function renderMfaModalIfNeeded(): ReactNode {
        if (mfaResponse == null)
            return

        return <MfaModal {...mfaResponse}
                         onSuccess={onMfaSuccess}
                         onClose={onCancelMfa}/>
    }

    // Events

    async function onSubmit() {
        setLoading(true)

        try {
            const response = await startPasswordChange()

            if (response.status === "verification-needed") {
                setMfaResponse(response)
                setError(undefined)
                return
            }

            await changePassword()

            setError(undefined)
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }

    async function onMfaSuccess() {
        setMfaResponse(undefined)

        setLoading(true)

        try {
            await changePassword()
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }

    function onCancelMfa() {
        setMfaResponse(undefined)
    }

    // Util

    async function changePassword() {
        assert(oldPassword != null && newPassword != null)

        try {
            await completePasswordChange({
                oldPassword,
                newPassword,
            })

            setNewPassword(undefined)
            setOldPassword(undefined)

            if (loginRequiredAfter)
                navigateToLoginOrMainPage()
        } catch (error) {
            await cancelPasswordChange()
            throw error
        }
    }

    function navigateToLoginOrMainPage() {
        const newPath = localUser != null
            ? LoginPage.createPath(localUser.id)
            : MainPage.PATH

        navigate(newPath)
    }
}
