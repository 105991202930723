// Types

import { DeepReadonly, Nullish, Comparator } from "my-util"
import { User } from "../User"
import { type Transfer } from "./Transfer"

export type TransferSortBy =
    | TransferSortByCompany
    | TransferSortByAgent
    | TransferSortByDate

export type TransferSortByCompany = typeof COMPANY_TRANSFER_SORT_BY
export type TransferSortByAgent = typeof AGENT_TRANSFER_SORT_BY
export type TransferSortByDate = typeof DATE_TRANSFER_SORT_BY

// Consts

export const COMPANY_TRANSFER_SORT_BY = "company"
export const AGENT_TRANSFER_SORT_BY = "agent"
export const DATE_TRANSFER_SORT_BY = "date"

// Comparator

export interface TransferSortByComparatorGettingOptions extends Nullish<{
    users: Map<string, User> | Iterable<User>
    reverse: boolean
}> {}

export function getTransferSortByComparator(
    sortBy: TransferSortBy,
    options: DeepReadonly<TransferSortByComparatorGettingOptions> = {},
): Comparator<Transfer> {
    const usersById = User.groupByIdOrPassOrCreate(options.users)
    const baseComparator = getBaseComparator()

    return options.reverse
        ? (lhs, rhs) => baseComparator(rhs, lhs)
        : baseComparator

    function getBaseComparator(): Comparator<Transfer> {
        switch (sortBy) {
            case COMPANY_TRANSFER_SORT_BY:
                return (lhs, rhs) => {
                    const lhsCreator = lhs.creatorId != null
                        ? usersById.get(lhs.creatorId)
                        : undefined

                    const rhsCreator = rhs.creatorId != null
                        ? usersById.get(rhs.creatorId)
                        : undefined

                    if (lhsCreator == null && rhsCreator == null)
                        return rhs.createdAt.getTime() - lhs.createdAt.getTime()

                    if (lhsCreator == null)
                        return 1

                    if (rhsCreator == null)
                        return -1

                    const lhsCompany = lhsCreator.company.anyName
                    const rhsCompany = rhsCreator.company.anyName

                    if (lhsCompany == null && rhsCompany == null)
                        return lhsCreator.name.localeCompare(rhsCreator.name)

                    if (lhsCompany == null)
                        return 1

                    if (rhsCompany == null)
                        return -1

                    return lhsCompany.localeCompare(rhsCompany)
                }

            case AGENT_TRANSFER_SORT_BY:
                return (lhs, rhs) => {
                    const lhsCreator = lhs.creatorId != null
                        ? usersById.get(lhs.creatorId)
                        : undefined

                    const lhsAgent = lhsCreator?.creatorId != null
                        ? usersById.get(lhsCreator.creatorId)
                        : undefined


                    const rhsCreator = rhs.creatorId != null
                        ? usersById.get(rhs.creatorId)
                        : undefined

                    const rhsAgent = rhsCreator?.creatorId != null
                        ? usersById.get(rhsCreator.creatorId)
                        : undefined


                    if (lhsAgent == null && rhsAgent == null)
                        return rhs.createdAt.getTime() - lhs.createdAt.getTime()

                    if (lhsAgent == null)
                        return 1

                    if (rhsAgent == null)
                        return -1

                    return lhsAgent.name.localeCompare(rhsAgent.name)
                }

            case DATE_TRANSFER_SORT_BY:
                return (lhs, rhs) => {
                    if (lhs.moment == null && rhs.moment == null)
                        return rhs.createdAt.getTime() - lhs.createdAt.getTime()

                    if (lhs.moment == null)
                        return 1

                    if (rhs.moment == null)
                        return -1

                    return lhs.moment.getTime() - rhs.moment.getTime()
                }
        }
    }
}
