import assert from "assert"
import { ForwardedRef, forwardRef, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { crossIconUrl, fileIconUrl, infoIconUrl } from "images"
import { tryDeleteDocumentById, downloadDocumentById, uploadDocuments, getDocumentById } from "api"
import { Document } from "model"
import { DeepReadonly, downloadBlob, downloadFile } from "my-util"
import { useStateWithDeps } from "ui/hook"
import { Button, ErrorText, FileSize, Flex, Icon, Loading, ProgressBar, Tooltip } from "ui/ui"

import { UiDocument,
         AbortedUiDocument, copyUiDocument,
         FailedToLoadUiDocument, FailedToUploadUiDocument,
         ReadyUiDocument, RemovedUiDocument, UiDocumentStatus } from "../UiDocument"

import style from "./style.module.css"

export namespace DocumentUpload {
    export interface Props {
        onChange?: (document: UiDocument) => void

        onFailedToUpload?: (error: unknown, file: File) => void
        onFailedToLoad?: (error: unknown, documentId: string) => void
        onError?: (error: unknown) => void

        onDocumentReady?: (document: Document) => void
        onProgress?: (loaded: number, total: number) => void

        onRemoved?: () => void
        onAborted?: () => void

        document: UiDocument

        noDelete?: boolean

        readonly?: boolean
        disabled?: boolean
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DocumentUpload = forwardRef((
    {
        onChange,
        onFailedToUpload,onFailedToLoad, onError,
        onDocumentReady, onProgress,
        onRemoved, onAborted,
        document,
        noDelete,
        readonly, disabled,
    }: DeepReadonly<DocumentUpload.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const [t] = useTranslation()

    // State

    const changedRef = useRef(false)

    const [innerDocument, setInnerDocument] = useStateWithDeps<UiDocument>(
        oldInnerDocument =>
            !changedRef.current || oldInnerDocument == null
                ? copyUiDocument(document)
                : oldInnerDocument,

        [document],
    )

    const [loadingProgress, setLoadingProgress] = useState(0)
    const [abort, setAbort] = useState(() => () => {})

    // Effects

    // - Changes propagation

    useEffect(() => {
        if (changedRef.current) {
            changedRef.current = false
            onChange?.(copyUiDocument(innerDocument))
        }
    }, [innerDocument, onChange])

    // - Uploading

    useEffect(() => {
        if (innerDocument.status !== "uploading" || innerDocument.paused)
            return

        const controller = new AbortController()

        uploadDocuments([innerDocument.file], {
            onSuccess(uploadedDocument) {
                if (uploadDocuments.length > 0)
                    onInnerReady(uploadedDocument[0])
                else
                    onInnerRemoved()
            },

            onProgress: onInnerProgress,

            onError(error) {
                if (!controller.signal.aborted)
                    onInnerFailedToUpload(error)
            },

            signal: controller.signal,
        })

        setAbort(() => () => {
            onInnerProgress(0, 1)
            controller.abort()
            onInnerAbort()
        })

        return () => {
            onInnerProgress(0, 1)
            controller.abort()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [innerDocument])

    // - Loading

    useEffect(() => {
        if (innerDocument.status !== "loading" || innerDocument.paused)
            return

        const controller = new AbortController()

        getDocumentById(innerDocument.id, controller.signal)
            .then(onInnerReady)
            .catch(error => {
                if (!controller.signal.aborted)
                    onInnerFailedToLoad(error)
            })

        return () => controller.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [innerDocument])

    // - State notification

    useEffect(() => {
        if (innerDocument.status === document.status)
            return

        switch (innerDocument.status) {
            case "uploading":
            case "loading":
                break

            case "uploading-failed":
                onFailedToUpload?.(innerDocument.error, innerDocument.file)
                onError?.(innerDocument.error)
                break

            case "loading-failed":
                onFailedToLoad?.(innerDocument.error, innerDocument.id)
                onError?.(innerDocument.error)
                break

            case "ready":
                onDocumentReady?.(innerDocument.document)
                break

            case "removed":
                onRemoved?.()
                break

            case "aborted":
                onAborted?.()
                break

            default:
                innerDocument satisfies never
        }
    }, [
        innerDocument, document,
        onFailedToUpload, onFailedToLoad, onError,
        onDocumentReady,
        onRemoved, onAborted,
    ])

    // Render

    return render()

    function render(): JSX.Element {
        switch (innerDocument.status) {
            case "uploading":
                return renderUploading()

            case "uploading-failed":
                return renderFailedUploading()

            case "loading":
                return renderLoading()

            case "loading-failed":
                return renderFailedLoading()

            case "ready":
                return renderReady()

            case "aborted":
            case "removed":
                return renderRemovedOrAborted()

            default:
                return innerDocument satisfies never
        }
    }

    function renderUploading(): JSX.Element {
        assert(innerDocument.status === "uploading")

        return <div className={style.Uploading}
                    ref={ref}>
            <div className={style.filename}>
                {innerDocument.file.name}
            </div>

            <div className={style.progressPercents}>
                {`${Math.trunc(100 * loadingProgress)}%`}
            </div>

            <div className={style.progressBar}>
                <ProgressBar progress={loadingProgress}/>
            </div>

            <div className={style.cancel}>
                <Button text={t("misc.actions.cancel")}
                        type="button"
                        buttonStyle="text"
                        onClick={abort}/>
            </div>
        </div>
    }

    function renderReady(): JSX.Element {
        assert(innerDocument.status === "ready")
        return renderReadyOrFailedUploading(false)
    }

    function renderFailedUploading(): JSX.Element {
        assert(innerDocument.status === "uploading-failed")
        return renderReadyOrFailedUploading(true)
    }

    function renderLoading(): JSX.Element {
        assert(innerDocument.status === "loading")

        return <div className={style.Loading}
                    ref={ref}>
            <Loading opacity={.1}/>
        </div>
    }

    function renderFailedLoading(): JSX.Element {
        assert(innerDocument.status === "loading-failed")

        return <div className={style.FailedLoading}
                    ref={ref}>
            <Tooltip>
                <ErrorText error={innerDocument.error}/>
            </Tooltip>

            <ErrorText error={t("errors.loadingFailed")}/>
        </div>
    }

    function renderReadyOrFailedUploading(failedUploading: boolean): JSX.Element {
        assert(innerDocument.status === "ready" || innerDocument.status === "uploading-failed")

        const documentLike = innerDocument.document ?? innerDocument.file!

        const className = failedUploading
            ? style.FailedUploading
            : style.SuccessfullyUploaded

        return <div className={className}
                    ref={ref}>
            <Tooltip>
                <Flex gap="8px">
                    <div>{documentLike.name}</div>

                    {innerDocument.status === "uploading-failed" &&
                        <ErrorText error={innerDocument.error}/>
                    }
                </Flex>
            </Tooltip>

            <div className={style.info}
                 onClick={onDownload}>
                {failedUploading
                    ? <Icon src={infoIconUrl}
                            alt="Info icon"
                            filter="brightness(0) saturate(100%) invert(32%) sepia(92%) saturate(1760%) hue-rotate(337deg) brightness(92%) contrast(96%)"/>

                    : <Icon src={fileIconUrl}
                            alt="File icon"
                            filter="brightness(0) saturate(100%) invert(51%) sepia(12%) saturate(1568%) hue-rotate(171deg) brightness(91%) contrast(91%)"/>
                }

                <div className={style.filename}>
                    {documentLike.name}
                </div>

                <FileSize bytes={documentLike.size}
                          color="#B3B3B3"
                          fontSize="12px"/>
            </div>

            {!readonly &&
                <Button type="button"
                        buttonStyle="text"
                        width="fit-content"
                        iconSrc={crossIconUrl}
                        iconAlt="Cross icon"
                        disabled={disabled}
                        onClick={onDelete}/>
            }
        </div>
    }

    function renderRemovedOrAborted(): JSX.Element {
        assert(innerDocument.status === "removed" || innerDocument.status === "aborted")

        return <div className={style.Removed}
                    ref={ref}/>
    }

    // Events

    function onDelete() {
        if (!noDelete && innerDocument.document != null)
            tryDeleteDocumentById(innerDocument.document.id)
                .catch(console.error)

        onInnerRemoved()
    }

    function onInnerProgress(loaded: number, total: number) {
        setLoadingProgress(loaded / total)
        onProgress?.(loaded, total)
    }

    function onInnerReady(uploadedDocument: Document) {
        updateInnerDocument((oldDocument): ReadyUiDocument => ({
            ...oldDocument,
            status: "ready",
            document: uploadedDocument,
        }))
    }

    function onInnerFailedToUpload(error: unknown) {
        updateInnerDocument(oldDocument => {
            if (oldDocument.status !== "uploading") {
                warnCannotChangeStatus(oldDocument.status, "uploading-failed")
                return oldDocument
            }

            return {
                ...oldDocument,
                status: "uploading-failed",
                error,
            } satisfies FailedToUploadUiDocument
        })
    }

    function onInnerFailedToLoad(error: unknown) {
        updateInnerDocument((oldDocument): FailedToLoadUiDocument => ({
            ...oldDocument,
            status: "loading-failed",
            error,
        }))
    }

    function onInnerRemoved() {
        updateInnerDocument((oldDocument): RemovedUiDocument => ({
            ...oldDocument,
            status: "removed",
        }))
    }

    function onInnerAbort() {
        updateInnerDocument(oldDocument => {
            if (oldDocument.status !== "uploading") {
                warnCannotChangeStatus(oldDocument.status, "aborted")
                return oldDocument
            }

            return {
                ...oldDocument,
                status: "aborted",
            } satisfies AbortedUiDocument
        })
    }

    function onDownload() {
        switch (innerDocument.status) {
            case "uploading":
            case "uploading-failed":
                downloadFile(innerDocument.file)
                break

            case "ready":
                if (innerDocument.file != null) {
                    downloadFile(innerDocument.file)
                    break
                }

                if (innerDocument.document.blob != null) {
                    downloadBlob(innerDocument.document.blob, innerDocument.document.name)
                    break
                }

                downloadDocumentById(innerDocument.document.id, innerDocument.document.name)

                break
        }
    }

    // Util

    function updateInnerDocument(
        createDocument: (oldDocument: DeepReadonly<UiDocument>) => DeepReadonly<UiDocument>,
    ) {
        setInnerDocument(oldInnerDocument => {
            const newInnerDocument = createDocument(oldInnerDocument)

            if (newInnerDocument !== oldInnerDocument)
                changedRef.current = true

            return newInnerDocument
        })
    }

    type NewType = UiDocumentStatus

    function warnCannotChangeStatus(
        from: UiDocumentStatus,
        to: NewType,
    ) {
        console.warn(`Cannot change ${DocumentUpload.displayName} component's status from ${from} to ${to}`)
    }
})

DocumentUpload.displayName = "DocumentUpload"
