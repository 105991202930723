import { DeepReadonly, Nullish } from "my-util/type"
import { createPath, PathCreationOptions } from "./path"
import { Schema, SCHEMA_SEP, startsWithSchema } from "./Schema"

export interface UriCreationOptions
    extends
        Omit<PathCreationOptions, "relative">,
        Nullish<{
            host: string
            schema: Schema
        }>
{}

export function createUri(options: DeepReadonly<UriCreationOptions> = {}): string {
    const { location } = window

    const host = options.host ?? location.host
    const schema = options.schema ?? location.protocol.replace(":", "") as Schema

    const origin = normalizeUri(host, schema)
    const path = createPath(options)

    const uri = origin + path

    return uri
}

export function normalizeUri(uri: string, schema: Schema = "https"): string {
    uri = uri.trim()

    if (!startsWithSchema(uri))
        uri = schema + SCHEMA_SEP + uri

    return encodeURI(uri)
}
