import { ForwardedRef, forwardRef, useMemo } from "react"
import { getLang } from "i18n"

import { getAllMonthDayNames, normalizeDayOrGetCurrent,
         normalizeMonthIndexOrGetCurrent, normalizeYearOrGetCurrent } from "my-util"

import { Carousel } from "ui/ui/input"

export namespace DayCarousel {
    export interface Props {
        onDayIndexSelect?: (dayIndex: number) => void
        dayIndex?: number
        monthIndex?: number
        year?: number

        maxShowDays?: number
        width?: string
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DayCarousel = forwardRef((
    {
        onDayIndexSelect, dayIndex, monthIndex, year,
        maxShowDays, width,
    }: Readonly<DayCarousel.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const now = useMemo(() => new Date(), [])

    const innerYear = useMemo(
        () => normalizeYearOrGetCurrent(year, now),
        [year, now],
    )

    const innerMonthIndex = useMemo(
        () => normalizeMonthIndexOrGetCurrent(monthIndex, now),
        [monthIndex, now],
    )

    const innerDayIndex = useMemo(
        () => normalizeDayOrGetCurrent(dayIndex, now),
        [dayIndex, now],
    )

    const allDays = useMemo(
        () => getAllMonthDayNames(innerMonthIndex, innerYear, true),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [innerMonthIndex, innerYear, getLang()],
    )

    return <Carousel items={allDays}
                     onSelect={onDayIndexSelect}
                     selected={innerDayIndex}
                     maxShowItems={maxShowDays}
                     width={width}
                     ref={ref}/>
})

DayCarousel.displayName = "DayCarousel"
