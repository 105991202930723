import { arrayOfNonNull, DeepReadonly, Nullish, tryNormalizeNullableUuid } from "my-util"
import { AbstractNotification } from "./AbstractNotification"

import { NotificationType,
         NewProviderNotificationType,
         NEW_PROVIDER_NOTIFICATION_TYPE } from "./NotificationType"

export namespace NewProviderNotification {
    export interface OptionsBase extends AbstractNotification.OptionsBase, Nullish<{
        providerId: string
    }> {}

    export interface CreationOptions
        extends
            Omit<AbstractNotification.CreationOptions<NotificationType>, "type">,
            OptionsBase
    {}

    export interface CopyOptions
        extends
            Omit<AbstractNotification.CopyOptions, "type">,
            Nullish<OptionsBase>
    {}
}

export class NewProviderNotification extends AbstractNotification<
    NewProviderNotificationType,
    NewProviderNotification.CopyOptions
> {
    static createOrPass(
        arg?: NewProviderNotification
            | DeepReadonly<NewProviderNotification.CreationOptions>
            | null,
    ): NewProviderNotification {
        return arg instanceof NewProviderNotification
            ? arg
            : new NewProviderNotification(arg ?? {})
    }

    // Fields

    readonly providerId: string | null

    // Constructor

    constructor(options: DeepReadonly<NewProviderNotification.CreationOptions> = {}) {
        super({ ...options, type: NEW_PROVIDER_NOTIFICATION_TYPE })

        this.providerId = tryNormalizeNullableUuid(options.providerId)
    }

    // User IDs

    override get userIds(): string[] {
        return arrayOfNonNull(this.recipientId, this.providerId)
    }

    // Copy

    protected override createCopy(
        options: DeepReadonly<NewProviderNotification.CopyOptions> = {},
    ): NewProviderNotification {
        return new NewProviderNotification({
            // Recipient ID

            recipientId: "recipientId" in options
                ? options.recipientId
                : this.recipientId,

            // Content

            providerId: "providerId" in options
                ? options.providerId
                : this.providerId,

            // Basic

            id: options.id ?? this.id,
            createdAt: options.createdAt ?? this.createdAt,
            modifiedAt: options.modifiedAt ?? this.modifiedAt,
        })
    }
}
