import { collapseWhiteSpace } from "my-util"
import { ViolationType } from "./ViolationType"

export const MIN_SWIFT_LENGTH = 8
export const MAX_SWIFT_LENGTH = 11

export type SwiftViolationType =
    | ViolationType
    | "missing-bank-code"
    | "too-short-bank-code"
    | "illegal-bank-code-char"
    | "missing-country-code"
    | "too-short-country-code"
    | "illegal-country-code-char"
    | "missing-location-code"
    | "too-short-location-code"
    | "illegal-location-code-char"
    | "too-short-branch-code"
    | "illegal-branch-code-char"

export function validateSwift(swift: string): SwiftViolationType | null {
    swift = collapseWhiteSpace(swift)

    // Bank

    if (swift.length === 0)
        return "missing-bank-code"

    if (swift.length < 4)
        return "too-short-bank-code"

    if (swift.substring(0, 4).match(/^[A-Z]{4}$/ig) == null)
        return "illegal-bank-code-char"

    // Country

    if (swift.length === 4)
        return "missing-country-code"

    if (swift.length < 6)
        return "too-short-country-code"

    if (swift.substring(4, 6).match(/^[A-Z]{2}$/ig) == null)
        return "illegal-country-code-char"

    // Location

    if (swift.length === 6)
        return "missing-location-code"

    if (swift.length < 8)
        return "too-short-location-code"

    if (swift.substring(6, 8).match(/^[A-Z0-9]{2}$/ig) == null)
        return "illegal-location-code-char"

    // Branchless

    if (swift.length === 8)
        return null

    // Branch

    if (swift.length < 11)
        return "too-short-branch-code"

    if (swift.substring(8, 11).match(/^[A-Z0-9]{3}$/ig) == null)
        return "illegal-branch-code-char"

    if (swift.length > 11)
        return "too-long"

    return null
}
