import { AbstractModelObject } from "model/AbstractModelObject"
import { DeepReadonly, map, tryNormalizeUuid } from "my-util"

export namespace ChatMessageBase {
    export interface OptionsBase {
        text?: string | null
        documentIds?: string[] | null
        readByIds?: Iterable<string> | null
        edited?: boolean | null
    }

    export interface CreationOptions
        extends
            AbstractModelObject.CreationOptions,
            OptionsBase
    {}

    export interface CopyOptions
        extends
            AbstractModelObject.CopyOptions,
            OptionsBase
    {}
}

export abstract class ChatMessageBase<CopyOptions extends ChatMessageBase.CopyOptions>
    extends
        AbstractModelObject<CopyOptions>
{
    // Fields

    // - Payload

    readonly text: string | null
    readonly documentIds: readonly string[]

    // - Meta

    readonly readByIds: ReadonlySet<string>
    readonly edited: boolean

    // Constructor

    constructor(options: DeepReadonly<ChatMessageBase.CreationOptions>) {
        super(options)

        // Payload

        this.text = options.text ?? null
        this.documentIds = options.documentIds?.map(tryNormalizeUuid) ?? []

        // Meta

        this.readByIds = new Set(map(options.readByIds ?? [], tryNormalizeUuid))
        this.edited = options.edited ?? false
    }
}
