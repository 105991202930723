import { getTransfersReport } from "api"
import { DeepReadonly, downloadBlob } from "my-util"
import { ForwardedRef, forwardRef, useRef } from "react"
import { useTranslation } from "react-i18next"
import { Clickable, Link } from "ui/ui"

export namespace DownloadTransfersReportLink {
    export interface Props {
        ids: string[]

        whiteSpace?: string
        fontSize?: string
        color?: string

        stopClickPropagation?: boolean
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DownloadTransfersReportLink = forwardRef((
    {
        ids,
        whiteSpace, fontSize, color,
        stopClickPropagation,
    }: DeepReadonly<DownloadTransfersReportLink.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const [t] = useTranslation()

    // State

    const downloadingReportRef = useRef(false)

    // Render

    return <Clickable stopPropagation={stopClickPropagation}
                      onClick={onDownloadReport}
                      ref={ref}>
        <Link text={t("domain.transfers.actions.downloadReport")}

              whiteSpace={whiteSpace}
              fontSize={fontSize}
              color={color}

              to="#"/>
    </Clickable>

    // Events

    async function onDownloadReport() {
        if (downloadingReportRef.current)
            return

        try {
            downloadingReportRef.current = true

            const report = await getTransfersReport({ ids })

            downloadBlob(report, "report.xlsx")
        } catch (error) {
            console.error(error)
        } finally {
            downloadingReportRef.current = false
        }
    }
})

DownloadTransfersReportLink.displayName = "DownloadTransfersReportLink"
