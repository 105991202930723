import { z } from "zod"
import { NEW_INVITE_APPLICATION_NOTIFICATION_TYPE } from "model"
import { UuidSchema } from "my-util"
import { NotificationResponseBaseSchema } from "./base"
import { NotificationTypeResponse } from "./type"

export const NewInviteApplicationNotificationResponseSchema = NotificationResponseBaseSchema.extend({
    type: z.literal(NEW_INVITE_APPLICATION_NOTIFICATION_TYPE satisfies NotificationTypeResponse),

    inviteApplicationId: UuidSchema.nullish(),
})

export type NewInviteApplicationNotificationResponse = z.infer<typeof NewInviteApplicationNotificationResponseSchema>
