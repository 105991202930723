export function applyToAllElementsToWindow(
    element: HTMLElement,
    apply: (element: HTMLElement | Window) => void,
) {
    applyToAllElementsToRoot(element, apply)
    apply(window)
}

export function applyToAllElementsToRoot(element: HTMLElement, apply: (element: HTMLElement) => void) {
    while (true) {
        apply(element)

        if (element.parentElement == null)
            break

        element = element.parentElement
    }
}
