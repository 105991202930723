import { ForwardedRef, forwardRef } from "react"
import { getUserStatusColors, UserStatus, getUserStatusName } from "model"
import { Badge } from "ui/ui/output"

export namespace UserStatusBadge {
    export interface Props {
        status: UserStatus
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserStatusBadge = forwardRef((
    { status }: Readonly<UserStatusBadge.Props>,
    ref: ForwardedRef<HTMLSpanElement>,
) =>
    <Badge { ...getUserStatusColors(status) }
           ref={ref}>
        {getUserStatusName(status)}
    </Badge>
)

UserStatusBadge.displayName = "UserStatusBadge"
