import { ForwardedRef, forwardRef } from "react"
import { getTransferStatusColors, TransferStatus, getTransferStatusName } from "model"
import { Badge } from "ui/ui/output"

export namespace TransferStatusBadge {
    export interface Props {
        status: TransferStatus
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransferStatusBadge = forwardRef((
    { status }: Readonly<TransferStatusBadge.Props>,
    ref: ForwardedRef<HTMLSpanElement>,
) =>
    <Badge { ...getTransferStatusColors(status) }
           ref={ref}>
        {getTransferStatusName(status)}
    </Badge>
)

TransferStatusBadge.displayName = "TransferStatusBadge"
