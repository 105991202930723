import { ForwardedRef, forwardRef, useEffect, useMemo, useRef } from "react"
import { getLang } from "i18n"
import { User } from "model"
import { DeepReadonly, map } from "my-util"
import { useStateWithDeps } from "ui/hook"
import { Flex, CheckBox } from "ui/ui"
import { UserLink } from "../UserLink"

export namespace UserCheckList {
    export interface Props {
        onChange?: (checked: User[]) => void
        checked?: Iterable<string>

        users: Map<string, User> | Iterable<User>

        linkTarget?: string

        readonly?: boolean
        loading?: boolean
        disabled?: boolean

        whiteSpace?: string
        fontSize?: string

        width?: string
        height?: string
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserCheckList = forwardRef((
    {
        onChange, checked,
        users,
        linkTarget,
        readonly, loading, disabled,
        whiteSpace, fontSize,
        width, height,
    }: DeepReadonly<UserCheckList.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    // State

    const [innerChecked, setInnerChecked] = useStateWithDeps(() => new Set(checked), [checked])
    const changedRef = useRef(false)

    const usersById = useMemo(
        () => User.groupByIdOrPass(users) as Map<string, User>,
        [users],
    )

    const innerUsers = useMemo(
        () => [...usersById.values()].sort((lhs, rhs) => lhs.name.localeCompare(rhs.name)),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [usersById, getLang()],
    )

    // Effects

    useEffect(() => {
        if (!changedRef.current)
            return

        onChange?.(
            map(innerChecked, userId => usersById.get(userId))
            .filter(user => user != null) as User[]
        )

        changedRef.current = false
    }, [innerChecked, usersById, onChange])

    // Render

    return <Flex height={height}
                 width={width}
                 ref={ref}>
        {innerUsers.map(user =>
            <Flex direction="row"
                  key={user.id}>
                <CheckBox onChange={checked => onChecked(user, checked)}
                          checked={innerChecked.has(user.id)}
                          loading={loading}
                          readonly={readonly}
                          disabled={disabled}/>

                <UserLink whiteSpace={whiteSpace}
                          fontSize={fontSize}
                          target={linkTarget}
                          user={user}/>
            </Flex>
        )}
    </Flex>

    // Events

    function onChecked(user: User, checked: boolean) {
        setInnerChecked(oldInnerChecked => {
            const newInnerChecked = new Set(oldInnerChecked)

            if (checked)
                newInnerChecked.add(user.id)
            else
                newInnerChecked.delete(user.id)

            changedRef.current = true

            return newInnerChecked
        })
    }
})

UserCheckList.displayName = "UserCheckList"
