import { z } from "zod"

import { NEW_USER_NOTIFICATION_TYPE,
         NEW_INVITE_NOTIFICATION_TYPE,
         NEW_PROVIDER_NOTIFICATION_TYPE,
         NEW_TRANSFER_NOTIFICATION_TYPE,
         NEW_CHAT_MESSAGES_NOTIFICATION_TYPE,
         NEW_INVITE_APPLICATION_NOTIFICATION_TYPE,
         TRANSFER_STATUS_CHANGED_NOTIFICATION_TYPE } from "model"

export const NotificationTypeResponseSchema = z.enum([
    NEW_CHAT_MESSAGES_NOTIFICATION_TYPE,
    NEW_INVITE_APPLICATION_NOTIFICATION_TYPE,
    NEW_INVITE_NOTIFICATION_TYPE,
    NEW_TRANSFER_NOTIFICATION_TYPE,
    NEW_USER_NOTIFICATION_TYPE,
    NEW_PROVIDER_NOTIFICATION_TYPE,
    TRANSFER_STATUS_CHANGED_NOTIFICATION_TYPE,
])

export type NotificationTypeResponse = z.infer<typeof NotificationTypeResponseSchema>
