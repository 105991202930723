import { ForwardedRef, forwardRef } from "react"
import { InviteStatus } from "model"
import { Flex } from "ui/ui/layout"
import { InviteStatusColorLegend } from "../InviteStatusColorLegend"

export namespace InviteStatusColorLegendList {
    export interface Props extends Omit<Flex.Props, "children"> {
        onClick?: (group: InviteStatus) => void

        hideActive?: boolean
        hideExpires?: boolean
        hideExpired?: boolean

        fontSize?: string

        showLegend?: boolean
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InviteStatusColorLegendList = forwardRef((
    props: Readonly<InviteStatusColorLegendList.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const {
        onClick,
        hideActive, hideExpires, hideExpired,
        fontSize,
        showLegend,
    } = props

    return <Flex width="fit-content"
                 direction="row"
                 { ...props }
                 ref={ref}>

        {!hideActive &&
            <InviteStatusColorLegend showLegend={showLegend}
                                     fontSize={fontSize}
                                     onClick={onClick}
                                     status="active"/>
        }

        {!hideExpires &&
            <InviteStatusColorLegend showLegend={showLegend}
                                     fontSize={fontSize}
                                     onClick={onClick}
                                     status="expires"/>
        }

        {!hideExpired &&
            <InviteStatusColorLegend showLegend={showLegend}
                                     fontSize={fontSize}
                                     onClick={onClick}
                                     status="expired"/>
        }
    </Flex>
})

InviteStatusColorLegendList.displayName = "InviteStatusColorLegendList"
