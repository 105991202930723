import { AnonymousUser } from "model/domain"
import { DeepReadonly, Nullish } from "my-util"
import { AbstractNotification } from "./AbstractNotification"

import { NotificationType,
         NewChatMessagesNotificationType,
         NEW_CHAT_MESSAGES_NOTIFICATION_TYPE } from "./NotificationType"

export namespace NewAnonymousChatMessagesNotification {
    export interface OptionsBase extends AbstractNotification.OptionsBase, Nullish<{
        count: number
    }> {}

    export interface CreationOptions
        extends
            Omit<AbstractNotification.CreationOptions<NotificationType>, "type">,
            OptionsBase,
            Nullish<{
                from: AnonymousUser | AnonymousUser.CreationOptions
                count: number
            }>
    {}

    export interface CopyOptions
        extends
            Omit<AbstractNotification.CopyOptions, "type">,
            Nullish<OptionsBase>,
            Nullish<{
                from: AnonymousUser | AnonymousUser.CopyOptions
            }>
    {}
}


export class NewAnonymousChatMessagesNotification extends AbstractNotification<
    NewChatMessagesNotificationType,
    NewAnonymousChatMessagesNotification.CopyOptions
> {
    static createOrPass(
        arg?: NewAnonymousChatMessagesNotification
            | DeepReadonly<NewAnonymousChatMessagesNotification.CreationOptions>
            | null
    ): NewAnonymousChatMessagesNotification {
        return arg instanceof NewAnonymousChatMessagesNotification
            ? arg
            : new NewAnonymousChatMessagesNotification(arg ?? {})
    }

    // Fields

    readonly from: AnonymousUser | null
    readonly count: number

    // Constructor

    constructor(options: DeepReadonly<NewAnonymousChatMessagesNotification.CreationOptions> = {}) {
        super({ ...options, type: NEW_CHAT_MESSAGES_NOTIFICATION_TYPE })

        this.from = options.from != null
            ? AnonymousUser.createOrPass(options.from)
            : null

        this.count = options.count ?? 1
    }

    // Copy

    protected override createCopy(
        options: DeepReadonly<NewAnonymousChatMessagesNotification.CopyOptions> = {},
    ): NewAnonymousChatMessagesNotification {
        return new NewAnonymousChatMessagesNotification({
            // Recipient ID

            recipientId: "recipientId" in options
                ? options.recipientId
                : this.recipientId,

            // Content

            from: AnonymousUser.copyOrPass(this.from, options.from),
            count: options.count ?? this.count,

            // Basic

            id: options.id ?? this.id,
            createdAt: options.createdAt ?? this.createdAt,
            modifiedAt: options.modifiedAt ?? this.modifiedAt,
        })
    }
}
