import assert from "assert"
import { ForwardedRef, forwardRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Provider } from "model"

import { removeWhiteSpaceToNull, RU_NAME_INPUT_REGEX, isBlank,
         capitalizedText, EN_NAME_INPUT_REGEX, collapseWhiteSpaceToNull} from "my-util"

import { validateRuName, validateEmail, validateEnName,
         MAX_SHORT_TEXT_LENGTH, validatePhone, validateShortText } from "validation"

import { ActionModal, DiLangInput, Flex, Limit, EmailInput, PhoneInput } from "ui/ui"

const MAX_WIDTH = "400px"

export namespace ProviderActionModal {
    export interface Props extends Omit<ActionModal.Props, "onYes" | "yesDisabled"> {
        onYes: (provider: Provider) => (Promise<void> | void)

        provider?: Provider
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProviderActionModal = forwardRef((
    props: Readonly<ProviderActionModal.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const { onYes, provider } = props

    const [t] = useTranslation()

    // State

    // - Name

    const [name, setName] = useState<{ en: string, ru: string } | undefined>(
        provider != null
            ? {
                en: provider.enName,
                ru: provider.ruName,
            }

            : undefined
    )

    const nameInvalid =
        name == null ||
        validateRuName(name.ru) != null ||
        validateEnName(name.en) != null

    const nameInputInvalid = name != null && nameInvalid

    // - Company

    const [company, setCompany] = useState({
        en: provider?.enCompany ?? "",
        ru: provider?.ruCompany ?? "",
    })

    const companyInvalid =
        (!isBlank(company.en) && validateShortText(company.en) != null) ||
        (!isBlank(company.ru) && validateShortText(company.ru) != null)

    // - Phone

    const [phone, setPhone] = useState(provider?.phone ?? "")
    const phoneInvalid = !isBlank(phone) && validatePhone(phone) != null

    // - Email

    const [email, setEmail] = useState(provider?.email ?? "")
    const emailInvalid = !isBlank(email) && validateEmail(email) != null

    // - Loading

    const [loading, setLoading] = useState(false)

    // Render

    const saveDisabled =
        nameInvalid ||
        companyInvalid ||
        phoneInvalid ||
        emailInvalid

    return <ActionModal { ...props }
                        onYes={onInnerYes}
                        yesDisabled={saveDisabled}
                        ref={ref}>
        <Flex align="start">
            <DiLangInput onChange={(en, ru) => setName({ en, ru })}
                         enValue={name?.en}
                         ruValue={name?.ru}

                         postProcess={capitalizedText}

                         ruRegex={RU_NAME_INPUT_REGEX}
                         enRegex={EN_NAME_INPUT_REGEX}

                         label={t("domain.providers.labels.name")}
                         placeholder={t("domain.providers.placeholders.name")}

                         loading={loading}
                         invalid={nameInputInvalid}

                         maxWidth={MAX_WIDTH}

                         required/>

            <DiLangInput onChange={(en, ru) => setCompany({ en, ru })}
                         enValue={company.en}
                         ruValue={company.ru}

                         max={MAX_SHORT_TEXT_LENGTH}

                         label={t("domain.providers.labels.company")}
                         placeholder={t("domain.providers.placeholders.company")}

                         loading={loading}
                         invalid={companyInvalid}

                         maxWidth={MAX_WIDTH}/>

            <Limit maxWidth={MAX_WIDTH}>
                <Flex align="start">
                    <PhoneInput onChange={setPhone}
                                value={phone}

                                label={t("messageTargets.labels.phone")}
                                placeholder={t("messageTargets.placeholders.phone")}

                                loading={loading}
                                invalid={phoneInvalid}/>

                    <EmailInput onChange={setEmail}
                                value={email}

                                label={t("messageTargets.labels.email")}
                                placeholder={t("messageTargets.placeholders.email")}

                                loading={loading}
                                invalid={emailInvalid}/>
                </Flex>
            </Limit>
        </Flex>
    </ActionModal>

    // Events

    async function onInnerYes() {
        setLoading(true)

        try {
            assert(name != null)

            const [enFirstname, enLastname, enPatronymic] = name.en
                .trim()
                .split(/\s+/)

            const [ruFirstname, ruLastname, ruPatronymic] = name.ru
                .trim()
                .split(/\s+/)

            const newProvider = new Provider({
                id: provider?.id,
                createdAt: provider?.createdAt,
                modifiedAt: provider?.modifiedAt,

                creatorId: provider?.creatorId,

                enFirstname, enLastname, enPatronymic,
                ruFirstname, ruLastname, ruPatronymic,

                enCompany: collapseWhiteSpaceToNull(company.en),
                ruCompany: collapseWhiteSpaceToNull(company.ru),

                phone: removeWhiteSpaceToNull(phone),
                email: removeWhiteSpaceToNull(email),
            })

            await onYes(newProvider)
        } finally {
            setLoading(false)
        }
    }
})

ProviderActionModal.displayName = "ProviderActionModal"
