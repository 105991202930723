import { CSSProperties, ForwardedRef, forwardRef } from "react"
import { triangleDownIconUrl } from "images"
import { useStateWithDeps } from "ui/hook"
import { Icon } from "ui/ui/icon"
import style from "./style.module.css"
import { arrayOfNonNull } from "my-util"

export namespace ExpandButton {
    export interface Props {
        onClick?: () => void
        expand?: boolean

        width?: CSSProperties["width"]
        height?: CSSProperties["height"]
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExpandButton = forwardRef((
    {
        onClick, expand,
        width, height,
    }: Readonly<ExpandButton.Props>,

    ref: ForwardedRef<HTMLDivElement>,
) => {
    // State

    const [innerExpand, setInnerExpand] = useStateWithDeps(() => expand ?? false, [expand])

    // Render

    return <div
        className={renderClassName()}
        style={{
            width: width ?? Icon.DEFAULT_WIDTH,
            height: height ?? Icon.DEFAULT_HEIGHT,
        }}

        onClick={onInnerClick}

        ref={ref}
    >
        <Icon src={triangleDownIconUrl}
              alt="Arrow icon"
              filter="brightness(.4)"

              width={width}
              height={height}/>
    </div>

    function renderClassName(): string {
        return arrayOfNonNull<string | null>(
            style.ExpandButton,
            innerExpand ? null : style.collapse,
        ).join(" ")
    }

    // Events

    function onInnerClick() {
        setInnerExpand(old => !old)
        onClick?.()
    }
})

ExpandButton.displayName = "ExpandButton"
