import { ForwardedRef, forwardRef, ReactElement, ReactNode, useMemo } from "react"
import { DeepReadonly, filter, isIterable } from "my-util"
import { useStateWithDeps } from "ui/hook"
import { Icon } from "ui/ui/icon"
import { Tab } from "../Tab"
import style from "./style.module.css"

export namespace TabbedView {
    export interface Props {
        onSelect?: (selected: Tab.Index) => void
        selected?: Tab.Index

        children?: Children
        right?: ReactNode

        gap?: string

        width?: string
        height?: string
    }

    export type Children =
        | Iterable<Child>
        | Child

    export type Child =
        | TabElement
        | undefined
        | null
        | false

    export type TabElement = ReactElement<Tab.Props>
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TabbedView = forwardRef((
    {
        onSelect, selected,
        children, right,
        gap,
        width, height,
    }: DeepReadonly<TabbedView.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    // State

    const tabs = useMemo<DeepReadonly<TabbedView.TabElement>[]>(
        () => {
            if (!children)
                return []

            if (isIterable(children))
                return filter(children, Boolean) as DeepReadonly<TabbedView.TabElement>[]

            return [children]
        },

        [children],
    )

    const [innerSelected, setInnerSelected] = useStateWithDeps(
        () => selected ?? tabs[0]?.props?.index ?? 0,
        [selected],
    )

    const selectedTab = useMemo(
        () => {
            for (const [i, tab] of tabs.entries()) {
                const tabIndex = tab.props.index ?? i

                if (innerSelected === tabIndex)
                    return tab
            }

            return null
        },

        [innerSelected, tabs],
    )

    // Render

    return <div style={{ gap, width, height }}
                className={style.TabbedView}
                ref={ref}>
        {renderTop()}
        {renderSelectedTab()}
    </div>

    function renderTop(): ReactNode {
        return <div className={style.top}>
            {renderHeader()}
            {right}
        </div>
    }

    function renderHeader(): ReactNode {
        return <div className={style.header}>
            {tabs.map((tab, i) => {
                if (!tab)
                    return null

                const index = tab.props.index ?? i
                const selected = index === innerSelected

                const className = selected
                    ? style.selectedTab
                    : style.tab

                const {
                    name,
                    width,
                    iconSrc, iconAlt, iconFilter,
                } = tab.props

                return <div onClick={() => onInnerSelected(index)}
                            className={className}
                            style={{ width }}
                            key={index}>
                    {iconSrc &&
                        <Icon src={iconSrc}
                              alt={iconAlt}
                              filter={iconFilter}/>
                    }

                    {name}
                </div>
            })}
        </div>
    }

    function renderSelectedTab(): ReactNode {
        const { Component } = selectedTab?.props ?? {}

        return Component != null
            ? <Component/>
            : null
    }

    // Events

    function onInnerSelected(newInnerSelected: Tab.Index) {
        setInnerSelected(newInnerSelected)
        onSelect?.(newInnerSelected)
    }
})

TabbedView.displayName = "TabbedView"
