import { getUserRoleColor, UserRole } from "model"
import { ForwardedRef, forwardRef } from "react"
import { Arrow } from "ui/ui/shapes"

export namespace UserRoleArrow {
    export interface Props extends Omit<Arrow.Props, "color"> {
        role?: UserRole
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserRoleArrow = forwardRef((
    props: Readonly<UserRoleArrow.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const { role } = props

    const color = role != null
        ? getUserRoleColor(role)
        : undefined

    const arrowStyle = props.arrowStyle ?? Arrow.DEFAULT_STYLE

    const [width, height] = arrowStyle === "stickless"
        ? [props.width ?? "10px", props.height ?? "24px"]
        : [props.width, props.height]

    return <Arrow { ...props }

                  color={color}

                  arrowStyle={arrowStyle}

                  width={width}
                  height={height}

                  ref={ref}/>
})

UserRoleArrow.displayName = "UserRoleArrow"
