import { ForwardedRef, forwardRef } from "react"
import { Icon } from "ui/ui/icon"
import fillStyle from "./style/fill.module.css"
import outlineStyle from "./style/outline.module.css"
import textStyle from "./style/text.module.css"
import loadingStyle from "./style/loading.module.css"

const style = Object.assign({}, fillStyle, outlineStyle, textStyle, loadingStyle)

export namespace Button {
    export type Type =
        | "button"
        | "reset"
        | "submit"

    export type Style =
        | "fill"
        | "outline"
        | "text"

    export interface Props {
        onClick?: () => void

        disabled?: boolean
        loading?: boolean

        critical?: boolean
        buttonStyle?: Style
        width?: string
        height?: string
        padding?: string
        fontSize?: string
        fontWeight?: string

        type?: Type
        text?: string
        autoFocus?: boolean

        iconSrc?: string
        iconAlt?: string
        iconFilter?: string
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Button = forwardRef((
    {
        onClick,
        disabled, loading,
        critical, buttonStyle, width, height, padding, fontSize, fontWeight,
        type, text, autoFocus,
        iconSrc, iconAlt, iconFilter,
    }: Readonly<Button.Props>,
    ref: ForwardedRef<HTMLButtonElement>,
) => {
    const innerButtonStyle = buttonStyle ?? "fill"
    const innerDisabled = disabled || loading

    return <button className={getClassName()}
                   style={{ width, height, padding, fontWeight, fontSize }}
                   disabled={innerDisabled}
                   autoFocus={autoFocus}
                   onClick={onClick}
                   type={type ?? "button"}
                   ref={ref}>
        {iconSrc &&
            <Icon src={iconSrc}
                  alt={iconAlt}
                  filter={iconFilter}
                  width="1em"
                  height="1em"/>
        }

        {text &&
            <span>{text}</span>
        }
    </button>

    function getClassName() {
        const classes = [style[`Button-${innerButtonStyle}-${critical ? "critical" : "nonCritical"}`]]

        if (loading)
            classes.push(style.loading)

        return classes.join(" ")
    }
})

Button.displayName = "Button"
