import { ForwardedRef, forwardRef, Fragment, useMemo } from "react"
import { getLang } from "i18n"
import { Document, User } from "model"
import { DeepReadonly, getNullableUuidColor, group } from "my-util"
import { UserLink } from "ui/component/user"
import { FileSize, Tooltip } from "ui/ui"
import { DocumentLink } from "../DocumentLink"
import style from "./style.module.css"

export namespace DocumentTable {
    export interface Props {
        documents?: Iterable<Document>
        users?: Iterable<User> | Map<string, User>

        showCreators?: boolean

        width?: string
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DocumentTable = forwardRef((
    {
        documents, users,
        showCreators,
        width,
    }: DeepReadonly<DocumentTable.Props>,
    ref: ForwardedRef<HTMLTableElement>,
) => {
    // State

    const innerDocuments = useMemo(
        () => [...documents ?? []],
        [documents],
    )

    const innerUserById = useMemo(
        () => User.groupByIdOrPassOrCreate(users),
        [users],
    )

    const creatorAndDocumentsPairs = useMemo<[User | undefined, Document[]][]>(
        () => [
            ...group(
                innerDocuments,

                ({ creatorId }) => creatorId != null
                    ? innerUserById.get(creatorId) as User | undefined
                    : undefined,
            ).entries()
        ],

        [innerDocuments, innerUserById],
    )

    const sortedCreatorAndDocumentPairs = useMemo(
        () => [...creatorAndDocumentsPairs].sort(([lhs], [rhs]) => {
            if (lhs == null && rhs == null)
                return 0

            if (lhs == null)
                return -1

            if (rhs == null)
                return 1

            return lhs.name.localeCompare(rhs.name)
        }),

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [creatorAndDocumentsPairs, getLang()],
    )

    // Render

    return <table className={style.table}
                  style={{ width }}
                  ref={ref}>
        <tbody>
            {sortedCreatorAndDocumentPairs.map(([creator, creatorDocuments], creatorIndex) => {
                const color = getNullableUuidColor(creator?.id, .2) ?? "#E8F9E0"

                return <Fragment key={creator?.id ?? creatorIndex}>
                    {creatorDocuments.map((document, documentIndex) =>
                        <tr style={{ backgroundColor: color }}
                            key={document.id}>
                            {showCreators && documentIndex === 0 &&
                                <td rowSpan={creatorDocuments.length}
                                    className={style.topText}>
                                    {
                                        creator != null
                                            ? <UserLink user={creator}/>
                                            : "-"
                                    }
                                </td>
                            }

                            <td className={style.name}>
                                <DocumentLink document={document}/>
                                <Tooltip>{document.name}</Tooltip>
                            </td>

                            <td><FileSize bytes={document.size}/></td>
                        </tr>
                    )}

                    <tr className={style.gap}/>
                </Fragment>
            })}
        </tbody>
    </table>
})

DocumentTable.displayName = "DocumentTable"
