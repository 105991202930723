import { z } from "zod"
import { UuidSchema } from "my-util"
import { UserRoleResponseSchema } from "./user/user-role"
import { UserSpecializationResponseSchema } from "./user"

export const WithUserRightsResponseSchema = z.object({
    role: UserRoleResponseSchema,
    specialization: UserSpecializationResponseSchema.nullish(),
    canManageTransfers: z.coerce.boolean(),
    canManageInvites: z.coerce.boolean(),
    canSendInvites: z.coerce.boolean(),
    canManageUsers: z.coerce.boolean(),
    canManageProviders: z.coerce.boolean(),
    canSeeAllUsers: z.coerce.boolean(),
    visibleUserIds: UuidSchema.array().nullish(),
})

export type WithUserRightsResponse = z.infer<typeof WithUserRightsResponseSchema>
