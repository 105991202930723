import { ForwardedRef, forwardRef, useContext } from "react"
import { useNavigate } from "react-router-dom"
import { avatarIconUrl } from "images"
import { UserContext } from "ui/context"
import { createPath } from "ui/page/sections/users/UserPage/path"
import { Icon } from "ui/ui"

export const ProfileButton = forwardRef((_, ref: ForwardedRef<HTMLImageElement>) => {
    const navigate = useNavigate()

    const [localUser] = useContext(UserContext)

    // Render

    return <Icon onClick={onClick}
                 src={avatarIconUrl}
                 alt="Avatar icon"
                 filter="brightness(0) saturate(100%) invert(29%) sepia(37%) saturate(574%) hue-rotate(164deg) brightness(99%) contrast(98%)"
                 cursor="pointer"
                 ref={ref}/>

    // Events

    function onClick() {
        if (localUser == null)
            return

        const localUserProfilePath = createPath(localUser.id)

        navigate(localUserProfilePath)
    }
})

ProfileButton.displayName = "ProfileButton"
