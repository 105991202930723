import { ForwardedRef } from "react"
import { arrowDownIconUrl } from "images"
import { forwardRefAndSetProperties } from "my-util"
import { Icon } from "../Icon"

export namespace ImportIcon {
    export interface Props extends Omit<Icon.Props, "src" | "alt" | "filter"> {}
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ImportIcon = forwardRefAndSetProperties(
    {
        SRC: arrowDownIconUrl as string,
        FILTER: "brightness(0) saturate(100%) invert(53%) sepia(39%) saturate(594%) hue-rotate(95deg) brightness(91%) contrast(95%)" as string,
        ALT: "Import icon" as string,
    } as const,

    (
        props: Readonly<ImportIcon.Props>,
        ref: ForwardedRef<HTMLImageElement>,
    ) =>
        <Icon { ...props }

              src={ImportIcon.SRC}
              filter={ImportIcon.FILTER}
              alt={ImportIcon.ALT}

              ref={ref}/>
)

ImportIcon.displayName = "ImportIcon"
