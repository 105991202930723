import Decimal from "decimal.js"
import { ReactNode, useContext, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { getAllMyTransferAgentPayments, getAllTransferAgentPayments, getAllUsersByIds } from "api"
import { TransferAgentPayment, User } from "model"
import { formatDecimal, uniqueArray } from "my-util"
import { useUsers } from "ui/hook"
import { UserContext } from "ui/context"
import { Error403Page, SessionExpiredErrorPage } from "ui/page/error"
import * as TransferPage from "ui/page/sections/transfers/TransferPage/path"
import { Page, TransferAgentPaymentTable } from "ui/component"
import { ErrorDisplay, ErrorText, Flex, Loading, Padding } from "ui/ui"
import style from "./style.module.css"

export function Component() {
    const [t] = useTranslation()

    const [localUser] = useContext(UserContext)

    const { localUserIsManager, localUserHasRightToManageTransfer } = useMemo(
        () => ({
            localUserIsManager: localUser?.isManager ?? false,
            localUserHasRightToManageTransfer: localUser?.hasRightToManageTransfers ?? false,
        }),

        [localUser],
    )

    const usersStorage = useUsers()

    const navigate = useNavigate()

    // State

    const [payments, setPayments] = useState<TransferAgentPayment[]>([])
    const [loadingPayments, setLoadingPayments] = useState(localUser?.isAgent ?? false)
    const [paymentsLoadingError, setPaymentsLoadingError] = useState(undefined as unknown)

    const { totalPaid, totalPending } = useMemo(() => {
        let totalPaid = new Decimal(0)
        let totalPending = totalPaid

        for (const payment of payments) {
            if (payment.agentPayment == null)
                continue

            if (payment.isPaid)
                totalPaid = totalPaid.add(payment.agentPayment.amount)
            else
                totalPending = totalPending.add(payment.agentPayment.amount)
        }

        return { totalPaid, totalPending }
    }, [payments])

    const [usersById, setUsersById] = useState(new Map<string, User>())
    const [loadingUsers, setLoadingUsers] = useState(localUser?.isAgent ?? false)
    const [usersLoadingError, setUsersLoadingError] = useState(undefined as unknown)

    const [updateError, setUpdateError] = useState(undefined as unknown)

    const loading = loadingPayments || loadingUsers
    const error = paymentsLoadingError ?? usersLoadingError

    // Effects

    // - Payments loading

    useEffect(() => {
        if (!loadingPayments)
            return

        const get = localUserIsManager
            ? getAllTransferAgentPayments
            : getAllMyTransferAgentPayments

        const controller = new AbortController()

        get(controller.signal)
            .then(loadedPayments => {
                setPayments(loadedPayments)
                setLoadingUsers(true)
            })
            .catch(error => {
                if (!controller.signal.aborted)
                    setPaymentsLoadingError(error)
            })
            .finally(() => {
                if (!controller.signal.aborted)
                    setLoadingPayments(false)
            })

        return () => controller.abort()
    }, [loadingPayments, localUserIsManager])

    // - Users loading

    useEffect(() => {
        if (!loadingUsers)
            return

        const idsToLoad = uniqueArray(
            payments
                .flatMap(({ creatorId, agentId }) => [creatorId, agentId])
                .filter(id => id != null) as string[]
        )

        if (idsToLoad.length === 0) {
            setLoadingUsers(false)
            return
        }

        const controller = new AbortController()

        getAllUsersByIds({ ids: idsToLoad }, controller.signal)
            .then(loadedUsers => {
                setUsersById(User.groupById(loadedUsers))
                usersStorage.addAll(loadedUsers)
            })
            .catch(error => {
                if (!controller.signal.aborted)
                    setUsersLoadingError(error)
            })
            .finally(() => {
                if (!controller.signal.aborted)
                    setLoadingUsers(false)
            })

        return () => controller.abort()
    }, [loadingUsers, payments, usersStorage])

    // Render

    if (localUser == null)
        return <SessionExpiredErrorPage/>

    if (!localUser.isAgent)
        return <Error403Page/>

    return <Page title={renderTitle()}
                 type="main">
        {renderContent()}
    </Page>

    function renderTitle(): ReactNode {
        return <Flex justify="space-between"
                     direction="row">
            <div>{t("sections.wallet.header")}</div>

            {!loading && error == null && payments.length > 0 &&
                <Flex direction="row"
                      width="fit-content">
                    <div className={style.amount}>
                        {t("domain.transferAgentPayments.labels.totalPaid")}
                        {": "}
                        {formatDecimal(totalPaid)}
                        {" ₽"}
                    </div>

                    <div className={style.amount}>
                        {t("domain.transferAgentPayments.labels.totalPending")}
                        {": "}
                        {formatDecimal(totalPending)}
                        {" ₽"}
                    </div>
                </Flex>
            }
        </Flex>
    }

    function renderContent(): ReactNode {
        if (loading)
            return <Loading/>

        if (error != null)
            return <ErrorDisplay error={error}/>

        if (payments.length === 0)
            return <Padding paddingTop="22px">
                {t("sections.wallet.messages.no")}
            </Padding>

        return <Flex justify="space-between"
                     align="start"
                     height="100%">
            <TransferAgentPaymentTable onPaymentClick={onPaymentClick}
                                       onPaymentsChange={setPayments}
                                       payments={payments}

                                       users={usersById}

                                       onError={setUpdateError}

                                       showAgents={localUserIsManager}
                                       showButtons={localUserHasRightToManageTransfer}/>

            <Flex>
                <ErrorText error={updateError}/>
            </Flex>
        </Flex>
    }

    // Events

    function onPaymentClick(payment: TransferAgentPayment) {
        if (localUserIsManager && payment.transferId != null)
            navigate(TransferPage.createPath(payment.transferId))
    }
}
