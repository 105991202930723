import { z } from "zod"
import { NamedResponseSchema } from "api/responses/common"
import { NEW_CHAT_MESSAGES_NOTIFICATION_TYPE } from "model"
import { NotificationResponseBaseSchema } from "./base"
import { NotificationTypeResponse } from "./type"

export const NewAnonymousChatMessagesNotificationResponseSchema = NotificationResponseBaseSchema.extend({
    type: z.literal(NEW_CHAT_MESSAGES_NOTIFICATION_TYPE satisfies NotificationTypeResponse),

    from: NamedResponseSchema.nullish(),
    count: z.number().nonnegative().nullish(),
})

export type NewAnonymousChatMessagesNotificationResponse = z.infer<typeof NewAnonymousChatMessagesNotificationResponseSchema>
