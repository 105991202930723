import { DeepReadonly, Nullish } from "my-util/type"
import { joinSearchParams } from "./search-params"

export const PATH_SEP = "/"

// Creation

export interface PathCreationOptions extends Nullish<{
    subpaths: string[]
    relative: boolean
    encodeSubpaths: boolean

    params: [string, string][]
    encodeParams: boolean
}> {}

export function createPath(
    {
        subpaths, relative, encodeSubpaths,
        params, encodeParams,
    }: DeepReadonly<PathCreationOptions> = {},
): string {
    const joinedSubpaths = joinSubpaths(subpaths ?? [], { relative, encode: encodeSubpaths })
    const joinedParams = joinSearchParams(params ?? [], { encode: encodeParams })

    return `${joinedSubpaths}?${joinedParams}`
}

// Joining

export interface SubpathsJoiningOptions extends Nullish<{
    relative: boolean
    encode: boolean
}> {}

export function joinSubpaths(
    subpaths: readonly string[],
    { relative, encode }: Readonly<SubpathsJoiningOptions> = {},
): string {
    const joined = subpaths
        .map(subpath => normalizeSubpath(subpath, { encode }))
        .filter(Boolean)
        .join(PATH_SEP)

    return relative
        ? joined
        : PATH_SEP + joined
}

// Normalization

export interface SubpathNormalizationOptions extends Nullish<{
    encode: boolean
    keepFirstSep: boolean
}> {}

export function normalizeSubpath(
    subpath: string,
    { encode, keepFirstSep }: Readonly<SubpathNormalizationOptions> = {},
): string {
    if (!keepFirstSep && subpath.startsWith(PATH_SEP))
        subpath = subpath.substring(1)

    if (encode)
        subpath = encodeURIComponent(subpath)

    return subpath
}
