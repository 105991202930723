import { ForwardedRef, forwardRef } from "react"
import { getInviteStatusColors, InviteStatus, getInviteStatusName } from "model"
import { Badge } from "ui/ui/output"

export namespace InviteStatusBadge {
    export interface Props {
        status: InviteStatus
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InviteStatusBadge = forwardRef((
    { status }: Readonly<InviteStatusBadge.Props>,
    ref: ForwardedRef<HTMLSpanElement>,
) =>
    <Badge { ...getInviteStatusColors(status) }
           ref={ref}>
        {getInviteStatusName(status)}
    </Badge>
)

InviteStatusBadge.displayName = "InviteStatusBadge"
