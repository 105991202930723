import { ForwardedRef, forwardRef } from "react"
import { generateRandomUuid } from "my-util"
import { Button } from "ui/ui"
import { UploadingUiDocument } from "../UiDocument"

export namespace AddDocumentsButton {
    export interface Props extends Omit<Button.Props, "onClick"> {
        onClick?: (documents: UploadingUiDocument[]) => void

        multiple?: boolean
        accept?: string
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AddDocumentsButton = forwardRef((
    props: Readonly<AddDocumentsButton.Props>,
    ref: ForwardedRef<HTMLButtonElement>,
) => {
    const { onClick, multiple, accept } = props

    return <Button {...props}
                   onClick={onInnerClick}
                   ref={ref}/>

    function onInnerClick() {
        if (onClick == null)
            return

        const input = document.createElement("input")

        input.type = "file"
        input.multiple = multiple ?? false
        input.accept = accept ?? ""

        input.onchange = () => {
            try {
                if (onClick == null || input.files === null || input.files.length === 0)
                    return

                const documents = [...input.files].map((file): UploadingUiDocument => ({
                    id: generateRandomUuid(),
                    status: "uploading",
                    file,
                }))

                onClick(documents)
            } finally {
                input.remove()
            }
        }

        input.click()
    }
})

AddDocumentsButton.displayName = "AddDocumentsButton"
