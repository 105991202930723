import { ForwardedRef, forwardRef, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { getAllUsersByIds, getUserById } from "api"
import { Invite, MANAGER_USER_ROLE, User } from "model"
import { useUsers } from "ui/hook"
import * as UserPage from "ui/page/sections/users/UserPage/path"
import { UserLink } from "ui/component/user"

import { DateTimeOutput, DurationOutput, FlexItem, Link, Output,
         Timer, Form, UserRoleOutput, Flex, CheckBox, Padding, FormControls,
         MessageTargetOutput, ErrorDisplay, UserSpecializationOutput, Loading } from "ui/ui"

export namespace InviteViewForm {
    export interface Props {
        onSubmit?: () => void
        onReset?: () => void
        onExpired?: () => void

        invite: Invite
        buttons?: FormControls.Button[]

        users?: Iterable<User> | Map<string, User>

        loading?: boolean
        disabled?: boolean
        error?: unknown

        width?: string
        height?: string
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InviteViewForm = forwardRef((
    {
        onSubmit, onReset, onExpired,
        invite, buttons,
        loading, disabled, error,
        width, height,
    }: Readonly<InviteViewForm.Props>,
    ref: ForwardedRef<HTMLFormElement>,
) => {
    const [t] = useTranslation()

    const storedUsers = useUsers()

    // State

    const showRights = invite.role === MANAGER_USER_ROLE
    const showSpecialization = showRights
    const showVisibleUsers = showRights && !invite.canSeeAllUsers

    const [creator, setCreator] = useState(undefined as User | undefined)
    const [loadingCreator, setLoadingCreator] = useState(invite.creatorId != null)
    const [creatorLoadingError, setCreatorLoadingError] = useState(undefined as unknown)

    const [visibleUsers, setVisibleUsers] = useState(new Array<User>())
    const [loadingVisibleUsers, setLoadingVisibleUsers] = useState(showVisibleUsers)
    const [visibleUsersLoadingError, setVisibleUsersLoadingError] = useState(undefined as unknown)

    // Effects

    // - Creator loading

    useEffect(() => {
        if (!loadingCreator || invite.creatorId == null)
            return

        const controller = new AbortController()

        getUserById(invite.creatorId, controller.signal)
            .then(creator => {
                storedUsers.add(creator)
                setCreator(creator)
            })
            .catch(error => {
                if (!controller.signal.aborted)
                    setCreatorLoadingError(error)
            })
            .finally(() => {
                if (!controller.signal.aborted)
                    setLoadingCreator(false)
            })

        return () => controller.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invite, loadingCreator])

    // - Visible users loading

    useEffect(() => {
        if (!loadingVisibleUsers)
            return

        if (invite.visibleUserIds.length === 0) {
            setLoadingVisibleUsers(false)
            return
        }

        const controller = new AbortController()

        getAllUsersByIds({ ids: invite.visibleUserIds }, controller.signal)
            .then(users => {
                setVisibleUsers(users)
                storedUsers.addAll(users)
            })
            .catch(error => {
                if (!controller.signal.aborted)
                    setVisibleUsersLoadingError(error)
            })
            .finally(() => {
                if (!controller.signal.aborted)
                    setLoadingVisibleUsers(false)
            })

        return () => controller.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invite, loadingVisibleUsers])

    // Render

    return <Form onSubmit={onSubmit}
                 onReset={onReset}
                 loading={loading}
                 width={width}
                 height={height}
                 ref={ref}>
        <Flex justify="space-between"
              height="100%">
            <FlexItem width="100%"
                      grow={1}>
                <Flex justify="stretch"
                      align="start"
                      height="100%">
                    <Flex direction="row"
                          justify="stretch"
                          wrap>
                        {invite.creatorId != null &&
                            <FlexItem grow={1}>
                                <Output label={t("domain.invites.labels.creator")}>
                                    {creator != null
                                        ? <UserLink user={creator}/>
                                        : creatorLoadingError != null
                                            ? <ErrorDisplay error={creatorLoadingError}/>
                                            : <Loading opacity={.1}/>
                                    }
                                </Output>
                            </FlexItem>
                        }

                        <FlexItem grow={1}>
                            <DateTimeOutput label={t("domain.invites.labels.createdAt.moment")}
                                            date={invite.createdAt}/>
                        </FlexItem>

                        {invite.sentAt != null &&
                            <FlexItem grow={1}>
                                <DateTimeOutput label={t("domain.invites.labels.sentAt.moment")}
                                                date={invite.sentAt}/>
                            </FlexItem>
                        }
                    </Flex>

                    <Flex direction="row"
                          justify="stretch"
                          wrap>
                        <FlexItem grow={1}>
                            <MessageTargetOutput label={t("messageTargets.labels.messageTarget")}
                                                 messageTarget={invite.messageTarget}/>
                        </FlexItem>

                        {invite.name != null &&
                            <FlexItem grow={1}>
                                <Output label={t("domain.invites.labels.name")}>
                                    {invite.name}
                                </Output>
                            </FlexItem>
                        }

                        {invite.anyCompany != null &&
                            <FlexItem grow={1}>
                                <Output label={t("domain.invites.labels.company")}>
                                    {invite.anyCompany}
                                </Output>
                            </FlexItem>
                        }

                        <FlexItem grow={1}>
                            <UserRoleOutput label={t("domain.userRights.labels.role")}
                                            role={invite.role}/>
                        </FlexItem>

                        {showSpecialization &&
                            <FlexItem grow={1}>
                                <UserSpecializationOutput label={t("domain.userRights.labels.specialization")}
                                                          specialization={invite.specialization}/>
                            </FlexItem>
                        }

                        {invite.reference != null &&
                            <FlexItem grow={1}>
                                <Output label={t("domain.invites.labels.reference")}>
                                    {
                                        invite.creatorId != null
                                            ? <Link to={UserPage.createPath(invite.creatorId)}
                                                    text={invite.reference}/>

                                            : invite.reference
                                    }
                                </Output>
                            </FlexItem>
                        }
                    </Flex>

                    {showRights &&
                        <Flex direction="row"
                              justify="space-between"
                              wrap>
                            <CheckBox label={t("domain.userRights.labels.canManageTransfers")}
                                      checked={invite.canManageTransfers}
                                      readonly/>

                            <CheckBox label={t("domain.userRights.labels.canManageInvites")}
                                      checked={invite.canManageInvites}
                                      readonly/>

                            <CheckBox label={t("domain.userRights.labels.canSendInvites")}
                                      checked={invite.canSendInvites}
                                      readonly/>

                            <CheckBox label={t("domain.userRights.labels.canManageUsers")}
                                      checked={invite.canManageUsers}
                                      readonly/>

                            <CheckBox label={t("domain.userRights.labels.canManageProviders")}
                                      checked={invite.canManageProviders}
                                      readonly/>

                            <CheckBox label={t("domain.userRights.labels.canNotSeeAllUsers")}
                                      checked={!invite.canSeeAllUsers}
                                      readonly/>
                        </Flex>
                    }

                    {showVisibleUsers &&
                        <Output label={t("domain.userRights.labels.visibleUsers")}>
                            <Padding padding="16px">
                            {(() => {
                                if (loadingVisibleUsers)
                                    return <Loading opacity={.1}/>

                                if (visibleUsersLoadingError != null)
                                    return <ErrorDisplay error={visibleUsersLoadingError}/>

                                return <Flex direction="row"
                                             wrap>
                                    {visibleUsers.map(user =>
                                        <UserLink key={user.id}
                                                  user={user}/>
                                    )}
                                </Flex>
                            })()}
                            </Padding>
                        </Output>
                    }

                    <FlexItem width="100%"
                              grow={1}>
                        <Flex direction="row"
                              height="100%">
                            {invite.text != null &&
                                <Output label={t("domain.invites.labels.text")}
                                        resize="vertical"
                                        wordBreak="break-all"
                                        valueHeight="100%"
                                        height="100%">
                                    {invite.text}
                                </Output>
                            }

                            {invite.comment != null &&
                                <Output label={t("domain.invites.labels.comment")}
                                        resize="vertical"
                                        wordBreak="break-all"
                                        valueHeight="100%"
                                        height="100%">
                                    {invite.comment}
                                </Output>
                            }
                        </Flex>
                    </FlexItem>

                    <Flex direction="row"
                          wrap>
                        <FlexItem grow={1}>
                            <DurationOutput label={t("domain.invites.labels.duration")}
                                            duration={invite.duration}/>
                        </FlexItem>

                        <FlexItem grow={1}>
                            <DateTimeOutput label={t("domain.invites.labels.expiresAt.moment")}
                                            date={invite.expiresAt}/>
                        </FlexItem>

                        <FlexItem grow={1}>
                            <Output label={t("datetime.labels.expiresIn")}>
                                <Timer expiresAt={invite.expiresAt}
                                       onExpired={onExpired}/>
                            </Output>
                        </FlexItem>
                    </Flex>
                </Flex>
            </FlexItem>

            <FormControls buttons={buttons}
                          loading={loading}
                          disabled={disabled}
                          error={error}/>
        </Flex>
    </Form>
})

InviteViewForm.displayName = "InviteViewForm"
