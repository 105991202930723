import { NotFoundError } from "./NotFoundError"

export class NotFoundByIdError extends NotFoundError {
    readonly id: string

    constructor(id: string, message?: string, options?: ErrorOptions) {
        super(message, options)

        this.id = id
    }
}
