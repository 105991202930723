import { ForwardedRef, forwardRef } from "react"
import { Output } from "ui/ui/output"
import { Phone } from "../Phone"

export namespace PhoneOutput {
    export interface Props
        extends
            Omit<Output.Props, "children">,
            Omit<Phone.Props, "hidePlus">
    {}
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PhoneOutput = forwardRef((
    props: Readonly<PhoneOutput.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) =>
    <Output left="+"
            { ...props }
            ref={ref}>
        <Phone { ...props }
               hidePlus/>
    </Output>
)

PhoneOutput.displayName = "PhoneOutput"
