import { ForwardedRef, forwardRef } from "react"
import { menuBurgerIconUrl } from "images"
import { Icon } from "ui/ui"
import style from "./style.module.css"

export namespace MenuButton {
    export interface Props {
        onClick?: () => void
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MenuButton = forwardRef((
    { onClick }: Readonly<MenuButton.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) =>
    <div className={style.MenuButton}
         onClick={onClick}
         ref={ref}>
        <Icon width="100%"
              height="100%"

              src={menuBurgerIconUrl}
              alt="Menu burger icon"
              filter="brightness(0)"/>
    </div>
)
