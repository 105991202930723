import { ForwardedRef, forwardRef } from "react"
import { DeepReadonly } from "my-util"
import { None, Center } from "ui/ui/layout"
import { ErrorText } from "../ErrorText"

export namespace ErrorDisplay {
    export interface Props extends ErrorText.Props {
        centerType?: Center.Type
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ErrorDisplay = forwardRef((
    props: DeepReadonly<ErrorDisplay.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) =>
    props.error
        ? <Center type={props.centerType ?? "absolute-fill"}
                  ref={ref}>
            <ErrorText {...props}/>
        </Center>

        : <None ref={ref}/>
)

ErrorDisplay.displayName = "ErrorDisplay"
