import { getLang } from "i18n"

import { Copyable, removeWhiteSpaceToNull, IsImmutable,
         collapseWhiteSpaceToNull, Immutable, DeepReadonly, Nullish } from "my-util"

import { Bank } from "./Bank"

export namespace Company {
    export interface OptionsBase extends Nullish<{
        itn: string

        ruName: string
        enName: string

        ruAddress: string
        enAddress: string

        correspondentAccount: string
        paymentAccount: string
    }> {}

    export interface CreationOptions extends OptionsBase, Nullish<{
        bank: Bank | Bank.CreationOptions
    }> {}

    export interface CopyOptions extends OptionsBase, Nullish<{
        bank: Bank | Bank.CopyOptions
    }> {}
}

export class Company
    extends
        Copyable<Company.CopyOptions>

    implements
        Immutable
{
    static createOrPass(arg?: Company | DeepReadonly<Company.CreationOptions> | null): Company {
        return arg instanceof Company
            ? arg
            : new Company(arg ?? {})
    }

    // Fields

    readonly [IsImmutable] = true

    readonly itn: string | null

    readonly ruName: string | null
    readonly enName: string | null

    readonly ruAddress: string | null
    readonly enAddress: string | null

    readonly bank: Bank

    readonly correspondentAccount: string | null
    readonly paymentAccount: string | null

    // Constructor

    constructor(options: DeepReadonly<Company.CreationOptions> = {}) {
        super()

        this.itn = removeWhiteSpaceToNull(options.itn ?? "")

        this.ruName = collapseWhiteSpaceToNull(options.ruName ?? "")
        this.enName = collapseWhiteSpaceToNull(options.enName ?? "")

        this.ruAddress = collapseWhiteSpaceToNull(options.ruAddress ?? "")
        this.enAddress = collapseWhiteSpaceToNull(options.enAddress ?? "")

        this.bank = Bank.createOrPass(options.bank)

        this.correspondentAccount = removeWhiteSpaceToNull(options.correspondentAccount ?? "")
            ?.toUpperCase() ?? null

        this.paymentAccount = removeWhiteSpaceToNull(options.paymentAccount ?? "")
    }

    // Name

    get anyName(): string | null {
        return getLang() === "ru"
            ? this.ruName ?? this.enName
            : this.enName ?? this.ruName
    }

    get name(): string | null {
        return getLang() === "ru"
            ? this.ruName
            : this.enName
    }

    // Address

    get anyAddress(): string | null {
        return getLang() === "ru"
            ? this.ruAddress ?? this.enAddress
            : this.enAddress ?? this.ruAddress
    }

    get address(): string | null {
        return getLang() === "ru"
            ? this.ruAddress
            : this.enAddress
    }

    // Copy

    protected override createCopy(options: DeepReadonly<Company.CopyOptions> = {}): Company {
        return new Company({
            // ITN

            itn: "itn" in options
                ? options.itn
                : this.itn,

            // Name

            ruName: "ruName" in options
                ? options.ruName
                : this.ruName,

            enName: "enName" in options
                ? options.enName
                : this.enName,

            // Address

            ruAddress: "ruAddress" in options
                ? options.ruAddress
                : this.ruAddress,

            enAddress: "enAddress" in options
                ? options.enAddress
                : this.enAddress,

            // Bank

            bank: this.bank.copyOrPass(options.bank),

            // Accounts

            correspondentAccount: "correspondentAccount" in options
                ? options.correspondentAccount
                : this.correspondentAccount,

            paymentAccount: "paymentAccount" in options
                ? options.paymentAccount
                : this.paymentAccount,
        })
    }
}
