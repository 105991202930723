import { ReactNode, useContext, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { plusIconUrl } from "images"
import { deleteProviderById, getAllProviders } from "api"
import { Provider } from "model"
import { useProviders } from "ui/hook"
import { UserContext } from "ui/context"
import { Error403Page, SessionExpiredErrorPage } from "ui/page/error"
import { InviteCreationPage } from "ui/page/sections/clients/invites/InviteCreationPage"
import { Page, ProviderCreationModal, ProviderEditingModal, ProviderTable } from "ui/component"
import { ActionModal, Button, ErrorDisplay, Flex, Loading, Padding } from "ui/ui"

export function Component() {
    const [t] = useTranslation()

    const navigate = useNavigate()

    const [localUser] = useContext(UserContext)

    const localUserHasRightToManageProviders = useMemo(
        () => localUser?.hasRightToManageProviders ?? false,
        [localUser],
    )

    const providersStorage = useProviders()

    // State

    const [providers, setProviders] = useState(new Array<Provider>())
    const [loading, setLoading] = useState(localUser?.isManager ?? false)
    const [error, setError] = useState(undefined as unknown)

    const [creating, setCreating] = useState(false)
    const [deletingId, setDeletingId] = useState(undefined as string | undefined)
    const [editingId, setEditingId] = useState(undefined as string | undefined)

    // Effects

    useEffect(() => {
        if (!loading)
            return

        const controller = new AbortController()

        getAllProviders(controller.signal)
            .then(providers => {
                providersStorage.addAll(providers)
                setProviders(providers)
            })
            .catch(error => {
                if (!controller.signal.aborted)
                    setError(error)
            })
            .finally(() => {
                if (!controller.signal.aborted)
                    setLoading(false)
            })

        return () => controller.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading])

    // Render

    if (localUser == null)
        return <SessionExpiredErrorPage/>

    if (!localUser.isManager)
        return <Error403Page/>

    return <Page compactTitle={renderCompactTitle()}
                 autoMoveCompactTitle
                 type="main">
        {renderContent()}

        {renderCreationModalIfCreating()}
        {renderDeletionModalIfDeleting()}
        {renderEditingModalIfEditing()}
    </Page>

    function renderCompactTitle(): ReactNode {
        return <Flex direction="row"
                     gap="8px">
            <Button onClick={() => setCreating(true)}

                    iconSrc={plusIconUrl}
                    iconAlt="Plus icon"

                    width="32px"/>

            {t("sections.providers.header").toUpperCase()}
        </Flex>
    }

    function renderContent(): ReactNode {
        if (loading)
            return <Loading/>

        if (error != null)
            return <ErrorDisplay error={error}/>

        if (providers.length === 0)
            return <Padding paddingTop="22px">
                {t("sections.providers.messages.no")}
            </Padding>

        return <ProviderTable onDelete={({ id }) => setDeletingId(id)}
                              onEdit={({ id }) => setEditingId(id)}

                              hideDeleteButton={!localUserHasRightToManageProviders}
                              hideEditButton={!localUserHasRightToManageProviders}

                              onInvite={onInvite}

                              providers={providers}/>

        function onInvite(provider: Provider) {
            const path = InviteCreationPage.createPath({
                providerId: provider.id,

                messageTarget: provider.phone ?? provider.email,

                name: {
                    en: provider.enName,
                    ru: provider.ruName,
                },

                company: {
                    en: provider.enCompany,
                    ru: provider.ruCompany,
                },
            })

            navigate(path)
        }
    }

    function renderCreationModalIfCreating(): ReactNode {
        if (!creating)
            return null

        return <ProviderCreationModal onCreated={newProvider => setProviders([...providers, newProvider])}
                                      onClose={() => setCreating(false)}
                                      width="calc(min(80vw, 900px))"
                                      closeOnSuccess/>
    }

    function renderDeletionModalIfDeleting(): ReactNode {
        if (deletingId == null)
            return

        return <ActionModal onNo={() => setDeletingId(undefined)}
                            onYes={onDelete}
                            closeOnSuccess
                            critical>
            {t("domain.providers.messages.warnings.deletion")}
        </ActionModal>

        async function onDelete() {
            await deleteProviderById(deletingId!)
            setProviders(oldProviders => oldProviders.filter(({ id }) => id !== deletingId))
        }
    }

    function renderEditingModalIfEditing(): ReactNode {
        if (editingId == null)
            return null

        const provider = providers.find(({ id }) => id === editingId)

        if (provider == null)
            return null

        return <ProviderEditingModal onSaved={onSaved}
                                     onClose={() => setEditingId(undefined)}
                                     provider={provider}
                                     width="calc(min(80vw, 900px))"
                                     closeOnSuccess/>

        function onSaved(newProvider: Provider) {
            setProviders(oldProviders => oldProviders.map(oldProvider =>
                oldProvider.id === newProvider.id
                    ? newProvider
                    : oldProvider
            ))
        }
    }
}
