import { Expirable, ExpirableFields, WithCreationMoment } from "model/interfaces"
import { Constructor, DeepReadonly, Duration } from "my-util"
import { ExpirableMixin } from "./ExpirableMixin"

export function ExpirableWithCreationDateMixin<
    BaseConstructor extends Constructor<DeepReadonly<ExpirableFields & WithCreationMoment>>
>(Base: BaseConstructor) {
    return class
        extends ExpirableMixin(Base)
        implements DeepReadonly<Expirable & WithCreationMoment>
    {
        private cachedDuration: Duration | null = null

        get duration(): Duration {
            if (this.cachedDuration == null)
                this.cachedDuration = new Duration({
                    millis: this.expiresAt.getTime() - this.createdAt.getTime(),
                }).normalized()

            return this.cachedDuration
        }
    }
}
