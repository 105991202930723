import { DiLangString } from "i18n"
import { UserRole, UserSpecialization } from "model"
import { createPath as createAnyPath, DeepNullish, Duration, joinSubpaths, Nullish } from "my-util"
import * as AllInvitesPage from "../AllInvitesPage/path"

// Creation

export interface PathCreationOptions
    extends
        DeepNullish<{
            messageTarget: string
            reference: string
            role: UserRole
            specialization: UserSpecialization
            text: string
            comment: string
            providerId: string

            company: DiLangString
            name: DiLangString
        }>,

        Nullish<{
            duration: Duration
        }>
{}

export function createPath(
    options: Readonly<PathCreationOptions> = {},
): string {
    const params: [string, string][] = []

    pushStringParamIfNotNull("messageTarget", MESSAGE_TARGET_SEARCH_PARAM)
    pushStringParamIfNotNull("reference", REFERENCE_SEARCH_PARAM)
    pushStringParamIfNotNull("role", ROLE_SEARCH_PARAM)
    pushStringParamIfNotNull("specialization", SPECIALIZATION_SEARCH_PARAM)
    pushStringParamIfNotNull("text", TEXT_SEARCH_PARAM)
    pushStringParamIfNotNull("comment", DURATION_SEARCH_PARAM)
    pushStringParamIfNotNull("providerId", PROVIDER_ID_SEARCH_PARAM)

    pushDiLangStringParamIfNotNull("company", COMPANY_SEARCH_PARAM)
    pushDiLangStringParamIfNotNull("name", NAME_SEARCH_PARAM)

    pushDurationParamIfNotNull("duration", DURATION_SEARCH_PARAM)

    return createAnyPath({
        subpaths: [PATH],
        params,
    })

    function pushStringParamIfNotNull(
        param: Exclude<keyof PathCreationOptions, "name" | "company" | "duration">,
        key: string,
    ) {
        if (options[param] != null)
            params.push([key, options[param]])
    }

    function pushDiLangStringParamIfNotNull(param: "name" | "company", key: string) {
        const value = options[param]

        if (value == null || (value.en == null && value.ru == null))
            return

        const stringValue = `${value.en ?? ""},${value.ru ?? ""}`

        params.push([key, stringValue])
    }

    function pushDurationParamIfNotNull(param: "duration", key: string) {
        const value = options[param]

        if (value == null)
            return

        const stringValue = value.toString()

        params.push([key, stringValue])
    }
}

// Search params

export const MESSAGE_TARGET_SEARCH_PARAM = "message-target"
export const NAME_SEARCH_PARAM = "name"
export const COMPANY_SEARCH_PARAM = "company"
export const REFERENCE_SEARCH_PARAM = "reference"
export const ROLE_SEARCH_PARAM = "role"
export const SPECIALIZATION_SEARCH_PARAM = "specialization"
export const TEXT_SEARCH_PARAM = "text"
export const COMMENT_SEARCH_PARAM = "comment"
export const DURATION_SEARCH_PARAM = "duration"
export const PROVIDER_ID_SEARCH_PARAM = "provider-id"

// Path

export const PATH = joinSubpaths([AllInvitesPage.PATH, "/new"])
