import { ForwardedRef, forwardRef } from "react"
import { useTranslation } from "react-i18next"

import { IMPORT_TRANSFER_DIRECTION_FILTER, TransferDirectionFilter,
         ALL_TRANSFER_DIRECTION_FILTER, EXPORT_TRANSFER_DIRECTION_FILTER } from "model"

import { Radio } from "ui/ui/input"

export namespace TransferDirectionFilterRadio {
    export interface Props {
        onChange?: (type: TransferDirectionFilter) => void
        checked?: TransferDirectionFilter

        readonly?: boolean
        loading?: boolean
        disabled?: boolean

        name?: string

        allHidden?: boolean
        allDisabled?: boolean

        importHidden?: boolean
        importDisabled?: boolean

        exportHidden?: boolean
        exportDisabled?: boolean

        hideButton?: boolean
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransferDirectionFilterRadio = forwardRef((
    {
        onChange, checked,
        readonly, loading, disabled,
        name,
        allHidden, allDisabled,
        importHidden, importDisabled,
        exportHidden, exportDisabled,
        hideButton,
    }: Readonly<TransferDirectionFilterRadio.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const [t] = useTranslation()

    return <Radio
        onChange={onChange as (value: string) => void}
        checked={checked ?? ALL_TRANSFER_DIRECTION_FILTER}

        readonly={readonly}
        loading={loading}
        disabled={disabled}

        name={name}
        hideButton={hideButton}

        items={{
            [ALL_TRANSFER_DIRECTION_FILTER]: {
                hidden: allHidden,
                disabled: allDisabled,

                label: t("misc.words.all"),
            },

            [IMPORT_TRANSFER_DIRECTION_FILTER]: {
                hidden: importHidden,
                disabled: importDisabled,

                label: t("domain.transfers.directions.import"),
            },

            [EXPORT_TRANSFER_DIRECTION_FILTER]: {
                hidden: exportHidden,
                disabled: exportDisabled,

                label: t("domain.transfers.directions.export"),
            },
        }}

        ref={ref}
    />
})

TransferDirectionFilterRadio.displayName = "TransferDirectionFilterRadio"
