export function retainMapKeys<K, V>(map: Map<K, V>, keysToRetain: Iterable<K>): Map<K, V> {
    const keysToRetainSet = new Set(keysToRetain)
    const keysToDelete: K[] = []

    for (const mapKey of map.keys())
        if (!keysToRetainSet.has(mapKey))
            keysToDelete.push(mapKey)

    for (const key of keysToDelete)
        map.delete(key)

    return map
}
