import { z } from "zod"
import { ACTIVE_USER_STATUS, BLOCKED_USER_STATUS, UNVERIFIED_USER_STATUS } from "model"

export const UserStatusResponseSchema = z.enum([
    ACTIVE_USER_STATUS,
    UNVERIFIED_USER_STATUS,
    BLOCKED_USER_STATUS,
])

export type UserStatusResponse = z.infer<typeof UserStatusResponseSchema>
