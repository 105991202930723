import { z } from "zod"
import { UuidSchema } from "my-util"
import { ModelObjectResponseSchema } from "../model-object"
import { ChatResponseType } from "./type"
import { ChatResponseBaseSchema } from "./base"

export const ChatMessageResponseSchema = ChatResponseBaseSchema.extend({
    // Type

    type: z.literal("message" satisfies ChatResponseType),

    // Users

    creatorId: UuidSchema.nullish(),
    recipientId: UuidSchema.nullish(),

    // Payload

    text: z.string().nullish(),
    documentIds: UuidSchema.array().nullish(),

    // Meta

    readByIds: UuidSchema.array().nullish(),
    edited: z.coerce.boolean().nullish(),
}).merge(ModelObjectResponseSchema)

export type ChatMessageResponse = z.infer<typeof ChatMessageResponseSchema>
