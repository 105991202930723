import { ForwardedRef, useRef, ReactNode, useImperativeHandle, forwardRef } from "react"
import { DeepReadonly } from "my-util"
import { Flex } from "ui/ui"
import style from "./style.module.css"

export namespace Widget {
    export interface Props {
        onHeaderClick?: () => void
        header?: ReactNode

        children?: ReactNode
        right?: ReactNode

        headerBackgroundColor?: string
        headerBorderColor?: string

        contentBackgroundColor?: string
        contentBorderColor?: string

        maxHeaderWidth?: string
        maxContentWidth?: string

        headerWidth?: string
        width?: string
        height?: string
    }

    export interface Colors {
        backgroundColor?: string
        borderColor?: string
        color?: string
    }

    export interface Ref {
        component: HTMLDivElement
        header: HTMLDivElement | null
        content: HTMLDivElement
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Widget = forwardRef((
    {
        onHeaderClick, header,
        children, right,
        headerBackgroundColor, headerBorderColor,
        contentBackgroundColor, contentBorderColor,
        maxHeaderWidth, maxContentWidth,
        headerWidth, width, height,
    }: DeepReadonly<Widget.Props>,
    ref: ForwardedRef<Widget.Ref>,
) => {
    // Refs

    const componentRef = useRef<HTMLDivElement | null>(null)
    const headerRef = useRef<HTMLDivElement | null>(null)
    const contentRef = useRef<HTMLDivElement | null>(null)

    useImperativeHandle(
        ref,

        () => ({
            component: componentRef.current!,
            header: headerRef.current,
            content: contentRef.current!,
        }),

        [componentRef, headerRef, contentRef],
    )

    // Render

    return <Flex ref={componentRef}
                 height={height}
                 width={width}
                 align="start"
                 gap="0">
        {header &&
            <div
                style={{
                    cursor: onHeaderClick != null ? "pointer" : undefined,

                    backgroundColor: headerBackgroundColor,
                    borderColor: headerBorderColor,

                    maxWidth: maxHeaderWidth ?? maxContentWidth,
                    width: headerWidth,
                }}
                className={style.header}
                onClick={onHeaderClick}
                ref={headerRef}
            >
                {header}
            </div>
        }

        <Flex direction="row"
              align="start"
              gap="8px">
            <div
                style={{
                    backgroundColor: contentBackgroundColor,
                    borderColor: contentBorderColor,
                    maxWidth: maxContentWidth
                }}
                className={style.content}
                ref={contentRef}
            >
                {children}
            </div>

            {right}
        </Flex>
    </Flex>
})

Widget.displayName = "Widget"
