import { joinSubpaths, createPath as createAnyPath, arrayOfNonNull } from "my-util"
import * as MainPage from "ui/page/sections/main/MainPage/path"

export function createPath(userId?: string | null): string {
    return createAnyPath({
        subpaths: [PATH],
        encodeSubpaths: false,

        params: arrayOfNonNull(
            userId != null
                ? [USER_ID_SEARCH_PARAM, userId]
                : null
        ),
    })
}

export const USER_ID_SEARCH_PARAM = "user-id"

export const PATH = joinSubpaths([MainPage.PATH, "/messenger"])
