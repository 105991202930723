import { t } from "i18next"
import { z } from "zod"
import { User } from "model"

import { DeepReadonly, Nullish, UuidSchema,
         tryNormalizeNullableUuid, tryNormalizeUuid } from "my-util"

import { UiChatMessage } from "ui/component"

// Last user ID

export const LAST_USER_ID_STORAGE_KEY = "messenger-last-user-id"

export function getLastUserId(): string | null {
    return tryNormalizeNullableUuid(localStorage.getItem(LAST_USER_ID_STORAGE_KEY))
}

export function setLastUserId(userId: string | undefined | null) {
    if (userId == null)
        localStorage.removeItem(LAST_USER_ID_STORAGE_KEY)
    else
        localStorage.setItem(LAST_USER_ID_STORAGE_KEY, tryNormalizeUuid(userId))
}

// Unsent messages

export const UNSENT_MESSAGES_STORAGE_KEY = "messenger-unsent-messages"

const UnsentMessageSchema = z.object({
    id: UuidSchema.nullish(),
    recipientId: UuidSchema.nullish(),
    text: z.string().nullish(),
    documentIds: UuidSchema.array().nullish(),
})

export type UnsentMessage = z.infer<typeof UnsentMessageSchema>

export function deleteUnsentMessageById(id: string) {
    const oldMessages = getUnsentMessages()
    const newMessages = oldMessages.filter(message => message.id !== id)

    if (oldMessages.length !== newMessages.length)
        setUnsentMessages(newMessages, false)
}

export function pushUnsentMessage(message: DeepReadonly<UnsentMessage>) {
    const messages = getUnsentMessages()

    messages.push(normalizeUnsentMessage(message))

    setUnsentMessages(messages, false)
}

export function getUnsentMessages(): UnsentMessage[] {
    const item = localStorage.getItem(UNSENT_MESSAGES_STORAGE_KEY)

    if (item == null)
        return []

    try {
        const itemJson = JSON.parse(item)
        const messages = UnsentMessageSchema.array().parse(itemJson)

        return messages
    } catch (error) {
        console.error("Failed to get unread messenger messages: ", error)
        localStorage.removeItem(UNSENT_MESSAGES_STORAGE_KEY)
        console.warn("Removed unreadable unsent messages:", item)
        return []
    }
}

export function setUnsentMessages(
    messages: DeepReadonly<UnsentMessage[]>,
    normalize: boolean = true,
) {
    if (normalize)
        messages = messages.map(normalizeUnsentMessage)

    localStorage.setItem(UNSENT_MESSAGES_STORAGE_KEY, JSON.stringify(messages))
}

export function clearUnsentMessages() {
    localStorage.removeItem(UNSENT_MESSAGES_STORAGE_KEY)
}

function normalizeUnsentMessage(message: DeepReadonly<UnsentMessage>): UnsentMessage {
    return {
        id: message.id,
        recipientId: tryNormalizeNullableUuid(message.recipientId),
        text: message.text,
        documentIds: message.documentIds?.map(tryNormalizeUuid),
    }
}

// Conversion

export interface UnsentMessageToUiOptions extends Nullish<{
    localUser: User
}> {}

export function unsentMessageToUi(
    message: DeepReadonly<UnsentMessage>,
    { localUser }: Readonly<UnsentMessageToUiOptions> = {},
): UiChatMessage {
    return {
        id: message.id,
        status: "error",
        senderId: localUser?.id,
        sender: () => t("misc.words.you"),
        text: message.text,

        documents: message.documentIds?.map(id => ({
            status: "loading",
            id,
        })),

        date: new Date(),
        local: true,
    }
}
