import assert from "assert"
import { ReactNode, useContext, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { arrowLeftIconUrl } from "images"
import { getLang } from "i18n"

import { cancelMfa, createLoginRequestFromMessageTarget, getLoginConfig,
         OtpResponse, login as performLogin, resendMfaOtp, verifyMfaOtp } from "api"

import { getMessageTargetTypeMfaPlaceholder,
         createMessageTargetInputPlaceholder,
         IS_ALLOWED_BY_EVERY_MESSAGE_TARGET_TYPE,
         isAllowedByMessageTargetTypeToMessageTargetTypeList,
         determineNullableMessageTargetType, MessageTargetType } from "model"

import { SECOND_MILLIS, isUuid, tryNormalizeUuid } from "my-util"
import { normalizeMessageTarget } from "normalization"
import { OTP_LENGTH, validateOtp, validatePassword, validateMessageTarget } from "validation"
import { UserContext } from "ui/context"
import { Error404Page } from "ui/page/error"
import { MessengerPage } from "ui/page/sections/messenger/MessengerPage"
import * as MainPage from "ui/page/sections/main/MainPage/path"
import { Otp, Page } from "ui/component"

import { Button, ErrorBlock, ErrorDisplay, Loading, Pane, Limit, Timer,
         Input, Flex, Form, MessageTargetInput, CapsLockDetector, Link } from "ui/ui"

import * as PasswordResetPage from "../PasswordResetPage/path"
import { USER_ID_PARAM } from "./path"

const MAX_WIDTH = "350px"

type State =
    | "bad-user-id" // For future refactoring

    // Config
    | "loading-config"
    | "config-loading-failed"

    // Credentials
    | "filling-in-credentials"
    | "verifying-credentials"

    // MFA
    | "filling-in-otp"
    | "resending-otp"
    | "verifying-otp"

    // Success
    | "success"

export function Component() {
    const [t] = useTranslation()

    const navigate = useNavigate()

    const [,, refetchUser] = useContext(UserContext)

    // State

    // - User ID

    const { [USER_ID_PARAM]: rawUserId } = useParams()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const userId = useMemo(
        () => rawUserId != null && isUuid(rawUserId)
            ? tryNormalizeUuid(rawUserId)
            : null,

        [rawUserId],
    )

    // - State

    const [state, setState] = useState<State>(
        userId != null
            ? "loading-config"
            : "bad-user-id"
    )

    const badUserId = state === "bad-user-id"
    const loadingConfig = state === "loading-config"
    const configLoadingFailed = state === "config-loading-failed"

    const fillingInCredentials = state === "filling-in-credentials"
    const verifyingCredentials = state === "verifying-credentials"

    const fillingInOtp = state === "filling-in-otp"
    const resendingOtp = state === "resending-otp"
    const verifyingOtp = state === "verifying-otp"

    const performingMfa =
        fillingInOtp ||
        resendingOtp ||
        verifyingOtp

    const mfaLoading =
        resendingOtp ||
        verifyingOtp

    const success = state === "success"

    // - Error

    const [error, setError] = useState<unknown>(undefined)

    // - Config

    const [config, setConfig] = useState(IS_ALLOWED_BY_EVERY_MESSAGE_TARGET_TYPE)

    const allowedLoginMessageTargetTypes = useMemo(
        () => isAllowedByMessageTargetTypeToMessageTargetTypeList(config),
        [config],
    )

    const loginPlaceholder = useMemo(
        () => createMessageTargetInputPlaceholder(allowedLoginMessageTargetTypes),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [allowedLoginMessageTargetTypes, getLang()],
    )

    // - Credentials

    const [login, setLogin] = useState<string | undefined>(undefined)
    const [touchedLogin, setTouchedLogin] = useState(false)
    const loginInvalid = login == null || validateMessageTarget(login, config) != null

    const [password, setPassword] = useState<string | undefined>(undefined)
    const [touchedPassword, setTouchedPassword] = useState(false)
    const passwordInvalid = password == null || validatePassword(password) != null

    const loginButtonDisabled = loginInvalid || passwordInvalid

    // - MFA

    const [otp, setOtp] = useState<string | undefined>(undefined)
    const [touchedOtp, setTouchedOtp] = useState(false)
    const otpInvalid = otp == null || validateOtp(otp) != null

    const [canResendOtpAt, setCanResendOtpAt] = useState(new Date())
    const [canResendOtp, setCanResendOtp] = useState(false)
    const [otpMessageTargetType, setOtpMessageTargetType] = useState<MessageTargetType | null>(null)

    // Effects

    // - Config loading

    useEffect(
        () => {
            if (!loadingConfig)
                return

            const controller = new AbortController()

            getLoginConfig(controller.signal)
                .then(config => {
                    setConfig(config)
                    setState("filling-in-credentials")
                })
                .catch(error => {
                    if (controller.signal.aborted)
                        return

                    setError(error)
                    setState("config-loading-failed")
                })

            return () => controller.abort()
        },

        [loadingConfig],
    )

    // - Success handling

    useEffect(
        () => {
            if (!success)
                return

            MessengerPage.clearUnsentMessages()
            MessengerPage.setLastUserId(null)

            refetchUser()

            navigate(MainPage.PATH)
        },

        [navigate, refetchUser, success],
    )

    // - OTP verification triggering

    useEffect(
        () => {
            if (otp != null && !otpInvalid && otp.length >= OTP_LENGTH)
                onVerifyOtp()
        },

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [otp],
    )

    // Render

    if (badUserId)
        return <Error404Page/>

    return <Page type="auth">
        <Pane>{renderContent()}</Pane>
    </Page>

    function renderContent() {
        if (loadingConfig)
            return <Loading/>

        if (configLoadingFailed)
            return <ErrorDisplay error={error}/>

        return <Form onSubmit={onSubmit}>
            <Flex align="start"
                  wrap>
                {renderLoginInput()}

                {performingMfa
                    ? renderMfaPart()

                    : <>
                        {renderPasswordInput()}
                        {renderResetPasswordLink()}
                        {renderLoginButton()}
                    </>
                }

                {renderError()}
            </Flex>
        </Form>
    }

    function renderLoginInput(): ReactNode {
        return <Limit maxWidth={MAX_WIDTH}>
            <MessageTargetInput onChange={setLogin}
                                value={login}

                                onFocus={() => setTouchedLogin(true)}

                                placeholder={loginPlaceholder}

                                readonly={performingMfa}
                                loading={verifyingCredentials}
                                invalid={touchedLogin && loginInvalid}/>
        </Limit>
    }

    function renderPasswordInput(): ReactNode {
        return <Flex direction="row"
                     wrap>
            <Limit maxWidth={MAX_WIDTH}>
                <Input onChange={setPassword}
                       value={password}

                       onFocus={() => setTouchedPassword(true)}

                       placeholder={t("misc.placeholders.password")}

                       readonly={performingMfa}
                       loading={verifyingCredentials}
                       invalid={touchedPassword && passwordInvalid}

                       type="password"

                       key="password"/>
            </Limit>

            <CapsLockDetector/>
        </Flex>
    }

    function renderResetPasswordLink(): ReactNode {
        assert(userId != null)

        return <Link to={PasswordResetPage.createPath(userId)}
                     text={t("auth.passwordRest.link")}/>
    }

    function renderLoginButton(): ReactNode {
        return <Limit maxWidth={MAX_WIDTH}>
            <Button text={t("misc.actions.login")}
                    width="50%"

                    loading={verifyingCredentials}
                    disabled={loginButtonDisabled}

                    type="submit"/>
        </Limit>
    }

    function renderMfaPart(): ReactNode {
        return <>
            {renderOtpInput()}

            {canResendOtp
                ? renderResendOtpButton()
                : renderResendCoolDownMessage()
            }

            {renderCancelMfaButton()}
            {renderOtp()}
        </>
    }

    function renderOtpInput(): ReactNode {
        return <Limit maxWidth={MAX_WIDTH}>
            <Input onChange={setOtp}
                   value={otp ?? ""}

                   onFocus={() => setTouchedOtp(true)}

                   placeholder={getMessageTargetTypeMfaPlaceholder(otpMessageTargetType)}

                   invalid={touchedOtp && otpInvalid}
                   loading={mfaLoading}

                   autoComplete="off"

                   regex={/^\d*$/g}
                   max={OTP_LENGTH}

                   key="otp"/>
        </Limit>
    }

    function renderResendOtpButton(): ReactNode {
        return <Limit maxWidth={MAX_WIDTH}>
            <Button text={t("auth.mfa.actions.resendCode")}
                    loading={mfaLoading}
                    buttonStyle="outline"
                    onClick={onResendOtp}/>
        </Limit>
    }

    function renderResendCoolDownMessage(): ReactNode {
        return <Limit maxWidth={MAX_WIDTH}>
            <p>
                {t("auth.mfa.messages.canResendIn")}

                {" "}

                <Timer onExpired={() => setCanResendOtp(true)}
                       expiresAt={canResendOtpAt}/>
            </p>
        </Limit>
    }

    function renderCancelMfaButton(): ReactNode {
        return <Button onClick={onCancelMfa}

                       text={t("misc.actions.back")}

                       buttonStyle="text"
                       width="fit-content"

                       iconSrc={arrowLeftIconUrl}
                       iconAlt="Arrow left icon"/>
    }

    function renderOtp(): ReactNode {
        return <Limit maxWidth={MAX_WIDTH}>
            <Otp key={canResendOtpAt.getTime()}/>
        </Limit>
    }

    function renderError(): ReactNode {
        return <Limit maxWidth={MAX_WIDTH}>
            <ErrorBlock
                error={error}
                apiErrorMessageMapping={{
                    422: t("auth.mfa.messages.errors.invalidCode"),
                    403: t("auth.login.messages.errors.invalidCredentials"),
                }}
            />
        </Limit>
    }

    // Events

    function onSubmit() {
        if (fillingInCredentials)
            return onLogin()

        if (fillingInOtp)
            return onVerifyOtp()
    }

    async function onLogin() {
        setState("verifying-credentials")

        try {
            assert(
                userId != null &&
                login != null &&
                password != null
            )

            const normalizedLogin = normalizeMessageTarget(login)

            const request = createLoginRequestFromMessageTarget(
                userId,
                normalizedLogin,
                password,
            )

            const response = await performLogin(request)

            let nextState: State

            if (response.status === "verification-needed") {
                updateOtpState(response)
                nextState = "filling-in-otp"
            } else
                nextState = "success"

            setError(undefined)
            setState(nextState)
        } catch (error) {
            setError(error)
            setState("filling-in-credentials")
        }
    }

    async function onVerifyOtp() {
        setState("verifying-otp")

        try {
            assert(otp != null)

            await verifyMfaOtp(otp)

            setState("success")
            setError(undefined)
        } catch (error) {
            setState("filling-in-otp")
            setError(error)
        }
    }

    async function onResendOtp() {
        setState("resending-otp")

        try {
            const response = await resendMfaOtp()

            updateOtpState(response)
            setError(undefined)
        } catch (error) {
            setError(error)
        } finally {
            setState("filling-in-otp")
        }
    }

    function onCancelMfa() {
        cancelMfa()
        setOtp(undefined)
        setState("filling-in-credentials")
    }

    // Util

    function updateOtpState(response: OtpResponse) {
        setOtp(undefined)

        const sentAtMilli = response.sentAt?.getTime() ?? Date.now()
        const sentAtSecond = sentAtMilli / SECOND_MILLIS
        const resendCoolDownEndSecond = sentAtSecond + response.resendCoolDown
        const newCanResendOtpAt = new Date(SECOND_MILLIS * Math.floor(resendCoolDownEndSecond))

        setCanResendOtpAt(newCanResendOtpAt)

        setCanResendOtp(false)

        const newOtpMessageTargetType = determineNullableMessageTargetType(response.messageTarget)

        setOtpMessageTargetType(newOtpMessageTargetType)
    }
}
