import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { deleteInviteById, getAllInvites, getAllMyInvites, getAllUsersByIds } from "api"
import { Invite, User } from "model"
import { uniqueArray } from "my-util"
import { useInvites, useUsers } from "ui/hook"
import { UserContext } from "ui/context"
import { InviteTable } from "ui/component"
import { Flex, Loading, Padding, ErrorDisplay, ActionModal } from "ui/ui"

export function Content() {
    const [t] = useTranslation()

    const [localUser] = useContext(UserContext)

    const storedInvites = useInvites()
    const storedUsers = useUsers()

    // State

    const [invites, setInvites] = useState<Invite[]>([])
    const [loadingInvites, setLoadingInvites] = useState(localUser?.isAgent ?? false)
    const [invitesLoadingError, setInvitesLoadingError] = useState<unknown>(undefined)

    const [usersById, setUsersById] = useState(new Map<string, User>())
    const [loadingUsers, setLoadingUsers] = useState(localUser?.isAgent ?? false)
    const [usersLoadingError, setUsersLoadingError] = useState<unknown>(undefined)

    const loading = loadingInvites || loadingUsers
    const loadingError = invitesLoadingError ?? usersLoadingError

    const [deletingId, setDeletingId] = useState<string | undefined>(undefined)

    const [, setExpiredCount] = useState(0)

    // Effects

    // - Invites loading

    useEffect(
        () => {
            if (!loadingInvites)
                return

            const controller = new AbortController()

            const load = localUser?.isManager
                ? getAllInvites
                : getAllMyInvites

            load(controller.signal)
                .then(invites => {
                    storedInvites.addAll(invites)
                    setInvites(invites)
                    setLoadingUsers(localUser?.isManager ?? false)
                })
                .catch(error => {
                    if (!controller.signal.aborted)
                        setInvitesLoadingError(error)
                })
                .finally(() => {
                    if (!controller.signal.aborted)
                        setLoadingInvites(false)
                })

            return () => controller.abort()
        },

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [loadingInvites],
    )

    // - Users loading

    useEffect(
        () => {
            if (!loadingUsers)
                return

            const idsToLoad = uniqueArray(
                invites
                    .map(({ creatorId }) => creatorId)
                    .filter(Boolean) as string[]
            )

            if (idsToLoad.length === 0) {
                setLoadingUsers(false)
                return
            }

            const controller = new AbortController()

            getAllUsersByIds({ ids: idsToLoad }, controller.signal)
                .then(users => {
                    storedUsers.addAll(users)
                    setUsersById(User.groupById(users))
                })
                .catch(error => {
                    if (!controller.signal.aborted)
                        setUsersLoadingError(error)
                })
                .finally(() => {
                    if (!controller.signal.aborted)
                        setLoadingUsers(false)
                })

            return () => controller.abort()
        },

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [loadingUsers],
    )

    // Render

    if (loading)
        return <Loading/>

    if (loadingError)
        return <ErrorDisplay error={loadingError}/>

    if (invites.length === 0)
        return <Padding paddingTop="22px">
            <div style={{ whiteSpace: "pre" }}>
                {localUser?.isManager
                    ? t("sections.clients.invites.messages.no")
                    : t("sections.clients.invites.messages.noYours")
                }
            </div>
        </Padding>

    return <Flex width="fit-content"
                 align="start"
                 gap="8px">
        <InviteTable onExpired={() => setExpiredCount(oldExpiredCount => ++oldExpiredCount)}
                     onDelete={({ id }) => setDeletingId(id)}

                     invites={invites}
                     users={usersById}/>

        {deletingId != null &&
            <ActionModal onNo={() => setDeletingId(undefined)}
                         onYes={onDelete}
                         closeOnSuccess
                         critical>
                {t("domain.invites.messages.warnings.deletion")}
            </ActionModal>
        }
    </Flex>

    // Events

    async function onDelete() {
        if (deletingId == null)
            return

        await deleteInviteById(deletingId)

        setInvites(oldInvites => oldInvites.filter(({ id }) => id !== deletingId))
    }
}
