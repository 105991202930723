import { ForwardedRef, forwardRef, ReactNode } from "react"
import { useTranslation } from "react-i18next"
import { createOrCopyDate, DateRange, DeepReadonly, ReadonlyDate } from "my-util"
import { useStateWithDeps } from "ui/hook"
import { Flex } from "ui/ui/layout"
import { Label } from "ui/ui/output"
import { DateInput } from "../DateInput"

export namespace DateRangeInput {
    export interface Props extends Flex.Props {
        onChange?: (value: DateRange) => void
        value?: DateRange

        invalid?: boolean
        disabled?: boolean
        readonly?: boolean
        loading?: boolean
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DateRangeInput = forwardRef((
    props: DeepReadonly<DateRangeInput.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const {
        onChange, value,
        invalid, disabled, readonly, loading,
    } = props

    const [t] = useTranslation()

    // State


    const [from, setFrom] = useStateWithDeps(
        () => createOrCopyDate(value?.from),
        [value?.from],
    )

    const [to, setTo] = useStateWithDeps(
        () => createOrCopyDate(value?.to),
        [value?.to],
    )

    // Render

    return <Flex { ...props }

                 direction="row"
                 width="fit-content"

                 ref={ref}>
        {renderInput(t("datetime.labels.from"), from, onFromChange)}
        {renderInput(t("datetime.labels.to"), to, onToChange)}
    </Flex>

    function renderInput(
        label: string,
        value: ReadonlyDate,
        onChange: (date: Date) => void,
    ): ReactNode {
        return <Flex direction="row"
                     gap="8px">
            <Label fontSize="16px"
                   text={label}/>

            <DateInput onChange={({ date }) => onChange(date)}
                       value={value}

                       invalid={invalid}
                       disabled={disabled}
                       loading={loading}
                       readonly={readonly}

                       width="min"/>
        </Flex>
    }

    // Events

    function onFromChange(newFrom: Date) {
        onInnerChange(
            newFrom,
            newFrom > to ? newFrom : to,
        )
    }

    function onToChange(newTo: Date) {
        onInnerChange(
            newTo < from ? newTo : from,
            newTo,
        )
    }

    function onInnerChange(newFrom: Date, newTo: Date) {
        setFrom(newFrom)
        setTo(newTo)

        onChange?.({
            from: new Date(newFrom),
            to: new Date(newTo),
        })
    }
})

DateRangeInput.displayName = "DateRangeInput"
