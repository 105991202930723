import Decimal from "decimal.js"
import { Comparator, ReadonlyDate } from "./type"

export const STRING_COMPARATOR: Comparator<string> =
    (lhs, rhs) => lhs.localeCompare(rhs)

export const DATE_COMPARATOR: Comparator<ReadonlyDate> =
    (lhs, rhs) => Math.sign(lhs.getTime() - rhs.getTime())

export const NUMBER_COMPARATOR: Comparator<number> =
    (lhs, rhs) => Math.sign(lhs - rhs)

export const DECIMAL_COMPARATOR: Comparator<Decimal> =
    (lhs, rhs) => lhs.comparedTo(rhs)
