import { ForwardedRef, forwardRef } from "react"

export namespace Gap {
    export interface Props {
        width?: string
        height?: string
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Gap = forwardRef((
    { width, height }: Readonly<Gap.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) =>
    <div style={{ width, height }}
         ref={ref}/>
)

Gap.displayName = "Gap"
