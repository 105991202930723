import assert from "assert"

import { ReactElement, ReactNode, useCallback, useState,
         useContext, useEffect, useLayoutEffect, useMemo, useRef } from "react"

import { useTranslation } from "react-i18next"
import { useSearchParams } from "react-router-dom"
import { arrowLeftIconUrl } from "images"
import { getAllUsers } from "api"
import { AnonymousChatMessage, ChatMessage, User } from "model"

import { SECOND_MILLIS, splicedArray, DeepReadonly,
         tryNormalizeNullableUuid, generateRandomUuid } from "my-util"

import { MAX_MEDIUM_TEXT_LENGTH } from "validation"
import { useUsers, useChat, ChatHook, useStateWithDeps} from "ui/hook"
import { UserContext } from "ui/context"
import { Error403Page, SessionExpiredErrorPage } from "ui/page/error"

import { ChatList, UiDocument, chatMessageToUi, UiChat,
         Page, Chat, UiChatMessage, UserLink, copyUiDocument } from "ui/component"

import { Button, Flex, Center, Loading, ErrorText, Link, Pane,
         LoadingIndicator, Segue, FlexItem, Clickable, UserRoleAbbr } from "ui/ui"

import { DebugPane } from "../DebugPane"
import {isUiChatMessageUnread, uiChatMessagesToUniqueAndSorted } from "../util"
import { USER_ID_SEARCH_PARAM } from "../path"

import { getLastUserId, setLastUserId,
         unsentMessageToUi, deleteUnsentMessageById,
         getUnsentMessages, pushUnsentMessage } from "../storage"

import style from "./style.module.css"

// Consts

// - WS

const LOAD_MORE_COUNT = 128
const LOAD_MORE_OFFSET = 512

// - UI

const CHAT_LIST_PANE_WIDTH = 350
const PANES_GAP = 8

const MOBILE_WIDTH_THRESHOLD = PANES_GAP + 2 * CHAT_LIST_PANE_WIDTH

// Types

// - Chat

interface UserChatChanges extends Partial<Omit<UserChat, "user">> {
    messagesAction?: MessagesAction
}

interface UserChat extends Required<UiChat> {
    hasMoreToLoad: boolean
    loadingMore: boolean
    loadingMoreError: unknown

    gotInfo: boolean

    messageText?: string
    messageDocuments?: UiDocument[]
}

type MessagesAction =
    | "replace"
    | "merge"

// Component

export function ManagerMessengerPage() {
    const [t] = useTranslation()

    const [searchParams, setSearchParams] = useSearchParams()

    // State

    // - Users

    const storedUsers = useUsers()
    const [localUser] = useContext(UserContext)

    const [usersById, setUsersById] = useState(new Map<string, User>())
    const [loadingUsers, setLoadingUsers] = useState(true)
    const [usersLoadingError, setUsersLoadingError] = useState(undefined as unknown)

    // - Selected user

    const userIdSearchParam = tryNormalizeNullableUuid(
        searchParams.get(USER_ID_SEARCH_PARAM)
    )

    const selectedUserId = userIdSearchParam ?? getLastUserId()

    const selectedUser = useMemo(
        () => selectedUserId != null
            ? usersById.get(selectedUserId)
            : null,

        [usersById, selectedUserId],
    )

    // - Mobile

    const [mobile, setMobile] = useState(false)
    const [showChatList, setShowChatList] = useState(selectedUserId == null)

    const contentRef = useRef(null as HTMLDivElement | null)

    // - Chat

    const chatHook = useChat({
        onResponse: onChatResponse,
        shouldReconnect: true,
        reconnectInterval: 5 * SECOND_MILLIS,
        reconnectAttempts: Infinity, // I think thats enough...
    })

    const [chatsByUserId, setChatsByUserId] = useStateWithDeps<Map<string, UserChat>>(
        oldChatsByUserId => {
            const unsentMessages = getUnsentMessages()
            const newChatsByUserId = new Map(oldChatsByUserId)

            for (const user of usersById.values()) {
                if (newChatsByUserId.has(user.id))
                    continue

                const chat: UserChat = {
                    hasMoreToLoad: true,
                    loadingMoreError: undefined,
                    loadingMore: false,
                    gotInfo: false,
                    unreadCount: 0,

                    messages: unsentMessages
                        .filter(({ recipientId }) => recipientId === user.id)
                        .map(message => unsentMessageToUi(message, { localUser })),

                    user,
                }

                newChatsByUserId.set(user.id, chat)
            }

            return newChatsByUserId
        },

        [usersById],
    )

    const chats = useMemo(() => [...chatsByUserId.values()], [chatsByUserId])

    const selectedChat = useMemo(
        () => selectedUserId != null
            ? chatsByUserId.get(selectedUserId)
            : null,

        [selectedUserId, chatsByUserId],
    )

    const chatsScrollByUserIdRef = useRef(new Map<string, number>())
    const sentRequestsByIdRef = useRef(new Map<string, ChatHook.Request>())

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onViewMessagesMemo = useCallback(onViewMessages, [selectedChat, mobile, showChatList])

    // Effects

    // - Adaptivity

    useLayoutEffect(() => {
        const content = contentRef.current

        if (content == null)
            return

        const observer = new ResizeObserver(handleResize)

        observer.observe(content)

        return () => observer.disconnect()

        function handleResize() {
            setMobile(content!.offsetWidth < MOBILE_WIDTH_THRESHOLD)
        }
    }, [])

    // - Search params fix

    useEffect(() => {
        if (userIdSearchParam == null && selectedUserId != null)
            setSearchParams(
                [[USER_ID_SEARCH_PARAM, selectedUserId]],
                { replace: true },
            )
    }, [selectedUserId, setSearchParams, userIdSearchParam])

    // - Users loading

    useEffect(() => {
        if (!loadingUsers)
            return

        const controller = new AbortController()

        getAllUsers(controller.signal)
            .then(users => {
                storedUsers.addAll(users)
                setUsersById(User.groupById(users))
            })
            .catch(error => {
                if (!controller.signal.aborted)
                    setUsersLoadingError(error)
            })
            .finally(() => {
                if (!controller.signal.aborted)
                    setLoadingUsers(false)
            })

        return () => controller.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingUsers])

    // - Chat info requesting

    useEffect(() => {
        for (const chat of chats)
            if (!chat.gotInfo && !isChatInfoRequested(chat.user.id))
                requestChatInfo(chat.user.id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chats])

    // Render

    return renderPage()

    function renderPage(): ReactElement {
        if (localUser == null)
            return <SessionExpiredErrorPage/>

        if (!localUser.isManager)
            return <Error403Page/>

        return <Page type="main">
            <Flex gap={`${PANES_GAP}px`}
                  ref={contentRef}
                  height="100%">
                {mobile
                    ? renderMobileContentPanes()
                    : renderDesktopContentPanes()
                }

                {process.env.NODE_ENV === "development" &&
                    <FlexItem width="100%"
                              shrink={1}>
                        {renderDebugPane()}
                    </FlexItem>
                }
            </Flex>
        </Page>
    }

    function renderMobileContentPanes(): ReactNode {
        return <Segue shown={showChatList ? 0 : 1}
                      swipeablePrev={!showChatList}
                      onSwipePrev={onSwipePrev}
                      height="100%">
            <Pane header={renderChatListTitle()}
                  contentOverflow="auto">
                {renderChatList()}
            </Pane>

            <Pane header={renderMobileChatHeader()}
                  headerOverflow="hidden"
                  headerPadding="8px"
                  contentPadding="0">
                {renderChat()}
            </Pane>
        </Segue>

        function onSwipePrev() {
            setShowChatList(true)
        }
    }

    function renderMobileChatHeader(): ReactElement {
        return <Flex direction="row">
            <Button onClick={onCloseChat}
                    width="fit-content"
                    iconSrc={arrowLeftIconUrl}
                    buttonStyle="text"/>

            {renderChatTitle()}
        </Flex>

        function onCloseChat() {
            setShowChatList(true)
        }
    }

    function renderDesktopContentPanes(): ReactNode {
        return <Flex direction="row"
                     height="100%"
                     gap={`${PANES_GAP}px`}>
            <Pane header={renderChatListTitle()}
                  width={`${CHAT_LIST_PANE_WIDTH}px`}
                  contentOverflow="auto">
                {renderChatList()}
            </Pane>

            <Pane header={renderChatTitle()}
                  width={`calc(100% - ${CHAT_LIST_PANE_WIDTH + PANES_GAP}px)`}
                  contentPadding="0">
                {renderChat()}
            </Pane>
        </Flex>
    }

    // - Chat list

    function renderChatListTitle(): ReactNode {
        return <Flex justify="space-between"
                     direction="row">
            {t("sections.messenger.headers.chats")}

            <Clickable onClick={() => localUser != null && onUserSelect(localUser)}>
                <Link text={t("chat.labels.selfChat")}
                      to="#"/>
            </Clickable>
        </Flex>
    }

    function renderChatList(): ReactNode {
        if (loadingUsers)
            return <Center>
                <LoadingIndicator/>
            </Center>

        if (usersLoadingError != null) {
            return <Flex justify="center"
                         height="100%">
                <ErrorText error={usersLoadingError}/>

                <Button text={t("misc.actions.retry")}
                        width="fit-content"
                        buttonStyle="text"/>
            </Flex>
        }

        return <div className={style.chatList}>
            <ChatList onClick={({ user }) => onUserSelect(user)}
                      selected={selectedUserId ?? undefined}
                      filter={({ user }) => user.id !== localUser?.id}
                      chats={chats}/>
        </div>
    }

    // - Chat

    function renderChatTitle(): ReactElement | null {
        if (selectedUser == null)
            return null

        if (selectedUser.id === localUser?.id)
            return <>{t("chat.labels.selfChat")}</>

        return <Flex direction="row">
            <UserRoleAbbr role={selectedUser.role}
                          colorful/>

            <UserLink user={selectedUser}/>
        </Flex>
    }

    function renderChat(): ReactNode {
        if (loadingUsers)
            return <Loading/>

        if (selectedUserId == null)
            return <Center>
                {t("chat.messages.noChatSelected")}
            </Center>

        if (selectedChat == null)
            return <Center>
                <ErrorText error={t("chat.messages.errors.chatNotFound")}/>
            </Center>

        if (!selectedChat.gotInfo)
            return <Loading/>

        const {
            hasMoreToLoad, loadingMore, loadingMoreError,
            messages,
            messageText, messageDocuments,
        } = selectedChat

        const scroll = chatsScrollByUserIdRef.current.get(selectedUserId)

        return <Chat onViewMessages={onViewMessagesMemo}

                     onDeleteMessage={onDeleteMessage}
                     onResendMessage={onResendMessage}

                     senderId={localUser?.id}
                     sender={() => t("misc.words.you")}
                     onSend={onSendMessage}

                     onLoadMore={onLoadMore}
                     hasMoreToLoad={hasMoreToLoad}
                     loadingMore={loadingMore}
                     loadingMoreError={loadingMoreError}
                     loadMoreOffset={LOAD_MORE_OFFSET}

                     onMessagesChange={onMessagesChange}
                     messages={messages}

                     onMessageTextChange={onMessageTextChange}
                     messageText={messageText}
                     maxMessageTextLength={MAX_MEDIUM_TEXT_LENGTH}

                     onMessageDocumentsChange={onMessageDocumentsChange}
                     messageDocuments={messageDocuments}

                     onScroll={onScroll}
                     scroll={scroll}

                     showScrollDownButton

                     key={selectedUserId}/>

        function onDeleteMessage(message: DeepReadonly<UiChatMessage>, messageIndex: number) {
            assert(selectedUserId != null)

            if (!message.local)
                return

            const newMessages = splicedArray(messages, messageIndex, 1)

            updateChatByUserId(selectedUserId, {
                messagesAction: "replace",
                messages: newMessages,
            })

            if (typeof message.id !== "string")
                return

            switch (message.status ?? "sent") {
                case "error":
                    deleteUnsentMessageById(message.id)
                    break

                case "read":
                case "sent":
                case "sending": {
                    const request: ChatHook.Request.DeleteMessages = {
                        type: "delete",
                        messageIds: [message.id],
                    }

                    sendChatRequest(request)

                    break
                }
            }
        }

        function onResendMessage(message: DeepReadonly<UiChatMessage>) {
            assert(selectedUserId != null)

            if (!message.local)
                return

            const request: ChatHook.Request.SendMessage = {
                type: "send-message",

                id: typeof message.id === "string"
                    ? message.id
                    : generateRandomUuid(),

                text: message.text,

                documentIds: message.documents
                    ?.filter(({ status }) => status === "ready")
                    .map(document => document.document!.id),

                recipientId: selectedUserId,
            }

            sendChatRequest(request)

            updateChatByUserId(selectedUserId, {
                messagesAction: "merge",

                messages: [{
                    ...message,

                    documents: message.documents?.map(copyUiDocument),
                    date: new Date(message.date.getTime()),
                    status: "sent",
                }],
            })
        }

        function onSendMessage(message: UiChatMessage): UiChatMessage | undefined {
            assert(selectedUserId != null)

            message.id = generateRandomUuid()
            message.status = "sending"

            const request: ChatHook.Request.SendMessage = {
                type: "send-message",
                id: message.id,
                text: message.text,

                documentIds: message.documents
                    ?.filter(({ status }) => status === "ready")
                    .map(document => document.document!.id),

                recipientId: selectedUserId,
            }

            sendChatRequest(request)

            return message
        }

        function onLoadMore() {
            assert(selectedUserId != null)

            const lastMessage = messages.length > 0
                ? messages[0]
                : null

            const request: ChatHook.Request.LoadMessages = {
                id: generateRandomUuid(),
                type: "load-messages",
                userId: selectedUserId,
                before: lastMessage?.date ?? new Date(),
                limit: LOAD_MORE_COUNT,
            }

            sendChatRequest(request)

            updateChatByUserId(selectedUserId, { loadingMore: true })
        }

        function onMessagesChange(newMessages: UiChatMessage[]) {
            assert(selectedUserId != null)
            updateChatByUserId(selectedUserId, { messages: newMessages })
        }

        function onMessageTextChange(newMessageText: string) {
            assert(selectedChat != null)
            selectedChat.messageText = newMessageText
        }

        function onMessageDocumentsChange(newMessageDocuments: UiDocument[]) {
            assert(selectedChat != null)
            selectedChat.messageDocuments = newMessageDocuments
        }

        function onScroll(newScroll: number) {
            assert(selectedUserId != null)
            chatsScrollByUserIdRef.current.set(selectedUserId, newScroll)
        }
    }

    // - Debug

    function renderDebugPane(): ReactNode {
        return <DebugPane connectionStatus={chatHook.readyState}
                          messageCount={selectedChat?.messages?.length}
                          onDisconnect={() => chatHook.close()}
                          contentOverflow="auto"/>
    }

    // Events

    // - UI

    function onUserSelect(user: User) {
        if (!mobile && user.id === selectedUserId) {
            setLastUserId(null)
            setSearchParams([])
            setShowChatList(true)
        } else {
            setLastUserId(user.id)
            setSearchParams([[USER_ID_SEARCH_PARAM, user.id ]])
            setShowChatList(false)
        }
    }

    function onViewMessages(messages: UiChatMessage[]) {
        if (mobile && showChatList)
            return

        const unreadMessages = messages.filter(
            message =>
                isUiChatMessageUnread(message) &&
                typeof message.id === "string"
        )

        if (unreadMessages.length === 0)
            return

        if (selectedChat != null)
            setTimeout(() => {
                updateChatByUserId(selectedChat.user.id, {
                    messagesAction: "merge",
                    unreadCount: Math.max(selectedChat.unreadCount - unreadMessages.length, 0),

                    messages: unreadMessages.map(message => ({
                        ...message,
                        status: "read",
                    }))
                })
            }, 0)

        const unreadMessageIds = new Set(unreadMessages.map(({ id }) => id) as string[])

        for (const request of sentRequestsByIdRef.current.values())
            if (request.type === "mark-read")
                for (const messageId of request.messageIds)
                    unreadMessageIds.delete(messageId)

        if (unreadMessageIds.size === 0)
            return

        const request: ChatHook.Request.MarkMessagesRead = {
            type: "mark-read",
            id: generateRandomUuid(),
            messageIds: [...unreadMessageIds],
        }

        sendChatRequest(request)
    }

    // - WS

    function onChatResponse(response: ChatHook.Response) {
        switch (response.type) {
            case "messages":
                onMessagesChatResponse(response)
                break

            case "info":
                onInfoChatResponse(response)
                break

            case "mark-read":
                onMarkReadChatResponse(response)
                break

            case "delete":
                onDeleteChatResponse(response)
                break

            case "request-verification":
                onRequestVerificationChatResponse(response)
                break

            default:
                response satisfies never
        }

        if (response.requestId != null)
            sentRequestsByIdRef.current.delete(response.requestId)
    }

    function onMessagesChatResponse(response: ChatHook.Response.Messages) {
        tryHandleAsLoadMoreRequestResponse(response)

        // Anonymous messages are filtered because it's impossible
        // to 100% precisely decide to which chat to dispatch them
        const filteredMessages = filterOutAnonymousChatMessages(response.messages)
        const messagesByUserId = groupChatMessagesByChatUserId(filteredMessages)
        const uiMessagesByUserId = chatMessagesByIdToUiChatMessagesById(messagesByUserId)

        updateAllChatsMessages(uiMessagesByUserId)

        return

        function tryHandleAsLoadMoreRequestResponse(response: ChatHook.Response.Messages) {
            const { requestId } = response

            if (requestId == null)
                return

            const request = sentRequestsByIdRef.current.get(requestId)

            if (request == null || request.type !== "load-messages" || request.userId == null)
                return

            updateChatByUserId(request.userId, {
                hasMoreToLoad: response.messages.length >= LOAD_MORE_COUNT,
                loadingMoreError: undefined,
                loadingMore: false,
            })
        }

        function filterOutAnonymousChatMessages(
            messages: readonly (ChatMessage | AnonymousChatMessage)[],
        ): ChatMessage[] {
            return messages.filter(message => message instanceof ChatMessage) as ChatMessage[]
        }

        function groupChatMessagesByChatUserId(
            messages: readonly ChatMessage[]
        ): Map<string, ChatMessage[]> {
            const messagesByChatUserId = new Map<string, ChatMessage[]>()

            for (const message of messages) {
                const chatMessages = getChatMessagesByMessage(message)

                chatMessages?.push(message)
            }

            return messagesByChatUserId

            function getChatMessagesByMessage(message: ChatMessage): ChatMessage[] | null {
                if (message.creatorId == null)
                    return null

                // Sent to you
                if (message.recipientId === localUser?.id)
                    return getOrCreateChatMessages(message.creatorId)

                // Sent to someone else
                if (message.recipientId != null)
                    return getOrCreateChatMessages(message.recipientId)

                // Sent (by client) to all managers
                return getOrCreateChatMessages(message.creatorId)
            }

            function getOrCreateChatMessages(userId: string): ChatMessage[] {
                let messages = messagesByChatUserId.get(userId)

                if (messages == null)
                    messagesByChatUserId.set(userId, messages = [])

                return messages
            }
        }

        function chatMessagesByIdToUiChatMessagesById(
            messagesById: Map<string, ChatMessage[]>,
        ): Map<string, UiChatMessage[]> {
            const uiMessagesById = new Map<string, UiChatMessage[]>()

            for (const [id, messages] of messagesById.entries()) {
                const uiMessages = messages.map(
                    message => chatMessageToUi(message, { usersById, localUser })
                )

                uiMessagesById.set(id, uiMessages)
            }

            return uiMessagesById
        }

        function updateAllChatsMessages(messagesByUserId: Map<string, UiChatMessage[]>) {
            for (const [userId, messages] of messagesByUserId.entries())
                updateChatByUserId(
                    userId,

                    {
                        messagesAction: "merge",
                        messages,
                    },

                    (newChat, oldChat) => {
                        const newUnreadCount = countUnreadMessages(newChat)
                        const oldUnreadCount = countUnreadMessages(oldChat)

                        if (newUnreadCount !== oldUnreadCount)
                            updateChatByUserId(userId, { unreadCount: newUnreadCount })

                        function countUnreadMessages(chat: UserChat): number {
                            let count = 0

                            for (const message of chat.messages)
                                if (isUiChatMessageUnread(message))
                                    ++count

                            return count
                        }
                    }
                )
        }
    }

    function onInfoChatResponse({ userId, lastMessage, unreadCount }: ChatHook.Response.Info) {
        updateChatByUserId(userId, {
            unreadCount,

            messages: lastMessage != null
                ? [chatMessageToUi(lastMessage, { usersById, localUser })]
                : undefined,

            messagesAction: "merge",

            gotInfo: true,
        })
    }

    function onMarkReadChatResponse({ messageIds }: ChatHook.Response.MarkMessagesRead) {
        // For speed-up
        const messageIdsSet = new Set(messageIds)

        for (const chat of chats) {
            const readMessages = chat.messages.filter(
                ({ id }) => typeof id === "string" && messageIdsSet.has(id),
            )

            if (readMessages.length === 0)
                continue

            const newMessages = readMessages.map<UiChatMessage>(message => ({
                ...message,
                status: "read",
            }))

            updateChatByUserId(chat.user.id, {
                messagesAction: "merge",
                messages: newMessages,
            })
        }
    }

    function onDeleteChatResponse({ messageIds }: ChatHook.Response.DeleteMessages) {
        // For speed-up
        const messageIdsSet = new Set(messageIds)

        for (const chat of chats) {
            const newMessages = new Array<UiChatMessage>()

            let newUnreadCount = chat.unreadCount

            for (const message of chat.messages) {
                if (typeof message.id !== "string" || !messageIdsSet.has(message.id)) {
                    newMessages.push(message)
                    continue
                }

                if (isUiChatMessageUnread(message))
                    --newUnreadCount
            }

            updateChatByUserId(chat.user.id, {
                unreadCount: newUnreadCount,
                messagesAction: "replace",
                messages: newMessages,
            })
        }
    }

    function onRequestVerificationChatResponse(response: ChatHook.Response.RequestVerification) {
        const { requestId, error } = response

        const request = sentRequestsByIdRef.current.get(requestId)

        if (request == null)
            return

        if (error != null)
            console.error(`Request ${requestId} failed: ${error}`)

        switch (request.type) {
            case "send-message":
                handleSendMessageRequestVerification(request)
                break

            case "load-messages":
                handleLoadMessagesRequestVerification(request)
                break
        }

        return

        function handleSendMessageRequestVerification(request: ChatHook.Request.SendMessage) {
            deleteUnsentMessageById(requestId)

            if (request.recipientId == null)
                return

            const chat = chatsByUserId.get(request.recipientId)

            if (chat == null)
                return

            const message = chat.messages.find(({ id }) => id === requestId)

            if (message == null)
                return

            const newMessage: UiChatMessage = {
                ...message,

                status: error != null
                    ? "error"
                    : chat.user.id === localUser?.id
                        ? "read"
                        : "sent",
            }

            updateChatByUserId(chat.user.id, {
                messagesAction: "merge",
                messages: [newMessage],
            })

            if (error != null)
                pushUnsentMessage(request)
        }

        function handleLoadMessagesRequestVerification(request: ChatHook.Request.LoadMessages) {
            if (request.userId != null)
                updateChatByUserId(request.userId, {
                    loadingMoreError: error != null ? t("errors.loadingFailed") : null,
                    loadingMore: false,
                })
        }
    }

    // Util

    // - UI

    function updateChatByUserId(
        userId: string,
        changes: UserChatChanges,
        onChatChanged?: ((newChat: UserChat, oldChat: UserChat) => void) | null,
    ) {
        setChatsByUserId(oldChatsByUserId => {
            const oldChat = oldChatsByUserId.get(userId)

            if (oldChat == null)
                return oldChatsByUserId

            const newChatsByUserId = new Map(oldChatsByUserId)

            const newChat: UserChat = {
                user: oldChat.user,

                messages: getNewMessages(),

                unreadCount: changes.unreadCount ?? oldChat.unreadCount,
                hasMoreToLoad: changes.hasMoreToLoad ?? oldChat.hasMoreToLoad,
                loadingMore: changes.loadingMore ?? oldChat.loadingMore,
                loadingMoreError: changes.loadingMoreError ?? oldChat.loadingMoreError,
                gotInfo: changes.gotInfo ?? oldChat.gotInfo,
            }

            newChatsByUserId.set(userId, newChat)

            onChatChanged?.(newChat, oldChat)

            return newChatsByUserId

            function getNewMessages(): UiChatMessage[] {
                assert(oldChat != null)

                if (changes.messages == null)
                    return oldChat.messages

                switch (changes.messagesAction ?? "replace") {
                    case "merge":
                        return uiChatMessagesToUniqueAndSorted([
                            ...oldChat.messages,
                            ...changes.messages,
                        ])

                    case "replace":
                        return uiChatMessagesToUniqueAndSorted(changes.messages)
                }
            }
        })
    }

    // - WS

    // -- Chat info

    function isChatInfoRequested(userId: string): boolean {
        for (const request of sentRequestsByIdRef.current.values())
            if (request.type === "get-info" && request.userId === userId)
                return true

        return false
    }

    function requestChatInfo(userId: string) {
        const request: ChatHook.Request.GetInfo = {
            id: generateRandomUuid(),
            type: "get-info",
            userId,
        }

        sendChatRequest(request)
    }

    // -- Common

    function sendChatRequest(request: ChatHook.Request) {
        chatHook.sendRequest(request)

        if (request.id != null)
            sentRequestsByIdRef.current.set(request.id, request)
    }
}
