import { t } from "i18next"
import type { Comparator } from "my-util"
import type { User } from "./User"

// Types

export type UserStatus =
    | ActiveUserStatus
    | UnverifiedUserStatus
    | BlockedUserStatus

export type ActiveUserStatus = typeof ACTIVE_USER_STATUS
export type UnverifiedUserStatus = typeof UNVERIFIED_USER_STATUS
export type BlockedUserStatus = typeof BLOCKED_USER_STATUS

// Consts

export const ACTIVE_USER_STATUS = "active"
export const UNVERIFIED_USER_STATUS = "unverified"
export const BLOCKED_USER_STATUS = "blocked"

// Name

export function getUserStatusName(status: UserStatus): string {
    return t(`domain.users.statuses.${status}`)
}

// Is-check

export function isUserStatus(s: string): s is UserStatus {
    switch (s) {
        case ACTIVE_USER_STATUS:
        case UNVERIFIED_USER_STATUS:
        case BLOCKED_USER_STATUS:
            s satisfies UserStatus
            return true

        default:
            return false
    }
}

// Color

export interface UserStatusColors {
    backgroundColor: string
    borderColor: string
    color: string
}

export const ACTIVE_USER_STATUS_COLORS: Readonly<UserStatusColors> = {
    backgroundColor: "#E6F4EA",
    borderColor: "#E6F4EA",
    color: "#08922E",
}

export const UNVERIFIED_USER_STATUS_COLORS: Readonly<UserStatusColors> = {
    backgroundColor: "#fffcf4",
    borderColor: "#cc5500",
    color: "#cc5500",
}

export const BLOCKED_USER_STATUS_COLORS: Readonly<UserStatusColors> = {
    backgroundColor: "#F2F2F2",
    borderColor: "#F2F2F2",
    color: "#333",
}

export function getUserStatusColors(status: UserStatus): UserStatusColors {
    switch (status) {
        case ACTIVE_USER_STATUS:
            return { ...ACTIVE_USER_STATUS_COLORS }

        case UNVERIFIED_USER_STATUS:
            return { ...UNVERIFIED_USER_STATUS_COLORS }

        case BLOCKED_USER_STATUS:
            return { ...BLOCKED_USER_STATUS_COLORS }
    }
}

// Sorting

export type UsersByStatusSortingOrder = UserStatus[]

export const ACTIVE_USERS_BY_STATUS_SORTING_ORDER: Readonly<UsersByStatusSortingOrder> = [
    ACTIVE_USER_STATUS,
    UNVERIFIED_USER_STATUS,
    BLOCKED_USER_STATUS,
]

export const UNVERIFIED_USERS_BY_STATUS_SORTING_ORDER: Readonly<UsersByStatusSortingOrder> = [
    UNVERIFIED_USER_STATUS,
    ACTIVE_USER_STATUS,
    BLOCKED_USER_STATUS,
]

export const BLOCKED_USERS_BY_STATUS_SORTING_ORDER: Readonly<UsersByStatusSortingOrder> = [
    BLOCKED_USER_STATUS,
    UNVERIFIED_USER_STATUS,
    ACTIVE_USER_STATUS,
]

export function getUsersByStatusSortingOrderComparator(
    statusOrOrder: UserStatus | Readonly<UsersByStatusSortingOrder>,
): Comparator<User> {
    const order = typeof statusOrOrder === "string"
        ? getUsersByStatusSortingOrder(statusOrOrder)
        : statusOrOrder

    return (lhs, rhs) => {
        const lhsIndex = order.indexOf(lhs.status)
        const rhsIndex = order.indexOf(rhs.status)

        return Math.sign(lhsIndex - rhsIndex)
    }
}

export function getUsersByStatusSortingOrder(status: UserStatus): UsersByStatusSortingOrder {
    switch (status) {
        case ACTIVE_USER_STATUS:
            return [...ACTIVE_USERS_BY_STATUS_SORTING_ORDER]

        case UNVERIFIED_USER_STATUS:
            return [...UNVERIFIED_USERS_BY_STATUS_SORTING_ORDER]

        case BLOCKED_USER_STATUS:
            return [...BLOCKED_USERS_BY_STATUS_SORTING_ORDER]
    }
}

// Presence

export interface UserStatusPresence {
    activePresent: boolean
    blockedPresent: boolean
    unverifiedPresent: boolean
}

export function checkUserStatusPresence(users: readonly User[]): UserStatusPresence {
    return {
        activePresent: users.some(({ status }) => status === ACTIVE_USER_STATUS),
        blockedPresent: users.some(({ status }) => status === BLOCKED_USER_STATUS),
        unverifiedPresent: users.some(({ status }) => status === UNVERIFIED_USER_STATUS),
    }
}
