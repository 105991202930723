import { ForwardedRef, forwardRef } from "react"
import style from "./style.module.css"

export namespace Subheader {
    export interface Props {
        text: string
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Subheader = forwardRef((
    { text }: Readonly<Subheader.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) =>
    <div className={style.Subheader}
         ref={ref}>
        {text}
    </div>
)

Subheader.displayName = "Subheader"
