import { ForwardedRef, forwardRef } from "react"
import { GlobalLangSelector } from "ui/component/i18n"
import { Copyright, Flex } from "ui/ui"
import style from "./style.module.css"

export namespace Footer {
    export interface Props {
        type?: Type
    }

    export type Type =
        | "auth"
        | "main"
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Footer = forwardRef((
    { type }: Readonly<Footer.Props>,
    ref: ForwardedRef<HTMLElement>,
) =>
    <footer className={style.Footer}
            ref={ref}>
        <Flex justify="space-between"
              direction="row">
            <GlobalLangSelector/>

            {type === "main" &&
                <Copyright/>
            }
        </Flex>
    </footer>
)

Footer.displayName = "Footer"
