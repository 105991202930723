import { CSSProperties, ForwardedRef, forwardRef, ReactNode } from "react"
import { DeepReadonly } from "my-util"
import style from "./style.module.css"

export namespace Limit {
    export interface Props {
        width?: string
        height?: string

        minWidth?: string
        maxWidth?: string

        minHeight?: string
        maxHeight?: string

        overflow?: CSSProperties["overflow"]

        children?: ReactNode
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Limit = forwardRef((
    {
        width, height,
        minWidth, maxWidth,
        minHeight, maxHeight,
        overflow,
        children,
    }: DeepReadonly<Limit.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) =>
    <div
        className={style.Limit}
        style={{
            width, height,
            minWidth, maxWidth,
            minHeight, maxHeight,
            overflow,
        }}
        ref={ref}
    >
        {children}
    </div>
)

Limit.displayName = "Limit"
