import { useNavigate } from "react-router-dom"
import { ForwardedRef, forwardRef, useEffect } from "react"

import { NEW_USER_NOTIFICATION_TYPE,
         NEW_INVITE_NOTIFICATION_TYPE,
         NEW_PROVIDER_NOTIFICATION_TYPE,
         NEW_TRANSFER_NOTIFICATION_TYPE,
         NEW_CHAT_MESSAGES_NOTIFICATION_TYPE,
         NEW_INVITE_APPLICATION_NOTIFICATION_TYPE,
         NewChatMessagesNotification, Notification,
         TRANSFER_STATUS_CHANGED_NOTIFICATION_TYPE } from "model"

import { useInviteApplications, useInvites, useUsers,
         useNotifications, useProviders, useTransfers } from "ui/hook"

import * as AllClientsPage from "ui/page/sections/clients/AllClientsPage/path"
import * as AllInviteApplicationsPage from "ui/page/sections/clients/invites/applications/AllInviteApplicationsPage/path"
import * as AllInvitesPage from "ui/page/sections/clients/invites/AllInvitesPage/path"
import * as InvitePage from "ui/page/sections/clients/invites/InvitePage/path"
import * as MessengerPage from "ui/page/sections/messenger/MessengerPage/path"
import * as AllTransfersPage from "ui/page/sections/transfers/AllTransfersPage/path"
import * as TransferPage from "ui/page/sections/transfers/TransferPage/path"
import * as UserPage from "ui/page/sections/users/UserPage/path"
import * as AllProvidersPage from "ui/page/sections/providers/AllProvidersPage/path"
import { NotificationList } from "ui/component/notification"

export namespace GlobalNotificationList {
    export interface Props {
        onChange?: (notifications: Notification[]) => void

        width?: string
        height?: string
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GlobalNotificationList = forwardRef((
    {
        onChange,
        width, height,
    }: Readonly<GlobalNotificationList.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const navigate = useNavigate()

    // State

    const [
        notifications,
        {
            clear: clearNotifications,
            deleteByValue: deleteNotification,
        }
    ] = useNotifications()

    const inviteApplicationsStorage = useInviteApplications()
    const invitesStorage = useInvites()
    const usersStorage = useUsers()
    const transfersStorage = useTransfers()
    const providersStorage = useProviders()

    // Effects

    // - State propagation

    useEffect(
        () => onChange?.(notifications),
        [notifications, onChange],
    )

    // - Objects loading

    useEffect(
        () => {
            for (const notification of notifications) {
                for (const userId of notification.userIds)
                    usersStorage.loadByIdIfNew(userId)

                switch (notification.type) {
                    case NEW_INVITE_APPLICATION_NOTIFICATION_TYPE: {
                        const { inviteApplicationId } = notification

                        if (inviteApplicationId != null)
                            inviteApplicationsStorage.loadByIdIfNew(inviteApplicationId)

                        break
                    }

                    case NEW_INVITE_NOTIFICATION_TYPE: {
                        const { inviteId } = notification

                        if (inviteId == null)
                            break

                        invitesStorage.loadByIdIfNew(inviteId)

                        const invite = invitesStorage.loadedById.get(inviteId)

                        if (invite?.creatorId != null)
                            usersStorage.loadByIdIfNew(invite.creatorId)

                        break
                    }

                    case NEW_TRANSFER_NOTIFICATION_TYPE:
                    case TRANSFER_STATUS_CHANGED_NOTIFICATION_TYPE: {
                        const { transferId } = notification

                        if (transferId == null)
                            break

                        transfersStorage.loadByIdIfNew(transferId)

                        const transfer = transfersStorage.loadedById.get(transferId)

                        if (transfer?.creatorId != null)
                            usersStorage.loadByIdIfNew(transfer.creatorId)

                        break
                    }

                    case NEW_PROVIDER_NOTIFICATION_TYPE: {
                        const { providerId } = notification

                        if (providerId == null)
                            break

                        providersStorage.loadByIdIfNew(providerId)

                        const provider = providersStorage.loadedById.get(providerId)

                        if (provider?.creatorId != null)
                            usersStorage.loadByIdIfNew(provider.creatorId)

                        break
                    }
                }
            }
        },

        [
            usersStorage, transfersStorage, providersStorage,
            notifications, inviteApplicationsStorage, invitesStorage,
        ]
    )

    // Render

    return <NotificationList notifications={notifications}
                             inviteApplications={inviteApplicationsStorage.loadedById}
                             invites={invitesStorage.loadedById}
                             users={usersStorage.loadedById}
                             transfers={transfersStorage.loadedById}
                             providers={providersStorage.loadedById}

                             onClick={onClick}
                             onClear={clearNotifications}
                             onDelete={deleteNotification}

                             width={width}
                             height={height}

                             ref={ref}/>

    // Events

    function onClick(notification: Notification) {
        switch (notification.type) {
            case NEW_CHAT_MESSAGES_NOTIFICATION_TYPE: {
                const newPath = MessengerPage.createPath(
                    notification instanceof NewChatMessagesNotification
                        ? notification.fromId
                        : null,
                )

                return navigate(newPath)
            }

            case NEW_INVITE_APPLICATION_NOTIFICATION_TYPE:
                return navigate(AllInviteApplicationsPage.PATH)

            case NEW_INVITE_NOTIFICATION_TYPE: {
                const { inviteId } = notification
                const newPath = inviteId != null
                    ? InvitePage.createPath(inviteId)
                    : AllInvitesPage.PATH

                return navigate(newPath)
            }

            case NEW_TRANSFER_NOTIFICATION_TYPE:
            case TRANSFER_STATUS_CHANGED_NOTIFICATION_TYPE: {
                const { transferId } = notification
                const newPath = transferId != null
                    ? TransferPage.createPath(transferId)
                    : AllTransfersPage.PATH

                return navigate(newPath)
            }

            case NEW_USER_NOTIFICATION_TYPE: {
                const { userId } = notification

                const newPath = userId != null
                    ? UserPage.createPath(userId)
                    : AllClientsPage.PATH

                return navigate(newPath)
            }

            case NEW_PROVIDER_NOTIFICATION_TYPE:
                return navigate(AllProvidersPage.PATH)

            default:
                notification satisfies never
        }
    }
})

GlobalNotificationList.displayName = "GlobalNotificationList"
