import { useEffect, useState } from "react"
import { getWindowSize, WindowSize } from "my-util"

export function useWindowSize(): WindowSize {
    const [size, setSize] = useState(getWindowSize())

    useEffect(() => {
        const handleResize = () => setSize(getWindowSize())
        window.addEventListener("resize", handleResize)
        return () => window.removeEventListener("resize", handleResize)
    }, [])

    return size
}
