import Decimal from "decimal.js"
import { ForwardedRef, useMemo } from "react"

import { formatDecimal, forwardRefAndSetProperties,
         DECIMAL_INPUT_REGEX, DeepReadonly, isDigit } from "my-util"

import { useStateWithDeps } from "ui/hook"
import { Input } from "ui/ui/input"

export namespace DecimalInput {
    export interface Props extends Omit<Input.Props, "onChange" | "value" | "text" | "regex"> {
        onChange?: (value: Decimal) => void
        value?: Decimal.Value | null
        precision?: number
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DecimalInput = forwardRefAndSetProperties(
    {
        DEFAULT_PRECISION: 2 as number,
    } as const,

    (
        props: DeepReadonly<DecimalInput.Props>,
        ref: ForwardedRef<HTMLDivElement>,
    ) => {
        const { onChange, value, precision } = props

        // State

        const innerPrecision = (precision ?? DecimalInput.DEFAULT_PRECISION) | 0

        const InnerDecimal = useMemo(
            () => Decimal.clone({ precision: innerPrecision }),
            [innerPrecision],
        )

        const [stringValue, setStringValue] = useStateWithDeps<string>(oldStringValue => {
                switch (typeof value) {
                    case "undefined":
                        return ""

                    case "string":
                        return value

                    case "number":
                        return formatDecimal(value, innerPrecision)

                    case "object":
                        if (value == null)
                            return ""

                        value satisfies Decimal

                        if (oldStringValue == null)
                            return formatDecimal(value, innerPrecision)

                        const oldDecimalValue = stringValueToDecimal(oldStringValue)

                        if (oldDecimalValue.equals(value))
                            return oldStringValue

                        return formatDecimal(value, innerPrecision)

                    default:
                        return value satisfies never
                }
            },

            [value],
        )

        // Render

        return <Input { ...props }

                      onChange={onStringValueChange}
                      value={stringValue}

                      regex={DECIMAL_INPUT_REGEX}
                      test={testInput}

                      ref={ref}/>

        // Events

        function onStringValueChange(newStringValue: string) {
            setStringValue(newStringValue)
            onChange?.(stringValueToDecimal(newStringValue))
        }

        // Util

        function stringValueToDecimal(value: string): Decimal {
            return new InnerDecimal(normalizeStringValue(value))
        }

        function normalizeStringValue(value: string): string {
            value = value
                .replaceAll(/\s+/g, "")
                .replace(",", ".")

            return value.length !== 0
                ? value
                : "0"
        }

        function testInput(amount: string): boolean {
            let sepIndex = amount.indexOf(".")

            if (sepIndex < 0)
                sepIndex = amount.indexOf(",")

            if (sepIndex < 0)
                return true

            const fractional = amount.substring(sepIndex + 1)

            let digitCount = 0

            for (const char of fractional)
                if (isDigit(char) && ++digitCount > innerPrecision)
                    return false

            return true
        }
    },
)

DecimalInput.displayName = "DecimalInput"
