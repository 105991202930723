import { z } from "zod"
import { UuidSchema } from "my-util"
import { ModelObjectResponseSchema } from "../model-object"

export const SignableDocumentResponseSchema = ModelObjectResponseSchema.extend({
    initialId: UuidSchema.nullish(),
    signedId: UuidSchema.nullish(),
    locked: z.coerce.boolean(),
})

export type SignableDocumentResponse = z.infer<typeof SignableDocumentResponseSchema>
