export function splicedArray<T>(
    array: readonly T[],
    start: number,
    deleteCount?: number,
    ...newItems: T[]
): T[] {
    const copy = Array.from(array)

    copy.splice(start, deleteCount!, ...newItems)

    return copy
}

(window as any).splitArray = splitArray

export function splitArray<T>(array: T[], chunkSize: number): T[][]
export function splitArray<T>(array: readonly T[], chunkSize: number): ReadonlyArray<T>[]
export function splitArray<T>(array: readonly T[], chunkSize: number): ReadonlyArray<T>[] {
    const chunks: T[][] = []

    for (let i = 0; i < array.length; ) {
        const nextI = i + chunkSize

        chunks.push(array.slice(i, Math.min(nextI, array.length)))

        i = nextI
    }

    return chunks
}

export function uniqueArray<T>(
    array: readonly T[],
    getProperty: (item: T, index: number, array: readonly T[]) => unknown = item => item,
): T[] {
    return [
        ...new Map(
            array.map((item, index, array) => [
                getProperty(item, index, array),
                item,
            ]),
        ).values(),
    ]
}
