import { ChangeEvent, ForwardedRef, forwardRef } from "react"
import { tickIconUrl } from "images"
import { useStateWithDeps } from "ui/hook"
import { Icon } from "ui/ui/icon"
import { Label } from "ui/ui/output"
import style from "./style.module.css"

export namespace CheckBox {
    export interface Props {
        onChange?: (checked: boolean) => void
        checked?: boolean

        label?: string

        readonly?: boolean
        loading?: boolean
        disabled?: boolean

        width?: string
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CheckBox = forwardRef((
    {
        onChange, checked,
        label,
        readonly, loading, disabled,
        width,
    }: Readonly<CheckBox.Props>,
    ref: ForwardedRef<HTMLLabelElement>,
) => {
    // State

    const [innerChecked, setInnerChecked] = useStateWithDeps(
        () => checked ?? false,
        [checked],
    )

    // Render

    const innerDisabled = disabled || readonly || loading
    const showTick = innerChecked && (readonly || loading || !disabled)

    return <label className={renderClassName()}
                  style={{ width }}
                  ref={ref}>
        <input className={style.htmlInput}
               onChange={onInnerChange}
               checked={innerChecked}
               type="checkbox"
               disabled={innerDisabled}/>

        <span className={style.input}>
            {showTick &&
                <Icon src={tickIconUrl}
                      alt="Tick icon"
                      width="14px"
                      height="fit-content"/>
            }
        </span>

        <Label text={label}
               wrap/>
    </label>

    function renderClassName(): string {
        if (readonly)
            return style.ReadonlyCheckBox

        if (loading)
            return style.LoadingCheckBox

        return style.CheckBox
    }

    // Events

    function onInnerChange(event: ChangeEvent<HTMLInputElement>) {
        const newChecked = event.currentTarget.checked

        setInnerChecked(newChecked)
        onChange?.(newChecked)
    }
})

CheckBox.displayName = "CheckBox"
