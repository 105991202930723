import { ReactNode, useContext } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { SessionExpiredErrorPage } from "ui/page/error"
import { UserContext } from "ui/context"
import * as AllInvitesPage from "ui/page/sections/clients/invites/AllInvitesPage/Content"
import { Page } from "ui/component"
import { Circle, Clickable, Flex, UserRoleArrow } from "ui/ui"
import * as MainPage from "../MainPage/path"

export function Component() {
    const [t] = useTranslation()
    const [localUser] = useContext(UserContext)

    const navigate = useNavigate()

    if (localUser == null)
        return <SessionExpiredErrorPage/>

    return <Page compactTitle={renderCompactTitle()}
                 title={renderTitle()}
                 type="main">
        <AllInvitesPage.Content/>
    </Page>

    function renderCompactTitle(): ReactNode {
        return <Flex direction="row">
            <Circle borderColor="red"
                    color="red"
                    radius="4px"/>

            <span>{t("datetime.labels.today") + ":"}</span>
        </Flex>
    }

    function renderTitle(): ReactNode {
        return <Flex direction="row">
            <Clickable onClick={() => navigate(MainPage.SECOND_PATH)}>
                <UserRoleArrow role={localUser?.role}
                               arrowStyle="with-stick"
                               width="20px"
                               height="10px"
                               angle="180deg"/>
            </Clickable>

            <span>{t("sections.main.invites.header") + ":"}</span>
        </Flex>
    }
}
