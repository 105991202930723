import assert from "assert"
import { ReactElement, ReactNode, useContext, useEffect, useMemo } from "react"
import { useParams, useSearchParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { linkIconUrl } from "images"
import { getUserById } from "api"
import { User } from "model"
import { isUuid, normalizeUuid } from "my-util"
import { useStateWithDeps, useUsers, useWindowSize } from "ui/hook"
import { UserContext } from "ui/context"
import { Error404Page, SessionExpiredErrorPage } from "ui/page/error"
import * as LoginPage from "ui/page/auth/LoginPage/path"
import * as MessengerPage from "ui/page/sections/messenger/MessengerPage/path"
import { Page } from "ui/component"

import { ErrorDisplay, Copy, Name, Flex, Tab, Pane,
         UserStatusBadge, Loading, TabbedView, Link } from "ui/ui"

import { BasicInfoTab } from "./BasicInfoTab"
import { LegalInfoTab } from "./LegalInfoTab"
import { PasswordTab } from "./PasswordTab"
import { RightsTab } from "./RightsTab"
import { NotificationsTab } from "./NotificationsTab"

import { BASIC_INFO_SCOPE, LEGAL_INFO_SCOPE, NOTIFICATIONS_SCOPE,
         PASSWORD_SCOPE, RIGHTS_SCOPE, SCOPE_SEARCH_PARAM, USER_ID_PARAM } from "./path"

const MAX_MOBILE_WIDTH = 800

export function Component() {
    const [t] = useTranslation()

    const storedUsers = useUsers()

    // State

    // - Tabs

    const [searchParams, setSearchParams] = useSearchParams()

    const selectedTabIndex = useMemo(
        () => searchParams.get(SCOPE_SEARCH_PARAM)?.trim()?.toLowerCase(),
        [searchParams],
    )

    // - Local user

    const [localUser, setLocalUser] = useContext(UserContext)

    // - Window size

    const { width: windowWidth } = useWindowSize()
    const mobile = windowWidth <= MAX_MOBILE_WIDTH

    // - User ID

    const { [USER_ID_PARAM]: rawUserId } = useParams()

    const userId = useMemo(
        () => rawUserId == null || !isUuid(rawUserId)
            ? undefined
            : normalizeUuid(rawUserId!),

        [rawUserId],
    )

    const userLoginPageLink = useMemo(
        () =>  window.location.origin + LoginPage.createPath(userId ?? ""),
        [userId],
    )

    // - User

    const [user, setUser] = useStateWithDeps(
        () => undefined as User | undefined,
        [userId],
    )

    const [loadingUser, setLoadingUser] = useStateWithDeps(
        () => localUser != null,
        [userId],
    )

    const [userLoadingError, setUserLoadingError] = useStateWithDeps(
        () => undefined as unknown,
        [userId],
    )

    // - Creator

    const [creator, setCreator] = useStateWithDeps(
        () => undefined as User | undefined,
        [userId],
    )

    const [loadingCreator, setLoadingCreator] = useStateWithDeps(
        () => false,
        [userId],
    )

    const [creatorLoadingError, setCreatorLoadingError] = useStateWithDeps(
        () => undefined as unknown,
        [userId],
    )

    // Effects

    // - User loading

    useEffect(() => {
        if (!loadingUser)
            return

        if (userId == null) {
            setLoadingUser(false)
            setUserLoadingError(Error("userId == null"))
            return
        }

        if (userId === localUser?.id) {
            setUser(localUser)
            setLoadingUser(false)
            setLoadingCreator(localUser.creatorId != null)
            storedUsers.add(localUser)
            return
        }

        const controller = new AbortController()

        getUserById(userId, controller.signal)
            .then(user => {
                setUser(user)
                setLoadingCreator(user.creatorId != null)
                storedUsers.add(user)
            })
            .catch(error => {
                if (!controller.signal.aborted)
                    setUserLoadingError(error)
            })
            .finally(() => {
                if (!controller.signal.aborted)
                    setLoadingUser(false)
            })

        return () => controller.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingUser])

    // - Creator loading

    useEffect(() => {
        if (!loadingCreator)
            return

        if (user?.creatorId == null) {
            setLoadingCreator(false)
            setCreatorLoadingError("user.creatorId == null")
            return
        }

        const controller = new AbortController()

        getUserById(user.creatorId, controller.signal)
            .then(setCreator)
            .catch(error => {
                if (!controller.signal.aborted)
                    setCreatorLoadingError(error)
            })
            .finally(() => {
                if (!controller.signal.aborted)
                    setLoadingCreator(false)
            })

        return () => controller.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingCreator])

    // Rendering

    if (userId == null)
        return <Error404Page/>

    if (localUser == null)
        return <SessionExpiredErrorPage/>

    return <Page title={renderTitle()}
                 type="main">
        {renderContent()}
    </Page>

    function renderTitle(): ReactElement | null {
        if (user == null)
            return null

        return <Flex justify="space-between"
                     direction="row"
                     gap="64px">
            <Flex direction="row"
                  width="fit-content">
                <Name value={user}/>
                <UserStatusBadge status={user.status}/>
            </Flex>

            <Flex width="fit-content"
                  direction="row"
                  gap="32px">
                {localUser?.isManager && userId !== localUser.id &&
                    <Link to={MessengerPage.createPath(userId)}
                          text={t("misc.actions.sendMessage")}
                          whiteSpace="nowrap"
                          fontSize="16px"/>
                }

                <Copy text={t("misc.actions.copyLoginLink")}
                      copiedText={t("misc.messages.loginLinkCopied")}

                      fontSize="16px"

                      value={userLoginPageLink}

                      iconSrc={linkIconUrl}
                      iconAlt="Link icon"
                      iconFilter="brightness(0.4)"/>
            </Flex>
        </Flex>
    }

    function renderContent(): ReactNode {
        if (loadingUser)
            return <Loading/>

        if (userLoadingError)
            return <ErrorDisplay
                error={userLoadingError}
                apiErrorMessageMapping={{
                    403: t("domain.users.messages.errors.accessDenied"),
                    404: t("domain.users.messages.errors.notFound"),
                }}
            />

        assert(user != null)

        const tabs = [
            <Tab
                Component={() =>
                    <Pane>
                        <BasicInfoTab onUserChange={onUserChange}
                                      user={user}

                                      creator={creator}
                                      creatorLoadingError={creatorLoadingError}

                                      mobile={mobile}/>
                    </Pane>
                }
                name={t("sections.users.tabNames.basicInfo")}
                index={BASIC_INFO_SCOPE}
                key={BASIC_INFO_SCOPE}
            />,

            <Tab
                Component={() =>
                    <Pane>
                        <LegalInfoTab onUserChange={onUserChange}
                                      user={user}
                                      mobile={mobile}/>
                    </Pane>
                }
                name={t("sections.users.tabNames.legalInfo")}
                index={LEGAL_INFO_SCOPE}
                key={LEGAL_INFO_SCOPE}
            />,
        ]

        if (userId === localUser?.id)
            tabs.push(
                <Tab
                    Component={() =>
                        <Pane>
                            <PasswordTab mobile={mobile}/>
                        </Pane>
                    }
                    name={t("sections.users.tabNames.password")}
                    index={PASSWORD_SCOPE}
                    key={PASSWORD_SCOPE}
                />,

                <Tab
                    Component={() =>
                        <Pane>
                            <NotificationsTab onUserChange={onUserChange}
                                              user={user}
                                              mobile={mobile}/>
                        </Pane>
                    }
                    name={t("sections.users.tabNames.notifications")}
                    index={NOTIFICATIONS_SCOPE}
                    key={NOTIFICATIONS_SCOPE}
                />,
            )

        if (user.role === "manager")
            tabs.push(
                <Tab
                    Component={() =>
                        <Pane>
                            <RightsTab onUserChange={onUserChange}
                                       user={user}
                                       mobile={mobile}
                                       readonly={!localUser?.isAdmin}/>
                        </Pane>
                    }
                    name={t("sections.users.tabNames.rights")}
                    index={RIGHTS_SCOPE}
                    key={RIGHTS_SCOPE}
                />
            )

        return <TabbedView onSelect={onTabSelected}
                           selected={selectedTabIndex}
                           height="100%">
            {tabs}
        </TabbedView>

        function onTabSelected(index: Tab.Index) {
            setSearchParams([[SCOPE_SEARCH_PARAM, index.toString()]])
        }
    }

    // Events

    function onUserChange(newUser: User) {
        setUser(newUser)

        if (newUser.id === localUser?.id)
            setLocalUser(newUser)
    }
}
