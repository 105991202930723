import { uniqueArray } from "my-util"
import { UiChatMessage } from "ui/component"

export function uiChatMessagesToUniqueAndSorted(messages: UiChatMessage[]) {
    return uniqueArray(messages, ({ id }) => id)
        .sort((lhs, rhs) => {
            const lhsError = lhs.status === "error"
            const rhsError = rhs.status === "error"

            if (lhsError && rhsError)
                return compareByDate()

            if (lhsError)
                return 1

            if (rhsError)
                return -1

            return compareByDate()

            function compareByDate(): number {
                return lhs.date.getTime() - rhs.date.getTime()
            }
        })
}

export function isUiChatMessageUnread(message: UiChatMessage) {
    return !message.local && message.status === "sent"
}
