import { NamedFields, WithCreatorId,
         WithUserRightsFields, MandatoryNamedFields,
         WithNotificationOptions, WithManyMessageTargets } from "model/interfaces"

import { NamedMixin, WithUserRightsMixin } from "model/mixins"
import { AbstractModelObject } from "model/AbstractModelObject"

import { capitalized, map, collapseWhiteSpace,
         collapseWhiteSpaceToNull, DeepReadonly,
         Nullable, removeWhiteSpaceToNull, Nullish,
         mixin, tryNormalizeNullableUuid, tryNormalizeUuid } from "my-util"

import { Company } from "../Company"
import { UserRole, CLIENT_USER_ROLE } from "./UserRole"
import { UserStatus, ACTIVE_USER_STATUS } from "./UserStatus"
import { UserSpecialization } from "./UserSpecialization"

export namespace User {
    export interface OptionsBase
        extends
            MandatoryNamedFields,
            Nullish<WithCreatorId>,
            Nullish<WithManyMessageTargets>,
            Nullish<WithUserRightsFields>,
            Nullish<WithNotificationOptions>,
            Nullish<{
                status: UserStatus
                nextTransferNumber: number
            }>
    {}

    export interface CreationOptions
        extends
            AbstractModelObject.CreationOptions,
            OptionsBase,
            Nullish<{
                company: Company | Company.CreationOptions
            }>
    {}

    export interface CopyOptions
        extends
            AbstractModelObject.CopyOptions,
            Nullish<OptionsBase>,
            Nullish<{
                company: Company | Company.CopyOptions
            }>
    {}
}

class UserBase
    extends
        AbstractModelObject<User.CopyOptions>

    implements
        Readonly<Nullable<WithCreatorId>>,
        Readonly<WithManyMessageTargets>,
        Readonly<NamedFields>,
        Readonly<WithNotificationOptions>,
        DeepReadonly<WithUserRightsFields>
{
    // Fields

    // - Creator ID

    readonly creatorId: string | null

    // - Message target

    readonly phone: string | null
    readonly email: string | null

    // - Name

    // -- En

    readonly enFirstname: string
    readonly enLastname: string
    readonly enPatronymic: string | null

    // -- Ru

    readonly ruFirstname: string
    readonly ruLastname: string
    readonly ruPatronymic: string | null

    // - Company

    readonly company: Company

    // - Rights

    readonly status: UserStatus
    readonly role: UserRole
    readonly specialization: UserSpecialization | null
    readonly canManageTransfers: boolean
    readonly canManageInvites: boolean
    readonly canSendInvites: boolean
    readonly canManageUsers: boolean
    readonly canManageProviders: boolean
    readonly canSeeAllUsers: boolean
    readonly visibleUserIds: string[]

    // - Notification options

    readonly notifyOnNewChatMessage: boolean
    readonly notifyOnNewInviteApplication: boolean
    readonly notifyOnNewInvite: boolean
    readonly notifyOnNewTransfer: boolean
    readonly notifyOnNewUser: boolean
    readonly notifyOnNewProvider: boolean
    readonly notifyOnTransferStatusChanged: boolean

    // - Misc

    readonly nextTransferNumber: number

    // Constructor

    constructor(options: DeepReadonly<User.CreationOptions>) {
        super(options)

        // Creator ID

        this.creatorId = tryNormalizeNullableUuid(options.creatorId)

        // Message target

        this.phone = removeWhiteSpaceToNull(options.phone ?? "")
        this.email = removeWhiteSpaceToNull(options.email ?? "")?.toLowerCase() ?? null

        // Name

        // - En

        this.enFirstname = collapseWhiteSpace(capitalized(options.enFirstname))
        this.enLastname = collapseWhiteSpace(capitalized(options.enLastname))
        this.enPatronymic = collapseWhiteSpaceToNull(capitalized(options.enPatronymic ?? ""))

        // - Ru

        this.ruFirstname = collapseWhiteSpace(capitalized(options.ruFirstname))
        this.ruLastname = collapseWhiteSpace(capitalized(options.ruLastname))
        this.ruPatronymic = collapseWhiteSpaceToNull(capitalized(options.ruPatronymic ?? ""))

        // Company

        this.company = Company.createOrPass(options.company)

        // Rights

        this.status = options.status ?? ACTIVE_USER_STATUS
        this.role = options.role ?? CLIENT_USER_ROLE
        this.specialization = options.specialization ?? null
        this.canManageTransfers = options.canManageTransfers ?? false
        this.canManageInvites = options.canManageInvites ?? false
        this.canSendInvites = options.canSendInvites ?? false
        this.canManageUsers = options.canManageUsers ?? false
        this.canManageProviders = options.canManageProviders ?? false
        this.canSeeAllUsers = options.canSeeAllUsers ?? true
        this.visibleUserIds = map(options.visibleUserIds ?? [], tryNormalizeUuid)

        // Notification options

        this.notifyOnNewChatMessage = options.notifyOnNewChatMessage ?? false
        this.notifyOnNewInviteApplication = options.notifyOnNewInviteApplication ?? false
        this.notifyOnNewInvite = options.notifyOnNewInvite ?? false
        this.notifyOnNewTransfer = options.notifyOnNewTransfer ?? false
        this.notifyOnNewUser = options.notifyOnNewUser ?? false
        this.notifyOnNewProvider = options.notifyOnNewProvider ?? false
        this.notifyOnTransferStatusChanged = options.notifyOnTransferStatusChanged ?? false

        // Misc

        this.nextTransferNumber = options.nextTransferNumber ?? 1
    }

    // Copy

    protected override createCopy(options: DeepReadonly<User.CopyOptions> = {}): UserBase {
        return new UserBase({
            // Creator ID

            creatorId: "creatorId" in options
                ? options.creatorId
                : this.creatorId,

            // Message targets

            phone: "phone" in options
                ? options.phone
                : this.phone,

            email: "email" in options
                ? options.email
                : this.email,

            // Name

            // - En

            enFirstname: options.enFirstname ?? this.enFirstname,
            enLastname: options.enLastname ?? this.enLastname,

            enPatronymic: "enPatronymic" in options
                ? options.enPatronymic
                : this.enPatronymic,

            // - Ru

            ruFirstname: options.ruFirstname ?? this.ruFirstname,
            ruLastname: options.ruLastname ?? this.ruLastname,

            ruPatronymic: "ruPatronymic" in options
                ? options.ruPatronymic
                : this.ruPatronymic,

            // Company

            company: this.company.copyOrPass(options.company),

            // Rights

            status: options.status ?? this.status,
            role: options.role ?? this.role,

            specialization: "specialization" in options
                ? options.specialization
                : this.specialization,

            canManageTransfers: options.canManageTransfers ?? this.canManageTransfers,
            canManageInvites: options.canManageInvites ?? this.canManageInvites,
            canSendInvites: options.canSendInvites ?? this.canSendInvites,
            canManageUsers: options.canManageUsers ?? this.canManageUsers,
            canManageProviders: options.canManageProviders ?? this.canManageProviders,
            canSeeAllUsers: options.canSeeAllUsers ?? this.canSeeAllUsers,
            visibleUserIds: options.visibleUserIds ?? this.visibleUserIds,

            // Notification options

            notifyOnNewChatMessage: options.notifyOnNewChatMessage ?? this.notifyOnNewChatMessage,
            notifyOnNewInviteApplication: options.notifyOnNewInviteApplication ?? this.notifyOnNewInviteApplication,
            notifyOnNewInvite: options.notifyOnNewInvite ?? this.notifyOnNewInvite,
            notifyOnNewTransfer: options.notifyOnNewTransfer ?? this.notifyOnNewTransfer,
            notifyOnNewUser: options.notifyOnNewUser ?? this.notifyOnNewUser,
            notifyOnNewProvider: options.notifyOnNewProvider ?? this.notifyOnNewProvider,
            notifyOnTransferStatusChanged: options.notifyOnTransferStatusChanged ?? this.notifyOnTransferStatusChanged,

            // Misc

            nextTransferNumber: options.nextTransferNumber ?? this.nextTransferNumber,

            // Basic

            id: options.id ?? this.id,
            createdAt: options.createdAt ?? this.createdAt,
            modifiedAt: options.modifiedAt ?? this.modifiedAt,
        })
    }
}

export class User extends mixin(UserBase, [WithUserRightsMixin, NamedMixin]) {
    static createOrPass(arg: User | DeepReadonly<User.CreationOptions>): User {
        return arg instanceof User
            ? arg
            : new User(arg)
    }
}
