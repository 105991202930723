import cyrillicToTranslit from "cyrillic-to-translit-js"
import { ForwardedRef, forwardRef } from "react"
import { getLang } from "i18n"
import { fixLayout } from "my-util"
import { useStateWithDeps } from "ui/hook"
import { Input } from "ui/ui/input"
import { Flex, Limit } from "ui/ui/layout"

export namespace DiLangInput {
    export interface Props {
        onChange?: (enValue: string, ruValue: string) => void
        enValue?: string
        ruValue?: string

        postProcess?: (value: string) => string
        enPostProcess?: (enValue: string) => string
        ruPostProcess?: (ruValue: string) => string

        onFocus?: () => void
        onEnFocus?: () => void
        onRuFocus?: () => void

        onBlur?: (text: string) => string | undefined
        onEnBlur?: (text: string) => string | undefined
        onRuBlur?: (text: string) => string | undefined

        label?: string
        placeholder?: string

        required?: boolean

        readonly?: boolean
        enReadonly?: boolean
        ruReadonly?: boolean

        disabled?: boolean
        enDisabled?: boolean
        ruDisabled?: boolean

        loading?: boolean
        enLoading?: boolean
        ruLoading?: boolean

        invalid?: boolean
        enInvalid?: boolean
        ruInvalid?: boolean

        regex?: RegExp
        enRegex?: RegExp
        ruRegex?: RegExp

        max?: number
        enMax?: number
        ruMax?: number

        maxWidth?: string
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiLangInput = forwardRef((
    {
        onChange, enValue, ruValue,
        postProcess, enPostProcess, ruPostProcess,
        onFocus, onEnFocus, onRuFocus,
        onBlur, onEnBlur, onRuBlur,
        label, placeholder,
        required,
        readonly, enReadonly, ruReadonly,
        disabled, enDisabled, ruDisabled,
        loading, enLoading, ruLoading,
        invalid, enInvalid, ruInvalid,
        regex, enRegex, ruRegex,
        max, enMax, ruMax,
        maxWidth,
    }: Readonly<DiLangInput.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const lang = getLang()
    const ru = lang === "ru"

    // State

    const [innerEnValue, setInnerEnValue] = useStateWithDeps(() => enValue ?? "", [enValue])
    const [innerRuValue, setInnerRuValue] = useStateWithDeps(() => ruValue ?? "", [ruValue])

    // Render

    return <Flex direction="row"
                 align="end"
                 ref={ref}
                 wrap>
        <Limit maxWidth={maxWidth}>
            <Input onChange={ru ? onPrimaryRuValueChange : onPrimaryEnValueChange}
                   value={ru ? innerRuValue : innerEnValue}

                   postProcess={ru ? innerRuPostProcess : innerEnPostProcess}

                   onFocus={(ru ? onRuFocus : onEnFocus) ?? onFocus}
                   onBlur={(ru ? onRuBlur : onEnBlur) ?? onBlur}

                   label={label}
                   placeholder={placeholder}

                   required={required}

                   readonly={(ru ? ruReadonly : enReadonly) ?? readonly}
                   disabled={(ru ? ruDisabled : enDisabled) ?? disabled}
                   loading={(ru ? ruLoading : enLoading) ?? loading}
                   invalid={(ru ? ruInvalid : enInvalid) ?? invalid}

                   regex={(ru ? ruRegex : enRegex) ?? regex}
                   max={(ru ? ruMax : enMax) ?? max}/>
        </Limit>

        <Limit maxWidth={maxWidth}>
            <Input onChange={ru ? onSecondaryEnValueChange : onSecondaryRuValueChange}
                   value={ru ? innerEnValue : innerRuValue}

                   postProcess={ru ? innerEnPostProcess : innerRuPostProcess}

                   onFocus={(ru ? onEnFocus : onRuFocus) ?? onFocus}
                   onBlur={(ru ? onEnBlur : onRuBlur) ?? onBlur}

                   readonly={(ru ? enReadonly : ruReadonly) ?? readonly}
                   disabled={(ru ? enDisabled : ruDisabled) ?? disabled}
                   loading={(ru ? enLoading : ruLoading) ?? loading}

                   regex={(ru ? enRegex : ruRegex) ?? regex}
                   max={(ru ? enMax : ruMax) ?? max}

                   backgroundColor="rgba(0, 0, 0, 0)"
                   borderColor="rgba(0, 0, 0, 0)"/>

        </Limit>
    </Flex>

    // Events

    // - Primary value change

    function onPrimaryEnValueChange(newInnerEnValue: string) {
        const newInnerRuValue = cyrillicToTranslit().reverse(newInnerEnValue)

        setInnerRuValue(newInnerRuValue)
        setInnerEnValue(newInnerEnValue)

        onChange?.(newInnerEnValue, newInnerRuValue)
    }

    function onPrimaryRuValueChange(newInnerRuValue: string) {
        const newInnerEnValue = cyrillicToTranslit().transform(newInnerRuValue)

        setInnerRuValue(newInnerRuValue)
        setInnerEnValue(newInnerEnValue)

        onChange?.(newInnerEnValue, newInnerRuValue)
    }

    // - Secondary value change

    function onSecondaryEnValueChange(newInnerEnValue: string) {
        setInnerEnValue(newInnerEnValue)

        onChange?.(newInnerEnValue, innerRuValue)
    }

    function onSecondaryRuValueChange(newInnerRuValue: string) {
        setInnerRuValue(newInnerRuValue)

        onChange?.(innerEnValue, newInnerRuValue)
    }

    // Util

    function innerEnPostProcess(text: string): string {
        text = fixLayout(text)
        text = (enPostProcess ?? postProcess)?.(text) ?? text

        return text
    }

    function innerRuPostProcess(text: string): string {
        text = fixLayout(text, { reverse: true })
        text = (ruPostProcess ?? postProcess)?.(text) ?? text

        return text
    }
})

DiLangInput.displayName = "DiLangInput"
