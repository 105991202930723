import { determineMessageTargetType } from "model"
import { normalizePhone } from "./phone"
import { normalizeEmail } from "./email"

export function normalizeMessageTarget(username: string): string {
    switch (determineMessageTargetType(username)) {
        case "phone":
            return normalizePhone(username)

        case "email":
            return normalizeEmail(username)
    }
}
