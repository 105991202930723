import { z } from "zod"
import { NEW_TRANSFER_NOTIFICATION_TYPE } from "model"
import { UuidSchema } from "my-util"
import { NotificationResponseBaseSchema } from "./base"
import { NotificationTypeResponse } from "./type"

export const NewTransferNotificationResponseSchema = NotificationResponseBaseSchema.extend({
    type: z.literal(NEW_TRANSFER_NOTIFICATION_TYPE satisfies NotificationTypeResponse),

    transferId: UuidSchema.nullish(),
})

export type NewTransferNotificationResponse = z.infer<typeof NewTransferNotificationResponseSchema>
