import { createContext } from "react"
import { User } from "model"

export type UserContextType = [
    User | null, // User
    (user: User | null) => void, // Set user
    () => void, // Trigger user update
]

const MISSING_CONTEXT = () => { throw Error("Missing UserContext") }
const DEFAULT_VALUE: UserContextType = [null, MISSING_CONTEXT, MISSING_CONTEXT]

export const UserContext = createContext(DEFAULT_VALUE)
