import { z } from "zod"

export const WithNotificationOptionsResponseSchema = z.object({
    notifyOnNewChatMessage: z.coerce.boolean(),
    notifyOnNewInviteApplication: z.coerce.boolean(),
    notifyOnNewInvite: z.coerce.boolean(),
    notifyOnNewTransfer: z.coerce.boolean(),
    notifyOnNewUser: z.coerce.boolean(),
    notifyOnNewProvider: z.coerce.boolean(),
    notifyOnTransferStatusChanged: z.coerce.boolean(),
})

export type WithNotificationOptionsResponse = z.infer<typeof WithNotificationOptionsResponseSchema>
