import { useEffect, useState } from "react"

import { NotFoundApiError, NotificationSource,
         clearMyNotifications, deleteNotificationById } from "api"

import { Notification } from "model"

// Globals

// - Source

const globalSource = new NotificationSource({ url: "/api/notifications/my/stream" })

function openGlobalSource() {
    if (globalSource.readyState !== "closed")
        return

    globalSource.on("error", error => console.error(error || "Something went wrong"))

    globalSource.on("old", addAllGlobalNotifications)
    globalSource.on("new", addGlobalNotification)

    globalSource.on("delete", deleteGlobalNotificationById)
    globalSource.on("clear", clearGlobalNotifications)

    globalSource.open()
}

// - Notifications

const globalNotifications = new Array<Notification>()

// -- Add

function addGlobalNotification(notification: Notification) {
    addAllGlobalNotifications([notification])
}

function addAllGlobalNotifications(notifications: readonly Notification[]) {
    for (const notification of notifications) {
        const oldNotificationIndex = globalNotifications.findIndex(({ id }) => id === notification.id)

        if (oldNotificationIndex >= 0)
            globalNotifications.splice(oldNotificationIndex, 1, notification)
        else
            globalNotifications.push(notification)
    }

    globalNotifications.sort((lhs, rhs) => rhs.modifiedAt.getTime() - lhs.modifiedAt.getTime())
}

// -- Delete

function deleteGlobalNotificationById(notificationId: string) {
    const notificationIndex = globalNotifications.findIndex(({ id }) => id === notificationId)

    if (notificationIndex >= 0)
        globalNotifications.splice(notificationIndex, 1)
}

function clearGlobalNotifications() {
    globalNotifications.length = 0
}

// Hook

export type NotificationsHook = [Notification[], NotificationsHook.Actions]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace NotificationsHook {
    export interface Actions {
        deleteByValue(notification: Notification): void
        deleteById(notificationId: string): void
        deleteByIndex(notificationIndex: number): void
        clear(): void
    }
}

const GLOBAL_ACTIONS: Readonly<NotificationsHook.Actions> = {
    deleteByIndex(index) {
        const notification = globalNotifications[index] as Notification | undefined

        if (notification != null)
            GLOBAL_ACTIONS.deleteByValue(notification)
        else
            console.warn(`Notification with index ${index} not found`)
    },

    deleteByValue(value) {
        GLOBAL_ACTIONS.deleteById(value.id)
    },

    async deleteById(id) {
        try {
            await deleteNotificationById(id)
        } catch (error) {
            if (error instanceof NotFoundApiError) {
                console.warn(`Notification with id ${id} not found`)
                deleteGlobalNotificationById(id)
                return
            }

            console.error(error)
        }
    },

    async clear() {
        try {
            await clearMyNotifications()
        } catch (error) {
            console.error(error)
        }
    },
}

export function useNotifications(): NotificationsHook {
    const [notifications, setNotifications] = useState([...globalNotifications])

    useEffect(() => {
        openGlobalSource()

        const updateEventNames = ["old", "new", "delete", "clear"] as const

        for (const eventName of updateEventNames)
            globalSource.on(eventName, setNotificationsFromGlobal)

        return () => {
            for (const eventName of updateEventNames)
                globalSource.off(eventName, setNotificationsFromGlobal)
        }

        function setNotificationsFromGlobal() {
            setNotifications([...globalNotifications])
        }
    }, [])

    return [notifications, GLOBAL_ACTIONS]
}
