import { z } from "zod"

import { TransferRecipientMessageSchema, MoneyMessageSchema,
         LegalDocumentMessageSchema, CurrencyRateMessageSchema } from "api/messages"

import { DecimalSchema, UuidSchema } from "my-util"
import { ModelObjectResponseSchema } from "../../model-object"
import { ProductResponseSchema } from "../product"
import { RoutePointResponseSchema } from "../route-point"
import { WithCreatorIdResponseSchema } from "../with-creator-id"
import { TransferStatusResponseSchema } from "./transfer-status"
import { SignableDocumentResponseSchema } from "../signable-document"
import { TransferProviderResponseSchema } from "../transfer-provider"
import { TransferDirectionResponseSchema } from "./transfer-direction"

export const TransferResponseSchema = ModelObjectResponseSchema.extend({
    stopperId: UuidSchema.nullish(),
    isStopped: z.coerce.boolean(),

    status: TransferStatusResponseSchema,
    direction: TransferDirectionResponseSchema,
    country: z.string(),
    recipient: TransferRecipientMessageSchema,
    number: z.number().nullish(),

    moment: z.coerce.date().nullish(),

    money: MoneyMessageSchema,
    currencyRate: CurrencyRateMessageSchema.nullish(),

    invoice: LegalDocumentMessageSchema,
    contract: LegalDocumentMessageSchema,

    cost: MoneyMessageSchema.nullish(),
    expenses: MoneyMessageSchema.nullish(),
    agentPercent: DecimalSchema.nullish(),
    attorneyFee: DecimalSchema.nullish(),
    isAgentPayed: z.coerce.boolean(),

    comment: z.string().nullish(),
    adminComment: z.string().nullish(),
    lawyerComment: z.string().nullish(),
    accountantComment: z.string().nullish(),

    companyItn: z.string().nullish(),

    documentIds: UuidSchema.array(),
    signableDocuments: SignableDocumentResponseSchema.array(),
    products: ProductResponseSchema.array(),
    routePoints: RoutePointResponseSchema.array(),
    providers: TransferProviderResponseSchema.array().nullish(),
})
    .merge(WithCreatorIdResponseSchema)

export type TransferResponse = z.infer<typeof TransferResponseSchema>
