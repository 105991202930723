import { ForwardedRef, forwardRef } from "react"

import { EMAIL_MESSAGE_TARGET_TYPE,
         PHONE_MESSAGE_TARGET_TYPE,
         determineMessageTargetType } from "model"

import { PhoneOutput } from "../phone"
import { EmailOutput } from "../email"

export namespace MessageTargetOutput {
    export interface Props
        extends
            Omit<PhoneOutput.Props, "phone">,
            Omit<EmailOutput.Props, "email">
    {
        messageTarget: string
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MessageTargetOutput = forwardRef((
    props: Readonly<MessageTargetOutput.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const { messageTarget } = props

    switch (determineMessageTargetType(messageTarget)) {
        case PHONE_MESSAGE_TARGET_TYPE:
            return <PhoneOutput phone={messageTarget}
                                { ...props }
                                ref={ref}/>

        case EMAIL_MESSAGE_TARGET_TYPE:
            return <EmailOutput email={messageTarget}
                                { ...props }
                                ref={ref}/>
    }
})

MessageTargetOutput.displayName = "MessageTargetOutput"
