import { ForwardedRef, forwardRef } from "react"
import { Transfer } from "model"
import { MONTH_INDEXES } from "my-util"
import { Flex } from "ui/ui/layout"
import { SmallMonthCalendar } from "../SmallMonthCalendar"

export namespace YearCalendar {
    export interface Props extends Flex.Props {
        onDateClick?: (date: Date) => void

        year?: number

        transfers?: Transfer[]
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const YearCalendar = forwardRef((
    props: Readonly<YearCalendar.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) =>
    <Flex { ...props }
          ref={ref}>
        {MONTH_INDEXES.map(month =>
            <SmallMonthCalendar onDateClick={props.onDateClick}
                                transfers={props.transfers}
                                monthIndex={month}
                                year={props.year}
                                key={month}/>
        )}
    </Flex>
)

YearCalendar.displayName = "YearCalendar"
