import { z } from "zod"
import { NEW_INVITE_NOTIFICATION_TYPE } from "model"
import { UuidSchema } from "my-util"
import { NotificationResponseBaseSchema } from "./base"
import { NotificationTypeResponse } from "./type"

export const NewInviteNotificationResponseSchema = NotificationResponseBaseSchema.extend({
    type: z.literal(NEW_INVITE_NOTIFICATION_TYPE satisfies NotificationTypeResponse),

    inviteId: UuidSchema.nullish(),
})

export type NewInviteNotificationResponse = z.infer<typeof NewInviteNotificationResponseSchema>
