import { ForwardedRef, useRef } from "react"
import { useTranslation } from "react-i18next"
import { clockIconUrl } from "images"

import { dateToTimeString, DeepReadonly,
         forwardRefAndSetProperties, TIME_INPUT_REGEX } from "my-util"

import { useStateWithDeps } from "ui/hook"
import { Input } from "ui/ui/input"

export namespace TimeInput {
    export interface Props {
        onChange?: (event: ChangeEvent) => void
        value?: Date | string | null

        autoFocus?: boolean
        width?: string

        invalid?: boolean
        disabled?: boolean
        readonly?: boolean
        loading?: boolean

        label?: string
        information?: string
    }

    export interface ChangeEvent {
        date: Date
        rawDate: string
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TimeInput = forwardRefAndSetProperties(
    {
        MIN_WIDTH: "75px" as string,
    } as const,

    (
        {
            onChange, value,
            autoFocus, width,
            invalid, disabled, readonly, loading,
            label, information,
        }: DeepReadonly<TimeInput.Props>,

        ref: ForwardedRef<HTMLDivElement>,
    ) => {
        const [t] = useTranslation()

        // State

        const enteringRef = useRef(false)

        const [innerValue, setInnerValue] = useStateWithDeps<string>(
            oldValue => {
                if (enteringRef.current && oldValue != null)
                    return oldValue

                if (typeof value !== "string")
                    return dateToTimeString(value ?? new Date())

                return value
            },
            [value],
        )

        // Render

        return <Input onChange={onInnerChange}
                    value={innerValue}

                    onFocus={onFocus}
                    onBlur={onBlur}

                    regex={TIME_INPUT_REGEX}

                    autoFocus={autoFocus}
                    width={width === "min" ? TimeInput.MIN_WIDTH : width}

                    invalid={invalid}
                    disabled={disabled}
                    readonly={readonly}
                    loading={loading}

                    label={label}
                    placeholder={t("datetime.placeholders.time")}
                    information={information}

                    iconSrc={clockIconUrl}
                    iconAlt="Clock icon"
                    iconFilter="brightness(0) saturate(100%) invert(71%) sepia(5%) saturate(5137%) hue-rotate(183deg) brightness(68%) contrast(104%)"

                    ref={ref}/>

        // Events

        function onInnerChange(input: string) {
            const date = stringToDate(input)

            setInnerValue(input)

            onChange?.({
                rawDate: input,
                date,
            })
        }

        function onFocus() {
            enteringRef.current = true
        }

        function onBlur(input: string): string {
            enteringRef.current = false

            const date = stringToDate(input)
            const newInput = dateToTimeString(date)

            onChange?.({
                rawDate: newInput,
                date,
            })

            return newInput
        }

        // Util

        function stringToDate(input: string): Date {
            const date = new Date()
            const parts = input.split(":").map(Number)
            const hours = parts[0]
            const minutes = parts[1]

            date.setHours(hours)
            date.setMinutes(minutes ?? 0)
            date.setSeconds(0)
            date.setMilliseconds(0)

            return date
        }
    },
)

TimeInput.displayName = "TimeInput"
