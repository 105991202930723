import { Component } from "./Component"

import { PathCreationOptions as LocalPathCreationOptions, createPath,
         isScope, Scope as LocalScope, YEAR_SCOPE, MONTH_SCOPE, WEEK_SCOPE, DAY_SCOPE,
         SCOPE_SEARCH_PARAM, YEAR_SEARCH_PARAM, MONTH_SEARCH_PARAM, DAY_SEARCH_PARAM,
         PATH } from "./path"

export namespace CalendarPage {
    export interface PathCreationOptions extends LocalPathCreationOptions {}
    export type Scope = LocalScope
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CalendarPage = Object.assign(Component, {
    createPath,
    isScope, YEAR_SCOPE, MONTH_SCOPE, WEEK_SCOPE, DAY_SCOPE,
    SCOPE_SEARCH_PARAM, YEAR_SEARCH_PARAM, MONTH_SEARCH_PARAM, DAY_SEARCH_PARAM,
    PATH,
} as const)
