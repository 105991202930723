import { ForwardedRef, forwardRef } from "react"

import { TransferGroup,
         IN_WORK_TRANSFER_GROUP, NEW_TRANSFER_GROUP,
         TODAY_TRANSFER_GROUP, STOPPED_TRANSFER_GROUP,
         DECLINED_TRANSFER_GROUP, DONE_TRANSFER_GROUP } from "model"

import { Flex } from "ui/ui/layout"
import { TransferGroupColorLegend } from "../TransferGroupColorLegend"

export namespace TransferGroupColorLegendList {
    export interface Props
        extends
            Omit<Flex.Props, "children">,
            Omit<TransferGroupColorLegend.Props, "group" | "active">

    {
        active?: TransferGroup

        hideNew?: boolean
        hideInWork?: boolean
        hideToday?: boolean
        hideDone?: boolean
        hideDeclined?: boolean
        hideStopped?: boolean
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransferGroupColorLegendList = forwardRef((
    props: Readonly<TransferGroupColorLegendList.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const {
        active,
        hideNew, hideInWork, hideToday, hideDone, hideDeclined, hideStopped,
    } = props

    return <Flex width="fit-content"
                 direction="row"
                 { ...props }
                 ref={ref}>
        {!hideNew &&
            <TransferGroupColorLegend { ...props }
                                      active={active === NEW_TRANSFER_GROUP}
                                      group={NEW_TRANSFER_GROUP}/>
        }

        {!hideInWork &&
            <TransferGroupColorLegend { ...props }
                                      active={active === IN_WORK_TRANSFER_GROUP}
                                      group={IN_WORK_TRANSFER_GROUP}/>
        }

        {!hideToday &&
            <TransferGroupColorLegend { ...props }
                                      active={active === TODAY_TRANSFER_GROUP}
                                      group={TODAY_TRANSFER_GROUP}/>
        }

        {!hideDone &&
            <TransferGroupColorLegend { ...props }
                                      active={active === DONE_TRANSFER_GROUP}
                                      group={DONE_TRANSFER_GROUP}/>
        }

        {!hideDeclined &&
            <TransferGroupColorLegend { ...props }
                                      active={active === DECLINED_TRANSFER_GROUP}
                                      group={DECLINED_TRANSFER_GROUP}/>
        }

        {!hideStopped &&
            <TransferGroupColorLegend { ...props }
                                      active={active === STOPPED_TRANSFER_GROUP}
                                      group={STOPPED_TRANSFER_GROUP}/>
        }
    </Flex>
})

TransferGroupColorLegendList.displayName = "TransferGroupColorLegendList"
