import { useTranslation } from "react-i18next"
import { ForwardedRef, forwardRef } from "react"
import { ColorLegend } from "../ColorLegend"

export namespace ConnectionIndicator {
    export interface Props {
        status: Status
        showLegend?: boolean
    }

    export type Status =
        | "unready"

        | "opening"
        | "open"

        | "closing"
        | "closed"
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ConnectionIndicator = forwardRef((
    { status, showLegend }: Readonly<ConnectionIndicator.Props>,
    ref: ForwardedRef<HTMLSpanElement>,
) => {
    const [t] = useTranslation()

    const legend = showLegend ? t(`misc.messages.connectionStatuses.${status}`) : undefined
    const color = getColor()

    return <ColorLegend legend={legend}
                        color={color}
                        ref={ref}/>

    function getColor(): string {
        switch (status) {
            case "unready":
                return "gray"

            case "opening":
                return "blue"

            case "open":
                return "lime"

            case "closing":
                return "orange"

            case "closed":
                return "red"
        }
    }
})

ConnectionIndicator.displayName = "ConnectionIndicator"
