import { ForwardedRef } from "react"
import { useTranslation } from "react-i18next"

import { TransferSortBy, COMPANY_TRANSFER_SORT_BY,
         AGENT_TRANSFER_SORT_BY, DATE_TRANSFER_SORT_BY } from "model"

import { forwardRefAndSetProperties } from "my-util"
import { Select } from "ui/ui/input"

export namespace TransferSortBySelect {
    export interface Props extends Omit<Select.Props, "onSelect" | "selected" | "options"> {
        onSelect?: (sortBy: TransferSortBy) => void
        selected?: TransferSortBy

        hideCompany?: boolean
        hideAgent?: boolean
        hideDate?: boolean
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransferSortBySelect = forwardRefAndSetProperties(
    {
        MIN_WIDTH: "120px" as const
    } as const,

    (
        props: Readonly<TransferSortBySelect.Props>,
        ref: ForwardedRef<HTMLDivElement>,
    ) => {
        const [t] = useTranslation()

        const {
            onSelect, selected,
            hideCompany, hideAgent, hideDate,
            width,
        } = props

        return <Select
            { ...props }

            onSelect={onSelect as (key: string) => void}
            selected={selected}

            options={{
                [COMPANY_TRANSFER_SORT_BY]: hideCompany
                    ? undefined
                    : t("domain.transfers.sortBy.company"),

                [AGENT_TRANSFER_SORT_BY]: hideAgent
                    ? undefined
                    : t("domain.transfers.sortBy.agent"),

                [DATE_TRANSFER_SORT_BY]: hideDate
                    ? undefined
                    : t("domain.transfers.sortBy.date"),
            }}

            width={width === "min" ? TransferSortBySelect.MIN_WIDTH : width}

            ref={ref}
        />
    },
)

TransferSortBySelect.displayName = "TransferSortBySelect"
