import { removeWhiteSpace } from "my-util"
import { ViolationType } from "./ViolationType"

export const CNFEA_LENGTH = 10

export function validateCnfea(cnfea: string): ViolationType | null {
    cnfea = removeWhiteSpace(cnfea)

    if (cnfea.length > CNFEA_LENGTH)
        return "too-long"

    if (cnfea.length < CNFEA_LENGTH)
        return "too-short"

    if (cnfea.match(/^[0-9]*$/g) == null)
        return "illegal-char"

    return null
}
