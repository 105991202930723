import assert from "assert"
import { ReactElement, ReactNode, useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { deleteInviteById, getInviteById, resendInviteById } from "api"
import { Invite } from "model"
import { isUuid } from "my-util"
import { useInvites } from "ui/hook"
import { UserContext } from "ui/context"
import { Error403Page, Error404Page, SessionExpiredErrorPage } from "ui/page/error"
import { InviteViewForm, Page } from "ui/component"
import { AgreementModal, Loading, ErrorDisplay, Flex, FormControls, Pane } from "ui/ui"
import { InviteHeader } from "../InviteHeader"
import { INVITE_ID_PARAM } from "./path"

export function Component() {
    const [t] = useTranslation()

    const [localUser] = useContext(UserContext)

    const storedInvites = useInvites()

    const navigate = useNavigate()

    const { [INVITE_ID_PARAM]: inviteId } = useParams()
    const badInviteId = inviteId == null || !isUuid(inviteId)

    // State

    const [invite, setInvite] = useState(undefined as Invite | undefined)
    const [loading, setLoading] = useState(!badInviteId && (localUser?.isAgent ?? false))

    const [error, setError] = useState(undefined as unknown)

    const [expired, setExpired] = useState(invite?.isExpired ?? false)

    const [deleting, setDeleting] = useState(false)
    const [deletionError, setDeletionError] = useState(undefined as unknown)
    const [showDeletionModal, setShowDeletionModal] = useState(undefined as unknown)

    const [resending, setResending] = useState(false)
    const [resendError, setResendError] = useState(undefined as unknown)

    // Effects

    useEffect(() => {
        if (!loading || inviteId == null)
            return

        const controller = new AbortController()

        getInviteById(inviteId, controller.signal)
            .then(invite => {
                storedInvites.add(invite)
                setInvite(invite)
            })
            .catch(error => {
                if (!controller.signal.aborted)
                    setError(error)
            })
            .finally(() => {
                if (!controller.signal.aborted)
                    setLoading(false)
            })

        return () => controller.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, inviteId])

    // Render

    if (badInviteId)
        return <Error404Page/>

    if (localUser == null)
        return <SessionExpiredErrorPage/>

    if (!localUser.isAgent)
        return <Error403Page/>

    return <Page title={renderTitle()}
                 type="main">
        <Pane>{renderContent()}</Pane>
    </Page>

    function renderTitle(): ReactElement {
        return <InviteHeader text={t("sections.clients.invites.one.header")}
                             status={invite?.status}
                             inviteId={inviteId ?? ""}/>
    }

    function renderContent(): ReactNode {
        if (loading)
            return <Loading/>

        if (error)
            return <ErrorDisplay
                error={error}
                apiErrorMessageMapping={{
                    403: t("domain.invites.messages.errors.accessDenied"),
                    404: t("domain.invites.messages.errors.notFound"),
                }}
            />

        return <>
            {renderForm()}
            {renderDeletionModalIfNeeded()}
        </>
    }

    function renderForm(): ReactNode {
        assert(invite != null)

        return <InviteViewForm buttons={renderButtons()}
                               onExpired={onExpired}
                               loading={resending}
                               error={resendError}
                               invite={invite}
                               height="100%"/>
    }

    function renderButtons(): FormControls.Button[] {
        const buttons: FormControls.Button[] = [{
            onClick: onBack,
            text: t("misc.actions.back"),
            buttonStyle: "outline",
            position: "right",
        }]

        const canManageInvites =
            invite?.creatorId === localUser?.id ||
            localUser?.canManageInvites

        if (canManageInvites)
            buttons.push(
                {
                    onClick: onShowDeletionModal,
                    text: t("misc.actions.delete"),
                    buttonStyle: "outline",
                    position: "left",
                    disabled: expired,
                    critical: true,
                },

                {
                    onClick: onResend,
                    text: t("misc.actions.resend"),
                    buttonStyle: "outline",
                    position: "left",
                    disabled: expired,
                }
            )

        return buttons
    }

    function renderDeletionModalIfNeeded(): ReactNode {
        if (!showDeletionModal)
            return null

        return <AgreementModal onNo={onHideDeletionModal}
                               onYes={onDelete}
                               loading={deleting}>
            <Flex>
                {t("domain.invites.messages.warnings.deletion")}

                <ErrorDisplay error={deletionError}
                              centerType="flex"/>
            </Flex>
        </AgreementModal>
    }

    // Events

    function onShowDeletionModal() {
        setShowDeletionModal(true)
    }

    function onHideDeletionModal() {
        setShowDeletionModal(false)
        setDeletionError(undefined)
    }

    async function onDelete() {
        setDeleting(true)

        try {
            assert(inviteId != null)

            await deleteInviteById(inviteId)

            navigate(-1)
        } catch (error) {
            setDeletionError(error)
        } finally {
            setDeleting(false)
        }
    }

    async function onResend() {
        setResending(true)

        try {
            assert(inviteId != null)

            await resendInviteById(inviteId)

            setInvite(invite?.copy({ sentAt: new Date() }))
            setResendError(undefined)
        } catch (error) {
            setResendError(error)
        } finally {
            setResending(false)
        }
    }

    function onExpired() {
        setExpired(true)
    }

    function onBack() {
        navigate(-1)
    }
}
