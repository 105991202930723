import { forwardRef, useState, ReactNode, useRef,
         useLayoutEffect, ForwardedRef, useReducer } from "react"

import { getLang } from "i18n"
import { DeepReadonly, tryParseNullableBoolean } from "my-util"
import { Flex, FlexItem } from "ui/ui"
import { Header } from "../Header"
import { Footer } from "../Footer"
import { Menu } from "../Menu"
import style from "./style.module.css"

const LAST_SHORT_MENU_STORAGE_KEY = "last-short-menu"

export namespace Page {
    export interface Props {
        type?: Type

        compactTitle?: CompactTitle
        title?: ReactNode

        autoMoveCompactTitle?: boolean
        noCompactTitle?: boolean
        compactTitleGap?: string

        children?: ReactNode
    }

    export type CompactTitle =
        | ((compact: boolean) => ReactNode)
        | ReactNode

    export type Type =
        | "auth"
        | "main"
}


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Page = forwardRef((
    {
        type,
        compactTitle, title,
        autoMoveCompactTitle, noCompactTitle, compactTitleGap,
        children,
    }: DeepReadonly<Page.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const main = type === "main"

    // Refs

    const firstRenderRef = useRef(true)
    const menuRef = useRef<Menu.Ref | null>(null)

    // State

    const [shortMenu, setShortMenu] = useReducer(
        (_: boolean, newShortMenu: boolean) => {
            localStorage.setItem(LAST_SHORT_MENU_STORAGE_KEY, newShortMenu.toString())
            firstRenderRef.current = false
            return newShortMenu
        },

        tryParseNullableBoolean(localStorage.getItem(LAST_SHORT_MENU_STORAGE_KEY)),
    )

    const [estimatingCompactTitle, setEstimatingCompactTitle] = useState(false)
    const [compactTitleDoesFitMenu, setCompactTitleDoesFitMenu] = useState(true)

    // Effects

    // - Compact title width estimation initiation

    useLayoutEffect(
        () => {
            if (!main || shortMenu || noCompactTitle || !autoMoveCompactTitle )
                return

            setCompactTitleDoesFitMenu(true)
            setEstimatingCompactTitle(true)
        },

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [main, shortMenu, autoMoveCompactTitle, compactTitle, getLang()],
    )

    // - Compact title width estimation

    useLayoutEffect(
        () => {
            if (!estimatingCompactTitle)
                return

            const menuElement = menuRef.current?.component
            const compactTitleElement = menuRef.current?.title

            if (menuElement == null || compactTitleElement == null)
                return

            const menuRect = menuElement.getBoundingClientRect()
            const compactTitleRect = compactTitleElement.getBoundingClientRect()

            setCompactTitleDoesFitMenu(menuRect.right >= compactTitleRect.right)
            setEstimatingCompactTitle(false)
        },

        [estimatingCompactTitle],
    )

    // Render

    const showCompactTitleAtMenu =
        main &&
        !shortMenu &&
        !noCompactTitle &&
        (!autoMoveCompactTitle || compactTitleDoesFitMenu)

    const showCompactTitleAtMain = !showCompactTitleAtMenu
    const compactTitleWillBeRenderedAtMain = showCompactTitleAtMain && Boolean(compactTitle)

    return <div className={style.Page}
                ref={ref}>
        <div className={style.header}>
            <Header type={type}/>
        </div>

        {main &&
            <div className={style.menu}>
                <Menu firstRender={firstRenderRef.current}

                      onShortChange={setShortMenu}
                      short={shortMenu}

                      title={showCompactTitleAtMenu ? renderCompactTitleForMenu() : undefined}

                      ref={menuRef}/>
            </div>
        }

        <main style={{ paddingLeft: shortMenu ? undefined : "16px" }}
              className={main ? style.main : style.authMain}>
            {(main || compactTitleWillBeRenderedAtMain || title) &&
                <div className={style.title}>
                    <Flex gap={compactTitleGap ?? "32px"}
                          direction="row"
                          height="100%">
                        {compactTitleWillBeRenderedAtMain &&
                            <FlexItem>{renderCompactTitleAsRegular()}</FlexItem>
                        }

                        <FlexItem grow={1}>
                            {title}
                        </FlexItem>
                    </Flex>
                </div>
            }

            <div className={style.content}>
                {children}
            </div>
        </main>

        <div className={style.footer}>
            <Footer type={type}/>
        </div>
    </div>

    // - Compact title

    function renderCompactTitleForMenu(): ReactNode {
        return renderCompactTitle(true)
    }

    function renderCompactTitleAsRegular(): ReactNode {
        return renderCompactTitle(false)
    }

    function renderCompactTitle(compact: boolean): ReactNode {
        return typeof compactTitle === "function"
            ? compactTitle(compact)
            : compactTitle
    }
})

Page.displayName = "Page"
