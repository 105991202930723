import { v4 } from "uuid"
import { UUID_REGEX } from "./regex"
import { getStringColor } from "./string"

// Reexports

export { UUID_REGEX } from "./regex"

// Consts

export const UUID_GROUP_1_LENGTH = 8
export const UUID_GROUP_2_LENGTH = 4
export const UUID_GROUP_3_LENGTH = 4
export const UUID_GROUP_4_LENGTH = 4
export const UUID_GROUP_5_LENGTH = 12

export const UUID_GROUP_LENGTHS: readonly number[] = [
    UUID_GROUP_1_LENGTH,
    UUID_GROUP_2_LENGTH,
    UUID_GROUP_3_LENGTH,
    UUID_GROUP_4_LENGTH,
    UUID_GROUP_5_LENGTH,
]

export const UUID_LENGTH =
    UUID_GROUP_LENGTHS.reduce((lhs, rhs) => lhs + rhs) +
    (UUID_GROUP_LENGTHS.length - 1)

export const ZERO_UUID = normalizeUuid("0-0-0-0-0")

// Color

export function getNullableUuidColor(id?: string | null, opacity?: number | null) {
    return getStringColor(
        id != null
            ? tryNormalizeUuid(id)
            : ZERO_UUID,

        opacity,
    )
}

export function getUuidColor(id: string, opacity?: number | null) {
    return getStringColor(tryNormalizeUuid(id), opacity)
}

// Generation

export function generateRandomUuid(): string {
    return v4()
}

// Normalization

// - Try

export function tryNormalizeNullableUuid(id: string | undefined | null): string | null {
    return id != null
        ? tryNormalizeUuid(id)
        : null
}

export function tryNormalizeUuid(id: string): string {
    return isUuid(id)
        ? normalizeUuid(id)
        : id
}

// - Force

export function normalizeNullableUuid(id: string | undefined | null): string | null {
    return id != null
        ? normalizeUuid(id)
        : null
}

export function normalizeUuid(id: string): string {
    id = id.trim().toLowerCase()

    const match = id.match(UUID_REGEX)

    if (match == null)
        throw Error("Not a valid UUID")

    if (id.length === UUID_LENGTH)
        return id

    return UUID_GROUP_LENGTHS
        .map((length, i) => match[i + 1].padStart(length, "0"))
        .join("-")
}

// Is-check

export function isUuid(s: string): boolean {
    return s.match(UUID_REGEX) != null
}
