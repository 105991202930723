import { get, post, del, countEntities, isEntityExists, tryDeleteEntity } from "api/http/util"
import { InviteApplicationRequest } from "api/requests"
import { InviteApplicationResponseSchema, MfaResponse, MfaResponseSchema } from "api/responses"
import { InviteApplication } from "model"
import { joinSubpaths } from "my-util"

export const INVITE_APPLICATIONS_SUBPATH = "/invite-applications"

// Count

export async function countInviteApplications(signal?: AbortSignal | null): Promise<number> {
    return countEntities(joinSubpaths([INVITE_APPLICATIONS_SUBPATH, "/count"]), signal)
}

// Exists

export async function isInviteApplicationExistsById(
    id: string,
    signal?: AbortSignal | null,
): Promise<boolean> {
    return isEntityExists(joinSubpaths([INVITE_APPLICATIONS_SUBPATH, id, "/exists"]), signal)
}

// Get many

export async function getAllInviteApplications(
    signal?: AbortSignal | null,
): Promise<InviteApplication[]> {
    return (await get({
        subpath: INVITE_APPLICATIONS_SUBPATH,
        schema: InviteApplicationResponseSchema.array(),
        signal,
    })).map(response => new InviteApplication(response))
}

// Get one

export async function getInviteApplicationById(
    id: string,
    signal?: AbortSignal | null,
): Promise<InviteApplication> {
    return new InviteApplication(await get({
        subpath: joinSubpaths([INVITE_APPLICATIONS_SUBPATH, id]),
        schema: InviteApplicationResponseSchema,
        signal,
    }))
}

// Delete many

export async function clearInviteApplications(signal?: AbortSignal | null) {
    return del({
        subpath: INVITE_APPLICATIONS_SUBPATH,
        signal,
    })
}

// Delete one

export async function deleteInviteApplicationById(id: string, signal?: AbortSignal | null) {
    return del({
        subpath: joinSubpaths([INVITE_APPLICATIONS_SUBPATH, id]),
        signal,
    })
}

export async function tryDeleteInviteApplicationById(
    id: string,
    signal?: AbortSignal | null,
): Promise<boolean> {
    return tryDeleteEntity(joinSubpaths([INVITE_APPLICATIONS_SUBPATH, id]), signal)
}

// Create

export async function createInviteApplication(
    request: Readonly<InviteApplicationRequest>,
    signal?: AbortSignal | null,
): Promise<MfaResponse> {
    return await post({
        subpath: INVITE_APPLICATIONS_SUBPATH,
        schema: MfaResponseSchema,
        body: request,
        signal,
    })
}
