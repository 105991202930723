import { ForwardedRef, forwardRef } from "react"
import { useTranslation } from "react-i18next"

import { RoutePointType, AIR_ROUTE_POINT_TYPE,
         EARTH_ROUTE_POINT_TYPE, WATER_ROUTE_POINT_TYPE } from "model"

import { Select } from "ui/ui/input"

export namespace RoutePointTypeSelect {
    export interface Props {
        onSelect?: (type: RoutePointType) => void
        selected?: RoutePointType

        loading?: boolean
        disabled?: boolean
        readonly?: boolean

        label?: string
        information?: string
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RoutePointTypeSelect = forwardRef((
    {
        onSelect, selected,
        loading, disabled, readonly,
        label, information,
    }: Readonly<RoutePointTypeSelect.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const [t] = useTranslation()

    return <Select
        onSelect={onSelect as (key: string) => void}
        selected={selected}

        options={{
            [AIR_ROUTE_POINT_TYPE]: t("domain.routePoints.types.air"),
            [EARTH_ROUTE_POINT_TYPE]: t("domain.routePoints.types.earth"),
            [WATER_ROUTE_POINT_TYPE]: t("domain.routePoints.types.water"),
        }}

        loading={loading}
        disabled={disabled}
        readonly={readonly}

        label={label}
        information={information}

        ref={ref}
    />
})

RoutePointTypeSelect.displayName = "RoutePointTypeSelect"
