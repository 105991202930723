import { ReactElement, ReactNode } from "react"
import { DeepReadonly } from "my-util"
import { Required } from "../Required"

export namespace OptionallyRequired {
    export interface Props {
        children?: ReactNode

        required?: boolean
    }
}

export function OptionallyRequired(
    {
        children,
        required,
    }: DeepReadonly<OptionallyRequired.Props>,
): ReactElement {
    return required
        ? <Required>{children}</Required>
        : <>{children}</>
}
