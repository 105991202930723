import assert from "assert"
import { ForwardedRef, forwardRef, useEffect, useState } from "react"
import { getTransfersConfig, putTransferById, TransfersConfigResponse } from "api"
import { DeepReadonly } from "my-util"
import { TransferFields, transferFieldsToTransferRequest, transferToTransferFields } from "ui/fields"
import { ErrorDisplay, Loading, FormControls } from "ui/ui"
import { TransferFillInForm } from "../TransferFillInForm"

type State =
    | "loading-config"
    | "error-loading-config"
    | "editing"
    | "saving"
    | "saved"

export namespace TransferEditingForm {
    export interface Props {
        onSubmit?: (fields: TransferFields) => void
        onReset?: () => void
        onChange?: (fields: TransferFields) => void
        transform?: (fields: TransferFields) => TransferFields

        fields: TransferFields
        buttons?: FormControls.Button[]

        noDocumentsDelete?: boolean
        showAgentPercentInput?: boolean

        width?: string
        height?: string
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransferEditingForm = forwardRef((
    props: DeepReadonly<TransferEditingForm.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const { onSubmit, transform } = props

    // State

    const [state, setState] = useState("loading-config" satisfies State as State)
    const [error, setError] = useState(undefined as any)
    const [config, setConfig] = useState(undefined as TransfersConfigResponse | undefined)

    // Config loading

    useEffect(() => {
        if (state !== "loading-config")
            return

        const controller = new AbortController()

        getTransfersConfig(controller.signal)
            .then(newConfig => {
                setConfig(newConfig)
                setState("editing")
            })
            .catch(error => {
                if (controller.signal.aborted)
                    return

                setError(error)
                setState("error-loading-config")
            })

        return () => controller.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state])

    // Render

    if (state === "loading-config")
        return <Loading/>

    if (state === "error-loading-config")
        return <ErrorDisplay error={error}/>

    assert(config != null)

    return <TransferFillInForm {...props}
                               error={error}
                               loading={state === "saving"}
                               disabled={state === "saved"}
                               exportDisabled={!config.isExportEnabled}
                               importDisabled={!config.isImportEnabled}
                               onSubmit={onInnerSubmit}
                               ref={ref}/>

    // Events

    async function onInnerSubmit(newFields: TransferFields) {
        setState("saving")

        if (transform != null)
            newFields = transform(newFields)

        assert(newFields.id != null)

        try {
            const request = transferFieldsToTransferRequest(newFields)
            const newTransfer = await putTransferById(newFields.id, request)

            newFields = transferToTransferFields(newTransfer)

            setState("saved")
            onSubmit?.(newFields)
        } catch (error) {
            setState("editing")
            setError(error)
        }
    }
})

TransferEditingForm.displayName = "TransferEditingForm"
