import { ForwardedRef, forwardRef, useMemo } from "react"
import { getLang } from "i18n"
import { getAllMonthNames, MonthIndex } from "my-util"
import { Carousel } from "ui/ui/input"

export namespace MonthCarousel {
    export interface Props {
        onMonthIndexSelect?: (monthIndex: MonthIndex) => void
        monthIndex?: number

        maxShowMonths?: number
        width?: string
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MonthCarousel = forwardRef((
    {
        onMonthIndexSelect, monthIndex,
        maxShowMonths, width,
    }: Readonly<MonthCarousel.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const monthNames = useMemo(getAllMonthNames, [getLang()])

    return <Carousel items={monthNames}
                     onSelect={onMonthIndexSelect as (monthIndex: number) => void}
                     selected={monthIndex}
                     maxShowItems={maxShowMonths}
                     width={width}
                     ref={ref}/>
})

MonthCarousel.displayName = "MonthCarousel"
