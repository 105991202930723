import { ForwardedRef, ReactNode, useState, useRef,
         useImperativeHandle, useLayoutEffect, forwardRef } from "react"

import { applyToAllElementsToWindow, DeepReadonly } from "my-util"
import style from "./style.module.css"

export namespace Sticky {
    export interface Props {
        children?: ReactNode

        top?: number

        gap?: number

        width?: string
        height?: string
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Sticky = forwardRef((
    {
        children,
        top,
        gap,
        width, height,
    }: DeepReadonly<Sticky.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const innerTop = top ?? 0
    const innerGap = gap ?? 0

    // Refs

    const componentRef = useRef<HTMLDivElement>(null)

    useImperativeHandle(ref, () => componentRef.current!, [])

    // State

    const [childrenTop, setChildrenTop] = useState(0)

    // Effects

    useLayoutEffect(
        () => {
            const component = componentRef.current

            if (component == null)
                return

            onScroll()

            applyToAllElementsToWindow(
                component,
                element => element.addEventListener("scroll", onScroll),
            )

            return () => applyToAllElementsToWindow(
                component,
                element => element.removeEventListener("scroll", onScroll)
            )

            function onScroll() {
                const parent = component!.parentElement

                if (parent == null)
                    return

                const componentRect = component!.getBoundingClientRect()
                const parentRect = parent.getBoundingClientRect()

                setChildrenTop(
                    componentRect.top < innerTop
                        ? Math.min(
                            innerTop - componentRect.top,
                            parentRect.height - componentRect.height - innerGap,
                        )

                        : 0
                )
            }
        },

        [innerGap, innerTop],
    )

    // Render

    return <div style={{ width, height }}
                className={style.Sticky}
                ref={componentRef}>
        <div style={{ top: `${childrenTop}px` }}
             className={style.children}>
            {children}
        </div>
    </div>
})
