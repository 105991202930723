import assert from "assert"
import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { ZodType } from "zod"
import { tickIconUrl } from "images"
import { cancelMfa, verifyMfaOtp } from "api"

import { determineNullableMessageTargetType,
         EMAIL_MESSAGE_TARGET_TYPE, PHONE_MESSAGE_TARGET_TYPE } from "model"

import { OTP_LENGTH, validateOtp } from "validation"
import { Form, ErrorText, Button, Input, Flex, MessageTargetInput } from "ui/ui"
import { Otp } from "../../Otp"

export namespace OtpForm {
    export interface Props {
        onSuccess?: (response: unknown) => void
        onBack?: () => void

        responseSchema?: ZodType

        messageTarget?: string
    }
}

export function OtpForm(
    {
        onSuccess, onBack,
        responseSchema,
        messageTarget,
    }: Readonly<OtpForm.Props>,
) {
    const [t] = useTranslation()

    // State

    const messageTargetType = useMemo(
        () => determineNullableMessageTargetType(messageTarget),
        [messageTarget],
    )

    const [otp, setOtp] = useState("")
    const [touchedOtp, setTouchedOtp] = useState(false)
    const otpInvalid = useMemo(() => validateOtp(otp) != null, [otp])

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<unknown>(undefined)

    // Effects

    useEffect(
        () => {
            if (!otpInvalid && otp.length >= OTP_LENGTH)
                onSubmit()
        },

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [otp],
    )

    // Render

    return <Form onSubmit={onSubmit}
                 loading={loading}>
        <Flex align="start">
            <MessageTargetInput value={messageTarget}

                                loading={loading}

                                iconSrc={tickIconUrl}
                                iconAlt="Tick icon"
                                iconFilter="invert(64%) sepia(96%) saturate(3701%) hue-rotate(88deg) brightness(113%) contrast(135%)"

                                readonly/>

            <Input onChange={setOtp}
                   value={otp}

                   onFocus={() => setTouchedOtp(true)}

                   placeholder={renderOtpInputPlaceholder()}

                   regex={/^\d*$/g}
                   max={OTP_LENGTH}

                   loading={loading}
                   invalid={touchedOtp && otpInvalid}

                   autoFocus/>

            <Button onClick={onInnerBack}
                    text={t("misc.actions.back")}
                    buttonStyle="text"
                    width={`calc(50% - ${Flex.DEFAULT_GAP})`}
                    loading={loading}/>

            <ErrorText
                textAlign="left"

                error={error}

                apiErrorMessageMapping={{
                    422: t("auth.mfa.messages.errors.invalidCode"),
                }}
            />

            <Otp/>
        </Flex>
    </Form>

    function renderOtpInputPlaceholder(): string {
        switch (messageTargetType) {
            case PHONE_MESSAGE_TARGET_TYPE:
                return t("auth.mfa.placeholders.smsCode")

            case EMAIL_MESSAGE_TARGET_TYPE:
                return t("auth.mfa.placeholders.emailCode")

            default:
                return t("auth.mfa.placeholders.code")
        }
    }

    // Events

    async function onSubmit() {
        setLoading(true)

        try {
            assert(otp != null)

            const response = await verifyMfaOtp(otp, responseSchema)

            setError(undefined)
            onSuccess?.(response)
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }

    function onInnerBack() {
        cancelMfa()
        onBack?.()
    }
}
