import { ForwardedRef, ReactNode } from "react"
import { DeepReadonly, forwardRefAndSetProperties, satAs } from "my-util"
import style from "./style.module.css"

export namespace Center {
    export interface Props {
        children?: ReactNode
        type?: Type
    }

    export type Type =
        | "flex"
        | "absolute"
        | "absolute-fill"
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Center = forwardRefAndSetProperties(
    {
        DEFAULT_TYPE: satAs<Center.Type>("flex"),
    } as const,

    (
        { children, type }: DeepReadonly<Center.Props>,
        ref: ForwardedRef<HTMLDivElement>
    ) => {
        const innerType = type ?? Center.DEFAULT_TYPE
        const className = style[`Center-${innerType}`]

        return <div className={className}
                    ref={ref}>
            {children}
        </div>
    },
)

Center.displayName = "Center"
