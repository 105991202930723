import { ForwardedRef, forwardRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { patchTransferById } from "api"
import { Transfer, WAITING_MOMENT_TRANSFER_STATUS } from "model"
import { addDate, dateWithDateSet, dateWithTimeSet } from "my-util"
import { MAX_MEDIUM_TEXT_LENGTH } from "validation"

import { DateInput, ErrorDisplay, ErrorText,
         Label, Modal, Required, TextArea, TimeInput } from "ui/ui"

import style from "./style.module.css"

export namespace TransferApprovalModal {
    export interface Props {
        onClose?: () => void
        onApproved?: (transfer: Transfer) => void

        transferId: string

        width?: string
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransferApprovalModal = forwardRef((
    {
        onClose, onApproved,
        transferId,
        width,
    }: Readonly<TransferApprovalModal.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const [t] = useTranslation()

    // State

    const [moment, setMoment] = useState(getInitialMoment())
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const isMomentInvalid = moment.getTime() <= Date.now()

    const [comment, setComment] = useState("")

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(undefined as any)

    // Render

    return <Modal
        header={t("domain.transfers.headers.approve")}
        loading={loading}
        onClose={onInnerClose}
        width={width}
        buttons={[
            {
                onClick: onInnerClose,
                text: t("misc.actions.cancel"),
                buttonStyle: "outline",
            },

            {
                onClick: onApprove,
                text: t("misc.actions.ok"),
                type: "submit",
                disabled: isMomentInvalid,
            },
        ]}
        ref={ref}
    >
        <div className={style.layout}>
            {/* date */}

            <div className={style.dateLabel}>
                <Required>
                    <Label text={t("domain.transfers.labels.date")}/>
                </Required>
            </div>

            <div className={style.dateValue}>
                <DateInput onChange={({ date }) => setMoment(dateWithDateSet(moment, date))}
                           value={moment}

                           loading={loading}/>
            </div>

            {/* time */}

            <div className={style.timeLabel}>
                <Required>
                    <Label text={t("datetime.labels.time")}/>
                </Required>
            </div>

            <div className={style.timeValue}>
                <TimeInput onChange={({ date }) => setMoment(dateWithTimeSet(moment, date))}
                           value={moment}

                           loading={loading}/>
            </div>

            {/* momentError */}

            {isMomentInvalid &&
                <div className={style.momentError}>
                    <ErrorText error={t("domain.transfers.messages.errors.momentMustBeInTheFuture")}/>
                </div>
            }

            {/* comment */}

            <div className={style.commentLabel}>
                <Label text={t("domain.transfers.labels.comment")}/>
            </div>

            <div className={style.commentValue}>
                <TextArea onChange={setComment}
                          value={comment}

                          placeholder={t("domain.transfers.placeholders.comment")}

                          resize="vertical"

                          loading={loading}

                          max={MAX_MEDIUM_TEXT_LENGTH}
                          showMax

                          canSubmit/>
            </div>

            {/* error */}

            {error != null &&
                <div className={style.error}>
                    <ErrorDisplay centerType="flex"
                                  error={error}/>
                </div>
            }
        </div>
    </Modal>

    // Events

    function onInnerClose() {
        if (!loading)
            onClose?.()
    }

    async function onApprove() {
        setLoading(true)

        try {
            const transfer = await patchTransferById(transferId, [
                {
                    op: "replace",
                    path: "/status",
                    value: WAITING_MOMENT_TRANSFER_STATUS,
                },

                {
                    op: "replace",
                    path: "/moment",
                    value: moment.toISOString(),
                },

                {
                    op: "replace",
                    path: "/adminComment",
                    value: comment,
                },
            ])

            onApproved?.(transfer)
            setError(undefined)
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }

    // Util

    function getInitialMoment(): Date {
        const date = addDate(new Date(), 1)

        date.setMinutes(0, 0, 0)

        return date
    }
})

TransferApprovalModal.displayName = "TransferApprovalModal"
