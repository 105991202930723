import { CSSProperties, ForwardedRef, forwardRef } from "react"
import style from "./style.module.css"

export namespace Information {
    export interface Props {
        type?: Type
        text?: string
        fontWeight?: string
        fontSize?: string
        whiteSpace?: CSSProperties["whiteSpace"]
    }

    export type Type =
        | "regular"
        | "error"
        | "warning"
        | "success"
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Information = forwardRef((
    {
        type,
        text,
        fontSize,
        fontWeight,
        whiteSpace,
    }: Readonly<Information.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    return <span
        className={style[`Information-${type ?? "regular"}`]}
        style={{
            display: text ? undefined : "none",
            fontWeight,
            fontSize,
            whiteSpace,
        }}
        ref={ref}
    >
        {text}
    </span>
})

Information.displayName = "Information"
