import { ForwardedRef, forwardRef } from "react"
import { useTranslation } from "react-i18next"
import { ProviderRequest, putProviderById } from "api"
import { Provider } from "model"
import { ProviderActionModal } from "../ProviderActionModal"

export namespace ProviderEditingModal {
    export interface Props {
        onSaved?: (provider: Provider) => void
        onClose?: () => void

        provider: Provider

        closeOnSuccess?: boolean

        width?: string
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProviderEditingModal = forwardRef((
    {
        onSaved, onClose,
        provider,
        closeOnSuccess,
        width,
    }: Readonly<ProviderEditingModal.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const [t] = useTranslation()

    return <ProviderActionModal header={t("domain.providers.headers.editing")}
                                yes={t("misc.actions.save")}
                                no={t("misc.actions.cancel")}

                                onYes={onInnerSave}
                                onNo={onClose}

                                provider={provider}

                                closeOnSuccess={closeOnSuccess}

                                apiErrorMessageMapping={{
                                    409: t("messageTargets.messages.errors.occupied.messageTarget")
                                }}

                                width={width}

                                ref={ref}/>

    // Events

    async function onInnerSave(provider: Provider) {
        const request: ProviderRequest = {
            // Name

            enFirstname: provider.enFirstname,
            enLastname: provider.enLastname,
            enPatronymic: provider.enPatronymic,

            ruFirstname: provider.ruFirstname,
            ruLastname: provider.ruLastname,
            ruPatronymic: provider.ruPatronymic,

            // Company

            enCompany: provider.enCompany,
            ruCompany: provider.ruCompany,

            // Message targets

            phone: provider.phone,
            email: provider.email,
        }

        const createdProvider = await putProviderById(provider.id, request)

        onSaved?.(createdProvider)
    }
})

ProviderEditingModal.displayName = "ProviderEditingModal"
