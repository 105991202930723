import { DependencyList, Dispatch, SetStateAction, useEffect, useState } from "react"

export function useStateWithDeps<T>(
    evalValue: (oldValue?: T) => T,
    deps?: DependencyList,
): [T, Dispatch<SetStateAction<T>>] {
    const [value, setValue] = useState(evalValue())

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => setValue(oldValue => evalValue(oldValue)), deps)

    return [value, setValue]
}
