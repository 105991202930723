import { Copyable, DeepReadonly, Immutable, IsImmutable } from "my-util"
import { ViolationType } from "validation"

export namespace RoutePointViolations {
    export interface OptionsBase {
        country?: ViolationType | null
    }

    export interface CreationOptions extends OptionsBase {}
    export interface CopyOptions extends OptionsBase {}
}

export class RoutePointViolations
    extends
        Copyable<RoutePointViolations.CopyOptions>

    implements
        Immutable
{
    static createOrPass(
        arg?: RoutePointViolations
            | DeepReadonly<RoutePointViolations.CreationOptions>
            | null
    ): RoutePointViolations {
        return arg instanceof RoutePointViolations
            ? arg
            : new RoutePointViolations(arg ?? {})
    }

    // Fields

    readonly [IsImmutable] = true

    readonly country: ViolationType | null

    // Constructor

    constructor(options: DeepReadonly<RoutePointViolations.CreationOptions> = {}) {
        super()

        this.country = options.country ?? null
    }

    // Validity check

    get isValid(): boolean {
        return this.country == null
    }

    // Copy

    protected override createCopy(
        options: DeepReadonly<RoutePointViolations.CopyOptions> = {},
    ): RoutePointViolations {
        return new RoutePointViolations({
            country: "country" in options
                ? options.country
                : this.country,
        })
    }
}
