import { CSSProperties, ForwardedRef } from "react"
import { forwardRefAndSetProperties, satAs } from "my-util"
import style from "./style.module.css"

export namespace Icon {
    export interface Props {
        onClick?: () => void

        src?: string
        alt?: string
        filter?: CSSProperties["filter"]
        cursor?: CSSProperties["cursor"]

        width?: CSSProperties["width"]
        height?: CSSProperties["height"]
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Icon = forwardRefAndSetProperties(
    {
        DEFAULT_WIDTH: satAs<CSSProperties["width"]>("20px"),
        DEFAULT_HEIGHT: satAs<CSSProperties["height"]>("20px"),
    } as const,

    (
        {
            onClick,
            src, alt, filter, cursor,
            width, height,
        }: Readonly<Icon.Props>,

        ref: ForwardedRef<HTMLImageElement>,
    ) =>
        <img
            className={style.Icon}
            style={{
                cursor, filter,

                width: width ?? Icon.DEFAULT_WIDTH,
                height: height ?? Icon.DEFAULT_HEIGHT,
            }}

            src={src}
            alt={alt ?? "Icon"}

            onClick={onClick}

            ref={ref}/>,
)

Icon.displayName = "Icon"
