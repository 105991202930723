import { CSSProperties, ForwardedRef, forwardRef } from "react"
import { arrayOfNonNull } from "my-util"
import style from "./style.module.css"

export namespace ColorLegend {
    export interface Props {
        onClick?: () => void

        color?: CSSProperties["color"]
        legend?: string

        active?: boolean

        fontSize?: CSSProperties["fontSize"]
        fontStyle?: CSSProperties["fontStyle"]
        fontWeight?: CSSProperties["fontWeight"]
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ColorLegend = forwardRef((
    {
        onClick,
        color, legend,
        active,
        fontSize, fontStyle, fontWeight,
    }: Readonly<ColorLegend.Props>,
    ref: ForwardedRef<HTMLSpanElement>,
) => {
    return <span style={{ fontSize, fontStyle, fontWeight }}
                 className={renderClassName()}
                 onClick={() => onClick?.()}
                 ref={ref}>
        <span style={{ backgroundColor: color }}
              className={style.color}/>

        {legend &&
            <span className={style.legend}>
                {legend}
            </span>
        }
    </span>

    function renderClassName(): string {
        return arrayOfNonNull(
            style.ColorLegend,

            onClick != null
                ? style.clickable
                : null,

            active
                ? style.active
                : null,
        ).join(" ")
    }
})
