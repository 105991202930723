import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { ZodType } from "zod"
import { validateMessageTarget } from "validation"
import { useStateWithDeps } from "ui/hook"
import { ErrorText, Form, Button, Flex, MessageTargetInput } from "ui/ui"

import { determineMessageTargetType, MessageTargetType,
         EMAIL_MESSAGE_TARGET_TYPE, PHONE_MESSAGE_TARGET_TYPE } from "model"

import { type MessageTargetMfaForm } from ".."

export namespace MessageTargetForm {
    export interface Props {
        performMfa: MessageTargetMfaForm.PerformMfa
        responseSchema?: ZodType

        onMessageTargetChange?: MessageTargetMfaForm.OnMessageTargetChange
        messageTarget?: string
        messageTargetPlaceholder?: string

        onSuccess?: MessageTargetMfaForm.OnSuccess
        onVerificationNeeded?: onVerificationNeeded
        onBack?: MessageTargetMfaForm.OnBack
    }

    export type onVerificationNeeded = (
        messageTarget: string,
        messageTargetType: MessageTargetType,
    ) => void
}

export function MessageTargetForm(
    {
        performMfa, responseSchema,
        onMessageTargetChange, messageTarget, messageTargetPlaceholder,
        onSuccess, onVerificationNeeded, onBack,
    }: Readonly<MessageTargetForm.Props>,
) {
    const [t] = useTranslation()

    // State

    // - Message target

    const [innerMessageTarget, setInnerMessageTarget] = useStateWithDeps(
        () => messageTarget ?? "",
        [messageTarget],
    )

    const messageTargetType = useMemo(
        () => determineMessageTargetType(innerMessageTarget),
        [innerMessageTarget],
    )

    const [touchedMessageTarget, setTouchedMessageTarget] = useState(false)

    const messageTargetInvalid = useMemo(
        () => innerMessageTarget == null || validateMessageTarget(innerMessageTarget) != null,
        [innerMessageTarget],
    )

    // - Loading

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(undefined as any)

    // Effects

    useEffect(
        () => onMessageTargetChange?.(innerMessageTarget ?? "", messageTargetType),
        [innerMessageTarget, messageTargetType, onMessageTargetChange],
    )

    // Render

    return <Form onSubmit={onSubmit}
                 loading={loading}>
        <Flex align="start">
            <MessageTargetInput onChange={setInnerMessageTarget}
                                value={messageTarget}

                                placeholder={messageTargetPlaceholder}

                                onFocus={() => setTouchedMessageTarget(true)}

                                invalid={touchedMessageTarget && messageTargetInvalid}
                                loading={loading}/>

            <Flex direction="row">
                {onBack != null &&
                    <Button onClick={onBack}
                            text={t("misc.actions.back")}
                            loading={loading}
                            buttonStyle="text"/>
                }

                <Button text={renderSubmitButtonText()}

                        disabled={messageTargetInvalid}
                        loading={loading}

                        type="submit"/>
            </Flex>

            <ErrorText
                textAlign="left"

                error={error}

                apiErrorMessageMapping={{
                    404: t("messageTargets.messages.errors.notFound.messageTarget"),
                    409: t("messageTargets.messages.errors.occupied.messageTarget"),
                }}
            />
        </Flex>
    </Form>

    function renderSubmitButtonText(): string {
        switch (messageTargetType) {
            case PHONE_MESSAGE_TARGET_TYPE:
                return t("misc.actions.sendSms")

            case EMAIL_MESSAGE_TARGET_TYPE:
                return t("misc.actions.sendEmail")

            default:
                return t("misc.actions.send")
        }
    }

    // Events

    async function onSubmit() {
        setLoading(true)

        try {
            const response = await performMfa(innerMessageTarget, messageTargetType, responseSchema)

            switch (response.status) {
                case "success":
                    onSuccess?.(innerMessageTarget, messageTargetType, response.body)
                    break

                case "verification-needed":
                    onVerificationNeeded?.(innerMessageTarget, messageTargetType)
                    break
            }

            setError(undefined)
        } catch (error) {
            setLoading(false)
            setError(error)
        }
    }
}
