import assert from "assert"
import { ForwardedRef, forwardRef, ReactNode, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { ZodType } from "zod"
import { cancelMfa, OtpResponse, resendMfaOtp, verifyMfaOtp } from "api"

import { determineNullableMessageTargetType,
         EMAIL_MESSAGE_TARGET_TYPE, PHONE_MESSAGE_TARGET_TYPE } from "model"

import { DeepReadonly, ReadonlyDate, SECOND_MILLIS } from "my-util"
import { OTP_LENGTH, validateOtp } from "validation"
import { useStateWithDeps } from "ui/hook"
import { Button, ErrorDisplay, Flex, Input, Modal, Timer } from "ui/ui"
import { Otp } from "../Otp"

export namespace MfaModal {
    export interface Props extends Partial<OtpResponse> {
        onSuccess?: (response: unknown) => void
        onClose?: () => void

        closeOnSuccess?: boolean

        responseSchema?: ZodType

        header?: string

        width?: string
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MfaModal = forwardRef((
    {
        messageTarget, resendCoolDown, sentAt, expiresAt,
        onClose, onSuccess,
        closeOnSuccess,
        responseSchema,
        header,
        width,
    }: DeepReadonly<MfaModal.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const [t] = useTranslation()

    // State

    // - OTP

    const [otp, setOtp] = useState("")
    const [touchedOtp, setTouchedOtp] = useState(false)
    const otpInvalid = useMemo(() => validateOtp(otp) != null, [otp])

    // - OTP response

    const [innerMessageTarget, setInnerMessageTarget] = useStateWithDeps(
        () => messageTarget,
        [messageTarget],
    )

    const innerMessageTargetType = useMemo(
        () => determineNullableMessageTargetType(innerMessageTarget),
        [innerMessageTarget],
    )

    const [innerResendCoolDown, setInnerResendCoolDown] = useStateWithDeps(
        () => resendCoolDown,
        [resendCoolDown],
    )

    const [innerSentAt, setInnerSentAt] = useStateWithDeps(
        () => sentAt,
        [sentAt],
    )

    // For future possible use
    const [/*innerExpiresAt*/, setInnerExpiresAt] = useStateWithDeps(
        () => expiresAt,
        [expiresAt],
    )

    // - Resend info

    const canResendAt = useMemo(evalResendMoment, [innerSentAt, innerResendCoolDown])
    const [canResend, setCanResend] = useState(new Date() >= canResendAt)

    // - Status

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(undefined as unknown)

    // Effects

    useEffect(
        () => {
            if (otp.length >= OTP_LENGTH)
                onVerify()
        },

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [otp],
    )

    // Render

    return <Modal onClose={onInnerClose}
                  header={renderHeader()}
                  width={width ?? "300px"}
                  loading={loading}
                  buttons={renderButtons()}
                  ref={ref}>
        <Flex>
            {renderOtpInput()}

            {canResend
                ? renderResendButton()
                : renderResendTimer()
            }

            {renderErrorDisplay()}

            <Otp key={innerSentAt?.getTime()}/>
        </Flex>
    </Modal>

    function renderHeader(): string {
        if (header != null)
            return header

        if (innerMessageTargetType == null)
            return t("auth.mfa.headers.verification")

        return t(`auth.mfa.headers.${innerMessageTargetType}Verification`)
    }

    function renderButtons(): Modal.Button[] {
        return [
            {
                text: t("misc.actions.cancel"),
                buttonStyle: "text",
                onClick: onInnerClose,
            },

            {
                text: t("misc.actions.ok"),
                type: "submit",
                disabled: otpInvalid,
                onClick: onVerify,
            },
        ]
    }

    function renderOtpInput(): ReactNode {
        return <Input onChange={setOtp}
                      value={otp}

                      onFocus={() => setTouchedOtp(true)}

                      invalid={touchedOtp && otpInvalid}
                      loading={loading}

                      regex={/^\d*$/}

                      placeholder={renderOtpInputPlaceholder()}

                      max={OTP_LENGTH}/>
    }

    function renderOtpInputPlaceholder(): string {
        switch (innerMessageTargetType) {
            case PHONE_MESSAGE_TARGET_TYPE:
                return t("auth.mfa.placeholders.smsCode")

            case EMAIL_MESSAGE_TARGET_TYPE:
                return t("auth.mfa.placeholders.emailCode")

            case null:
                return t("auth.mfa.placeholders.code")
        }
    }

    function renderResendButton(): ReactNode {
        return <Button text={t("auth.mfa.actions.resendCode")}
                       loading={loading}
                       buttonStyle="outline"
                       onClick={onResend}/>
    }

    function renderResendTimer(): ReactNode{
        return <p>
            {t("auth.mfa.messages.canResendIn")}

            {" "}

            <Timer onExpired={() => setCanResend(true)}
                   expiresAt={canResendAt}/>
        </p>
    }

    function renderErrorDisplay(): ReactNode {
        return <ErrorDisplay
            centerType="flex"
            error={error}
            apiErrorMessageMapping={{
                422: t("auth.mfa.messages.errors.invalidCode"),
            }}
        />
    }

    // Event

    function onInnerClose() {
        cancelMfa()
        onClose?.()
    }

    async function onVerify() {
        setLoading(true)

        try {
            assert(otp != null)

            const response = await verifyMfaOtp(otp, responseSchema)

            if (closeOnSuccess)
                onClose?.()

            onSuccess?.(response)
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }

    async function onResend() {
        setLoading(true)

        try {
            const response = await resendMfaOtp()

            setInnerMessageTarget(response.messageTarget)
            setInnerResendCoolDown(response.resendCoolDown)
            setInnerSentAt(response.sentAt)
            setInnerExpiresAt(response.expiresAt)

            setCanResend(false)
            setError(undefined)
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }

    // Util

    function evalResendMoment(
        sentAt: ReadonlyDate | undefined | null = innerSentAt,
        coolDownSeconds: number | undefined | null = innerResendCoolDown,
    ): Date {
        return sentAt != null && coolDownSeconds != null
            ? new Date(SECOND_MILLIS * Math.floor(sentAt.getTime() / SECOND_MILLIS + coolDownSeconds))
            : new Date()
    }
})

MfaModal.displayName = "MfaModal"
