import { ForwardedRef, ReactNode } from "react"
import { DeepReadonly, forwardRefAndSetProperties, isIterable, satAs } from "my-util"
import style from "./style.module.css"

export namespace List {
    export interface Props {
        type?: Type

        autoPunct?: boolean

        children?: ReactNode
    }

    export type Type =
        | "ordered"
        | "unordered"
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const List = forwardRefAndSetProperties(
    {
        DEFAULT_TYPE: satAs<List.Type>("unordered"),
    } as const,

    (
        {
            type,
            autoPunct,
            children,
        }: DeepReadonly<List.Props>,

        ref: ForwardedRef<HTMLOListElement | HTMLUListElement>,
    ) => {
        const innerType = type ?? List.DEFAULT_TYPE

        switch (innerType) {
            case "ordered":
                return <ol ref={ref as ForwardedRef<HTMLOListElement>}
                           className={style.List}>
                    {renderChildren()}
                </ol>

            case "unordered":
                return <ul ref={ref as ForwardedRef<HTMLUListElement>}
                           className={style.List}>
                    {renderChildren()}
                </ul>

            default:
                innerType satisfies never
        }

        function renderChildren(): ReactNode {
            if (!autoPunct || !isIterable(children))
                return <li>{children}</li>

            const childrenArray = Array.isArray(children)
                ? children
                : [...children]

            return childrenArray.map((child, i) => {
                const key = "key" in child && child.key != null
                    ? child.key
                    : i

                return <li key={key}>
                        {child}

                        {i !== childrenArray.length - 1
                            ? ";"
                            : "."
                        }
                </li>
            })
        }
    },
)

List.displayName = "List"
