import { Constructor, UnionToIntersection } from "./type"

export function mixin<
    BaseConstructor extends Constructor<any>,
    MixinConstructor extends (Base: BaseConstructor) => any,
>(
    Base: BaseConstructor,
    mixins: MixinConstructor[],
) : BaseConstructor & UnionToIntersection<ReturnType<MixinConstructor>> {
    return mixins.reduce((Result, Mixin) => Mixin(Result), Base) as any
}
