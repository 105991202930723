import { z } from "zod"
import { CompanyMessageSchema } from "api/messages"
import { WithManyMessageTargetsResponseSchema, NamedResponseSchema } from "api/responses/common"
import { ModelObjectResponseSchema } from "api/responses/model/model-object"
import { WithNotificationOptionsResponseSchema } from "../with-notification-options"
import { WithUserRightsResponseSchema } from "../with-user-rights"
import { WithCreatorIdResponseSchema } from "../with-creator-id"
import { UserStatusResponseSchema } from "./user-status"

export const UserResponseSchema = z.object({
    company: CompanyMessageSchema.nullish(),
    status: UserStatusResponseSchema.nullish(),
    nextTransferNumber: z.number(),
})
    .merge(ModelObjectResponseSchema)
    .merge(WithCreatorIdResponseSchema)
    .merge(WithManyMessageTargetsResponseSchema)
    .merge(NamedResponseSchema)
    .merge(WithUserRightsResponseSchema)
    .merge(WithNotificationOptionsResponseSchema)

export type UserResponse = z.infer<typeof UserResponseSchema>
