import { DeepReadonly, Nullish, tryNormalizeNullableUuid } from "my-util"
import { AbstractNotification } from "./AbstractNotification"

import { NotificationType,
         NewChatMessagesNotificationType,
         NEW_CHAT_MESSAGES_NOTIFICATION_TYPE } from "./NotificationType"

export namespace NewChatMessagesNotification {
    export interface OptionsBase extends AbstractNotification.OptionsBase, Nullish<{
        fromId: string
        count: number
    }> {}

    export interface CreationOptions
        extends
            Omit<AbstractNotification.CreationOptions<NotificationType>, "type">,
            OptionsBase
    {}

    export interface CopyOptions
        extends
            Omit<AbstractNotification.CopyOptions, "type">,
            Nullish<OptionsBase>
    {}
}


export class NewChatMessagesNotification extends AbstractNotification<
    NewChatMessagesNotificationType,
    NewChatMessagesNotification.CopyOptions
> {
    static createOrPass(
        arg?: NewChatMessagesNotification
            | DeepReadonly<NewChatMessagesNotification.CreationOptions>
            | null
    ): NewChatMessagesNotification {
        return arg instanceof NewChatMessagesNotification
            ? arg
            : new NewChatMessagesNotification(arg ?? {})
    }

    // Fields

    readonly fromId: string | null
    readonly count: number

    // Constructor

    constructor(options: DeepReadonly<NewChatMessagesNotification.CreationOptions> = {}) {
        super({ ...options, type: NEW_CHAT_MESSAGES_NOTIFICATION_TYPE })

        this.fromId = tryNormalizeNullableUuid(options.fromId)
        this.count = options.count ?? 1
    }

    // User IDs

    override get userIds(): string[] {
        const userIds = super.userIds

        if (this.fromId != null)
            userIds.push(this.fromId)

        return userIds
    }

    // Copy

    protected override createCopy(
        options: DeepReadonly<NewChatMessagesNotification.CopyOptions> = {},
    ): NewChatMessagesNotification {
        return new NewChatMessagesNotification({
            // Recipient ID

            recipientId: "recipientId" in options
                ? options.recipientId
                : this.recipientId,

            // Content

            fromId: "fromId" in options
                ? options.fromId
                : this.fromId,

            count: options.count ?? this.count,

            // Basic

            id: options.id ?? this.id,
            createdAt: options.createdAt ?? this.createdAt,
            modifiedAt: options.modifiedAt ?? this.modifiedAt,
        })
    }
}
