import { AbstractModelObject } from "model/AbstractModelObject"
import { collapseWhiteSpace, DeepReadonly, map, Nullish } from "my-util"
import { normalizeCnfeaCode } from "normalization"

export namespace Product {
    export interface OptionsBase extends Nullish<{
        name: string
        cnfeaCodes: Iterable<string>
    }> {}

    export interface CreationOptions
        extends
            AbstractModelObject.CreationOptions,
            OptionsBase
    {}

    export interface CopyOptions
        extends
            AbstractModelObject.CopyOptions,
            OptionsBase
    {}
}

export class Product extends AbstractModelObject<Product.CopyOptions> {
    static createOrPass(
        arg?: Product
            | DeepReadonly<Product.CreationOptions>
            | null,
    ): Product {
        return arg instanceof Product
            ? arg
            : new Product(arg ?? {})
    }

    // Fields

    readonly name: string
    readonly cnfeaCodes: ReadonlySet<string>

    // Constructor

    constructor(options: DeepReadonly<Product.CreationOptions> = {}) {
        super(options)

        this.name = collapseWhiteSpace(options.name ?? "")
        this.cnfeaCodes = new Set(map(options.cnfeaCodes ?? [], normalizeCnfeaCode))
    }

    // Copy

    protected override createCopy(options: DeepReadonly<Product.CopyOptions> = {}): Product {
        return new Product({
            // Info

            name: options.name ?? this.name,
            cnfeaCodes: options.cnfeaCodes ?? this.cnfeaCodes,

            // Basic

            id: options.id ?? this.id,
            createdAt: options.createdAt ?? this.createdAt,
            modifiedAt: options.modifiedAt ?? this.modifiedAt,
        })
    }
}
