import { DeepReadonly, Nullish, tryNormalizeNullableUuid } from "my-util"
import { AbstractNotification } from "./AbstractNotification"

import { NotificationType,
         NewInviteApplicationNotificationType,
         NEW_INVITE_APPLICATION_NOTIFICATION_TYPE } from "./NotificationType"

export namespace NewInviteApplicationNotification {
    export interface OptionsBase extends AbstractNotification.OptionsBase, Nullish<{
        inviteApplicationId: string
    }> {}

    export interface CreationOptions
        extends
            Omit<AbstractNotification.CreationOptions<NotificationType>, "type">,
            OptionsBase
    {}

    export interface CopyOptions
        extends
            Omit<AbstractNotification.CopyOptions, "type">,
            Nullish<OptionsBase>
    {}
}

export class NewInviteApplicationNotification extends AbstractNotification<
    NewInviteApplicationNotificationType,
    NewInviteApplicationNotification.CopyOptions
> {
    static createOrPass(
        arg?: NewInviteApplicationNotification
            | DeepReadonly<NewInviteApplicationNotification.CreationOptions>
            | null
    ): NewInviteApplicationNotification {
        return arg instanceof NewInviteApplicationNotification
            ? arg
            : new NewInviteApplicationNotification(arg ?? {})
    }

    // Fields

    readonly inviteApplicationId: string | null

    // Constructor

    constructor(options: DeepReadonly<NewInviteApplicationNotification.CreationOptions> = {}) {
        super({ ...options, type: NEW_INVITE_APPLICATION_NOTIFICATION_TYPE })

        this.inviteApplicationId = tryNormalizeNullableUuid(options.inviteApplicationId)
    }

    // Copy

    protected override createCopy(
        options: DeepReadonly<NewInviteApplicationNotification.CopyOptions> = {},
    ): NewInviteApplicationNotification {
        return new NewInviteApplicationNotification({
            // Recipient ID

            recipientId: "recipientId" in options
                ? options.recipientId
                : this.recipientId,

            // Content

            inviteApplicationId: "inviteApplicationId" in options
                ? options.inviteApplicationId
                : this.inviteApplicationId,

            // Basic

            id: options.id ?? this.id,
            createdAt: options.createdAt ?? this.createdAt,
            modifiedAt: options.modifiedAt ?? this.modifiedAt,
        })
    }
}
