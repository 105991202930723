import { AbstractModelObject } from "model/AbstractModelObject"

import { WithManyMessageTargets, MandatoryNamedFields,
         WithCompanyNameFields, NamedFields, WithCreatorId } from "model/interfaces"

import { WithNullableCompanyNameMixin, NamedMixin } from "model/mixins"

import { capitalized, collapseWhiteSpace, Nullable, Nullish, DeepReadonly, mixin,
         collapseWhiteSpaceToNull, removeWhiteSpaceToNull, tryNormalizeNullableUuid } from "my-util"

export namespace Provider {
    export interface CreationOptions
        extends
            AbstractModelObject.CreationOptions,
            Nullish<WithCompanyNameFields>,
            Nullish<WithManyMessageTargets>,
            Nullish<WithCreatorId>,
            MandatoryNamedFields
    {}


    export interface CopyOptions
        extends
            AbstractModelObject.CopyOptions,
            Nullish<WithCompanyNameFields>,
            Nullish<WithManyMessageTargets>,
            Nullish<WithCreatorId>,
            Nullish<NamedFields>
    {}
}

class ProviderBase
    extends
        AbstractModelObject<Provider.CopyOptions>

    implements
        Readonly<Nullable<WithCompanyNameFields>>,
        Readonly<Nullable<WithCreatorId>>,
        Readonly<WithManyMessageTargets>,
        Readonly<NamedFields>
{
    // Fields

    // - Creator ID

    readonly creatorId: string | null

    // - Message targets

    readonly phone: string | null
    readonly email: string | null

    // - Name

    // -- En

    readonly enFirstname: string
    readonly enLastname: string
    readonly enPatronymic: string | null

    // -- Ru

    readonly ruFirstname: string
    readonly ruLastname: string
    readonly ruPatronymic: string | null

    // - Company

    readonly enCompany: string | null
    readonly ruCompany: string | null

    // Constructor

    constructor(options: DeepReadonly<Provider.CreationOptions>) {
        super(options)

        // Creator ID

        this.creatorId = tryNormalizeNullableUuid(options.creatorId)

        // Message targets

        this.phone = removeWhiteSpaceToNull(options.phone ?? "")
        this.email = removeWhiteSpaceToNull(options.email ?? "")?.toLowerCase() ?? null

        // Name

        // - En

        this.enFirstname = collapseWhiteSpace(capitalized(options.enFirstname))
        this.enLastname = collapseWhiteSpace(capitalized(options.enLastname))
        this.enPatronymic = collapseWhiteSpaceToNull(capitalized(options.enPatronymic ?? ""))

        // - Ru

        this.ruFirstname = collapseWhiteSpace(capitalized(options.ruFirstname))
        this.ruLastname = collapseWhiteSpace(capitalized(options.ruLastname))
        this.ruPatronymic = collapseWhiteSpaceToNull(capitalized(options.ruPatronymic ?? ""))

        // Company

        this.enCompany = collapseWhiteSpaceToNull(options.enCompany ?? "")
        this.ruCompany = collapseWhiteSpaceToNull(options.ruCompany ?? "")
    }

    // Copy

    protected override createCopy(options: DeepReadonly<Provider.CopyOptions> = {}): ProviderBase {
        return new ProviderBase({
            // Creator ID

            creatorId: "creatorId" in options
                ? options.creatorId
                : this.creatorId,

            // Message targets

            phone: "phone" in options
                ? options.phone
                : this.phone,

            email: "email" in options
                ? options.email
                : this.email,

            // Company

            enCompany: "enCompany" in options
                ? options.enCompany
                : this.enCompany,

            ruCompany: "ruCompany" in options
                ? options.ruCompany
                : this.ruCompany,

            // Name

            // - En

            enFirstname: options.enFirstname ?? this.enFirstname,
            enLastname: options.enLastname ?? this.enLastname,

            enPatronymic: "enPatronymic" in options
                ? options.enPatronymic
                : this.enPatronymic,

            // - Ru

            ruFirstname: options.ruFirstname ?? this.ruFirstname,
            ruLastname: options.ruLastname ?? this.ruLastname,

            ruPatronymic: "ruPatronymic" in options
                ? options.ruPatronymic
                : this.ruPatronymic,

            // Basic

            id: options.id ?? this.id,
            createdAt: options.createdAt ?? this.createdAt,
            modifiedAt: options.modifiedAt ?? this.modifiedAt,
        })
    }
}

export class Provider extends mixin(ProviderBase, [
    WithNullableCompanyNameMixin,
    NamedMixin,
]) {
    // Create

    static createOrPass(
        arg: Provider | DeepReadonly<Provider.CreationOptions>,
    ): Provider {
        return arg instanceof Provider
            ? arg
            : new Provider(arg)
    }
}
