import { ForwardedRef, forwardRef, ReactNode, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { countTransfersByStatus, countMyTransfersInWork,
         countMyTransfersByStatus, countTransfersInWork } from "api"

import * as AllTransfersPage from "ui/page/sections/transfers/AllTransfersPage/path"
import * as MainTransfersPage from "ui/page/sections/main/MainTransfersPage/path"
import { Clickable, CountDataRow, ErrorDisplay, Flex, Loading } from "ui/ui"
import { Widget } from "../Widget"

export namespace TransfersWidget {
    export interface Props {
        newCountType?: CountType
        inWorkCountType?: CountType

        maxHeaderWidth?: string
        maxContentWidth?: string

        headerWidth?: string
        width?: string
        height?: string

        colorful?: boolean
    }

    export type CountType =
        | "all"
        | "my"
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransfersWidget = forwardRef((
    {
        newCountType, inWorkCountType,
        maxHeaderWidth, maxContentWidth,
        headerWidth, width, height,
        colorful,
    }: Readonly<TransfersWidget.Props>,
    ref: ForwardedRef<Widget.Ref>,
) => {
    const [t] = useTranslation()

    const navigate = useNavigate()

    // State

    // - New

    const [newCount, setNewCount] = useState(0)
    const [loadingNewCount, setLoadingNewCount] = useState(true)
    const [newCountLoadingError, setNewCountLoadingError] = useState<unknown>(undefined)

    // - In work

    const [inWorkCount, setInWorkCount] = useState(0)
    const [loadingInWorkCount, setLoadingInWorkCount] = useState(true)
    const [inWorkCountLoadingError, setInWorkCountLoadingError] = useState<unknown>(undefined)

    // - Common

    const loading = loadingNewCount || loadingInWorkCount
    const error = newCountLoadingError ?? inWorkCountLoadingError

    // Loading

    // - New counting

    useEffect(
        () => {
            if (!loadingNewCount)
                return

            const controller = new AbortController()

            const count = newCountType === "my"
                ? countMyTransfersByStatus
                : countTransfersByStatus

            count("new", controller.signal)
                .then(setNewCount)
                .catch(error => {
                    if (!controller.signal.aborted)
                        setNewCountLoadingError(error)
                })
                .finally(() => {
                    if (!controller.signal.aborted)
                        setLoadingNewCount(false)
                })

            return () => controller.abort()
        },

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [loadingNewCount],
    )

    // - In work counting

    useEffect(
        () => {
            if (!loadingInWorkCount)
                return

            const controller = new AbortController()

            const count = inWorkCountType === "my"
                ? countMyTransfersInWork
                : countTransfersInWork

            count(controller.signal)
                .then(setInWorkCount)
                .catch(error => {
                    if (!controller.signal.aborted)
                        setInWorkCountLoadingError(error)
                })
                .finally(() => {
                    if (!controller.signal.aborted)
                        setLoadingInWorkCount(false)
                })

            return () => controller.abort()
        },

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [loadingInWorkCount],
    )

    // Render

    const [contentBackgroundColor, contentBorderColor] = colorful
        ? ["#FCF2F8", "#FFE2D7"]
        : ["white", "#D5D5D5"]

    return <Widget header={t("widgets.transfers.header") + ":"}
                   onHeaderClick={navigateToMainTransfersPage}

                   headerBackgroundColor="#FCE6E8"
                   headerBorderColor="#F5F4F0"

                   contentBackgroundColor={contentBackgroundColor}
                   contentBorderColor={contentBorderColor}

                   maxHeaderWidth={maxHeaderWidth}
                   maxContentWidth={maxContentWidth}

                   headerWidth={headerWidth}
                   height={height}
                   width={width}

                   ref={ref}>
        {renderContent()}
    </Widget>

    function renderContent(): ReactNode {
        if (loading)
            return <Loading centerType="flex"/>

        if (error != null)
            return <ErrorDisplay centerType="flex"
                                 error={error}/>

        return <Flex>
            <Clickable onClick={navigateToAllTransfersPage}
                       cursor="pointer"
                       width="100%">
                <CountDataRow label={t("widgets.transfers.labels.newCount")}
                              value={newCount}
                              importance="medium"
                              underline/>
            </Clickable>

            <Clickable onClick={navigateToAllTransfersPage}
                       cursor="pointer"
                       width="100%">
                <CountDataRow label={t("widgets.transfers.labels.inWorkCount")}
                              value={inWorkCount}
                              underline/>
            </Clickable>
        </Flex>
    }

    // Util

    function navigateToMainTransfersPage() {
        navigate(MainTransfersPage.PATH)
    }

    function navigateToAllTransfersPage() {
        navigate(AllTransfersPage.PATH)
    }
})

TransfersWidget.displayName = "TransferWidget"
