import { ForwardedRef, forwardRef } from "react"
import { Output } from "ui/ui/output"
import { Email } from "../Email"

export namespace EmailOutput {
    export interface Props extends
        Omit<Output.Props, "children">,
        Email.Props
    {}
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EmailOutput = forwardRef((
    props: Readonly<EmailOutput.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) =>
    <Output { ...props }
            ref={ref}>
        <Email { ...props }/>
    </Output>
)

EmailOutput.displayName = "EmailOutput"
