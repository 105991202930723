import { useEffect, useImperativeHandle,
         ForwardedRef, useState, useRef, ReactNode } from "react"

import { useTranslation } from "react-i18next"
import { calendarIconUrl } from "images"

import { dateToDateString, SHORT_DATE_INPUT_REGEX,
         forwardRefAndSetProperties, DeepReadonly } from "my-util"

import { useStateWithDeps } from "ui/hook"
import { Input } from "ui/ui/input"
import { Tooltip } from "ui/ui/structure"
import { SmallMonthCalendar } from "../calendar"
import style from "./style.module.css"

export namespace DateInput {
    export interface Props {
        onChange?: (event: ChangeEvent) => void
        value?: Date | string | null

        autoFocus?: boolean
        width?: string

        invalid?: boolean
        disabled?: boolean
        readonly?: boolean
        loading?: boolean

        label?: string
        information?: string
    }

    export interface ChangeEvent {
        date: Date
        rawDate: string
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DateInput = forwardRefAndSetProperties(
    {
        MIN_WIDTH: "100px" as string,
    } as const,

    (
        {
            onChange, value,
            autoFocus, width,
            invalid, disabled, readonly, loading,
            label, information,
        }: DeepReadonly<DateInput.Props>,

        ref: ForwardedRef<HTMLDivElement>,
    ) => {
        const [t] = useTranslation()

        // State

        const enteringRef = useRef(false)

        const [stringValue, setStringValue] = useStateWithDeps<string>(
            oldValue => {
                if (enteringRef.current && oldValue != null)
                    return oldValue

                if (typeof value !== "string")
                    return dateToDateString(value ?? new Date())

                return value
            },
            [value],
        )

        const [showDateSelect, setShowDateSelect] = useState(false)

        // Refs

        const innerRef = useRef(null as HTMLDivElement | null)
        const inputRef = useRef(null as HTMLDivElement | null)

        useImperativeHandle(ref, () => innerRef.current!, [])

        // Click outside handler

        useEffect(() => {
            window.addEventListener("click", handleClick)

            return () => window.removeEventListener("click", handleClick)

            function handleClick(event: MouseEvent) {
                if (event.target instanceof Node && !inputRef.current!.contains(event.target))
                    setShowDateSelect(innerRef.current!.contains(event.target))
            }
        }, [])

        // Render

        return <div style={{ width: width === "min" ? DateInput.MIN_WIDTH : width }}
                    className={style.DateInput}
                    ref={innerRef}>
            {renderInput()}
            {renderDateSelect()}
        </div>

        function renderInput(): ReactNode {
            return <Input onChange={onInnerChange}
                          value={stringValue}

                          onFocus={onFocus}
                          onBlur={onBlur}

                          regex={SHORT_DATE_INPUT_REGEX}
                          autoFocus={autoFocus}

                          invalid={invalid}
                          disabled={disabled}
                          readonly={readonly}
                          loading={loading}

                          label={label}
                          placeholder={t("datetime.placeholders.date")}
                          information={information}

                          onIconClick={onToggleShowDateSelect}
                          iconSrc={calendarIconUrl}
                          iconAlt="Calendar icon"
                          iconFilter="brightness(0) saturate(100%) invert(71%) sepia(5%) saturate(5137%) hue-rotate(183deg) brightness(68%) contrast(104%)"

                          ref={inputRef}/>
        }

        function renderDateSelect(): ReactNode {
            const dateValue = stringToDate(stringValue)

            return <Tooltip show={showDateSelect}
                            position="bottom">
                <SmallMonthCalendar onDateClick={onDateClick}
                                    year={dateValue.getFullYear()}
                                    monthIndex={dateValue.getMonth()}
                                    showScrollButtons
                                    showYear/>
            </Tooltip>
        }

        // Events

        function onDateClick(date: Date) {
            if (readonly)
                return

            const newInnerValue = dateToDateString(date)

            setStringValue(newInnerValue)

            onChange?.({
                rawDate: newInnerValue,
                date,
            })
        }

        function onInnerChange(input: string) {
            setStringValue(input)

            onChange?.({
                date: stringToDate(input),
                rawDate: input,
            })
        }

        function onFocus() {
            enteringRef.current = true
        }

        function onBlur(input: string): string {
            enteringRef.current = false

            const date = stringToDate(input)
            const newInput = dateToDateString(date)

            onChange?.({
                rawDate: newInput,
                date,
            })

            return newInput
        }

        function onToggleShowDateSelect() {
            if (!readonly)
                setShowDateSelect(oldShowDateSelect => !oldShowDateSelect)
        }

        // Util

        function stringToDate(s: string): Date {
            const today = new Date()
            const parts = s.split(".").map(Number)
            const date = parts[0]
            const month = parts[1] != null ? parts[1] - 1 : today.getMonth()
            const year = parts[2] != null ? 2000 + parts[2] : (today.getFullYear() % 100)

            return new Date(year, month, date)
        }
    },
)

DateInput.displayName = "DateInput"
