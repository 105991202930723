import assert from "assert"
import { useContext, useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"

import { getRegistrationConfig,
         StartRegistrationResponse,
         StartRegistrationResponseSchema } from "api"

import { MessageTargetType, User } from "model"
import { UserContext } from "ui/context"
import * as LoginPage from "ui/page/auth/LoginPage/path"
import * as MainPage from "ui/page/sections/main/MainPage/path"
import { ErrorDisplay, Loading } from "ui/ui"
import { StartPane } from "./StartPane"
import { CompletePane } from "./CompletePane"
import { SuccessPane } from "./SuccessPane"
import { WaitPane } from "./WaitPane"

type State =
    | "start"
    | "complete"
    | "success"
    | "wait"

export namespace RegistrationPane {
    export interface Props {
        inviteId: string
    }
}

export function RegistrationPane({ inviteId }: Readonly<RegistrationPane.Props>) {
    const [,, refetchUser] = useContext(UserContext)

    const navigate = useNavigate()

    // State

    const [state, setState] = useState<State>("start")

    const [isLoginRequiredAfter, setIsLoginRequiredAfter] = useState(true)

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(undefined as unknown)

    const messageTargetRef = useRef("")
    const messageTargetTypeRef = useRef(null as MessageTargetType | null)

    const userRef = useRef(null as User | null)
    const passwordRef = useRef("")

    const responseRef = useRef(null as StartRegistrationResponse | null)

    // Config loading

    useEffect(() => {
        const controller = new AbortController()

        getRegistrationConfig(controller.signal)
            .then(({ isLoginRequiredAfter }) => setIsLoginRequiredAfter(isLoginRequiredAfter))
            .catch(error => {
                if (!controller.signal.aborted)
                    setError(error)
            })
            .finally(() => {
                if (!controller.signal.aborted)
                    setLoading(false)
            })

        return () => controller.abort()
    }, [])

    // Render

    if (loading)
        return <Loading/>

    if (error)
        return <ErrorDisplay error={error}/>

    switch (state) {
        case "start":
            return <StartPane
                inviteId={inviteId}
                onSuccess={(newMessageTarget, newMessageTargetType, response) => {
                    messageTargetRef.current = newMessageTarget
                    messageTargetTypeRef.current = newMessageTargetType

                    try {
                        responseRef.current = StartRegistrationResponseSchema.parse(response)
                    } catch (error) {
                        console.error(error)
                    }

                    setState("complete")
                }}
            />

        case "complete":
            return <CompletePane
                messageTarget={messageTargetRef.current}
                response={responseRef.current}
                onSuccess={(user, password) => {
                    userRef.current = user
                    passwordRef.current = password

                    setState("success")
                }}
            />

        case "success":
            assert(userRef.current != null)

            return <SuccessPane
                user={userRef.current}
                password={passwordRef.current}
                onSuccess={() => {
                    const user = userRef.current

                    if (!isLoginRequiredAfter) {
                        navigate(MainPage.PATH)
                        refetchUser()
                        return
                    }

                    if (user?.status === "active") {
                        navigate(LoginPage.createPath(user.id))
                        return
                    }

                    setState("wait")
                }}
            />

        case "wait":
            return <WaitPane/>
    }
}
