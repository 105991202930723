import { ForwardedRef, forwardRef, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Product } from "model"

import { collapseWhiteSpace, DeepReadonly,
         COMMA_WITH_WHITESPACE_REGEX, isBlank, join } from "my-util"

import { normalizeCnfeaCode } from "normalization"
import { validateCnfea, validateShortText, ViolationType } from "validation"
import { useStateWithDeps } from "ui/hook"
import { Flex, Group, Input, Label, OptionallyRequired, Output } from "ui/ui"
import { ProductViolations } from "./ProductViolations"

export namespace ProductEditor {
    export interface Props {
        onValidate?: (violations: ProductViolations) => void
        onChange?: (value: Product) => void
        value?: Product

        loading?: boolean
        disabled?: boolean
        readonly?: boolean
        required?: boolean
        output?: boolean
        mobile?: boolean

        width?: string
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProductEditor = forwardRef((
    {
        onValidate, onChange, value,
        loading, disabled, readonly, required, output, mobile,
        width,
    }: DeepReadonly<ProductEditor.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const [t] = useTranslation()

    // Refs

    const valueChangedRef = useRef(true)
    const violationsChangedRef = useRef(true)

    // State

    // - Name

    const [name, setName] = useStateWithDeps(
        () => value?.name ?? "",
        [value?.id],
    )

    const [touchedName, setTouchedName] = useState(false)

    // - CNFEA codes

    const [cnfeaCodes, setCnfeaCodes] = useStateWithDeps(
        () => join(value?.cnfeaCodes ?? [], ", "),
        [value?.id],
    )

    const [touchedCnfeaCodes, setTouchedCnfeaCodes] = useState(false)

    // - Violations

    const [violations, setViolations] = useState(
        new ProductViolations({
            name: validateName(name),
            cnfeaCodes: validateCnfeaCodesInput(cnfeaCodes),
        })
    )

    // Effects

    // - Value propagation

    useEffect(
        () => {
            if (!valueChangedRef.current)
                return

            valueChangedRef.current = false

            if (onChange == null || !violations.isValid)
                return

            const newValueOptions = {
                cnfeaCodes: normalizeCnfeaCodesInput(cnfeaCodes),
                name: collapseWhiteSpace(name),
            }

            const newValue = value != null
                ? value.copy(newValueOptions)
                : new Product(newValueOptions)

            onChange(newValue)
        },

        [onChange, value, name, cnfeaCodes, violations.isValid],
    )

    // - Violations propagation

    useEffect(
        () => {
            if (violationsChangedRef.current) {
                violationsChangedRef.current = false
                onValidate?.(violations)
            }
        },

        [onValidate, violations],
    )

    // Render

    const FIELD_WIDTH = `calc(50% - ${Flex.DEFAULT_GAP})`

    return <Group width={width}
                  ref={ref}>
        <Flex direction="row">
            <Flex direction="row"
                  width={FIELD_WIDTH}>
                <OptionallyRequired required={required}>
                    <Label text={t("domain.products.labels.name")}/>
                </OptionallyRequired>

                {output
                    ? <Output>{name}</Output>

                    : <Input onChange={onNameChange}
                             value={name}

                             onFocus={() => setTouchedName(true)}

                             placeholder={t("domain.products.placeholders.name")}

                             invalid={touchedName && !violations.isNameValid}
                             loading={loading}
                             disabled={disabled}
                             readonly={readonly}/>
                }
            </Flex>

            <Flex direction="row"
                  width={FIELD_WIDTH}>
                <OptionallyRequired required={required}>
                    <Label text={t("domain.products.labels.cnfeaCodes")}/>
                </OptionallyRequired>

                {output
                    ? <Output>{cnfeaCodes}</Output>

                    : <Input onChange={onCnfeaCodesChange}
                             value={cnfeaCodes}

                             onFocus={() => setTouchedCnfeaCodes(true)}

                             placeholder={t("domain.products.placeholders.cnfeaCodes")}

                             invalid={touchedCnfeaCodes && !violations.areCnfeaCodesValid}
                             loading={loading}
                             disabled={disabled}
                             readonly={readonly}

                             regex={/^[,\d\s]*$/g}/>
                }
            </Flex>
        </Flex>
    </Group>

    // Events

    function onNameChange(newName: string) {
        setViolations(oldViolations => oldViolations.copy({
            name: validateName(newName),
        }))

        setName(newName)

        violationsChangedRef.current = true
        valueChangedRef.current = true
    }

    function onCnfeaCodesChange(newCnfeaCodes: string) {
        setViolations(oldViolations => oldViolations.copy({
            cnfeaCodes: validateCnfeaCodesInput(newCnfeaCodes),
        }))

        setCnfeaCodes(newCnfeaCodes)

        violationsChangedRef.current = true
        valueChangedRef.current = true
    }

    // Util

    function validateName(name: string): ViolationType | null {
        return required && isBlank(name)
            ? "too-short"
            : validateShortText(name)
    }

    function validateCnfeaCodesInput(input: string): (ViolationType | null)[] {
        return required && isBlank(input)
            ? ["too-short"]
            : normalizeCnfeaCodesInput(input).map(validateCnfea)
    }

    function normalizeCnfeaCodesInput(input: string): string[] {
        return input
            .trim()
            .split(COMMA_WITH_WHITESPACE_REGEX)
            .filter(part => !isBlank(part))
            .map(normalizeCnfeaCode)
    }
})

ProductEditor.displayName = "ProductEditor"
