import { WithUserRightsFields, NamedFields, ExpirableFields,
         WithCreatorId, WithOneMessageTargetFields, WithCompanyNameFields } from "model/interfaces"

import { ExpirableWithCreationDateMixin,
         WithOneMessageTargetMixin, WithUserRightsMixin,
         WithNullableCompanyNameMixin, NullableNamedMixin } from "model/mixins"

import { AbstractModelObject } from "model/AbstractModelObject"

import { mixin,ReadonlyDate, tryNormalizeUuid, Nullable, DAY_MILLIS,
         DeepReadonly, Nullish, HOUR_MILLIS, tryCopyDate, map, capitalized,
         tryNormalizeNullableUuid, removeWhiteSpace, collapseWhiteSpaceToNull } from "my-util"

import { CLIENT_USER_ROLE, UserSpecialization, type UserRole } from "../User"

import { InviteStatus,
         ACTIVE_INVITE_STATUS,
         EXPIRED_INVITE_STATUS,
         EXPIRES_INVITE_STATUS } from "./InviteStatus"

export namespace Invite {
    export interface OptionsBase
        extends
            WithOneMessageTargetFields,
            Nullish<WithCompanyNameFields>,
            Nullish<WithUserRightsFields>,
            Nullish<ExpirableFields>,
            Nullish<WithCreatorId>,
            Nullish<NamedFields>,
            Nullish<{
                providerId: string

                sentAt: Date

                reference: string
                text: string
                comment: string
                link: string
            }>
    {}

    export interface CreationOptions
        extends
            AbstractModelObject.CreationOptions,
            OptionsBase
    {}

    export interface CopyOptions
        extends
            AbstractModelObject.CopyOptions,
            Nullish<OptionsBase>
    {}
}

class InviteBase
    extends
        AbstractModelObject<Invite.CopyOptions>

    implements
        Readonly<Nullable<WithCreatorId>>,
        Readonly<Nullable<WithCompanyNameFields>>,
        Readonly<Nullable<NamedFields>>,
        Readonly<WithOneMessageTargetFields>,
        DeepReadonly<WithUserRightsFields>,
        DeepReadonly<ExpirableFields>
{
    static readonly EXPIRES_WHEN_LEFT_MILLIS = HOUR_MILLIS

    // Fields

    // - Creator ID

    readonly creatorId: string | null

    // - Provider ID

    readonly providerId: string | null

    // - Sending info

    readonly messageTarget: string
    readonly sentAt: ReadonlyDate | null

    // - Company name

    readonly enCompany: string | null
    readonly ruCompany: string | null

    // - Name

    // -- En

    readonly enFirstname: string | null
    readonly enLastname: string | null
    readonly enPatronymic: string | null

    // -- Ru

    readonly ruFirstname: string | null
    readonly ruLastname: string | null
    readonly ruPatronymic: string | null

    // - Content

    readonly reference: string | null
    readonly text: string | null
    readonly comment: string | null
    readonly link: string | null

    // - Rights

    readonly role: UserRole
    readonly specialization: UserSpecialization | null
    readonly canManageTransfers: boolean
    readonly canManageInvites: boolean
    readonly canSendInvites: boolean
    readonly canManageUsers: boolean
    readonly canManageProviders: boolean
    readonly canSeeAllUsers: boolean
    readonly visibleUserIds: string[]

    // - Expiration

    readonly expiresAt: ReadonlyDate

    // Constructor

    constructor(options: DeepReadonly<Invite.CreationOptions>) {
        super(options)

        // Creator ID

        this.creatorId = tryNormalizeNullableUuid(options.creatorId)

        // Provider ID

        this.providerId = tryNormalizeNullableUuid(options.providerId)

        // Sending info

        this.messageTarget = removeWhiteSpace(options.messageTarget).toLowerCase()
        this.sentAt = tryCopyDate(options.sentAt)

        // Company

        this.enCompany = collapseWhiteSpaceToNull(options.enCompany ?? "")
        this.ruCompany = collapseWhiteSpaceToNull(options.ruCompany ?? "")

        // Name

        // - En

        this.enFirstname = collapseWhiteSpaceToNull(capitalized(options.enFirstname ?? ""))
        this.enLastname = collapseWhiteSpaceToNull(capitalized(options.enLastname ?? ""))
        this.enPatronymic = collapseWhiteSpaceToNull(capitalized(options.enPatronymic ?? ""))

        // - Ru

        this.ruFirstname = collapseWhiteSpaceToNull(capitalized(options.ruFirstname ?? ""))
        this.ruLastname = collapseWhiteSpaceToNull(capitalized(options.ruLastname ?? ""))
        this.ruPatronymic = collapseWhiteSpaceToNull(capitalized(options.ruPatronymic ?? ""))

        // Content

        this.reference = collapseWhiteSpaceToNull(options.reference ?? "")
        this.text = collapseWhiteSpaceToNull(options.text ?? "")
        this.comment = collapseWhiteSpaceToNull(options.comment ?? "")
        this.link = collapseWhiteSpaceToNull(options.link ?? "")

        // Rights

        this.role = options.role ?? CLIENT_USER_ROLE
        this.specialization = options.specialization ?? null
        this.canManageTransfers = options.canManageTransfers ?? false
        this.canManageInvites = options.canManageInvites ?? false
        this.canSendInvites = options.canSendInvites ?? false
        this.canManageUsers = options.canManageUsers ?? false
        this.canManageProviders = options.canManageProviders ?? false
        this.canSeeAllUsers = options.canSeeAllUsers ?? true
        this.visibleUserIds = map(options.visibleUserIds ?? [], tryNormalizeUuid)

        // Expiration

        this.expiresAt = tryCopyDate(options.expiresAt) ?? new Date(Date.now() + DAY_MILLIS)
    }

    // Status

    get status(): InviteStatus {
        const now = new Date()

        if (now >= this.expiresAt)
            return EXPIRED_INVITE_STATUS

        const millisLeft = this.expiresAt.getTime() - now.getTime()

        if (millisLeft <= InviteBase.EXPIRES_WHEN_LEFT_MILLIS)
            return EXPIRES_INVITE_STATUS

        return ACTIVE_INVITE_STATUS
    }

    // Copy

    protected override createCopy(options: DeepReadonly<Invite.CopyOptions> = {}): InviteBase {
        return new InviteBase({
            // Creator ID

            creatorId: "creatorId" in options
                ? options.creatorId
                : this.creatorId,

            // Provider ID

            providerId: "providerId" in options
                ? options.providerId
                : this.providerId,

            // Sending info

            messageTarget: options.messageTarget ?? this.messageTarget,

            sentAt: "sentAt" in options
                ? options.sentAt
                : this.sentAt,

            // Company

            enCompany: "enCompany" in options
                ? options.enCompany
                : options.enCompany,

            ruCompany: "ruCompany" in options
                ? options.ruCompany
                : options.ruCompany,

            // Name

            // - En

            enFirstname: "enFirstname" in options
                ? options.enFirstname
                : this.enFirstname,

            enLastname: "enLastname" in options
                ? options.enLastname
                : this.enLastname,

            enPatronymic: "enPatronymic" in options
                ? options.enPatronymic
                : this.enPatronymic,

            // - Ru

            ruFirstname: "ruFirstname" in options
                ? options.ruFirstname
                : this.ruFirstname,

            ruLastname: "ruLastname" in options
                ? options.ruLastname
                : this.ruLastname,

            ruPatronymic: "ruPatronymic" in options
                ? options.ruPatronymic
                : this.ruPatronymic,

            // Content

            reference: "reference" in options
                ? options.reference
                : this.reference,

            text: "text" in options
                ? options.text
                : this.text,

            comment: "comment" in options
                ? options.comment
                : this.comment,

            link: "link" in options
                ? options.link
                : this.link,

            // Rights

            role: options.role ?? this.role,

            specialization: "specialization" in options
                ? options.specialization
                : this.specialization,

            canManageTransfers: options.canManageTransfers ?? this.canManageTransfers,
            canManageInvites: options.canManageInvites ?? this.canManageInvites,
            canSendInvites: options.canSendInvites ?? this.canSendInvites,
            canManageUsers: options.canManageUsers ?? this.canManageUsers,
            canManageProviders: options.canManageProviders ?? this.canManageProviders,
            canSeeAllUsers: options.canSeeAllUsers ?? this.canSeeAllUsers,
            visibleUserIds: options.visibleUserIds ?? this.visibleUserIds,

            // Expiration

            expiresAt: options.expiresAt ?? this.expiresAt,

            // Basic

            id: options.id ?? this.id,
            createdAt: options.createdAt ?? this.createdAt,
            modifiedAt: options.modifiedAt ?? this.modifiedAt,
        })
    }
}

export class Invite extends mixin(InviteBase, [
    ExpirableWithCreationDateMixin,
    WithNullableCompanyNameMixin,
    WithOneMessageTargetMixin,
    NullableNamedMixin,
    WithUserRightsMixin,
]) {
    static createOrPass(arg: Invite | DeepReadonly<Invite.CreationOptions>): Invite {
        return arg instanceof Invite
            ? arg
            : new Invite(arg)
    }
}
