import { ForwardedRef, forwardRef, Fragment, ReactNode } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { envelopeIconUrl, trashCanIconUrl } from "images"
import { InviteApplication } from "model"
import { dateToDateString, DeepReadonly, map } from "my-util"
import * as  InviteCreationPage from "ui/page/sections/clients/invites/InviteCreationPage/path"
import { Button, Flex, MessageTarget } from "ui/ui"
import style from "./style.module.css"

export namespace InviteApplicationTable {
    export interface Props {
        onDelete?: (application: InviteApplication) => void

        applications: Iterable<InviteApplication>,

        loading?: boolean
        disabled?: boolean

        width?: string
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InviteApplicationTable = forwardRef((
    {
        onDelete,
        applications,
        loading, disabled,
        width,
    }: DeepReadonly<InviteApplicationTable.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const [t] = useTranslation()

    const navigate = useNavigate()

    // Render

    return <div className={style.InviteApplicationTable}
                style={{ width }}
                ref={ref}>
        {map(applications, renderApplication)}
    </div>

    function renderApplication(application: InviteApplication, index: number = 0): ReactNode {
        const BUTTON_SIZE = "16px"

        const baseGridRow = 3 * index + 1

        return <Fragment key={application.id}>
            {index > 0 &&
                <div style={{ gridRow: baseGridRow - 1 }}
                     className={style.gap}/>
            }

            <div style={{ gridRow: `${baseGridRow}/${baseGridRow + 2}` }}
                 className={style.background}/>

            <div style={{ gridRow: baseGridRow }}
                 className={style.date}>
                {dateToDateString(application.createdAt)}
            </div>

            <div style={{ gridRow: baseGridRow }}
                 className={style.company}>
                {application.anyCompany ?? t("domain.companies.messages.notSet")}
            </div>

            <div style={{ gridRow: baseGridRow }}
                 className={style.messageTarget}>
                <MessageTarget messageTarget={application.messageTarget}/>
            </div>

            <div style={{ gridRow: baseGridRow + 1 }}
                 className={style.name}>
                {application.anyName ?? t("domain.inviteApplications.messages.nameNotSet")}
            </div>

            <div style={{ gridRow: baseGridRow }}
                 className={style.buttons}>
                <Flex direction="row"
                      justify="end"
                      gap="8px">
                    <Button onClick={() => onCreateInvite(application)}

                            loading={loading}
                            disabled={disabled}

                            iconSrc={envelopeIconUrl}
                            iconAlt="Envelope icon"

                            buttonStyle="text"

                            fontSize="inherit"

                            width={BUTTON_SIZE}
                            height={BUTTON_SIZE}/>

                    {onDelete != null &&
                        <Button onClick={() => onDelete?.(application)}

                                iconSrc={trashCanIconUrl}
                                iconAlt="Trash can icon"

                                loading={loading}
                                disabled={disabled}

                                buttonStyle="text"

                                fontSize="inherit"

                                width={BUTTON_SIZE}
                                height={BUTTON_SIZE}

                                critical/>
                    }
                </Flex>
            </div>
        </Fragment>
    }

    // Events

    function onCreateInvite(application: InviteApplication) {
        const path = InviteCreationPage.createPath({
            messageTarget: application.messageTarget,

            company: {
                en: application.enCompany,
                ru: application.ruCompany,
            },

            name: {
                en: application.enName,
                ru: application.ruName,
            },
        })

        navigate(path)
    }
})

InviteApplicationTable.displayName = "InviteApplicationTable"
