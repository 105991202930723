import { removeWhiteSpace } from "my-util"
import { ViolationType } from "./ViolationType"

export const MIN_IBAN_LENGTH = 10
export const MAX_IBAN_LENGTH = 34

export function validateIban(iban: string): ViolationType | null {
    iban = removeWhiteSpace(iban)

    if (iban.length > MAX_IBAN_LENGTH)
        return "too-long"

    if (iban.length < MIN_IBAN_LENGTH)
        return "too-short"

    if (iban.match(/^\w+$/g) == null)
        return "illegal-char"

    return null
}
