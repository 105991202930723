import { z } from "zod"
import { NEW_USER_NOTIFICATION_TYPE } from "model"
import { UuidSchema } from "my-util"
import { NotificationResponseBaseSchema } from "./base"
import { NotificationTypeResponse } from "./type"

export const NewUserNotificationResponseSchema = NotificationResponseBaseSchema.extend({
    type: z.literal(NEW_USER_NOTIFICATION_TYPE satisfies NotificationTypeResponse),

    userId: UuidSchema.nullish(),
})

export type NewUserNotificationResponse = z.infer<typeof NewUserNotificationResponseSchema>
