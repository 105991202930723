import { ForwardedRef, ReactNode, useState, useEffect,
         useImperativeHandle, useLayoutEffect, useRef } from "react"

import { DeepReadonly, forwardRefAndSetProperties, isIterable, map } from "my-util"
import style from "./style.module.css"

export namespace Segue {
    export interface Props {
        children?: ReactNode
        shown?: number

        width?: string
        height?: string
        gap?: string
        transitionDuration?: number

        onSwipePrev?: () => void
        onSwipeNext?: () => void

        swipeablePrev?: boolean
        swipeableNext?: boolean

        swipeThreshold?: number
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Segue = forwardRefAndSetProperties(
    {
        DEFAULT_SWIPE_THRESHOLD: .5 as number,
        DEFAULT_INNER_GAP: "64px" as string,
        DEFAULT_TRANSITION_DURATION: 400 as number,
    } as const,

    (
        {
            children, shown,
            width, height, gap, transitionDuration,
            onSwipePrev, onSwipeNext,
            swipeablePrev, swipeableNext,
            swipeThreshold,
        }: DeepReadonly<Segue.Props>,
        ref: ForwardedRef<HTMLDivElement>,
    ) => {
        const innerGap = gap ?? Segue.DEFAULT_INNER_GAP
        const innerSwipeThreshold = swipeThreshold ?? Segue.DEFAULT_SWIPE_THRESHOLD
        const innerTransitionDuration = transitionDuration ?? Segue.DEFAULT_TRANSITION_DURATION

        // State

        const [innerShown, setInnerShown] = useState(0)
        const [childrenWidth, setChildrenWidth] = useState(0)

        // Refs

        // - Elements

        const componentRef = useRef(null as HTMLDivElement | null)
        const childrenRef = useRef(null as HTMLDivElement | null)

        useImperativeHandle(ref, () => componentRef.current!, [])

        // - Swipes

        const swipeTouchIdRef = useRef(0)
        const swipeTouchStartXRef = useRef(0)
        const swipeDistanceRef = useRef(0)

        // Effects

        // - Animation

        useLayoutEffect(() => {
            setInnerShown(shown ?? 0)

            const childrenElement = childrenRef.current

            if (childrenElement == null)
                return

            childrenElement.style.transition = `left ${innerTransitionDuration}ms`

            const timeout = setTimeout(
                () => childrenElement.style.transition = "",
                innerTransitionDuration,
            )

            return () => clearTimeout(timeout)
        }, [innerTransitionDuration, shown])

        // - Resize handling

        useLayoutEffect(() => {
            const component = componentRef.current

            if (component == null)
                return

            const observer = new ResizeObserver(handleResize)

            observer.observe(component)

            return () => observer.disconnect()

            function handleResize() {
                setChildrenWidth(component!.offsetWidth)
            }
        }, [childrenWidth, innerGap])

        // - Swipe handling

        useEffect(
            () => {
                const component = componentRef.current

                if (component == null || (!swipeablePrev && !swipeableNext))
                    return

                component.addEventListener("touchstart", handleTouchStart)
                component.addEventListener("touchmove", handleTouchMove)
                component.addEventListener("touchend", handleTouchEnd)

                return () => {
                    component.removeEventListener("touchstart", handleTouchStart)
                    component.removeEventListener("touchmove", handleTouchMove)
                    component.removeEventListener("touchend", handleTouchEnd)
                }

                function handleTouchStart(event: TouchEvent) {
                    const { touches } = event

                    const touch = touches.item(0)

                    if (touch == null)
                        return

                    swipeTouchIdRef.current = touch.identifier
                    swipeTouchStartXRef.current = touch.pageX
                    swipeDistanceRef.current = 0
                }

                function handleTouchMove(event: TouchEvent) {
                    for (let i = 0; i < event.touches.length; ++i) {
                        const touch = event.touches.item(i)!

                        if (touch.identifier !== swipeTouchIdRef.current)
                            continue

                        const newSwipeDistance = touch.pageX - swipeTouchStartXRef.current

                        swipeDistanceRef.current = newSwipeDistance

                        const childrenElement = childrenRef.current

                        if (childrenElement != null &&
                            ((swipeableNext && newSwipeDistance < 0) ||
                            (swipeablePrev && newSwipeDistance > 0)))
                            childrenElement.style.transform = `translateX(${newSwipeDistance}px)`
                    }
                }

                function handleTouchEnd() {
                    const childrenElement = childrenRef.current

                    if (childrenElement != null)
                        childrenElement.style.transform = ""

                    const swipeDistance = swipeDistanceRef.current

                    if (Math.abs(swipeDistance) < childrenWidth * innerSwipeThreshold)
                        return

                    if (swipeDistance < 0) {
                        if (swipeableNext)
                            onSwipeNext?.()
                    } else {
                        if (swipeablePrev)
                            onSwipePrev?.()
                    }
                }
            },

            [
                childrenWidth,
                onSwipeNext, onSwipePrev,
                swipeablePrev, swipeableNext,
                innerSwipeThreshold,
            ],
        )

        // Render

        return <div className={style.Segue}
                    style={{ width, height }}
                    ref={componentRef}>
            <div
                className={style.children}
                style={{
                    gap: innerGap,
                    left: `calc(-${innerShown} * (${innerGap} + ${childrenWidth}px))`,
                }}
                ref={childrenRef}
            >
                {renderChildren()}
            </div>
        </div>

        function renderChildren(): ReactNode {
            return isIterable(children)
                ? map(children, (child, i) =>
                    <div style={{ width: childrenWidth }}
                         key={i}>
                        {child}
                    </div>
                )

                : <div style={{ width: childrenWidth }}>
                    {children}
                </div>
        }
    },
)

Segue.displayName = "SegueView"
