import { useContext } from "react"
import { UserContext } from "ui/context"
import { SessionExpiredErrorPage } from "ui/page/error"
import { ClientMessengerPage } from "./ClientMessengerPage"
import { ManagerMessengerPage } from "./ManagerMessengerPage"

export function Component() {
    const [localUser] = useContext(UserContext)

    if (localUser == null)
        return <SessionExpiredErrorPage/>

    return localUser.isManager
         ? <ManagerMessengerPage/>
         : <ClientMessengerPage/>
}
