import { ForwardedRef, forwardRef } from "react"
import { getTransferGroupColors, getTransferGroupName, TransferGroup  } from "model"
import { ColorLegend } from "ui/ui/output"

export namespace TransferGroupColorLegend {
    export interface Props extends Omit<ColorLegend.Props, "onClick" | "color" | "legend"> {
        onClick?: (group: TransferGroup) => void
        group: TransferGroup

        showLegend?: boolean
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransferGroupColorLegend = forwardRef((
    props: Readonly<TransferGroupColorLegend.Props>,
    ref: ForwardedRef<HTMLSpanElement>,
) => {
    const {
        onClick, group,
        showLegend,
     } = props

    const innerOnClick = onClick != null
        ? () => onClick(group)
        : undefined

    const legend = showLegend
        ? getTransferGroupName(group)
        : undefined

    const { color } = getTransferGroupColors(group)

    return <ColorLegend { ...props }

                        onClick={innerOnClick}
                        legend={legend}
                        color={color}

                        ref={ref}/>
})

TransferGroupColorLegend.displayName = "TransferGroupColorLegend"
