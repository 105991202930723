export function clamp(value: number, min: number, max: number): number {
    [min, max] = normalizeBounds(min, max)

    if (value < min)
        return min

    if (value > max)
        return max

    return value
}

export function randomInt(min: number, max: number): number {
    [min, max] = normalizeBounds(min, max)

    return Math.floor(Math.random() * (max - min))
}

export function normalizeBounds(min: number, max: number): [number, number] {
    return min <= max
        ? [min, max]
        : [max, min]
}
