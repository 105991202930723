import { z } from "zod"
import { ModelObjectResponseSchema } from "../model-object"
import { WithCreatorIdResponseSchema } from "./with-creator-id"

export const DocumentResponseSchema = ModelObjectResponseSchema.extend({
    name: z.string(),
    mimeType: z.string(),
    size: z.number(),
})
    .merge(WithCreatorIdResponseSchema)

export type DocumentResponse = z.infer<typeof DocumentResponseSchema>
