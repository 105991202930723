import { ForwardedRef, forwardRef, useState } from "react"
import { DeepReadonly } from "my-util"
import { Flex } from "ui/ui/layout"
import { ErrorDisplay, ErrorText } from "ui/ui/output"
import { AgreementModal } from "../AgreementModal"

export namespace ActionModal {
    export interface Props extends Omit<AgreementModal.Props, "onYes"> {
        onYes?: () => (Promise<void> | void)

        closeOnSuccess?: boolean

        apiErrorMessageMapping?: ErrorText.ApiErrorMessageMapping
        doNotUseDefaultApiErrorMessageMapping?: boolean
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ActionModal = forwardRef((
    props: DeepReadonly<ActionModal.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const {
        onYes, onNo,
        closeOnSuccess,
        loading,
        children,
        apiErrorMessageMapping, doNotUseDefaultApiErrorMessageMapping,
    } = props

    // State

    const [performingAction, setPerformingAction] = useState(false)
    const [actionError, setActionError] = useState(undefined as unknown)

    // Render

    return <AgreementModal { ...props }
                           onYes={onInnerAction}
                           loading={loading || performingAction}
                           ref={ref}>
        <Flex>
            {children}

            <ErrorDisplay doNotUseDefaultApiErrorMessageMapping={doNotUseDefaultApiErrorMessageMapping}
                          apiErrorMessageMapping={apiErrorMessageMapping}
                          error={actionError}
                          centerType="flex"/>
        </Flex>
    </AgreementModal>

    // Events

    async function onInnerAction() {
        const result = onYes?.()

        if (!(result instanceof Promise))
            return

        setPerformingAction(true)

        try {
            await result

            setActionError(undefined)

            if (closeOnSuccess)
                onNo?.()
        } catch (error) {
            setActionError(error)
        } finally {
            setPerformingAction(false)
        }
    }
})

ActionModal.displayName = "ActionModal"
