import { useTranslation } from "react-i18next"
import { ErrorPage } from "../ErrorPage"

export namespace Error404Page {
    export interface Props {
        message?: string
    }
}

export function Error404Page ({ message }: Readonly<Error404Page.Props>) {
    const [t] = useTranslation()

    return <ErrorPage
        header={{
            text: t("errors.pages.404.header"),
            fontSize: "36px",
        }}

        message={{
            text: message ?? t("errors.pages.404.defaultMessage"),
            fontSize: "16px",
        }}
    />
}
