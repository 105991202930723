import { ForwardedRef, forwardRef, ReactNode } from "react"
import { DeepReadonly } from "my-util"
import style from "./style.module.css"

export namespace Hovering {
    export interface Props {
        children?: ReactNode

        borderRadius?: string

        width?: string
        height?: string
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Hovering = forwardRef((
    {
        children,
        borderRadius,
        width, height,
    }: DeepReadonly<Hovering.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) =>
    <div style={{ width, height, borderRadius }}
         className={style.Hovering}
         ref={ref}>
        {children}
    </div>
)

Hovering.displayName = "Hovering"
