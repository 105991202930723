import assert from "assert"
import { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { tickIconUrl } from "images"
import { completeRegistration, CompleteRegistrationRequest, StartRegistrationResponse } from "api"
import { User } from "model"

import { capitalizedText, EN_NAME_INPUT_REGEX,
         generateRandomPassword, makeNullableName, RU_NAME_INPUT_REGEX } from "my-util"

import { validateRuName, validateEnName } from "validation"

import { Button, CheckBox, ErrorText, Form, Pane,
         Icon, Flex, Limit, MessageTarget, DiLangInput } from "ui/ui"

export namespace CompletePane {
    export interface Props {
        onSuccess?: (user: User, password: string) => void

        messageTarget: string

        response?: StartRegistrationResponse | null
    }
}

export function CompletePane({ onSuccess, messageTarget, response }: Readonly<CompletePane.Props>) {
    const [t] = useTranslation()

    // State

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(undefined as unknown)

    const [name, setName] = useState(
        response != null
            ? {
                en: makeNullableName(response.enFirstname, response.enLastname, response.enPatronymic) ?? "",
                ru: makeNullableName(response.ruFirstname, response.ruLastname, response.ruPatronymic) ?? "",
            }

            : {
                en: "",
                ru: "",
            }
    )

    const nameInvalid = useMemo(
        () => (response?.canChangeName ?? true)
            ? {
                en: validateRuName(name.ru) != null,
                ru: validateEnName(name.en) != null,
            }

            : {
                en: false,
                ru: false,
            },

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [name],
    )

    const [touchedName, setTouchedName] = useState(false)

    const [acceptedRules, setAcceptedRules] = useState(false)
    const [acceptedPersonalDataProcessing, setAcceptedPersonalDataProcessing] = useState(false)

    // Render

    const submitDisabled =
        nameInvalid.en ||
        nameInvalid.ru ||

        !acceptedRules ||
        !acceptedPersonalDataProcessing

    return <Pane header={t("auth.reg.headers.completion")}>
        <Form onSubmit={onSubmit}>
            <Flex align="start">
                <Flex direction="row"
                      align="start">
                    <MessageTarget messageTarget={messageTarget}/>

                    <Icon src={tickIconUrl}
                          alt="Tick icon"
                          filter="invert(64%) sepia(96%) saturate(3701%) hue-rotate(88deg) brightness(113%) contrast(135%)"
                          width="16px"
                          height="16px"/>
                </Flex>

                <DiLangInput onChange={(en, ru) => setName({ en, ru })}
                             enValue={name.en}
                             ruValue={name.ru}

                             postProcess={capitalizedText}

                             placeholder={t("auth.reg.placeholders.name")}

                             loading={loading}

                             readonly={!(response?.canChangeName ?? true)}

                             onFocus={() => setTouchedName(true)}

                             enInvalid={touchedName && nameInvalid.en}
                             ruInvalid={touchedName && nameInvalid.ru}

                             ruRegex={RU_NAME_INPUT_REGEX}
                             enRegex={EN_NAME_INPUT_REGEX}

                             maxWidth="400px"/>

                <CheckBox label={t("auth.reg.labels.agreeWithRules")}
                          onChange={setAcceptedRules}
                          loading={loading}
                          checked={acceptedRules}/>

                <CheckBox label={t("auth.reg.labels.agreeWithPersonalDataProcessing")}
                          onChange={setAcceptedPersonalDataProcessing}
                          loading={loading}
                          checked={acceptedPersonalDataProcessing}/>

                <Limit maxWidth="300px">
                    <Button text={t("misc.actions.ok")}
                            disabled={submitDisabled}
                            loading={loading}
                            type="submit"/>
                </Limit>

                <ErrorText error={error}/>
            </Flex>
        </Form>
    </Pane>

    // Events

    async function onSubmit() {
        setLoading(true)

        try {
            const password = generateRandomPassword()

            assert(name.en != null && name.ru != null)

            const [enFirstname, enLastname, enPatronymic] = name.en.trim().split(/\s+/).filter(Boolean)
            const [ruFirstname, ruLastname, ruPatronymic] = name.ru.trim().split(/\s+/).filter(Boolean)

            const request: CompleteRegistrationRequest = {
                password,

                enFirstname,
                enLastname,
                enPatronymic,

                ruFirstname,
                ruLastname,
                ruPatronymic,
            }

            const user = await completeRegistration(request)

            onSuccess?.(user, password)
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }
}
