import { ForwardedRef, forwardRef } from "react"
import { getUserRoleAbbr, getUserRoleColor, UserRole } from "model"

export namespace UserRoleAbbr {
    export interface Props {
        role: UserRole

        colorful?: boolean
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserRoleAbbr = forwardRef((
    {
        role,
        colorful,
    }: Readonly<UserRoleAbbr.Props>,
    ref: ForwardedRef<HTMLSpanElement>,
) =>
    <span style={{ color: colorful ? getUserRoleColor(role) : undefined }}
          ref={ref}>
        {getUserRoleAbbr(role)}
    </span>
)

UserRoleAbbr.displayName = "UserRoleAbbr"
