import { ForwardedRef, forwardRef } from "react"
import { useTranslation } from "react-i18next"

import { UserSpecialization,
         LAWYER_USER_SPECIALIZATION,
         ACCOUNTANT_USER_SPECIALIZATION} from "model"

import { Select } from "ui/ui/input"

const NO_USER_SPECIALIZATION = "no"

export namespace UserSpecializationSelect {
    export interface Props {
        onSelect?: (specialization: UserSpecialization | null) => void
        selected?: UserSpecialization | null

        hideNo?: boolean
        hideLawyer?: boolean
        hideAccountant?: boolean

        loading?: boolean
        disabled?: boolean
        readonly?: boolean

        label?: string
        information?: string
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserSpecializationSelect = forwardRef((
    {
        onSelect, selected,
        hideNo, hideLawyer, hideAccountant,
        loading, disabled, readonly,
        label, information,
    }: Readonly<UserSpecializationSelect.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const [t] = useTranslation()

    return <Select
        onSelect={onInnerSelect}
        selected={selected ?? NO_USER_SPECIALIZATION}

        options={{
            [NO_USER_SPECIALIZATION]: hideNo
                ? undefined
                : t("misc.words.no"),

            [LAWYER_USER_SPECIALIZATION]: hideLawyer
                ? undefined
                : t("domain.users.specializations.lawyer"),

            [ACCOUNTANT_USER_SPECIALIZATION]: hideAccountant
                ? undefined
                : t("domain.users.specializations.accountant"),
        }}

        loading={loading}
        disabled={disabled}
        readonly={readonly}

        label={label}
        information={information}

        ref={ref}
    />

    function onInnerSelect(key: string) {
        switch (key) {
            case NO_USER_SPECIALIZATION:
                onSelect?.(null)
                break

            case LAWYER_USER_SPECIALIZATION:
            case ACCOUNTANT_USER_SPECIALIZATION:
                onSelect?.(key)
                break
        }
    }
})

UserSpecializationSelect.displayName = "UserSpecializationSelect"
