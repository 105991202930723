import { get, performMfaRequest } from "api/http/util"
import { LoginRequest } from "api/requests"
import { LoginConfigResponse, LoginConfigResponseSchema, MfaResponse } from "api/responses"
import { joinSubpaths, tryNormalizeUuid } from "my-util"
import { AUTH_SUBPATH } from "./subpath"

export const LOGIN_SUBPATH = joinSubpaths([AUTH_SUBPATH, "/login"])

// Login

export const USER_ID_STORAGE_KEY = "user-id"

export async function login(
    request: Readonly<LoginRequest>,
    signal?: AbortSignal | null,
): Promise<MfaResponse> {
    localStorage.setItem(USER_ID_STORAGE_KEY, tryNormalizeUuid(request.id))
    return performMfaRequest(LOGIN_SUBPATH, request, signal)
}

// Config

export async function getLoginConfig(signal?: AbortSignal | null): Promise<LoginConfigResponse> {
    return get({
        subpath: joinSubpaths([LOGIN_SUBPATH, "/config"]),
        schema: LoginConfigResponseSchema,
        signal,
    })
}
