import { ForwardedRef, forwardRef, ReactNode } from "react"
import { DeepReadonly } from "my-util"
import style from "./style.module.css"

export namespace FieldSet {
    export interface Props {
        children?: ReactNode
        legend?: string
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FieldSet = forwardRef((
    {
        children,
        legend,
    }: DeepReadonly<FieldSet.Props>,
    ref: ForwardedRef<HTMLFieldSetElement>,
) =>
    <fieldset className={style.FieldSet}
              ref={ref}>
        {legend &&
            <legend className={style.legend}>
                {legend}
            </legend>
        }

        {children}
    </fieldset>
)

FieldSet.displayName = "FieldSet"
