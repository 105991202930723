import { ComponentType, ForwardedRef, forwardRef, RefAttributes } from "react"
import { EXPORT_TRANSFER_DIRECTION, IMPORT_TRANSFER_DIRECTION, TransferDirection } from "model"
import { ExportIcon, ImportIcon } from "ui/ui/icon"

export namespace TransferDirectionIcon {
    export interface Props {
        onClick?: (direction: TransferDirection) => void
        direction: TransferDirection

        cursor?: string

        width?: string
        height?: string
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransferDirectionIcon = forwardRef((
    {
        onClick, direction,
        cursor,
        width, height,
    }: Readonly<TransferDirectionIcon.Props>,
    ref: ForwardedRef<HTMLImageElement>,
) => {
    let Component: ComponentType<ImportIcon.Props & ExportIcon.Props & RefAttributes<HTMLImageElement>>

    switch (direction) {
        case IMPORT_TRANSFER_DIRECTION:
            Component = ImportIcon
            break

        case EXPORT_TRANSFER_DIRECTION:
            Component = ExportIcon
            break

        default:
            return direction satisfies never
    }

    const onInnerClick = onClick != null
        ? () => onClick(direction)
        : undefined

    return <Component onClick={onInnerClick}

                      cursor={cursor}

                      width={width}
                      height={height}

                      ref={ref}/>
})

TransferDirectionIcon.displayName = "TransferDirectionIcon"
