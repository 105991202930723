import { ReactNode, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { clipIconUrl, sendIconUrl } from "images"
import { DeepReadonly, isNullOrBlank } from "my-util"
import { useDocumentPaste, useDragging, useStateWithDeps } from "ui/hook"

import { AddDocumentsButton, copyUiDocument, DropZone,
         DocumentListUpload, UiDocument, UploadingUiDocument } from "ui/component/document"

import { Button, Flex, Padding, TextArea } from "ui/ui"

const BUTTON_SIZE = "32px"

export namespace ChatInputs {
    export interface Props {
        onSubmit?: (messageText: string, documents: UiDocument[]) => void

        onMessageTextChange?: (messageText: string) => void
        messageText?: string
        maxMessageTextLength?: number

        onMessageDocumentsChange?: (messageDocuments: UiDocument[]) => void
        messageDocuments?: UiDocument[]

        information?: ReactNode
    }
}

export function ChatInputs(
    {
        onSubmit,
        onMessageTextChange, messageText, maxMessageTextLength,
        onMessageDocumentsChange, messageDocuments,
        information,
    }: DeepReadonly<ChatInputs.Props>,
) {
    const [t] = useTranslation()

    const dragging = useDragging()

    const lastPastedDocument = useDocumentPaste()

    // State

    const [innerMessageText, setInnerMessageText] = useStateWithDeps(
        () => messageText ?? "",
        [messageText],
    )

    const [innerMessageDocuments, setInnerMessageDocuments] = useStateWithDeps(
        () => messageDocuments?.map(copyUiDocument) ?? [] as UiDocument[],
        [messageDocuments],
    )

    // Effects

    useEffect(() => {
        if (lastPastedDocument != null)
            onDocumentPaste(lastPastedDocument)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastPastedDocument, setInnerMessageDocuments])

    // Render

    if (dragging)
        return <DropZone onDrop={onDocumentsDrop}
                         height="300px"
                         multiple/>

    const innerMessageDocumentsEmpty = innerMessageDocuments.length === 0

    const submitDisabled =
        (isNullOrBlank(innerMessageText) && innerMessageDocumentsEmpty) ||
        innerMessageDocuments.some(({ status }) => status === "uploading")

    return <Flex gap="8px">
        {Boolean(information) &&
            <Padding paddingLeft="40px"
                     paddingRight="40px">
                {information}
            </Padding>
        }

        <Flex direction="row"
              align="start"
              gap="8px">
            {innerMessageDocumentsEmpty &&
                <AddDocumentsButton onClick={onInnerMessageDocumentsChange}

                                    width={BUTTON_SIZE}
                                    height={BUTTON_SIZE}

                                    buttonStyle="text"

                                    iconSrc={clipIconUrl}
                                    iconAlt="Clip icon"

                                    multiple/>
            }

            <TextArea placeholder={t("misc.placeholders.message")}

                      onChange={onInnerMessageTextChange}
                      value={innerMessageText}
                      max={maxMessageTextLength}

                      resize="auto"
                      rows={1}
                      maxRows={10}

                      canSubmit/>

            <Button onClick={onInnerSubmit}

                    width={BUTTON_SIZE}
                    height={BUTTON_SIZE}

                    buttonStyle="text"
                    fontSize="18px"

                    type="submit"

                    disabled={submitDisabled}

                    iconSrc={sendIconUrl}
                    iconAlt="Arrow icon"/>
        </Flex>

        {!innerMessageDocumentsEmpty &&
            <DocumentListUpload onChange={onInnerMessageDocumentsChange}
                                documents={innerMessageDocuments}
                                height="300px"
                                multiple/>
        }
    </Flex>

    // Events

    function onInnerSubmit() {
        onSubmit?.(innerMessageText, innerMessageDocuments)

        setInnerMessageText("")
        setInnerMessageDocuments([])
    }

    function onInnerMessageTextChange(newInnerMessageText: string) {
        setInnerMessageText(newInnerMessageText)
        onMessageTextChange?.(newInnerMessageText)
    }

    function onInnerMessageDocumentsChange(newInnerMessageDocuments: UiDocument[]) {
        setInnerMessageDocuments(newInnerMessageDocuments)
        onMessageDocumentsChange?.(newInnerMessageDocuments)
    }

    function onDocumentPaste(pastedDocument: UploadingUiDocument) {
        setInnerMessageDocuments(oldInnerDocuments => [pastedDocument, ...oldInnerDocuments])
    }

    function onDocumentsDrop(droppedDocuments: UploadingUiDocument[]) {
        setInnerMessageDocuments(oldInnerDocuments => [...droppedDocuments, ...oldInnerDocuments])
    }
}
