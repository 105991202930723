import { Copyable, DeepReadonly, every, Immutable, IsImmutable } from "my-util"

import { RoutePointViolations } from "../RoutePointEditor"

export namespace RoutePointListViolations {
    export interface OptionsBase {
        deleteValid?: boolean
    }

    export interface CreationOptions extends OptionsBase {
        violationsByRoutePointId?:
            | Map<string, RoutePointViolations | RoutePointViolations.CreationOptions>
            | null
    }

    export interface CopyOptions extends OptionsBase {
        violationsByRoutePointId?:
            | Map<string, RoutePointViolations | RoutePointViolations.CopyOptions>
            | null
    }
}

export class RoutePointListViolations
    extends
        Copyable<RoutePointListViolations.CopyOptions>

    implements
        Immutable
{

    // Fields

    readonly [IsImmutable] = true

    readonly violationsByRoutePointId: ReadonlyMap<string, RoutePointViolations>

    private cachedIsValid: boolean | null = null

    // Constructor

    constructor(options: DeepReadonly<RoutePointListViolations.CreationOptions> = {}) {
        super()

        const newViolationsByRoutePointId = new Map<string, RoutePointViolations>()

        if (options.violationsByRoutePointId != null)
            for (const [id, violations] of options.violationsByRoutePointId)
                newViolationsByRoutePointId.set(id, RoutePointViolations.createOrPass(violations))

        if (options.deleteValid) {
            const idsToDelete: string[] = []

            for (const [id, violations] of newViolationsByRoutePointId.entries())
                if (violations.isValid)
                    idsToDelete.push(id)

            for (const id of idsToDelete)
                newViolationsByRoutePointId.delete(id)

            this.cachedIsValid = newViolationsByRoutePointId.size === 0
        }

        this.violationsByRoutePointId = newViolationsByRoutePointId
    }

    // Validity check

    get isValid(): boolean {
        if (this.cachedIsValid == null)
            this.cachedIsValid = every(
                this.violationsByRoutePointId.values(),
                violations => violations.isValid,
            )

        return this.cachedIsValid
    }

    // With

    withNew(routePointId: string, violations: RoutePointViolations): RoutePointListViolations {
        const newViolationsByProductId = new Map(this.violationsByRoutePointId)

        newViolationsByProductId.set(routePointId, violations)

        return this.copy({ violationsByRoutePointId: newViolationsByProductId })
    }

    withDeleted(routePointId: string): RoutePointListViolations {
        const newViolationsByProductId = new Map(this.violationsByRoutePointId)

        newViolationsByProductId.delete(routePointId)

        return this.copy({ violationsByRoutePointId: newViolationsByProductId })
    }

    withoutValid(): RoutePointListViolations {
        return this.copy({ deleteValid: true })
    }

    // Copy

    protected override createCopy(
        options: DeepReadonly<RoutePointListViolations.CopyOptions> = {}
    ): RoutePointListViolations {
        return new RoutePointListViolations({
            violationsByRoutePointId: options.violationsByRoutePointId ?? this.violationsByRoutePointId,
        })
    }
}
