import { isDigit } from "my-util"
import { ViolationType } from "./ViolationType"

export const OTP_LENGTH = 6

export function validateOtp(otp: string): ViolationType | null {
    otp = otp.trim()

    if (otp.length > OTP_LENGTH)
        return "too-long"

    if (otp.length < OTP_LENGTH)
        return "too-short"

    for (const char of otp)
        if (!isDigit(char))
            return "illegal-char"

    return null
}
