// Months

export const MONTH_COUNT = 12

export const JANUARY_INDEX = 0
export const FEBRUARY_INDEX = 1
export const MARCH_INDEX = 2
export const APRIL_INDEX = 3
export const MAY_INDEX = 4
export const JUNE_INDEX = 5
export const JULY_INDEX = 6
export const AUGUST_INDEX = 7
export const SEPTEMBER_INDEX = 8
export const OCTOBER_INDEX = 9
export const NOVEMBER_INDEX = 10
export const DECEMBER_INDEX = 11

export const MONTH_INDEXES = [
    JANUARY_INDEX,
    FEBRUARY_INDEX,
    MARCH_INDEX,
    APRIL_INDEX,
    MAY_INDEX,
    JUNE_INDEX,
    JULY_INDEX,
    AUGUST_INDEX,
    SEPTEMBER_INDEX,
    OCTOBER_INDEX,
    NOVEMBER_INDEX,
    DECEMBER_INDEX,
] as const

// Weeks

export const WEEK_DAYS = 7

export const MONDAY_INDEX = 0
export const TUESDAY_INDEX = 1
export const WEDNESDAY_INDEX = 2
export const THURSDAY_INDEX = 3
export const FRIDAY_INDEX = 4
export const SATURDAY_INDEX = 5
export const SUNDAY_INDEX = 6

export const WEEK_DAY_INDEXES = [
    MONDAY_INDEX,
    TUESDAY_INDEX,
    WEDNESDAY_INDEX,
    THURSDAY_INDEX,
    FRIDAY_INDEX,
    SATURDAY_INDEX,
    SUNDAY_INDEX,
] as const

// Days

export const YEAR_DAYS = 365

// Hours

export const DAY_HOURS = 24
export const WEEK_HOURS = WEEK_DAYS * DAY_HOURS
export const YEAR_HOURS = YEAR_DAYS * DAY_HOURS

export const HOURS: readonly number[] = new Array(DAY_HOURS)
    .fill(0)
    .map((_, i) => i + 1)

export const HOUR_STRINGS: readonly string[] = HOURS.map(hour => {
    let hourString = `${hour}:00`

    if (hourString.length === 4)
        hourString = "0" + hourString

    return hourString
})

// Minutes

export const HOUR_MINUTES = 60
export const DAY_MINUTES = DAY_HOURS * HOUR_MINUTES
export const WEEK_MINUTES = WEEK_DAYS * DAY_MINUTES
export const YEAR_MINUTES = YEAR_DAYS * DAY_MINUTES

// Seconds

export const MINUTE_SECONDS = 60
export const HOUR_SECONDS = HOUR_MINUTES * MINUTE_SECONDS
export const DAY_SECONDS = DAY_HOURS * HOUR_SECONDS
export const WEEK_SECONDS = WEEK_DAYS * DAY_SECONDS
export const YEAR_SECONDS = YEAR_DAYS * DAY_SECONDS

// Milliseconds

export const SECOND_MILLIS = 1_000
export const MINUTE_MILLIS = MINUTE_SECONDS * SECOND_MILLIS
export const HOUR_MILLIS = HOUR_MINUTES * MINUTE_MILLIS
export const DAY_MILLIS = DAY_HOURS * HOUR_MILLIS
export const WEEK_MILLIS = WEEK_DAYS * DAY_MILLIS
export const YEAR_MILLIS = YEAR_DAYS * DAY_MILLIS
