import assert from "assert"
import { ReactNode, useContext, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useSearchParams } from "react-router-dom"
import { DiLangString } from "i18n"
import { createInvite, getAllUsers, InviteRequest } from "api"

import { messageTargetToObject, UserRole, User,
         UserSpecialization, isUserSpecialization,
         determineNullableMessageTargetType, isUserRole,
         MessageTargetType, MANAGER_USER_ROLE, CLIENT_USER_ROLE } from "model"

import { generateRandomUuid, RU_NAME_INPUT_REGEX, tryNormalizeUuid,
         capitalizedText, Duration, EN_NAME_INPUT_REGEX, trimToNull } from "my-util"

import { validateEnName, validateRuName,
         MAX_SHORT_TEXT_LENGTH, validateMessageTarget } from "validation"

import { UserContext } from "ui/context"
import { useUsers, useWindowSize } from "ui/hook"
import { Error403Page, SessionExpiredErrorPage } from "ui/page/error"
import * as RegistrationPage from "ui/page/auth/RegistrationPage/path"
import { Page, UserCheckList } from "ui/component"

import { DurationInput, MessageTargetInput, FormControls,
         Pane, Input, Limit, TextArea, Form, Flex, UserSpecializationSelect,
         CheckBox, UserRoleSelect, LoadingIndicator, ErrorText, DiLangInput } from "ui/ui"

import { InviteHeader } from "../InviteHeader"
import * as InvitePage from "../InvitePage/path"
import * as path from "./path"
import style from "./style.module.css"

export function Component() {
    const [t] = useTranslation()

    const navigate = useNavigate()
    const [searchParams] = useSearchParams()

    const { width: windowWidth } = useWindowSize()
    const mobile = windowWidth < 800

    const [localUser] = useContext(UserContext)
    const storedUsers = useUsers()

    // State

    // - Ids

    const [id] = useState(generateRandomUuid())
    const providerId = useMemo(getProviderId, [searchParams])

    // - Message target

    const [messageTarget, setMessageTarget] = useState(getInitialMessageTarget() ?? undefined)
    const [touchedMessageTarget, setTouchedMessageTarget] = useState(false)

    const messageTargetInvalid =
        messageTarget == null ||
        validateMessageTarget(messageTarget) != null

    const [messageTargetType, setMessageTargetType] = useState(
        determineNullableMessageTargetType(messageTarget)
    )

    // - Name

    const [name, setName] = useState(getInitialName())
    const [touchedName, setTouchedName] = useState(false)

    const nameInvalid = useMemo(
        () => ({
            ru: validateRuName(name.ru, true) != null,
            en: validateEnName(name.en, true) != null,
        }),

        [name],
    )

    // - Content

    const [company, setCompany] = useState(getInitialCompany())
    const [reference, setReference] = useState(getInitialReference())
    const [text, setText] = useState(getInitialText())
    const [comment, setComment] = useState(getInitialComment())
    const [duration, setDuration] = useState(getInitialDuration())

    // - Rights

    const [role, setRole] = useState(getInitialRole())
    const [specialization, setSpecialization] = useState(getInitialSpecialization())
    const [canManageTransfers, setCanManageTransfers] = useState(true)
    const [canManageInvites, setCanManageInvites] = useState(true)
    const [canSendInvites, setCanSendInvites] = useState(true)
    const [canManageUsers, setCanManageUsers] = useState(true)
    const [canManageProviders, setCanManageProviders] = useState(true)
    const [canSeeAllUsers, setCanSeeAllUsers] = useState(true)
    const [visibleUserIds, setVisibleUserIds] = useState(new Array<string>())

    // - Users

    const [usersById, setUsersById] = useState(undefined as Map<string, User> | undefined)
    const [loadingUsers, setLoadingUsers] = useState(false)
    const [usersLoadingError, setUsersLoadingError] = useState(undefined as unknown)

    // - Sending status

    const [sending, setSending] = useState(false)
    const [sendingError, setSendingError] = useState(undefined as any)

    // Effects

    useEffect(() => {
        if (!loadingUsers)
            return

        const controller = new AbortController()

        getAllUsers(controller.signal)
            .then(users => {
                setUsersById(User.groupById(users))
                storedUsers.addAll(users)
            })
            .catch(error => {
                if (!controller.signal.aborted)
                    setUsersLoadingError(error)
            })
            .finally(() => {
                if (!controller.signal.aborted)
                    setLoadingUsers(false)
            })

        return () => controller.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingUsers])

    // Render

    if (localUser == null)
        return <SessionExpiredErrorPage/>

    if (!localUser.hasRightToSendInvites)
        return <Error403Page/>

    return <Page title={renderTitle()}
                 type="main">
        <Pane>
            <Form onSubmit={onSend}
                  loading={sending}
                  height="100%">
                <Flex justify="space-between"
                      align="start"
                      height="100%">
                    {renderFields()}
                    {renderControls()}
                </Flex>
            </Form>
        </Pane>
    </Page>

    function renderTitle(): ReactNode {
        return <InviteHeader text={t("sections.clients.invites.new.header").toUpperCase()}
                             inviteId={id}/>
    }

    function renderFields(): ReactNode {
        const MAX_WIDTH = "400px"

        return <Flex align="start">
            <Limit maxWidth={MAX_WIDTH}>
                <MessageTargetInput onChange={onMessageTargetChange}
                                    value={messageTarget}

                                    onFocus={() => setTouchedMessageTarget(true)}

                                    label={t("messageTargets.labels.messageTarget")}
                                    placeholder={t("messageTargets.placeholders.messageTarget")}

                                    invalid={touchedMessageTarget && messageTargetInvalid}
                                    loading={sending}
                                    required/>
            </Limit>

            <DiLangInput onChange={(en, ru) => setName({ en, ru })}
                         enValue={name.en}
                         ruValue={name.ru}

                         enInvalid={touchedName && nameInvalid.en}
                         ruInvalid={touchedName && nameInvalid.ru}

                         postProcess={capitalizedText}

                         enRegex={EN_NAME_INPUT_REGEX}
                         ruRegex={RU_NAME_INPUT_REGEX}

                         onFocus={() => setTouchedName(true)}

                         label={t("domain.invites.labels.name")}
                         placeholder={t("domain.invites.placeholders.name")}

                         loading={sending}

                         maxWidth={MAX_WIDTH}/>

            <DiLangInput onChange={(en, ru) => setCompany({ en, ru })}
                         enValue={company.en}
                         ruValue={company.ru}

                         label={t("domain.invites.labels.company")}
                         placeholder={t("domain.invites.placeholders.company")}

                         loading={sending}

                         max={MAX_SHORT_TEXT_LENGTH}

                         maxWidth={MAX_WIDTH}/>

            <Limit maxWidth={MAX_WIDTH}>
                <Flex align="start">
                    <Input onChange={setReference}
                           value={reference}

                           label={t("domain.invites.labels.reference")}
                           placeholder={t("domain.invites.placeholders.reference")}

                           loading={sending}

                           max={MAX_SHORT_TEXT_LENGTH}/>

                    <UserRoleSelect onSelect={setRole}
                                    selected={role}

                                    hideAgent={!localUser?.isAgent}
                                    hideManager={!localUser?.isManager}
                                    hideAdmin={!localUser?.isAdmin}

                                    label={t("domain.userRights.labels.role")}

                                    loading={sending}/>

                    {role === MANAGER_USER_ROLE && localUser?.isAdmin && <>
                        <UserSpecializationSelect onSelect={setSpecialization}
                                                  selected={specialization}

                                                  label={t("domain.userRights.labels.specialization")}

                                                  loading={sending}/>

                        <CheckBox onChange={setCanManageTransfers}
                                  checked={canManageTransfers}

                                  label={t("domain.userRights.labels.canManageTransfers")}

                                  loading={sending}/>

                        <CheckBox onChange={setCanManageInvites}
                                  checked={canManageInvites}

                                  label={t("domain.userRights.labels.canManageInvites")}

                                  loading={sending}/>

                        <CheckBox onChange={setCanSendInvites}
                                  checked={canSendInvites}

                                  label={t("domain.userRights.labels.canSendInvites")}

                                  loading={sending}/>

                        <CheckBox onChange={setCanManageUsers}
                                  checked={canManageUsers}

                                  label={t("domain.userRights.labels.canManageUsers")}

                                  loading={sending}/>

                        <CheckBox onChange={setCanManageProviders}
                                  checked={canManageProviders}

                                  label={t("domain.userRights.labels.canManageProviders")}

                                  loading={sending}/>

                        <CheckBox onChange={checked => onCanSeeAllUsersChange(!checked)}
                                  checked={!canSeeAllUsers}

                                  label={t("domain.userRights.labels.canNotSeeAllUsers")}

                                  loading={sending}/>

                        {!canSeeAllUsers &&
                            <Pane header={t("domain.userRights.labels.visibleUsers")}
                                  backgroundColor="white"
                                  paneStyle="widget"
                                  width="100%">
                                  {(() => {
                                    if (usersLoadingError != null)
                                        return <ErrorText error={usersLoadingError}/>

                                    if (usersById == null)
                                        return <LoadingIndicator/>

                                    return <div className={style.userList}>
                                        <UserCheckList onChange={onVisibleUsersChange}
                                                       checked={visibleUserIds}
                                                       linkTarget="_blank"
                                                       users={usersById}/>
                                    </div>
                                  })()}
                            </Pane>
                        }
                    </>}

                    <TextArea onChange={setText}
                              value={text}

                              label={t("domain.invites.labels.text")}
                              placeholder={t("domain.invites.placeholders.text")}
                              information={t("sections.clients.invites.new.messages.linkWillBeSent")}

                              loading={sending}

                              resize="vertical"

                              max={MAX_SHORT_TEXT_LENGTH}
                              showMax/>

                    <TextArea onChange={setComment}
                              value={comment}

                              label={t("domain.invites.labels.comment")}
                              placeholder={t("domain.invites.placeholders.comment")}

                              loading={sending}

                              resize="vertical"

                              max={MAX_SHORT_TEXT_LENGTH}
                              showMax/>

                    <DurationInput label={t("domain.invites.labels.duration")}
                                   onChange={setDuration}
                                   loading={sending}
                                   showSeconds={process.env.NODE_ENV === "development"}
                                   {...duration}/>
                </Flex>
            </Limit>
        </Flex>
    }

    function renderControls(): ReactNode {
        return <FormControls
            mobile={mobile}

            loading={sending}
            error={sendingError}

            buttons={[
                {
                    type: "submit",
                    position: "left",
                    disabled: messageTargetInvalid || nameInvalid.en || nameInvalid.ru,
                    text: t("domain.invites.actions.send"),
                },

                {
                    onClick() { navigate(-1) },
                    buttonStyle: "text",
                    position: "right",
                    text: t("misc.actions.back"),
                },
            ]}

            apiErrorMessageMapping={{
                409: t("messageTargets.messages.errors.occupied.messageTarget"),
            }}
        />
    }

    // Events

    function onMessageTargetChange(
        newMessageTarget: string,
        newMessageTargetType: MessageTargetType | null,
    ) {
        setMessageTarget(newMessageTarget)
        setMessageTargetType(newMessageTargetType)
    }

    function onCanSeeAllUsersChange(checked: boolean) {
        if (!checked && usersById == null)
            setLoadingUsers(true)

        setCanSeeAllUsers(checked)
    }

    function onVisibleUsersChange(users: User[]) {
        setVisibleUserIds(users.map(({ id }) => id))
    }

    async function onSend() {
        setSending(true)

        try {
            assert(
                messageTarget != null &&
                messageTargetType != null
            )

            const durationSeconds = Math.floor(duration.totalSeconds)
            const link = window.location.origin + RegistrationPage.createPath(id)

            const [enFirstname, enLastname, enPatronymic] = name.en.trim().split(/\s+/).filter(Boolean)
            const [ruFirstname, ruLastname, ruPatronymic] = name.ru.trim().split(/\s+/).filter(Boolean)

            const request: InviteRequest = {
                id,
                providerId,

                ...messageTargetToObject(messageTarget, messageTargetType),

                duration: durationSeconds,

                enCompany: company.en,
                ruCompany: company.ru,

                enFirstname, enLastname, enPatronymic,
                ruFirstname, ruLastname, ruPatronymic,

                reference, text, comment, link,

                role, specialization,
                canManageTransfers, canManageInvites, canSendInvites,
                canManageUsers, canManageProviders,
                canSeeAllUsers, visibleUserIds,
            }

            await createInvite(request)

            const newPath = InvitePage.createPath(id)

            navigate(newPath, { replace: true })
        } catch (error) {
            setSendingError(error)
        } finally {
            setSending(false)
        }
    }

    // Util

    function getInitialMessageTarget(): string | null {
        return trimToNull(
            decodeNullableUriComponent(
                searchParams.get(path.MESSAGE_TARGET_SEARCH_PARAM)
            )
        )
    }

    function getInitialName(): DiLangString {
        return getDiLangStringSearchParam(path.NAME_SEARCH_PARAM)
    }

    function getInitialCompany(): DiLangString {
        return getDiLangStringSearchParam(path.COMPANY_SEARCH_PARAM)
    }

    function getDiLangStringSearchParam(key: string): DiLangString {
        const param = searchParams.get(key)
        const decodedParam = decodeNullableUriComponent(param)
        const [en, ru] = decodedParam.split(",").concat("")

        return { en, ru }
    }

    function getInitialReference(): string {
        const param = searchParams.get(path.REFERENCE_SEARCH_PARAM)

        if (param == null)
            return `${t("misc.words.from")} ${localUser?.formalName ?? ""}`

        return decodeURIComponent(param).trim()
    }

    function getInitialRole(): UserRole {
        const param = searchParams.get(path.ROLE_SEARCH_PARAM)
            ?.trim()
            ?.toLowerCase()

        return param != null && isUserRole(param)
            ? param
            : CLIENT_USER_ROLE
    }

    function getInitialSpecialization(): UserSpecialization | null {
        const param = searchParams.get(path.SPECIALIZATION_SEARCH_PARAM)
            ?.trim()
            ?.toLowerCase()

        return param != null && isUserSpecialization(param)
            ? param
            : null
    }

    function getInitialText(): string {
        return decodeNullableUriComponent(searchParams.get(path.TEXT_SEARCH_PARAM))
    }

    function getInitialComment(): string {
        return decodeNullableUriComponent(searchParams.get(path.COMMENT_SEARCH_PARAM))
    }

    function getInitialDuration(): Duration {
        try {
            return Duration.parse(
                decodeNullableUriComponent(
                    searchParams.get(path.DURATION_SEARCH_PARAM)
                )
            )
        } catch {
            return new Duration({ days: 1 })
        }
    }

    function decodeNullableUriComponent(component?: string | null): string {
        return component != null
            ? decodeURIComponent(component)
            : ""
    }

    function getProviderId(): string | null {
        const param = searchParams.get(path.PROVIDER_ID_SEARCH_PARAM)

        if (param == null)
            return null

        const decodedParam = decodeURIComponent(param)
        const id = tryNormalizeUuid(decodedParam)

        return id
    }
}
