import { z } from "zod"
import { get, post } from "api/http/util"

import { EndpointStatusResponse, EndpointStatusResponseSchema,
         OtpCodeResponseSchema, OtpResponse, OtpResponseSchema } from "api/responses"

import { joinSubpaths } from "my-util"
import { AUTH_SUBPATH } from "./subpath"

export const MFA_SUBPATH = joinSubpaths([AUTH_SUBPATH, "/mfa"])

// OTP

export async function getMfaOtp(signal?: AbortSignal | null): Promise<string> {
    return (await get({
        subpath: joinSubpaths([MFA_SUBPATH, "/code"]),
        schema: OtpCodeResponseSchema,
        signal,
    })).code
}

export async function getMfaOtpEndpointStatus(signal?: AbortSignal | null): Promise<EndpointStatusResponse> {
    return get({
        subpath: joinSubpaths([MFA_SUBPATH, "/code/endpoint-status"]),
        schema: EndpointStatusResponseSchema,
        signal,
    })
}

// Resend

export async function resendMfaOtp(signal?: AbortSignal | null): Promise<OtpResponse> {
    return post({
        subpath: joinSubpaths([MFA_SUBPATH, "/resend"]),
        schema: OtpResponseSchema,
        signal,
    })
}

// Verify

export async function verifyMfaOtp(
    otp: string,
    schema?: z.ZodType<any, any, any> | null,
    signal?: AbortSignal | null,
): Promise<void> {
    return post({
        subpath: joinSubpaths([MFA_SUBPATH, "/verify"]),
        schema: schema,
        body: { code: otp },
        signal,
    })
}

// Cancel

export async function cancelMfa(signal?: AbortSignal | null): Promise<void> {
    return post({
        subpath: joinSubpaths([MFA_SUBPATH, "/cancel"]),
        signal,
    })
}
