import { Expirable, ExpirableFields } from "model/interfaces"
import { Constructor, DeepReadonly, ReadonlyDate } from "my-util"

export function ExpirableMixin<BaseConstructor extends Constructor<DeepReadonly<ExpirableFields>>>(
    Base: BaseConstructor,
) {
    return class extends Base implements DeepReadonly<Expirable> {
        get isExpired(): boolean {
            return this.isExpiredAt(new Date())
        }

        isExpiredAt(date: ReadonlyDate): boolean {
            return this.expiresAt.getTime() <= date.getTime()
        }
    }
}
