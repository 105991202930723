import { ForwardedRef, forwardRef, ReactNode, useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { plusIconUrl } from "images"
import { countInvites, countMyInvites, countMyNewUsers, countNewUsers } from "api"
import { useStateWithDeps } from "ui/hook"
import { UserContext } from "ui/context"
import * as AllInvitesPage from "ui/page/sections/clients/invites/AllInvitesPage/path"
import * as MainInvitesPage from "ui/page/sections/main/MainInvitesPage/path"
import { PATH } from "ui/page/sections/clients/invites/InviteCreationPage/path"
import { Button, Clickable, CountDataRow, ErrorDisplay, Flex, Loading } from "ui/ui"
import { Widget } from "../Widget"

export namespace InvitesWidget {
    export interface Props {
        showSentInviteCount?: boolean
        showNewUserCount?: boolean

        sentInviteCountType?: CountType
        newUserCountType?: CountType

        maxHeaderWidth?: string
        maxContentWidth?: string

        headerWidth?: string
        width?: string
        height?: string

        colorful?: boolean
    }

    export type CountType =
        | "all"
        | "my"
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InvitesWidget = forwardRef((
    {
        showSentInviteCount, showNewUserCount,
        sentInviteCountType, newUserCountType,
        maxHeaderWidth, maxContentWidth,
        headerWidth, width, height,
        colorful,
    }: Readonly<InvitesWidget.Props>,
    ref: ForwardedRef<Widget.Ref>,
) => {
    const [t] = useTranslation()

    const [localUser] = useContext(UserContext)

    const navigate = useNavigate()

    // State

    // - Sent invites

    const [sentInviteCount, setSentInviteCount] = useState(0)

    const [loadingSentInviteCount, setLoadingSentInviteCount] = useStateWithDeps(
        () => showSentInviteCount,
        [showSentInviteCount],
    )

    const [sentInviteCountLoadingError, setSentInviteCountLoadingError] = useState<unknown>(undefined)

    // - New users

    const [newUserCount, setNewUserCount] = useState(0)

    const [loadingNewUserCount, setLoadingNewUserCount] = useStateWithDeps(
        () => showNewUserCount,
        [showNewUserCount],
    )

    const [newUserCountLoadingError, setNewUserCountLoadingError] = useState<unknown>(undefined)

    // - Common

    const loading = loadingSentInviteCount || loadingNewUserCount
    const error = sentInviteCountLoadingError ?? newUserCountLoadingError

    // Effects

    // - Sent invite counting

    useEffect(
        () => {
            if (!loadingSentInviteCount)
                return

            const controller = new AbortController()

            const count = sentInviteCountType === "my"
                ? countMyInvites
                : countInvites

            count(controller.signal)
                .then(setSentInviteCount)
                .catch(error => {
                    if (!controller.signal.aborted)
                        setSentInviteCountLoadingError(error)
                })
                .finally(() => {
                    if (!controller.signal.aborted)
                        setLoadingSentInviteCount(false)
                })

            return () => controller.abort()
        },

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [loadingSentInviteCount],
    )

    // - New user counting

    useEffect(
        () => {
            if (!loadingNewUserCount)
                return

            const controller = new AbortController()

            const count = newUserCountType === "my"
                ? countMyNewUsers
                : countNewUsers

            count(controller.signal)
                .then(setNewUserCount)
                .catch(error => {
                    if (!controller.signal.aborted)
                        setNewUserCountLoadingError(error)
                })
                .finally(() => {
                    if (!controller.signal.aborted)
                        setLoadingNewUserCount(false)
                })

            return () => controller.abort()
        },

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [loadingNewUserCount],
    )

    // Render

    const [contentBackgroundColor, contentBorderColor] = colorful
        ? ["#EEFFE4", "#9DF99D"]
        : ["white", "#D5D5D5"]

    return <Widget onHeaderClick={navigateToMainInvitesPage}
                   header={t("widgets.invites.header") + ":"}
                   right={renderRight()}

                   headerBackgroundColor="#EEFFC1"
                   headerBorderColor="#9DF99D"

                   contentBackgroundColor={contentBackgroundColor}
                   contentBorderColor={contentBorderColor}

                   maxHeaderWidth={maxHeaderWidth}
                   maxContentWidth={maxContentWidth}

                   headerWidth={headerWidth}
                   height={height}
                   width={width}

                   ref={ref}>
        {renderContent()}
    </Widget>

    function renderRight(): ReactNode {
        if (!localUser?.hasRightToSendInvites)
            return null

        return <Button onClick={() => navigate(PATH)}
                       iconSrc={plusIconUrl}
                       iconAlt="Plus icon"
                       width="fit-content"/>
    }

    function renderContent(): ReactNode {
        if (loading)
            return <Loading centerType="flex"/>

        if (error != null)
            return <ErrorDisplay centerType="flex"
                                 error={error}/>

        return <Flex justify="space-between"
                     height="100%">
            <Flex>
                {showSentInviteCount &&
                    <Clickable onClick={navigateToAllInvitesPage}
                               cursor="pointer"
                               width="100%">
                        <CountDataRow label={t("widgets.invites.labels.sentInviteCount")}
                                      value={sentInviteCount}
                                      underline/>
                    </Clickable>
                }

                {showNewUserCount &&
                    <Clickable onClick={navigateToAllInvitesPage}
                               cursor="pointer"
                               width="100%">
                        <CountDataRow label={t("widgets.invites.labels.newUserCount")}
                                      value={newUserCount}
                                      importance="medium"
                                      underline/>
                    </Clickable>
                }
            </Flex>
        </Flex>
    }

    // Util

    function navigateToMainInvitesPage() {
        navigate(MainInvitesPage.PATH)
    }

    function navigateToAllInvitesPage() {
        navigate(AllInvitesPage.PATH)
    }
})

InvitesWidget.displayName = "InvitesWidget"
