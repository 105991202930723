import copyToClipboard from "copy-to-clipboard"
import { ForwardedRef, forwardRef, useImperativeHandle, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { SECOND_MILLIS } from "my-util"
import { Hovering } from "ui/ui/appearance"
import { Icon } from "ui/ui/icon"
import { Padding } from "ui/ui/layout"
import style from "./style.module.css"

// Consts

const WINDOW_PADDING = 8
const GAP = 8

export namespace Copy {
    export interface Props {
        text?: string
        copiedText?: string

        fontSize?: string

        value: string

        iconSrc?: string
        iconAlt?: string
        iconFilter?: string
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Copy = forwardRef((
    {
        text, copiedText,
        fontSize,
        value,
        iconSrc, iconAlt, iconFilter,
    }: Readonly<Copy.Props>,
    ref: ForwardedRef<HTMLSpanElement>,
) => {
    const [t] = useTranslation()

    const innerText = text ?? t("misc.actions.copy")
    const innerCopiedText = copiedText ?? t("misc.messages.copied")

    // State

    const [showNotification, setShowNotification] = useState(false)

    // Refs

    const copyRef = useRef(null as HTMLSpanElement | null)
    const notificationRef = useRef(null as HTMLSpanElement | null)

    useImperativeHandle(ref, () => copyRef.current!, [])

    // Render

    return <span className={style.Copy}
                 style={{ fontSize }}
                 onClick={onClick}
                 ref={copyRef}>
        <span className={style.iconAndTextContainer}>
            {iconSrc &&
                <Icon src={iconSrc}
                      alt={iconAlt}
                      filter={iconFilter}/>
            }

            {innerText &&
                <span className={style.text}>
                    {innerText}
                </span>
            }
        </span>

        <span style={{ opacity: showNotification ? "1" : "0" }}
              className={style.notification}
              ref={notificationRef}>
            <Hovering>
                <Padding padding="8px">
                    {innerCopiedText}
                </Padding>
            </Hovering>
        </span>
    </span>

    // Events

    function onClick() {
        setShowNotification(true)
        setTimeout(() => setShowNotification(false), SECOND_MILLIS)
        moveNotification()

        copyToClipboard(value)
    }

    // Util

    function moveNotification() {
        const copy = copyRef.current

        if (copy == null)
            return

        const notification = notificationRef.current

        if (notification == null)
            return

        const copyRect = copy.getBoundingClientRect()

        // X

        const notificationWidth = notification.offsetWidth

        let newX = copyRect.x + copyRect.width / 2 - notificationWidth / 2

        const windowWidth = document.documentElement.offsetWidth

        if (newX + notificationWidth > windowWidth - WINDOW_PADDING)
            newX = windowWidth - notificationWidth - WINDOW_PADDING

        if (newX < WINDOW_PADDING)
            newX = WINDOW_PADDING

        // Y

        let newY = copyRect.bottom + GAP

        const windowHeight = document.documentElement.offsetHeight

        if (newY > windowHeight - WINDOW_PADDING) {
            const notificationHeight = notification.offsetHeight

            newY = windowHeight - notificationHeight - WINDOW_PADDING
        }

        if (newY < WINDOW_PADDING)
            newY = WINDOW_PADDING

        // Update

        notification.style.left = `${newX}px`
        notification.style.top = `${newY}px`
    }
})

Copy.displayName = "Copy"
