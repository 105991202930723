import { useContext } from "react"
import { UserContext } from "ui/context"
import { Error403Page, SessionExpiredErrorPage } from "ui/page/error"
import { Page } from "ui/component"
import { CompactTitle } from "./CompactTitle"
import { Content } from "./Content"
import { Title } from "./Title"

export function Component() {
    const [localUser] = useContext(UserContext)

    if (localUser == null)
        return <SessionExpiredErrorPage/>

    if (!localUser.isAgent)
        return <Error403Page/>

    return <Page compactTitle={<CompactTitle/>}
                 title={<Title/>}

                 noCompactTitle={!localUser.hasRightToSendInvites}
                 autoMoveCompactTitle

                 type="main">
        <Content/>
    </Page>
}
