import { TransferStatus } from "model/domain"
import { DeepReadonly, Nullish, tryNormalizeNullableUuid } from "my-util"
import { AbstractNotification } from "./AbstractNotification"

import { NotificationType,
         TransferStatusChangedNotificationType,
         TRANSFER_STATUS_CHANGED_NOTIFICATION_TYPE } from "./NotificationType"

export namespace TransferStatusChangedNotification {
    export interface OptionsBase
        extends
            AbstractNotification.OptionsBase
    {
        transferId?: string | null
        from: TransferStatus
        to: TransferStatus
    }

    export interface CreationOptions
        extends
            Omit<AbstractNotification.CreationOptions<NotificationType>, "type">,
            OptionsBase
    {}

    export interface CopyOptions
        extends
            Omit<AbstractNotification.CopyOptions, "type">,
            Nullish<OptionsBase>
    {}
}

export class TransferStatusChangedNotification extends AbstractNotification<
    TransferStatusChangedNotificationType,
    TransferStatusChangedNotification.CopyOptions
> {
    static createOrPass(
        arg: TransferStatusChangedNotification
           | DeepReadonly<TransferStatusChangedNotification.CreationOptions>
    ): TransferStatusChangedNotification {
        return arg instanceof TransferStatusChangedNotification
            ? arg
            : new TransferStatusChangedNotification(arg)
    }

    // Fields

    readonly transferId: string | null
    readonly from: TransferStatus
    readonly to: TransferStatus

    // Constructor

    constructor(options: DeepReadonly<TransferStatusChangedNotification.CreationOptions>) {
        super({ ...options, type: TRANSFER_STATUS_CHANGED_NOTIFICATION_TYPE })

        this.transferId = tryNormalizeNullableUuid(options.transferId)
        this.from = options.from
        this.to = options.to
    }

    // Copy

    protected override createCopy(
        options: DeepReadonly<TransferStatusChangedNotification.CopyOptions> = {},
    ): TransferStatusChangedNotification {
        return new TransferStatusChangedNotification({
            // Recipient ID

            recipientId: "recipientId" in options
                ? options.recipientId
                : this.recipientId,

            // Content

            transferId: "transferId" in options
                ? options.transferId
                : this.transferId,

            from: options.from ?? this.from,
            to: options.to ?? this.to,

            // Basic

            id: options.id ?? this.id,
            createdAt: options.createdAt ?? this.createdAt,
            modifiedAt: options.modifiedAt ?? this.modifiedAt,
        })
    }
}
