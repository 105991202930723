import { AbstractModelObject } from "model/AbstractModelObject"
import { arrayOfNonNull, DeepReadonly, Nullish, tryNormalizeNullableUuid } from "my-util"
import type { NotificationType } from "./NotificationType"

export namespace AbstractNotification {
    export interface OptionsBase extends Nullish<{
        recipientId: string
    }> {}

    export interface CreationOptions<Type extends NotificationType>
        extends
            AbstractModelObject.CreationOptions,
            OptionsBase
    {
        type: Type
    }

    export interface CopyOptions
        extends
            AbstractModelObject.CopyOptions,
            OptionsBase
    {}
}

export abstract class AbstractNotification<
    Type extends NotificationType,
    CopyOptions extends AbstractNotification.CopyOptions,
> extends AbstractModelObject<CopyOptions> {
    // Fields

    readonly type: Type
    readonly recipientId: string | null

    // Constructor

    constructor(options: DeepReadonly<AbstractNotification.CreationOptions<Type>>) {
        super(options)

        this.type = options.type as Type
        this.recipientId = tryNormalizeNullableUuid(options.recipientId)
    }

    // User IDs

    get userIds(): string[] {
        return arrayOfNonNull(this.recipientId)
    }
}
