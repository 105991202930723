import { FormEvent, ForwardedRef, forwardRef, ReactNode } from "react"
import { DeepReadonly } from "my-util"
import style from "./style.module.css"

export namespace Form {
    export interface Props {
        onSubmit?: () => void
        onReset?: () => void

        loading?: boolean

        children?: ReactNode

        width?: string
        height?: string
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Form = forwardRef((
    {
        onSubmit, onReset,
        loading,
        children,
        width, height,
    }: DeepReadonly<Form.Props>,
    ref: ForwardedRef<HTMLFormElement>,
) => {
    return <form className={loading ? style.LoadingForm : style.Form}
                 onSubmit={onInnerSubmit}
                 onReset={onInnerReset}
                 style={{ width, height }}
                 ref={ref}>
        {children}
    </form>

    function onInnerSubmit(event: FormEvent) {
        event.preventDefault()
        onSubmit?.()
    }

    function onInnerReset(event: FormEvent) {
        event.preventDefault()
        onReset?.()
    }
})

Form.displayName = "Form"
