import { ForwardedRef, forwardRef, ReactNode, useMemo } from "react"
import { DeepReadonly, generateRandomUuid } from "my-util"
import { useStateWithDeps } from "ui/hook"
import { Icon } from "ui/ui/icon"
import { Flex } from "ui/ui/layout"
import { Label } from "ui/ui/output"
import style from "./style.module.css"

export namespace Radio {
    export interface Props {
        onChange?: (value: string) => void
        checked?: string

        readonly?: boolean
        loading?: boolean
        disabled?: boolean

        items?: Items
        name?: string

        hideButton?: boolean

        width?: string
    }

    export interface Items {
        [key: string]: ItemValue | undefined
    }

    export interface ItemValue {
        label?: string

        disabled?: boolean
        hidden?: boolean

        iconSrc?: string
        iconAlt?: string
        iconFilter?: string
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Radio = forwardRef((
    {
        onChange, checked,
        readonly, loading, disabled,
        items, name,
        hideButton,
        width
    }: DeepReadonly<Radio.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    // State

    const itemsEntries = items != null
        ? Object.entries(items)
        : []

    const [innerChecked, setInnerChecked] = useStateWithDeps(
        () => checked
            ?? (
                itemsEntries.length > 0
                    ? itemsEntries[0][0]
                    : undefined
            ),
        [checked],
    )

    const innerName = useMemo(() => name ?? generateRandomUuid(), [name])

    // Render

    const inputStyle = hideButton
        ? { display: "none" } as const
        : undefined

    const allDisabled =
        disabled ||
        readonly ||
        loading

    return <Flex direction="row"
                 align="center"
                 width={width}
                 gap="16px"
                 ref={ref}>
        {itemsEntries.map(renderItemEntry)}
    </Flex>

    function renderItemEntry(
        [itemKey, itemValue]: [string, Readonly<Radio.ItemValue> | undefined],
        itemIndex: number = 0,
    ): ReactNode {
        if (itemValue == null || itemValue.hidden)
            return null

        const {
            label,
            disabled,
            iconSrc, iconAlt, iconFilter,
        } = itemValue

        return <label className={renderLabelClassName()}
                      key={itemIndex}>
            <input className={style.htmlInput}
                   style={inputStyle}

                   onChange={() => onInnerChange(itemKey)}
                   checked={itemKey === innerChecked}

                   disabled={allDisabled || disabled}
                   type="radio"

                   name={innerName}/>

            <span className={style.input}
                  style={inputStyle}/>

            {iconSrc &&
                <Icon src={iconSrc}
                      alt={iconAlt}
                      filter={iconFilter ?? renderDefaultIconFilter()}

                      width="fit-content"/>
            }

            <Label text={label}/>
        </label>
    }

    function renderLabelClassName(): string {
        if (readonly)
            return style.readonlyLabel

        if (loading)
            return style.loadingLabel

        return style.label
    }

    function renderDefaultIconFilter(): string {
        return readonly || loading || disabled
            ? "brightness(0) invert(50%)"
            : "brightness(0) invert(6%)"
    }

    // Events

    function onInnerChange(itemKey: string) {
        setInnerChecked(itemKey)
        onChange?.(itemKey)
    }
})

Radio.displayName = "Radio"
