import { ReactNode, useContext, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { getAllUsersByIds, getAllTransfersByStatus, getAllMyTransfersByStatus } from "api"

import { Transfer, TransferDirection, User,
         EXPORT_TRANSFER_DIRECTION, IMPORT_TRANSFER_DIRECTION,
         TransferSortBy, DATE_TRANSFER_SORT_BY, getTransferSortByComparator } from "model"

import { DATE_COMPARATOR, findMin, findMax, Comparator,
         DateRange, createOrCopyDate, uniqueArray, ReadonlyDate } from "my-util"

import { useTransfers, useUsers } from "ui/hook"
import { UserContext } from "ui/context"
import { SessionExpiredErrorPage } from "ui/page/error"
import { DownloadTransfersReportLink, Page, TransferTable } from "ui/component"

import { DateRangeInput, Flex, ErrorDisplay, Padding, Label,
         Loading, TransferDirectionRadio, TransferSortBySelect, CheckBox } from "ui/ui"

import * as ArchivedTransferPage from "../ArchivedTransferPage/path"

export function Component() {
    const [t] = useTranslation()

    const [localUser] = useContext(UserContext)

    const storedUsers = useUsers()
    const storedTransfers = useTransfers()

    const navigate = useNavigate()

    // State

    // - Users

    const [creators, setCreators] = useState<User[]>([])
    const [loadingCreators, setLoadingCreators] = useState(false)

    const [agents, setAgents] = useState<User[]>([])
    const [loadingAgents, setLoadingAgents] = useState(false)

    const usersById = useMemo(
        () => User.groupById([...creators, ...agents]),
        [creators, agents],
    )

    // - Transfers

    const [transfers, setTransfers] = useState<Transfer[]>([])
    const [loadingTransfers, setLoadingTransfers] = useState(true)

    // -- Direction filtering

    const importTransfers = useMemo(
        () => transfers.filter(({ direction }) => direction === IMPORT_TRANSFER_DIRECTION),
        [transfers],
    )

    const exportTransfers = useMemo(
        () => transfers.filter(({ direction }) => direction === EXPORT_TRANSFER_DIRECTION),
        [transfers],
    )

    const [transferDirection, setTransferDirection] = useState<TransferDirection>(
        IMPORT_TRANSFER_DIRECTION,
    )


    const directionTransfers = useMemo(
        () => {
            switch (transferDirection) {
                case IMPORT_TRANSFER_DIRECTION:
                    return importTransfers

                case EXPORT_TRANSFER_DIRECTION:
                    return exportTransfers
            }
        },

        [importTransfers, exportTransfers, transferDirection],
    )

    // -- Moment filtering

    const [dateRange, setDateRange] = useState<DateRange | undefined>(undefined)

    const shownTransfers = useMemo(
        () => directionTransfers.filter(({ moment }) => {
            if (moment == null || dateRange == null)
                return true

            if (moment < dateRange.from)
                return false

            if (moment > dateRange.to)
                return false

            return true
        }),

        [directionTransfers, dateRange],
    )

    const shownTransferIds = useMemo(
        () => shownTransfers.map(({ id }) => id),
        [shownTransfers],
    )

    // -- Sorting

    const [sortTransfersBy, setSortTransferBy] = useState<TransferSortBy>(DATE_TRANSFER_SORT_BY)
    const [reverseTransferSorting, setReverseTransferSorting] = useState(false)

    const transferComparator = useMemo<Comparator<Transfer>>(
        () => getTransferSortByComparator(sortTransfersBy, {
            reverse: reverseTransferSorting,
            users: usersById,
        }),

        [reverseTransferSorting, sortTransfersBy, usersById],
    )

    // - Loading

    const [error, setError] = useState(undefined as any)

    const loading =
        loadingTransfers ||
        loadingCreators ||
        loadingAgents

    // Effects

    // - Transfers loading

    useEffect(() => {
        if (localUser == null || !loadingTransfers)
            return

        const controller = new AbortController()

        const promise = localUser.isManager
            ? getAllTransfersByStatus("done", controller.signal)
            : getAllMyTransfersByStatus("done", controller.signal)

        promise
            .then(transfers => {
                storedTransfers.addAll(transfers)

                setTransfers(transfers)
                setLoadingCreators(localUser.isManager)

                const moments = transfers
                    .map(({ moment }) => moment)
                    .filter(moment => moment != null) as ReadonlyDate[]

                setDateRange({
                    from: createOrCopyDate(findMin(moments, DATE_COMPARATOR)),
                    to: createOrCopyDate(findMax(moments, DATE_COMPARATOR)),
                })
            })
            .catch(error => {
                if (!controller.signal.aborted)
                    setError(error)
            })
            .finally(() => {
                if (!controller.signal.aborted)
                    setLoadingTransfers(false)
            })

        return () => controller.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // - Creators loading

    useEffect(() => {
        if (!loadingCreators)
            return

        const creatorIds = uniqueArray(
            transfers
                .map(({ creatorId }) => creatorId)
                .filter(id => id != null) as string[]
        )

        if (creatorIds.length === 0) {
            setLoadingCreators(false)
            return
        }

        const controller = new AbortController()

        getAllUsersByIds({ ids: creatorIds }, controller.signal)
            .then(creators => {
                storedUsers.addAll(creators)
                setCreators(creators)
                setLoadingAgents(true)
            })
            .catch(error => {
                if (!controller.signal.aborted)
                    setError(error)
            })
            .finally(() => {
                if (!controller.signal.aborted)
                    setLoadingCreators(false)
            })

        return () => controller.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingCreators])

    // - Agents loading

    useEffect(() => {
        if (!loadingAgents)
            return

        const agentIds = uniqueArray(
            creators
                .map(({ creatorId }) => creatorId)
                .filter(id => id != null) as string[]
        )

        if (agentIds.length === 0) {
            setLoadingAgents(false)
            return
        }

        const controller = new AbortController()

        getAllUsersByIds({ ids: agentIds }, controller.signal)
            .then(agents => {
                storedUsers.addAll(agents)
                setAgents(agents)
            })
            .catch(error => {
                if (!controller.signal.aborted)
                    setError(error)
            })
            .finally(() => {
                if (!controller.signal.aborted)
                    setLoadingAgents(false)
            })

        return () => controller.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingAgents])

    // Render

    if (localUser == null)
        return <SessionExpiredErrorPage/>

    return <Page title={renderTitle()}
                 type="main">
        {renderContent()}
    </Page>

    function renderTitle(): ReactNode {
        const transfersPresent = !loading && transfers.length > 0

        return <Flex justify="space-between"
                     direction="row"
                     gap="32px">
            <Flex direction="row"
                  width="fit-content"
                  gap="32px">
                {t("sections.archive.header").toUpperCase()}

                {transfersPresent && <>
                    <TransferDirectionRadio onChange={setTransferDirection}
                                            checked={transferDirection}/>

                    <DateRangeInput onChange={setDateRange}
                                    value={dateRange}/>

                    {localUser?.isManager &&
                        <Flex width="fit-content"
                              direction="row"
                              gap="8px">
                            <Label text={t("misc.labels.sortBy")}/>

                            <TransferSortBySelect onSelect={setSortTransferBy}
                                                  selected={sortTransfersBy}
                                                  width="min"/>

                            <CheckBox onChange={setReverseTransferSorting}
                                      checked={reverseTransferSorting}
                                      label={t("misc.labels.reverseSortingOrder")}/>
                        </Flex>
                    }
                </>}
            </Flex>

            {transfersPresent && localUser?.isManager &&
                <DownloadTransfersReportLink ids={shownTransferIds}
                                             whiteSpace="nowrap"
                                             fontSize="16px"/>
            }
        </Flex>
    }

    function renderContent(): ReactNode {
        if (loading)
            return <Loading/>

        if (error)
            return <ErrorDisplay error={error}/>

        if (transfers.length === 0)
            return <Padding paddingTop="22px">
                {localUser?.isManager
                    ? t("sections.archive.messages.no")
                    : t("sections.archive.messages.noYours")
                }
            </Padding>

        return <TransferTable onTransferClick={onTransferClick}
                              transfers={shownTransfers}
                              comparator={transferComparator}

                              showAgents={localUser?.isManager}
                              users={usersById}/>
    }

    // Events

    function onTransferClick(transfer: Transfer) {
        navigate(ArchivedTransferPage.createPath(transfer.id))
    }
}
