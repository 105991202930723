import { Component } from "./Component"

import { PathCreationOptions as LocalPathCreationOptions,
         createPath, MESSAGE_TARGET_SEARCH_PARAM,
         NAME_SEARCH_PARAM, COMPANY_SEARCH_PARAM,
         REFERENCE_SEARCH_PARAM, ROLE_SEARCH_PARAM,
         SPECIALIZATION_SEARCH_PARAM, TEXT_SEARCH_PARAM, PATH,
         COMMENT_SEARCH_PARAM, DURATION_SEARCH_PARAM, PROVIDER_ID_SEARCH_PARAM } from "./path"

export namespace InviteCreationPage {
    export interface PathCreationOptions extends LocalPathCreationOptions {}
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InviteCreationPage = Object.assign(Component, {
    createPath, MESSAGE_TARGET_SEARCH_PARAM,
    NAME_SEARCH_PARAM, COMPANY_SEARCH_PARAM,
    REFERENCE_SEARCH_PARAM, ROLE_SEARCH_PARAM,
    SPECIALIZATION_SEARCH_PARAM, TEXT_SEARCH_PARAM, PATH,
    COMMENT_SEARCH_PARAM, DURATION_SEARCH_PARAM, PROVIDER_ID_SEARCH_PARAM,
} as const)
