import { z } from "zod"

import { WAITING_PAYMENT_TRANSFER_STATUS,
         DONE_TRANSFER_STATUS, NEW_TRANSFER_STATUS,
         PAID_TRANSFER_STATUS, PAYMENT_EXPIRED_TRANSFER_STATUS,
         REJECTED_TRANSFER_STATUS, WAITING_MOMENT_TRANSFER_STATUS } from "model"

export const TransferStatusResponseSchema = z.enum([
    NEW_TRANSFER_STATUS,
    WAITING_MOMENT_TRANSFER_STATUS,
    WAITING_PAYMENT_TRANSFER_STATUS,
    PAID_TRANSFER_STATUS,
    DONE_TRANSFER_STATUS,
    PAYMENT_EXPIRED_TRANSFER_STATUS,
    REJECTED_TRANSFER_STATUS,
])

export type TransferStatusResponse = z.infer<typeof TransferStatusResponseSchema>
