import Decimal from "decimal.js"

export const ZERO_DECIMAL = new Decimal(0)

export function createOrPassDecimal(
    value?: Decimal.Value | null,
    defaultValue?: Decimal.Value | null,
): Decimal {
    if (value == null)
        return createOrPassDecimal(defaultValue, ZERO_DECIMAL)

    if (value instanceof Decimal)
        return value

    return new Decimal(value)
}

export function createOrPassNullableDecimal(value?: Decimal.Value | null): Decimal | null {
    if (value == null)
        return null

    if (value instanceof Decimal)
        return value

    return new Decimal(value)
}
