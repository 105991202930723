import { EN_NAME_INPUT_REGEX } from "my-util"
import { ViolationType } from "../ViolationType"
import { validateName } from "./name"

export const MIN_EN_NAME_PART_LENGTH = 1
export const MAX_EN_NAME_PART_LENGTH = 255

export function validateEnName(name: string, nullable: boolean = false): ViolationType | null {
    return validateName({
        name, nullable,
        regex: EN_NAME_INPUT_REGEX,
        minPartLength: MIN_EN_NAME_PART_LENGTH,
        maxPartLength: MAX_EN_NAME_PART_LENGTH,
    })
}
