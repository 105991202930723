import { ReactNode } from "react"
import { useTranslation } from "react-i18next"
import { BackToTheMainPageLink, ReloginLink } from "ui/component"
import { Center, Flex, Padding } from "ui/ui"
import style from "./style.module.css"
import { DeepReadonly } from "my-util"

export namespace ErrorPage {
    export interface Props {
        header?: Text
        message?: Text
        linkType?: LinkType
    }

    export interface Text {
        text?: string
        fontSize?: string
        fontWeight?: string
    }

    export type LinkType =
        | "main"
        | "relogin"
}

export function ErrorPage({ header, message, linkType }: DeepReadonly<ErrorPage.Props>) {
    const [t] = useTranslation()

    return <Center type="absolute-fill">
        <Padding padding="32px">
            <Flex>
                {renderHeader()}
                {renderMessage()}
                {renderLink()}
            </Flex>
        </Padding>
    </Center>

    function renderHeader(): ReactNode {
        const { text, fontSize, fontWeight } = header ?? {}

        return <h1 style={{ fontSize, fontWeight }}
                   className={style.header}>
            {text ?? t("errors.pages.defaultHeader")}
        </h1>
    }

    function renderMessage(): ReactNode {
        const { text, fontSize, fontWeight } = message ?? {}

        if (!text)
            return null

        return <p style={{ fontSize, fontWeight }}
                  className={style.message}>
            {text}
        </p>
    }

    function renderLink(): ReactNode {
        const effectiveLinkType = linkType ?? "main"

        switch (effectiveLinkType) {
            case "main":
                return <BackToTheMainPageLink/>

            case "relogin":
                return <ReloginLink/>

            default:
                effectiveLinkType satisfies never
        }
    }
}
