import { ForwardedRef, forwardRef, Fragment, ReactNode, useEffect, useRef } from "react"
import { plusIconUrl, trashCanIconUrl } from "images"
import { DeepReadonly, map, splicedArray, generateRandomUuid } from "my-util"
import { useStateWithDeps } from "ui/hook"
import { copySignableDocumentFields, SignableDocumentFields } from "ui/fields"
import { Button, Flex } from "ui/ui"
import { SignableDocumentEditor } from "../SignableDocumentEditor"

export namespace SignableDocumentListEditor {
    export interface Props {
        onChange?: (values: SignableDocumentFields[]) => void
        values?: Iterable<DeepReadonly<SignableDocumentFields>>

        noDocumentDelete?: boolean

        allowEmpty?: boolean

        loading?: boolean
        disabled?: boolean
        output?: boolean
        readonly?: boolean
        readonlyIfLocked?: boolean
        readonlyCount?: boolean
        readonlyInitial?: boolean
        readonlySigned?: boolean
        readonlyLocked?: boolean

        width?: string
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SignableDocumentListEditor = forwardRef((
    {
        onChange, values,
        noDocumentDelete,
        allowEmpty,
        loading, disabled, output,
        readonly, readonlyIfLocked, readonlyCount, readonlyInitial, readonlySigned, readonlyLocked,
        width,
    }: DeepReadonly<SignableDocumentListEditor.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    // Refs

    const changedRef = useRef(true)

    // State

    const [innerValues, setInnerValues] = useStateWithDeps<SignableDocumentFields[]>(
        oldValues => {
            if (changedRef.current && oldValues != null)
                return oldValues

            const newValues = map(values ?? [], copySignableDocumentFields)

            if (!output && !allowEmpty && newValues.length === 0)
                newValues.push(createEmptySignableDocument())

            return newValues
        },

        [values, output, allowEmpty],
    )

    // Effects

    useEffect(() => {
        if (changedRef.current) {
            onChange?.(innerValues.map(copySignableDocumentFields))
            changedRef.current = false
        }
    }, [onChange, innerValues])

    // Render

    return <Flex align="start"
                 width={width}
                 ref={ref}>
        {renderContent()}
    </Flex>

    function renderContent(): ReactNode {
        if (innerValues.length === 0)
            return renderAddButton()

        if (output || readonly || readonlyCount)
            return renderAllDocuments()

        return renderAllDocumentsWithEditingButtons()
    }

    // - Documents

    function renderAllDocuments(): ReactNode {
        return innerValues.map((document, index) =>
            <Fragment key={document.id ?? index}>
                {renderDocument(document, index)}
            </Fragment>
        )
    }

    function renderAllDocumentsWithEditingButtons(): ReactNode {
        return innerValues.map((document, index) =>
            <Flex key={document.id ?? index}
                  direction="row"
                  align="stretch">
                {renderDocument(document, index)}

                <Flex justify="space-between"
                      width="fit-content">
                    {renderDeleteButton(index)}

                    {index === innerValues.length - 1 &&
                        renderAddButton()
                    }
                </Flex>
            </Flex>
        )
    }

    function renderDocument(value: SignableDocumentFields, index: number = -1): ReactNode {
        const readonlyByLocked = readonlyIfLocked && value.locked

        return <SignableDocumentEditor onChange={newValue => onValueChange(newValue, index)}
                                       value={value}

                                       noDocumentDelete={noDocumentDelete}

                                       loading={loading}
                                       disabled={disabled}
                                       output={output}
                                       readonly={readonly || readonlyByLocked}
                                       readonlyInitial={readonlyInitial || readonlyByLocked}
                                       readonlySigned={readonlySigned || readonlyByLocked}
                                       readonlyLocked={readonlyLocked || readonlyByLocked}/>
    }

    // - Buttons

    function renderAddButton(): ReactNode {
        return <Button onClick={onAdd}

                       iconSrc={plusIconUrl}
                       iconAlt="Plus icon"

                       width="32px"/>
    }

    function renderDeleteButton(documentIndex: number = -1): ReactNode {
        return <Button onClick={() => onDelete(documentIndex)}

                       iconSrc={trashCanIconUrl}
                       iconAlt="Trash can icon"

                       buttonStyle="outline"
                       width="32px"

                       critical/>
    }

    // Events

    function onAdd() {
        setInnerValues(oldValues => {
            changedRef.current = true
            return [...oldValues, createEmptySignableDocument()]
        })
    }

    function onDelete(index: number) {
        if (index < 0)
            return

        setInnerValues(oldValues => {
            const newValues = splicedArray(oldValues, index, 1)

            if (newValues.length === oldValues.length)
                return oldValues

            changedRef.current = true

            return newValues
        })
    }

    function onValueChange(newValue: SignableDocumentFields, index: number) {
        if (index < 0)
            return

        setInnerValues(oldValues => {
            changedRef.current = true
            return splicedArray(oldValues, index, 1, newValue)
        })
    }

    // Util

    function createEmptySignableDocument(): SignableDocumentFields {
        return {
            id: generateRandomUuid(),
            initial: null,
            signed: null,
            locked: false,
        }
    }
})

SignableDocumentListEditor.displayName = "SignableDocumentListEditor"
