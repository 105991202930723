import Decimal from "decimal.js"
import { AbstractModelObject } from "model/AbstractModelObject"
import { createOrPassDecimal, DeepReadonly, Nullish, tryNormalizeNullableUuid } from "my-util"

export namespace TransferProvider {
    export interface OptionsBase extends Nullish<{
        userId: string
        providerId: string

        percent: Decimal.Value
    }> {}

    export interface CreationOptions
        extends
            AbstractModelObject.CreationOptions,
            OptionsBase
    {}

    export interface CopyOptions
        extends
            AbstractModelObject.CopyOptions,
            OptionsBase
    {}
}

export class TransferProvider extends AbstractModelObject<TransferProvider.CopyOptions> {
    static createOrPass(
        arg?: TransferProvider
            | DeepReadonly<TransferProvider.CreationOptions>
            | null,
    ): TransferProvider {
        return arg instanceof TransferProvider
            ? arg
            : new TransferProvider(arg ?? {})
    }

    // Fields

    // - Targets

    readonly userId: string | null
    readonly providerId: string | null

    // - Percent

    readonly percent: Decimal

    // Constructor

    constructor(options: DeepReadonly<TransferProvider.CreationOptions> = {}) {
        super(options)

        // Targets

        this.userId = tryNormalizeNullableUuid(options.userId)
        this.providerId = tryNormalizeNullableUuid(options.providerId)

        // Percent

        this.percent = createOrPassDecimal(options.percent)
    }

    // Copy

    protected override createCopy(
        options: DeepReadonly<TransferProvider.CopyOptions> = {},
    ): TransferProvider {
        return new TransferProvider({
            // Targets

            userId: "userId" in options
                ? options.userId
                : this.userId,

            providerId: "providerId" in options
                ? options.providerId
                : this.providerId,

            // Percent

            percent: options.percent ?? this.percent,

            // Basic

            id: options.id ?? this.id,
            createdAt: options.createdAt ?? this.createdAt,
            modifiedAt: options.modifiedAt ?? this.modifiedAt,
        })
    }
}
