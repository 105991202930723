import { ForwardedRef, forwardRef, ReactNode } from "react"
import { useTranslation } from "react-i18next"
import { DeepReadonly } from "my-util"
import { Modal } from "../Modal"

export namespace NotificationModal {
    export interface Props {
        onClose?: () => void

        header?: string
        opacity?: number
        children?: ReactNode
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NotificationModal = forwardRef((
    {
        onClose,
        header,
        opacity,
        children,
    }: DeepReadonly<NotificationModal.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const [t] = useTranslation()

    return <Modal
        header={header}
        onClose={onClose}
        opacity={opacity}
        buttons={[{
            onClick: onClose,
            text: t("misc.actions.ok"),
        }]}
        ref={ref}
    >
        {children}
    </Modal>
})

NotificationModal.displayName = "NotificationModal"
