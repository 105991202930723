import formatDuration from "format-duration"
import { DeepReadonly, SECOND_MILLIS } from "my-util"
import { ForwardedRef, forwardRef, useEffect, useState } from "react"

export namespace Timer {
    export interface Props {
        onExpired?: () => void
        expiresAt: Date
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Timer = forwardRef((
    { onExpired, expiresAt }: DeepReadonly<Timer.Props>,
    ref: ForwardedRef<HTMLSpanElement>,
) => {
    // State

    const expirationMilli = expiresAt.getTime()
    const [currentMilli, setCurrentMilli] = useState(Date.now())
    const millisLeft = Math.max(0, expirationMilli - currentMilli)

    // Timer

    useEffect(() => {
        const intervalId = setInterval(
            handleTick,
            SECOND_MILLIS,
            currentMilli % SECOND_MILLIS,
        )

        handleTick()

        return () => clearTimeout(intervalId)

        function handleTick() {
            const newCurrentMilli = Date.now()

            setCurrentMilli(newCurrentMilli)

            if (newCurrentMilli >= expirationMilli) {
                clearInterval(intervalId)
                onExpired?.()
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expiresAt])

    // Render

    return <span ref={ref}>
        {formatDuration(millisLeft)}
    </span>
})

Timer.displayName = "Timer"
