import { ForwardedRef, ReactNode } from "react"
import { DeepReadonly, forwardRefAndSetProperties, satAs } from "my-util"
import style from "./style.module.css"

export namespace Dim {
    export interface Props {
        onClick?: () => void

        children?: ReactNode

        opacity?: number
        type?: Type
    }

    export type Type =
        | "fixed"
        | "absolute"
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Dim = forwardRefAndSetProperties(
    {
        DEFAULT_OPACITY: .5 as number,
        DEFAULT_TYPE: satAs<Dim.Type>("absolute"),
    } as const,

    (
        {
            onClick,
            children,
            opacity, type,
        }: DeepReadonly<Dim.Props>,
        ref: ForwardedRef<HTMLDivElement>,
    ) => {
        const innerOpacity = opacity ?? Dim.DEFAULT_OPACITY
        const innerType = type ?? Dim.DEFAULT_TYPE

        const className = innerType === "fixed" ? style.FixedDim : style.AbsoluteDim
        const backgroundColor = `rgba(0, 0, 0, ${innerOpacity})`

        return <div style={{ backgroundColor }}
                    className={className}
                    onClick={onClick}
                    ref={ref}>
            {children}
        </div>
    },
)

Dim.displayName = "Dim"
