import { ForwardedRef, forwardRef, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import style from "./style.module.css"

export namespace CapsLockDetector {
    export interface Props {
        onChange?: (active: boolean) => void
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CapsLockDetector = forwardRef((
    { onChange }: Readonly<CapsLockDetector.Props>,
    ref: ForwardedRef<HTMLSpanElement>,
) => {
    const [t] = useTranslation()

    // State

    const [active, setActive] = useState(false)

    // Effects

    // - State notification

    useEffect(() => onChange?.(active), [active, onChange])

    // - Event listener registration

    useEffect(() => {
        document.addEventListener("keyup", handler)

        return () => document.removeEventListener("keydown", handler)

        function handler(event: KeyboardEvent) {
            const newActive = event.getModifierState && event.getModifierState("CapsLock")

            setActive(newActive)
        }
    }, [])

    // Render

    return <span style={{ display: active ? undefined : "none" }}
                 className={style.CapsLockDetector}
                 ref={ref}>
        {active &&
            t("misc.messages.warnings.capsLock")
        }
    </span>
})

CapsLockDetector.displayName = "CapsLockDetector"
