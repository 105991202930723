import { ForwardedRef, forwardRef } from "react"
import { useTranslation } from "react-i18next"

import { UserRole,
         ADMIN_USER_ROLE, AGENT_USER_ROLE,
         CLIENT_USER_ROLE, MANAGER_USER_ROLE } from "model"

import { Select } from "ui/ui/input"

export namespace UserRoleSelect {
    export interface Props {
        onSelect?: (role: UserRole) => void
        selected?: UserRole

        hideAdmin?: boolean
        hideManager?: boolean
        hideAgent?: boolean
        hideClient?: boolean

        loading?: boolean
        disabled?: boolean
        readonly?: boolean

        label?: string
        information?: string
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserRoleSelect = forwardRef((
    {
        onSelect, selected,
        hideAdmin, hideManager, hideAgent, hideClient,
        loading, disabled, readonly,
        label, information,
    }: Readonly<UserRoleSelect.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const [t] = useTranslation()

    return <Select
        onSelect={onSelect as (key: string) => void}
        selected={selected}

        options={{
            [CLIENT_USER_ROLE]: hideClient
                ? undefined
                : t("domain.users.roles.client"),

            [AGENT_USER_ROLE]: hideAgent
                ? undefined
                : t("domain.users.roles.agent"),

            [MANAGER_USER_ROLE]: hideManager
                ? undefined
                : t("domain.users.roles.manager"),

            [ADMIN_USER_ROLE]: hideAdmin
                ? undefined
                : t("domain.users.roles.admin"),
        }}

        loading={loading}
        disabled={disabled}
        readonly={readonly}

        label={label}
        information={information}

        ref={ref}
    />
})

UserRoleSelect.displayName = "UserRoleSelect"
