import assert from "assert"
import EventEmitter from "events"
import { getDocumentById, uploadDocuments } from "api"
import { Document } from "model"
import { count, DeepNullish, DeepReadonly, generateRandomUuid, tryNormalizeUuid } from "my-util"
import { DeletedError, NotFoundByIdError } from "../errors"

import { DocumentStatus,
         copyDocumentStatus,
         isSyntheticDocumentStatusType,
         isPhysicalDocumentStatusType,

         PhysicalDocumentStatus,
         PhysicalDocumentStatusType,

         READY_DOCUMENT_STATUS_TYPE,
         LOADING_DOCUMENT_STATUS_TYPE,
         DELETED_DOCUMENT_STATUS_TYPE,
         ABORTED_DOCUMENT_STATUS_TYPE,
         UPLOADING_DOCUMENT_STATUS_TYPE,
         LOADING_FAILED_DOCUMENT_STATUS_TYPE,
         UPLOADING_FAILED_DOCUMENT_STATUS_TYPE } from "./DocumentStatus"

export namespace DocumentManager {
    export interface CreationOptions {
        api: Api
    }

    export interface Api {
        getById: GetById,
        upload: Upload,
    }

    export type GetById = typeof getDocumentById
    export type Upload = typeof uploadDocuments

    export interface AllErrorsByIdOptions {
        omitLoading?: boolean
        omitUploading?: boolean
    }
}

type Abort = () => void

export class DocumentManager extends EventEmitter<{
    "clear": [],

    "status-change": [status: DocumentStatus, oldId: string],

    [READY_DOCUMENT_STATUS_TYPE]: [document: Document, oldId: string],

    [LOADING_DOCUMENT_STATUS_TYPE]: [id: string],
    [LOADING_FAILED_DOCUMENT_STATUS_TYPE]: [id: string, error: unknown],

    [UPLOADING_DOCUMENT_STATUS_TYPE]: [id: string, loaded: number, total: number],
    [UPLOADING_FAILED_DOCUMENT_STATUS_TYPE]: [id: string, error: unknown],

    [DELETED_DOCUMENT_STATUS_TYPE]: [id: string],
    [ABORTED_DOCUMENT_STATUS_TYPE]: [id: string],
}> {
    // Fields

    private statusesById = new Map<string, PhysicalDocumentStatus>()
    private abortsById = new Map<string, Abort>()

    private api: DocumentManager.Api

    // Constructor

    constructor({ api }: DeepReadonly<DeepNullish<DocumentManager.CreationOptions>> = {}) {
        super()

        this.api = {
            getById: api?.getById ?? getDocumentById,
            upload: api?.upload ?? uploadDocuments,
        }
    }

    // Count

    count(): number {
        return this.statusesById.size
    }

    countReady(): number {
        return this.countByStatusType(READY_DOCUMENT_STATUS_TYPE)
    }

    countLoading(): number {
        return this.countByStatusType(LOADING_DOCUMENT_STATUS_TYPE)
    }

    countLoadingFailed(): number {
        return this.countByStatusType(LOADING_FAILED_DOCUMENT_STATUS_TYPE)
    }

    countUploading(): number {
        return this.countByStatusType(UPLOADING_DOCUMENT_STATUS_TYPE)
    }

    countUploadingFailed(): number {
        return this.countByStatusType(UPLOADING_FAILED_DOCUMENT_STATUS_TYPE)
    }

    countByStatusType(targetType: PhysicalDocumentStatusType): number {
        return count(this.statusesById.values(), ({ type }) => type === targetType)
    }

    countFailed(): number {
        return count(
            this.statusesById.values(),

            ({ type }) =>
                type === LOADING_FAILED_DOCUMENT_STATUS_TYPE ||
                type === UPLOADING_FAILED_DOCUMENT_STATUS_TYPE,
        )
    }

    // Iterators

    ids(): Iterable<string> {
        return this.statusesById.keys()
    }

    *statuses(): Iterable<PhysicalDocumentStatus> {
        for (const status of this.statusesById.values())
            yield copyDocumentStatus(status)
    }

    *entries(): Iterable<[string, PhysicalDocumentStatus]> {
        for (const [id, status] of this.statusesById.entries())
            yield [id, copyDocumentStatus(status)]
    }

    *all(): Iterable<Document> {
        for (const status of this.statusesById.values())
            if (status.type === READY_DOCUMENT_STATUS_TYPE)
                yield status.document
    }

    allReadyIds(): Iterable<string> {
        return this.allIdsWithStatusType(READY_DOCUMENT_STATUS_TYPE)
    }

    allLoadingIds(): Iterable<string> {
        return this.allIdsWithStatusType(LOADING_DOCUMENT_STATUS_TYPE)
    }

    allLoadingFailedIds(): Iterable<string> {
        return this.allIdsWithStatusType(LOADING_FAILED_DOCUMENT_STATUS_TYPE)
    }

    allUploadingIds(): Iterable<string> {
        return this.allIdsWithStatusType(UPLOADING_DOCUMENT_STATUS_TYPE)
    }

    allUploadingFailedIds(): Iterable<string> {
        return this.allIdsWithStatusType(UPLOADING_FAILED_DOCUMENT_STATUS_TYPE)
    }

    *allIdsWithStatusType(type: PhysicalDocumentStatusType): Iterable<string> {
        for (const [id, status] of this.statusesById.entries())
            if (status.type === type)
                yield id
    }

    allLoadingErrorsById(): Iterable<[string, unknown]> {
        return this.allErrorsById({ omitUploading: true })
    }

    allUploadingErrorsById(): Iterable<[string, unknown]> {
        return this.allErrorsById({ omitLoading: true })
    }

    *allErrorsById(
        { omitLoading, omitUploading }: Readonly<DocumentManager.AllErrorsByIdOptions> = {},
    ): Iterable<[string, unknown]> {
        for (const status of this.statusesById.values())
            if ((!omitLoading && status.type === LOADING_FAILED_DOCUMENT_STATUS_TYPE) ||
                (!omitUploading && status.type === UPLOADING_FAILED_DOCUMENT_STATUS_TYPE))
                yield [status.document.id, status.error]
    }

    // Get one

    async get(id: string, idNormalized: boolean = false): Promise<Document> {
        id = this.tryNormalizeIdIfNeeded(id, idNormalized)

        this.startLoadingIfNew(id, true)

        const status = this.statusesById.get(id)

        assert(status != null)

        switch (status.type) {
            case READY_DOCUMENT_STATUS_TYPE:
                return status.document

            case LOADING_FAILED_DOCUMENT_STATUS_TYPE:
            case UPLOADING_FAILED_DOCUMENT_STATUS_TYPE:
                throw status.error
        }

        return new Promise((resolve, reject) => {
            const onStatusChange = (status: DocumentStatus) => {
                if (status.document.id !== id)
                    return

                switch (status.type) {
                    case READY_DOCUMENT_STATUS_TYPE:
                        this.off("status-change", onStatusChange)
                        resolve(status.document)
                        return

                    case LOADING_FAILED_DOCUMENT_STATUS_TYPE:
                    case UPLOADING_FAILED_DOCUMENT_STATUS_TYPE:
                        this.off("status-change", onStatusChange)
                        reject(status.error)
                        return
                }
            }

            this.on("status-change", onStatusChange)
        })
    }

    getStatusById(id: string, idNormalized: boolean = false): PhysicalDocumentStatus {
        id = this.tryNormalizeIdIfNeeded(id, idNormalized)

        return this.getStatusByIdOrNull(id, true)
            ?? this.throwDocumentNotFoundById(id)
    }

    getStatusByIdOrNull(id: string, idNormalized: boolean = false): PhysicalDocumentStatus | null {
        id = this.tryNormalizeIdIfNeeded(id, idNormalized)

        const status = this.statusesById.get(id)

        return status != null
            ? copyDocumentStatus(status)
            : null
    }

    // Add

    add(...documents: Document[]) {
        for (const document of documents)
            this.update(document.id, {
                type: READY_DOCUMENT_STATUS_TYPE,
                document,
            })
    }

    // Delete

    delete(id: string) {
        this.update(id, {
            type: DELETED_DOCUMENT_STATUS_TYPE,
            document: { id },
        })
    }

    clear() {
        // Aborts

        for (const abort of this.abortsById.values())
            abort()

        this.abortsById.clear()

        // Statuses

        if (this.listenerCount(DELETED_DOCUMENT_STATUS_TYPE) > 0 || this.listenerCount("status-change"))
            for (const id of [...this.statusesById.keys()])
                this.update(id, {
                    type: DELETED_DOCUMENT_STATUS_TYPE,
                    document: { id },
                })
        else
            this.statusesById.clear()

        this.emit("clear")
    }

    // Start loading

    startLoadingIfNew(id: string, idNormalized: boolean = false) {
        id = this.tryNormalizeIdIfNeeded(id, idNormalized)

        if (this.statusesById.get(id) == null)
            this.startLoading(id, true)
    }

    startLoading(id: string, idNormalized: boolean = false) {
        id = this.tryNormalizeIdIfNeeded(id, idNormalized)

        const controller = new AbortController()

        this.update(
            id,

            {
                type: LOADING_DOCUMENT_STATUS_TYPE,
                document: { id },
            },

            () => controller.abort(),
        )

        this.api.getById(id, controller.signal)
            .then(document => this.update(id, {
                type: READY_DOCUMENT_STATUS_TYPE,
                document,
            }))
            .catch(error => this.update(id, {
                type: LOADING_FAILED_DOCUMENT_STATUS_TYPE,
                document: { id },
                error,
            }))
    }

    // Upload

    async upload(file: File, id?: string | null): Promise<Document> {
        id = this.startUploading(file, id)

        return new Promise((resolve, reject) => {
            const onStatusChange = (status: DocumentStatus, oldId: string) => {
                if (id !== oldId)
                    return

                switch (status.type) {
                    case READY_DOCUMENT_STATUS_TYPE:
                        this.off("status-change", onStatusChange)
                        resolve(status.document)
                        break

                    case UPLOADING_FAILED_DOCUMENT_STATUS_TYPE:
                        this.off("status-change", onStatusChange)
                        reject(status.error)
                        break
                }
            }

            this.on("status-change", onStatusChange)
        })
    }

    // Start uploading

    startUploadingIfNew(file: File, id: string, idNormalized: boolean = false): string | null {
        id = this.tryNormalizeIdIfNeeded(id, idNormalized)

        return this.getStatusByIdOrNull(id, true) != null
            ? this.startUploading(file, id, true)
            : null
    }

    startUploading(file: File, id?: string | null, idNormalized?: boolean): string {
        id = id != null
            ? this.tryNormalizeIdIfNeeded(id, idNormalized)
            : generateRandomUuid()

        this.api.upload([file], {
            onSuccess: ([document]) => this.update(id!, {
                type: READY_DOCUMENT_STATUS_TYPE,
                document,
            }),

            onError: error => this.update(id!, {
                type: UPLOADING_FAILED_DOCUMENT_STATUS_TYPE,
                document: { id: id! },
                error,
            })
        })

        this.update(
            id,

            {
                type: UPLOADING_DOCUMENT_STATUS_TYPE,
                document: { id },
                loaded: 0,
                total: -1,
            },

            // abort,
        )

        return id
    }

    // Util

    // - Update

    private update(
        normalizedId: string,
        status: DocumentStatus,
        abort?: Abort | null,
    ) {
        this.updateStatus(normalizedId, status)
        this.updateAbort(normalizedId, abort, status.document.id)
    }

    // -- Statuses

    private updateStatus(normalizedId: string, status: DocumentStatus) {
        const oldId = normalizedId
        const newId = status.document.id

        // Old deletion

        const { type } = status
        const isStatusTypeSynthetic = isSyntheticDocumentStatusType(type)

        if (oldId !== newId || isStatusTypeSynthetic) {
            this.statusesById.delete(oldId)

            if (isStatusTypeSynthetic) {
                this.emit(type, newId)
                return
            }
        }

        // New setting

        assert(isPhysicalDocumentStatusType(type))
        status = status as PhysicalDocumentStatus

        this.statusesById.set(newId, status)

        this.emit("status-change", status, oldId)

        switch (type) {
            case READY_DOCUMENT_STATUS_TYPE:
                this.emit(type, status.document, oldId)
                break

            case LOADING_DOCUMENT_STATUS_TYPE:
                this.emit(type, newId)
                break

            case UPLOADING_DOCUMENT_STATUS_TYPE:
                this.emit(type, newId, status.loaded, status.total)
                break

            case LOADING_FAILED_DOCUMENT_STATUS_TYPE:
            case UPLOADING_FAILED_DOCUMENT_STATUS_TYPE:
                this.emit(type, newId, status.error)
                break

            default:
                status satisfies never
        }
    }

    // -- Aborts

    private updateAbort(
        oldNormalizedId: string,
        abort?: Abort | null,
        newNormalizedId?: string | null,
    ) {
        this.deleteAbort(oldNormalizedId)

        if (abort != null)
            this.abortsById.set(newNormalizedId ?? oldNormalizedId, abort)
    }

    private deleteAbort(normalizedId: string) {
        const abort = this.abortsById.get(normalizedId)

        if (abort == null)
            return

        abort()

        this.abortsById.delete(normalizedId)
    }

    // - Errors

    private throwDocumentNotFoundById(normalizedId: string): never {
        throw new NotFoundByIdError(normalizedId, `Document with id ${normalizedId} not found`)
    }

    private throwDocumentDeleted(normalizedId: string): never {
        throw new DeletedError(`Document with id ${normalizedId} was deleted`)
    }

    // - Misc

    private tryNormalizeIdIfNeeded(id: string, idNormalized: boolean = false): string {
        return idNormalized
            ? id
            : tryNormalizeUuid(id)
    }
}
