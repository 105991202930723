import { ForwardedRef, forwardRef, useEffect } from "react"
import { getLang, setLang } from "i18n"
import { LangSelector } from "ui/ui"

export const GlobalLangSelector = forwardRef((_, ref: ForwardedRef<HTMLButtonElement>) => {
    const lang = getLang()

    useEffect(() => { document.documentElement.lang = lang }, [lang])

    return <LangSelector onSelect={setLang}
                         lang={lang}
                         ref={ref}/>
})

GlobalLangSelector.displayName = "GlobalLangSelector"
