import { ReactNode, useContext, useImperativeHandle,
         ForwardedRef, forwardRef, useRef, useEffect } from "react"

import { DeepReadonly } from "my-util"
import { useStateWithDeps } from "ui/hook"
import { UserContext } from "ui/context"
import { Clickable, Flex, Limit, Padding, UserRoleAbbr } from "ui/ui"
import { SectionLinkList } from "../SectionLinkList"
import { MenuButton } from "../MenuButton"
import style from "./style.module.css"

export namespace Menu {
    export interface Props {
        onShortChange?: (short: boolean) => void
        short?: boolean

        title?: ReactNode

        firstRender?: boolean
    }

    export interface Ref {
        component: HTMLDivElement
        title: HTMLDivElement | null
        links: HTMLDivElement
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Menu = forwardRef((
    {
        onShortChange, short,
        title,
        firstRender,
    }: DeepReadonly<Menu.Props>,
    ref: ForwardedRef<Menu.Ref>,
) => {
    const [localUser] = useContext(UserContext)

    // Refs

    const changedShortRef = useRef(false)

    // State

    const [innerShort, setInnerShort] = useStateWithDeps(
        () => short ?? false,
        [short],
    )

    // Refs

    const componentRef = useRef<HTMLDivElement | null>(null)
    const titleRef = useRef<HTMLDivElement | null>(null)
    const linksRef = useRef<HTMLDivElement | null>(null)

    useImperativeHandle(
        ref,

        () => ({
            component: componentRef.current!,
            title: titleRef.current,
            links: linksRef.current!,
        }),

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [componentRef, titleRef, linksRef, Boolean(title)],
    )

    // Effects

    useEffect(
        () => {
            if (changedShortRef.current) {
                onShortChange?.(innerShort)
                changedShortRef.current = false
            }
        },

        [innerShort, onShortChange],
    )

    // Render

    return <Flex width="fit-content"
                 align="start"
                 ref={componentRef}>
        <Padding paddingLeft="14px"
                 height="32px">
            <Limit maxWidth="236px"
                   overflow="hidden">
                <Flex justify="space-between"
                      direction="row"
                      height="32px">
                    {renderBurgerWithRole()}
                    {renderTitle()}
                </Flex>
            </Limit>
        </Padding>

        <SectionLinkList firstRender={firstRender}
                         short={innerShort}
                         ref={linksRef}/>
    </Flex>

    function renderBurgerWithRole(): ReactNode {
        return <Clickable onClick={onShortToggle}
                          cursor="pointer">
            <Flex direction="row">
                <MenuButton/>
                {renderRole()}
            </Flex>
        </Clickable>
    }

    function renderRole(): ReactNode {
        if (localUser == null)
            return null

        const className = short
            ? firstRender
                ? style.hiddenRole
                : style.hidingRole

            : style.role

        return <span className={className}>
            <UserRoleAbbr role={localUser.role}
                          colorful/>
        </span>
    }

    function renderTitle(): ReactNode {
        if (!title)
            return null

        return <span className={style.title}
                     ref={titleRef}>
            {title}
        </span>
    }

    // Events

    function onShortToggle() {
        setInnerShort(oldInnerShort => {
            changedShortRef.current = true
            return !oldInnerShort
        })
    }
})

Menu.displayName = "Menu"
