import { useEffect, useState } from "react"

export function useDragging(): boolean {
    const [dragging, setDragging] = useState(false)

    useEffect(() => {
        let timer: number | null = null

        document.addEventListener("dragover", handler)

        return () => document.removeEventListener("dragover", handler)

        function handler() {
            setDragging(true)

            if (timer != null)
                window.clearTimeout(timer)

            timer = window.setTimeout(() => setDragging(false), 100)
        }
    }, [])

    return dragging
}
