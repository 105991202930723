import { ForwardedRef, forwardRef, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { USER_ID_STORAGE_KEY } from "api"
import * as LoginPage from "ui/page/auth/LoginPage/path"
import { Link } from "ui/ui"
import { BackToTheMainPageLink } from "../BackToTheMainPageLink"

export namespace ReloginLink {
    export interface Props {
        hideOnMissing?: boolean
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReloginLink = forwardRef((
    { hideOnMissing }: Readonly<ReloginLink.Props>,
    ref: ForwardedRef<HTMLAnchorElement | null>,
) => {
    const [t] = useTranslation()

    const to = useMemo(
        () => {
            const userId = localStorage.getItem(USER_ID_STORAGE_KEY)

            return userId != null
                ? LoginPage.createPath(userId)
                : null
        },

        [],
    )

    return to
        ? <Link text={t("misc.actions.relogin")}
                to={to}
                ref={ref}/>

        : hideOnMissing
            ? null
            : <BackToTheMainPageLink ref={ref}/>
})

ReloginLink.displayName = "ReloginLink"
