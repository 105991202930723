import { CSSProperties, ForwardedRef, forwardRef } from "react"
import style from "./style.module.css"

export namespace NotificationCount {
    export interface Props {
        count: number
        max?: number

        fontSize?: CSSProperties["fontSize"]

        backgroundColor?: CSSProperties["backgroundColor"]
        color?: CSSProperties["color"]
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NotificationCount = forwardRef((
    {
        count, max,
        fontSize,
        backgroundColor, color,
    }: Readonly<NotificationCount.Props>,
    ref: ForwardedRef<HTMLSpanElement>,
) => {
    const text = max != null && count > max
        ? `${max}+`
        : String(count)

    const width = `calc(.5em + ${text.length}ch)`

    return <span style={{ fontSize, backgroundColor, color, width }}
                 className={style.NotificationCount}
                 ref={ref}>
        <span className={style.count}>
            {text}
        </span>
    </span>
})

NotificationCount.displayName = "NotificationCount"
