import { WithCreatorId } from "model/interfaces"

import { ReadonlyDate, tryCopyDate, tryNormalizeNullableUuid,
         Copyable, DeepReadonly, Immutable, IsImmutable, Nullable, Nullish } from "my-util"

import { Money } from "./Money"

export namespace TransferAgentPayment {
    export interface OptionsBase extends Nullish<{
        transferId: string

        creatorId: string

        agentId: string
        agentPayedAt: Date
    }> {}

    export interface CreationsOptions extends OptionsBase, Nullish<{
        agentPayment: Money | Money.CreationOptions
    }> {}

    export interface CopyOptions extends OptionsBase, Nullish<{
        agentPayment: Money | Money.CopyOptions
    }> {}
}

export class TransferAgentPayment
    extends
        Copyable<TransferAgentPayment.CopyOptions>

    implements
        Readonly<Nullable<WithCreatorId>>,
        Immutable

{
    static createOrPass(
        arg?: TransferAgentPayment
            | DeepReadonly<TransferAgentPayment.CreationsOptions>
            | null,
    ): TransferAgentPayment {
        return arg instanceof TransferAgentPayment
            ? arg
            : new TransferAgentPayment(arg ?? {})
    }

    // Fields

    readonly [IsImmutable] = true

    readonly transferId: string | null

    readonly creatorId: string | null

    readonly agentId: string | null
    readonly agentPayedAt: ReadonlyDate | null
    readonly agentPayment: Money | null

    // Constructor

    constructor(options: DeepReadonly<TransferAgentPayment.CreationsOptions> = {}) {
        super()

        this.transferId = tryNormalizeNullableUuid(options.transferId)

        this.creatorId = tryNormalizeNullableUuid(options.creatorId)

        this.agentId = tryNormalizeNullableUuid(options.agentId)
        this.agentPayedAt = tryCopyDate(options.agentPayedAt)

        this.agentPayment = options.agentPayment != null
            ? Money.createOrPass(options.agentPayment)
            : null
    }

    // Is paid

    get isPaid(): boolean {
        return this.agentPayedAt != null
    }

    // Copy

    protected override createCopy(
        options: DeepReadonly<TransferAgentPayment.CopyOptions> = {}
    ): TransferAgentPayment {
        return new TransferAgentPayment({
            transferId: "transferId" in options
                ? options.transferId
                : this.transferId,

            creatorId: "creatorId" in options
                ? options.creatorId
                : this.creatorId,

            agentId: "agentId" in options
                ? options.agentId
                : this.agentId,

            agentPayedAt: "agentPayedAt" in options
                ? options.agentPayedAt
                : this.agentPayedAt,

            agentPayment: "agentPayment" in options
                ? options.agentPayment
                : this.agentPayment,
        })
    }
}
