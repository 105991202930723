import { get, performMfaRequest, post } from "api/http/util"
import { CompleteRegistrationRequest, StartRegistrationRequest } from "api/requests"

import { MfaResponse, RegistrationConfigResponse,
         RegistrationConfigResponseSchema, UserResponseSchema } from "api/responses"

import { User } from "model"
import { joinSubpaths } from "my-util"
import { AUTH_SUBPATH } from "./subpath"

export const REGISTRATION_SUBPATH = joinSubpaths([AUTH_SUBPATH, "/register"])

export async function startRegistration(
    request: Readonly<StartRegistrationRequest>,
    signal?: AbortSignal | null,
): Promise<MfaResponse> {
    return performMfaRequest(joinSubpaths([REGISTRATION_SUBPATH, "/start"]), request, signal)
}

export async function cancelRegistration(signal?: AbortSignal | null) {
    return post({
        subpath: joinSubpaths([REGISTRATION_SUBPATH, "/cancel"]),
        signal,
    })
}

export async function completeRegistration(
    request: Readonly<CompleteRegistrationRequest>,
    signal?: AbortSignal | null,
): Promise<User> {
    return new User(await post({
        subpath: joinSubpaths([REGISTRATION_SUBPATH, "/complete"]),
        schema: UserResponseSchema,
        body: request,
        signal,
    }))
}

export async function getRegistrationConfig(
    signal?: AbortSignal | null,
): Promise<RegistrationConfigResponse> {
    return get({
        subpath: joinSubpaths([REGISTRATION_SUBPATH, "/config"]),
        schema: RegistrationConfigResponseSchema,
        signal,
    })
}
