import { ViolationType } from "./ViolationType"

export const MIN_PASSWORD_LENGTH = 4
export const MAX_PASSWORD_LENGTH = 255

export function validatePassword(password: string): ViolationType | null {
    if (password.length > MAX_PASSWORD_LENGTH)
        return "too-long"

    if (password.length < MIN_PASSWORD_LENGTH)
        return "too-short"

    return null
}
