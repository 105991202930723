import { ForwardedRef, forwardRef } from "react"
import { doubleTickIconUrl, tickIconUrl } from "images"
import { Icon } from "ui/ui"
import { UiChatMessageStatus } from "../UiChatMessage"

export namespace ChatMessageTicks {
    export interface Props {
        status: UiChatMessageStatus
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ChatMessageTicks = forwardRef((
    { status }: Readonly<ChatMessageTicks.Props>,
    ref: ForwardedRef<HTMLImageElement | null>,
) => {
        switch (status) {
            case "sending":
                return <Icon src={tickIconUrl}
                             alt="Tick icon"
                             filter="brightness(0) invert(50%)"

                             width="10px"
                             height="14px"

                             ref={ref}/>

            case "sent":
                return <Icon src={doubleTickIconUrl}
                             alt="Double tick icon"
                             filter="brightness(0) invert(50%)"

                             width="14px"
                             height="14px"

                             ref={ref}/>

            case "read":
                return <Icon src={doubleTickIconUrl}
                             alt="Double tick icon"
                             filter="brightness(0) saturate(100%) invert(67%) sepia(27%) saturate(3251%) hue-rotate(180deg) brightness(98%) contrast(90%)"

                             width="14px"
                             height="14px"

                             ref={ref}/>

            case "error":
                return null

            default:
                return status satisfies never
        }
})

ChatMessageTicks.displayName = "ChatMessageTicks"
