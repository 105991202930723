import { ReactNode, useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { JsonPatch, patchUserById } from "api"
import { User } from "model"

import { PAYMENT_ACCOUNT_INPUT_REGEX, SWIFT_INPUT_REGEX,
         BIC_INPUT_REGEX, CORRESPONDENT_ACCOUNT_INPUT_REGEX,
         isBlank, LEGAL_ITN_INPUT_REGEX, collapseWhiteSpaceToNull } from "my-util"

import { BIC_LENGTH, CORRESPONDENT_ACCOUNT_LENGTH,
         LEGAL_ITN_LENGTH, MAX_SHORT_TEXT_LENGTH,
         MAX_SWIFT_LENGTH, PAYMENT_ACCOUNT_LENGTH,
         validateCorrespondentAccount, validatePaymentAccount,
         validateShortText, validateBic, validateLegalItn, validateSwift } from "validation"

import { useStateWithDeps } from "ui/hook"
import { UserContext } from "ui/context"

import { DiLangInput, DiLangOutput, Flex, Limit,
         FormControls, Input, Subheader, Form, Output, Center } from "ui/ui"

const MAX_WIDTH = "400px"

export namespace LegalInfoTab {
    export interface Props {
        onUserChange?: (user: User) => void
        user: User

        mobile?: boolean
    }
}

export function LegalInfoTab(
    {
        onUserChange, user,
        mobile,
    }: Readonly<LegalInfoTab.Props>,
) {
    const [t] = useTranslation()

    const [localUser] = useContext(UserContext)

    const navigate = useNavigate()

    // State

    // - Company

    // -- Name

    const [companyName, setCompanyName] = useStateWithDeps(
        () => ({
            en: user.company.enName ?? "",
            ru: user.company.ruName ?? "",
        }),
        [user],
    )

    const companyNameInvalid = {
        en: !isBlank(companyName.en) && validateShortText(companyName.en) != null,
        ru: !isBlank(companyName.ru) && validateShortText(companyName.ru) != null,
    }

    // -- Address

    const [companyAddress, setCompanyAddress] = useStateWithDeps(
        () => ({
            en: user.company.enAddress ?? "",
            ru: user.company.ruAddress ?? "",
        }),
        [user],
    )

    const companyAddressInvalid = {
        en: !isBlank(companyAddress.en) && validateShortText(companyAddress.en) != null,
        ru: !isBlank(companyAddress.ru) && validateShortText(companyAddress.ru) != null,
    }

    // -- ITN

    const [companyItn, setCompanyItn] = useStateWithDeps(
        () => user.company.itn ?? "",
        [user],
    )

    const companyItnInvalid =
        !isBlank(companyItn) &&
        validateLegalItn(companyItn) != null

    // -- Correspondent account

    const [companyCorrespondentAccount, setCompanyCorrespondentAccount] = useStateWithDeps(
        () => user.company.correspondentAccount ?? "",
        [user],
    )

    const companyCorrespondentAccountInvalid =
        !isBlank(companyCorrespondentAccount) &&
        validateCorrespondentAccount(companyCorrespondentAccount) != null

    // -- Payment account

    const [companyPaymentAccount, setCompanyPaymentAccount] = useStateWithDeps(
        () => user.company.paymentAccount ?? "",
        [user],
    )

    const companyPaymentAccountInvalid =
        !isBlank(companyPaymentAccount) &&
        validatePaymentAccount(companyPaymentAccount) != null

    // -- Common

    const companyInvalid =
        companyNameInvalid.ru ||
        companyNameInvalid.en ||

        companyAddressInvalid.ru ||
        companyAddressInvalid.en ||

        companyItnInvalid ||
        companyCorrespondentAccountInvalid ||
        companyPaymentAccountInvalid

    // - Bank

    // -- Name

    const [bankName, setBankName] = useStateWithDeps(
        () => ({
            en: user.company.bank.enName ?? "",
            ru: user.company.bank.ruName ?? "",
        }),
        [user],
    )

    const bankNameInvalid = {
        en: !isBlank(bankName.en) && validateShortText(bankName.en) != null,
        ru: !isBlank(bankName.ru) && validateShortText(bankName.ru) != null,
    }

    // -- Address

    const [bankAddress, setBankAddress] = useStateWithDeps(
        () => ({
            en: user.company.bank.enAddress ?? "",
            ru: user.company.bank.ruAddress ?? "",
        }),
        [user],
    )

    const bankAddressInvalid = {
        en: !isBlank(bankAddress.en) && validateShortText(bankAddress.en) != null,
        ru: !isBlank(bankAddress.ru) && validateShortText(bankAddress.ru) != null,
    }

    // -- ITN

    const [bankItn, setBankItn] = useStateWithDeps(
        () => user.company.bank.itn ?? "",
        [user],
    )

    const bankItnInvalid =
        !isBlank(bankItn) &&
        validateLegalItn(bankItn) != null

    // -- BIC

    const [bankBic, setBankBic] = useStateWithDeps(
        () => user.company.bank.bic ?? "",
        [user],
    )

    const bankBicInvalid =
        !isBlank(bankBic) &&
        validateBic(bankBic) != null

    // -- SWIFT

    const [bankSwift, setBankSwift] = useStateWithDeps(
        () => user.company.bank.swift ?? "",
        [user],
    )

    const bankSwiftInvalid =
        !isBlank(bankSwift) &&
        validateSwift(bankSwift) != null

    // -- Common

    const bankInvalid =
        bankNameInvalid.en ||
        bankNameInvalid.ru ||

        bankAddressInvalid.en ||
        bankAddressInvalid.ru ||

        bankItnInvalid ||
        bankBicInvalid ||
        bankSwiftInvalid

    // - Common

    const [editing, setEditing] = useState(false)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(undefined as unknown)

    const invalid =
        companyInvalid ||
        bankInvalid

    // Render

    return <Form loading={loading}
                 height="100%">
        <Flex justify="space-between"
              align="start"
              height="100%">
            {editing
                ? renderFieldInputs()
                : renderFieldOutputs()
            }

            <FormControls buttons={renderFormControlsButtons()}
                          loading={loading}
                          error={error}
                          mobile={mobile}
                          wrap/>
        </Flex>
    </Form>

    function renderFieldInputs(): ReactNode {
        return <Flex align="start">
            <Subheader text={t("sections.users.subheaders.company")}/>

            <DiLangInput onChange={(en, ru) => setCompanyName({ en, ru })}
                         enValue={companyName.en}
                         ruValue={companyName.ru}

                         label={t("domain.companies.labels.name")}
                         placeholder={t("domain.companies.placeholders.name")}

                         enInvalid={companyNameInvalid.en}
                         ruInvalid={companyNameInvalid.ru}

                         max={MAX_SHORT_TEXT_LENGTH}

                         maxWidth={MAX_WIDTH}/>

            <DiLangInput onChange={(en, ru) => setCompanyAddress({ en, ru })}
                         enValue={companyAddress.en}
                         ruValue={companyAddress.ru}

                         label={t("domain.companies.labels.address")}
                         placeholder={t("domain.companies.placeholders.address")}

                         enInvalid={companyAddressInvalid.en}
                         ruInvalid={companyAddressInvalid.ru}

                         max={MAX_SHORT_TEXT_LENGTH}

                         maxWidth={MAX_WIDTH}/>

            <Limit maxWidth={MAX_WIDTH}>
                <Flex align="start">
                    <Input onChange={setCompanyItn}
                           value={companyItn}

                           label={t("domain.companies.labels.itn")}
                           placeholder={t("domain.companies.placeholders.itn")}

                           invalid={companyItnInvalid}

                           regex={LEGAL_ITN_INPUT_REGEX}
                           max={LEGAL_ITN_LENGTH}/>

                    <Input onChange={setCompanyCorrespondentAccount}
                           value={companyCorrespondentAccount}

                           label={t("domain.companies.labels.correspondentAccount")}
                           placeholder={t("domain.companies.placeholders.correspondentAccount")}

                           invalid={companyCorrespondentAccountInvalid}

                           regex={CORRESPONDENT_ACCOUNT_INPUT_REGEX}
                           max={CORRESPONDENT_ACCOUNT_LENGTH}/>

                    <Input onChange={setCompanyPaymentAccount}
                           value={companyPaymentAccount}

                           label={t("domain.companies.labels.paymentAccount")}
                           placeholder={t("domain.companies.placeholders.paymentAccount")}

                           invalid={companyPaymentAccountInvalid}

                           regex={PAYMENT_ACCOUNT_INPUT_REGEX}
                           max={PAYMENT_ACCOUNT_LENGTH}/>
                </Flex>
            </Limit>

            <Subheader text={t("sections.users.subheaders.bank")}/>

            <DiLangInput onChange={(en, ru) => setBankName({ en, ru })}
                         enValue={bankName.en}
                         ruValue={bankName.ru}

                         label={t("domain.banks.labels.name")}
                         placeholder={t("domain.banks.placeholders.name")}

                         enInvalid={bankNameInvalid.en}
                         ruInvalid={bankNameInvalid.ru}

                         maxWidth={MAX_WIDTH}/>

            <DiLangInput onChange={(en, ru) => setBankAddress({ en, ru })}
                         enValue={bankAddress.en}
                         ruValue={bankAddress.ru}

                         label={t("domain.banks.labels.address")}
                         placeholder={t("domain.banks.placeholders.address")}

                         enInvalid={bankAddressInvalid.en}
                         ruInvalid={bankAddressInvalid.ru}

                         maxWidth={MAX_WIDTH}/>

            <Limit maxWidth={MAX_WIDTH}>
                <Flex align="start">
                    <Input onChange={setBankItn}
                           value={bankItn}

                           label={t("domain.banks.labels.itn")}
                           placeholder={t("domain.banks.placeholders.itn")}

                           invalid={bankItnInvalid}

                           regex={LEGAL_ITN_INPUT_REGEX}
                           max={LEGAL_ITN_LENGTH}/>

                    <Input onChange={setBankBic}
                           value={bankBic}

                           label={t("domain.banks.labels.bic")}
                           placeholder={t("domain.banks.placeholders.bic")}

                           invalid={bankBicInvalid}

                           regex={BIC_INPUT_REGEX}
                           max={BIC_LENGTH}/>

                    <Input onChange={setBankSwift}
                           value={bankSwift}

                           label={t("domain.banks.labels.swift")}
                           placeholder={t("domain.banks.placeholders.swift")}

                           invalid={bankSwiftInvalid}

                           regex={SWIFT_INPUT_REGEX}
                           max={MAX_SWIFT_LENGTH}/>
                </Flex>
            </Limit>
        </Flex>
    }

    function renderFieldOutputs(): ReactNode {
        // Company blank check

        const companyNameBlank =
            isBlank(companyName.en) &&
            isBlank(companyName.ru)

        const companyAddressBlank =
            isBlank(companyAddress.en) &&
            isBlank(companyAddress.ru)

        const companyItnBlank = isBlank(companyItn)
        const companyCorrespondentAccountBlank = isBlank(companyCorrespondentAccount)
        const companyPaymentAccountBlank = isBlank(companyPaymentAccount)

        const companyItnAndCaAndPaBlank =
            companyItnBlank &&
            companyCorrespondentAccountBlank &&
            companyPaymentAccountBlank

        const companyFullyBlank =
            companyNameBlank &&
            companyAddressBlank &&
            companyItnAndCaAndPaBlank

        const companyPartiallyBlank =
            companyNameBlank ||
            companyAddressBlank ||
            companyItnBlank ||
            companyCorrespondentAccountBlank ||
            companyPaymentAccountBlank

        // Bank blank check

        const bankNameBlank =
            isBlank(bankName.en) &&
            isBlank(bankName.ru)

        const bankAddressBlank =
            isBlank(bankAddress.en) &&
            isBlank(bankAddress.ru)

        const bankItnBlank = isBlank(bankItn)
        const bankBicBlank = isBlank(bankBic)
        const bankSwiftBlank = isBlank(bankSwift)

        const bankItnAndBicAndSwiftBlank =
            bankItnBlank &&
            bankBicBlank &&
            bankSwiftBlank

        const bankFullyBlank =
            bankNameBlank &&
            bankAddressBlank &&
            bankItnAndBicAndSwiftBlank

        const bankPartiallyBlank =
            bankNameBlank ||
            bankAddressBlank ||
            bankItnBlank ||
            bankBicBlank ||
            bankSwiftBlank

        // Common

        const fullyBlank =
            companyFullyBlank &&
            bankFullyBlank

        const partiallyBlank =
            companyPartiallyBlank ||
            bankPartiallyBlank

        // Render

        if (fullyBlank)
            return <Center>
                {t("misc.messages.noData")}
            </Center>

        return <Flex align="start">
            {!companyFullyBlank && <>
                <Subheader text={t("sections.users.subheaders.company")}/>

                {!companyNameBlank &&
                    <DiLangOutput enValue={companyName.en ?? ""}
                                  ruValue={companyName.ru ?? ""}

                                  label={t("domain.companies.labels.name")}

                                  maxWidth={MAX_WIDTH}/>
                }

                {!companyAddressBlank &&
                    <DiLangOutput enValue={companyAddress.en ?? ""}
                                  ruValue={companyAddress.ru ?? ""}

                                  label={t("domain.companies.labels.address")}

                                  maxWidth={MAX_WIDTH}/>
                }

                {!companyItnAndCaAndPaBlank &&
                    <Limit maxWidth={MAX_WIDTH}>
                        <Flex align="start">
                            {!companyItnBlank &&
                                <Output label={t("domain.companies.labels.itn")}>
                                    {companyItn}
                                </Output>
                            }

                            {!companyCorrespondentAccountBlank &&
                                <Output label={t("domain.companies.labels.correspondentAccount")}>
                                    {companyCorrespondentAccount}
                                </Output>
                            }

                            {!companyPaymentAccountBlank &&
                                <Output label={t("domain.companies.labels.paymentAccount")}>
                                    {companyPaymentAccount}
                                </Output>
                            }
                        </Flex>
                    </Limit>
                }
            </>}

            {!bankFullyBlank && <>
                <Subheader text={t("sections.users.subheaders.bank")}/>

                {!bankNameBlank &&
                    <DiLangOutput enValue={bankName.en}
                                  ruValue={bankName.ru}

                                  label={t("domain.banks.labels.name")}

                                  maxWidth={MAX_WIDTH}/>
                }

                {!bankAddressBlank &&
                    <DiLangOutput enValue={bankAddress.en}
                                  ruValue={bankAddress.ru}

                                  label={t("domain.banks.labels.address")}

                                  maxWidth={MAX_WIDTH}/>
                }

                {!bankItnAndBicAndSwiftBlank &&
                    <Limit maxWidth={MAX_WIDTH}>
                        <Flex align="start">
                            {!bankItnBlank &&
                                <Output label={t("domain.banks.labels.itn")}>
                                    {bankItn}
                                </Output>
                            }

                            {!bankBicBlank &&
                                <Output label={t("domain.banks.labels.bic")}>
                                    {bankBic}
                                </Output>
                            }

                            {!bankSwiftBlank &&
                                <Output label={t("domain.banks.labels.swift")}>
                                    {bankSwift}
                                </Output>
                            }
                        </Flex>
                    </Limit>
                }
            </>}

            {partiallyBlank &&
                t("misc.messages.unfilledFieldsHidden")
            }
        </Flex>
    }

    function renderFormControlsButtons(): FormControls.Button[] {
        return editing
            ? renderFormControlsEditingButtons()
            : renderFormControlsViewingButtons()
    }

    function renderFormControlsEditingButtons(): FormControls.Button[] {
        return [
            {
                onClick: onSave,
                text: t("misc.actions.save"),
                disabled: invalid,
                type: "submit",
                position: "left",
            },

            {
                onClick: onCancelEditing,
                text: t("misc.actions.cancel"),
                buttonStyle: "outline",
                position: "left",
            },
        ]
    }

    function renderFormControlsViewingButtons(): FormControls.Button[] {
        const buttons: FormControls.Button[] = [{
            onClick: onBack,
            text: t("misc.actions.back"),
            buttonStyle: "outline",
            position: "right",
        }]

        if (user.id === localUser?.id ||
            localUser?.isAdmin ||
            (localUser?.hasRightToManageUsers && !user.isAdmin))
            buttons.push({
                onClick: onStartEditing,
                text: t("misc.actions.edit"),
                position: "left",
            })

        return buttons
    }

    // Events

    function onBack() {
        navigate(-1)
    }

    async function onSave() {
        setLoading(true)

        try {
            const patches: JsonPatch[] = [
                // Company

                {
                    op: "replace",
                    path: "/company/enName",
                    value: collapseWhiteSpaceToNull(companyName.en),
                },

                {
                    op: "replace",
                    path: "/company/ruName",
                    value: collapseWhiteSpaceToNull(companyName.ru),
                },

                {
                    op: "replace",
                    path: "/company/enAddress",
                    value: collapseWhiteSpaceToNull(companyAddress.en),
                },

                {
                    op: "replace",
                    path: "/company/ruAddress",
                    value: collapseWhiteSpaceToNull(companyAddress.ru),
                },

                {
                    op: "replace",
                    path: "/company/itn",
                    value: collapseWhiteSpaceToNull(companyItn),
                },

                {
                    op: "replace",
                    path: "/company/correspondentAccount",
                    value: collapseWhiteSpaceToNull(companyCorrespondentAccount),
                },

                {
                    op: "replace",
                    path: "/company/paymentAccount",
                    value: collapseWhiteSpaceToNull(companyPaymentAccount),
                },

                // Bank

                {
                    op: "replace",
                    path: "/company/bank/enName",
                    value: collapseWhiteSpaceToNull(bankName.en),
                },

                {
                    op: "replace",
                    path: "/company/bank/ruName",
                    value: collapseWhiteSpaceToNull(bankName.ru),
                },

                {
                    op: "replace",
                    path: "/company/bank/ruAddress",
                    value: collapseWhiteSpaceToNull(bankAddress.ru),
                },

                {
                    op: "replace",
                    path: "/company/bank/enAddress",
                    value: collapseWhiteSpaceToNull(bankAddress.en),
                },

                {
                    op: "replace",
                    path: "/company/bank/swift",
                    value: collapseWhiteSpaceToNull(bankSwift),
                },

                {
                    op: "replace",
                    path: "/company/bank/itn",
                    value: collapseWhiteSpaceToNull(bankItn),
                },

                {
                    op: "replace",
                    path: "/company/bank/bic",
                    value: collapseWhiteSpaceToNull(bankBic),
                },
            ]

            const response = await patchUserById(user.id, patches)

            if (response.status === "verification-needed") {
                console.error("MFA is required but shouldn't")
                throw t("errors.unspecific")
            }

            const newUser = new User(response.body)

            onUserChange?.(newUser)
            setError(undefined)
            setEditing(false)
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }

    function onCancelEditing() {
        setEditing(false)
        resetFields()
    }

    function onStartEditing() {
        setEditing(true)
    }

    // Util

    function resetFields() {
        resetCompanyFields()
        resetBankFields()
    }

    function resetCompanyFields() {
        setCompanyName({
            en: user.company.enName ?? "",
            ru: user.company.ruName ?? "",
        })

        setCompanyAddress({
            en: user.company.enAddress ?? "",
            ru: user.company.ruAddress ?? "",
        })

        setCompanyItn(user.company.itn ?? "")
        setCompanyCorrespondentAccount(user.company.correspondentAccount ?? "")
        setCompanyPaymentAccount(user.company.paymentAccount ?? "")
    }

    function resetBankFields() {
        setBankAddress({
            en: user.company.bank.enName ?? "",
            ru: user.company.bank.ruName ?? "",
        })

        setBankAddress({
            en: user.company.bank.enAddress ?? "",
            ru: user.company.bank.ruAddress ?? "",
        })

        setBankItn(user.company.bank.itn ?? "")
        setBankBic(user.company.bank.bic ?? "")
        setBankSwift(user.company.bank.swift ?? "")
    }
}
