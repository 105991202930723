import { ComponentType, CSSProperties } from "react"
import { DeepReadonly } from "my-util"

export namespace Tab {
    export interface Props {
        index?: Index

        name: string

        iconSrc?: string
        iconAlt?: string
        iconFilter?: string

        Component?: ComponentType

        overflow?: CSSProperties["overflow"]
        width?: string
    }

    export type Index =
        | number
        | string
        | bigint
}

export function Tab(props: DeepReadonly<Tab.Props>) {
    return null
}
