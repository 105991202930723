import { ForwardedRef, forwardRef } from "react"
import { Provider } from "model"
import { DeepReadonly } from "my-util"
import * as AllProvidersPage from "ui/page/sections/providers/AllProvidersPage/path"
import { Link } from "ui/ui"

export namespace ProviderLink {
    export interface Props extends Omit<Link.Props, "to"> {
        provider: Provider

        showCompany?: boolean
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProviderLink = forwardRef((
    props: DeepReadonly<ProviderLink.Props>,
    ref: ForwardedRef<HTMLAnchorElement>,
) => {
    const {
        provider,
        showCompany,
        text, children,
    } = props

    return <Link { ...props }
                 to={AllProvidersPage.PATH}
                 ref={ref}>
        {children ?? text ?? (showCompany ? provider.anyCompany ?? provider.name : provider.name)}
    </Link>
})

ProviderLink.displayName = "ProviderLink"
