import { ForwardedRef, forwardRef, useContext, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { fullBlueLogoUrl, miniBlueLogoUrl } from "images"
import { UserContext } from "ui/context"
import * as MainPage from "ui/page/sections/main/MainPage/path"
import style from "./style.module.css"

export namespace Logo {
    export interface Props {
        full?: boolean

        refetchUserOnClick?: boolean

        width?: string
        height?: string
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Logo = forwardRef((
    {
        full,
        refetchUserOnClick,
        width, height,
    }: Readonly<Logo.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const location = useLocation()
    const navigate = useNavigate()

    const [,, refetchUser] = useContext(UserContext)

    const [clicked, setClicked] = useState(false)

    useEffect(
        () => {
            if (!clicked)
                return

            if (refetchUserOnClick)
                refetchUser()

            navigate(MainPage.PATH)
        },

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [refetchUserOnClick, clicked],
    )

    const src = full
        ? fullBlueLogoUrl
        : miniBlueLogoUrl

    return <div className={style.Logo}
                onClick={onClick}
                ref={ref}>
        <img style={{ width, height }}
             className={style.image}
             src={src}
             alt="Logo"/>
    </div>

    function onClick() {
        if (location.pathname !== MainPage.PATH)
            setClicked(true)
    }
})

Logo.displayName = "Logo"
