import { ForwardedRef, forwardRef, useMemo } from "react"
import { DeepReadonly, Duration } from "my-util"
import { Output } from "ui/ui/output"

export namespace DurationOutput {
    export interface Props extends Omit<Output.Props, "children"> {
        duration?: Duration
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DurationOutput = forwardRef((
    props: DeepReadonly<DurationOutput.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const output = useMemo(
        () => (props.duration ?? new Duration()).toString(),
        [props.duration],
    )

    return <Output {...props as DurationOutput.Props}
                   ref={ref}>
        {output}
    </Output>
})

DurationOutput.displayName = "DurationOutput"
