import { ForwardedRef, forwardRef } from "react"
import { Carousel } from "ui/ui/input"

export namespace YearCarousel {
    export interface Props {
        onYearSelect?: (year: number) => void
        year?: number

        maxShowYears?: number
        width?: string
    }
}

const YEARS = new Array(3000).fill(0).map((_, i) => i)

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const YearCarousel = forwardRef((
    props: Readonly<YearCarousel.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) =>
    <Carousel onSelect={props.onYearSelect}
              selected={props.year}
              items={YEARS}

              maxShowItems={props.maxShowYears ?? 3}
              width={props.width}

              ref={ref}/>
)

YearCarousel.displayName = "YearCarousel"
