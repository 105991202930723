import { useMemo, useState } from "react"
import useWebSocket, { ReadyState } from "react-use-websocket"
import { ChatRequest, ChatResponse, ChatResponseSchema } from "api"
import { AnonymousChatMessage, ChatMessage } from "model"
import { DeepReadonly, tryNormalizeNullableUuid, tryNormalizeUuid } from "my-util"
import { ChatHook } from "./ChatHook"
import { CHAT_HOOK_URL } from "./url"

export function useChat(
    {
        onResponse,
        shouldReconnect, reconnectInterval, reconnectAttempts,
    }: Readonly<ChatHook.Options> = {},
): ChatHook {
    // State

    const { getWebSocket, sendJsonMessage, readyState: webSocketReadyState } = useWebSocket(
        CHAT_HOOK_URL,

        {
            shouldReconnect: () => shouldReconnect ?? false,
            retryOnError: shouldReconnect,
            reconnectInterval,
            reconnectAttempts,
            onMessage,
        },
    )

    const [lastResponse, setLastResponse] = useState(null as ChatHook.Response | null)

    const readyState = useMemo<ChatHook.ReadyState>(
        () => {
            switch (webSocketReadyState) {
                case ReadyState.UNINSTANTIATED:
                    return "unready"

                case ReadyState.CONNECTING:
                    return "opening"

                case ReadyState.OPEN:
                    return "open"

                case ReadyState.CLOSING:
                    return "closing"

                case ReadyState.CLOSED:
                    return "closed"
            }
        },

        [webSocketReadyState]
    )

    // Hook

    return {
        sendRequest, lastResponse, readyState,

        close() {
            getWebSocket()?.close()
        }
    }

    // Events

    function onMessage(event: WebSocketEventMap["message"]) {
        try {
            const rawResponse = typeof event.data === "string"
                ? JSON.parse(event.data)
                : event.data

            const response = ChatResponseSchema.parse(rawResponse)
            const hookResponse = responseToHookResponse(response)

            setLastResponse(hookResponse)
            onResponse?.(hookResponse)
        } catch (error) {
            console.error("useChat error:", error)
        }
    }

    // Util

    // - Response

    function responseToHookResponse(response: DeepReadonly<ChatResponse>): ChatHook.Response {
        switch (response.type) {
            case "message-list":
                return {
                    requestId: tryNormalizeNullableUuid(response.requestId),
                    type: "messages",

                    messages: response.messages.map(message =>
                        message.type === "anonymous-message"
                            ? new AnonymousChatMessage(message)
                            : new ChatMessage(message)
                    )
                }

            case "anonymous-message":
                return {
                    requestId: tryNormalizeNullableUuid(response.requestId),
                    type: "messages",

                    messages: [
                        new AnonymousChatMessage(response),
                    ],
                }

            case "message":
                return {
                    requestId: tryNormalizeNullableUuid(response.requestId),
                    type: "messages",

                    messages: [
                        new ChatMessage(response),
                    ],
                }

            case "info":
                return {
                    requestId: tryNormalizeNullableUuid(response.requestId),
                    type: "info",

                    userId: tryNormalizeUuid(response.userId),
                    unreadCount: response.unreadCount,

                    lastMessage: response.lastMessage != null
                        ? response.lastMessage.type === "anonymous-message"
                            ? new AnonymousChatMessage(response.lastMessage)
                            : new ChatMessage(response.lastMessage)
                        : null,
                }

            case "mark-read":
                return {
                    requestId: tryNormalizeNullableUuid(response.requestId),
                    type: "mark-read",

                    messageIds: response.messageIds.map(tryNormalizeUuid),
                }

            case "delete":
                return {
                    requestId: tryNormalizeNullableUuid(response.requestId),
                    type: "delete",

                    messageIds: response.messageIds.map(tryNormalizeUuid),
                }

            case "request-verification":
                return {
                    requestId: tryNormalizeUuid(response.requestId),
                    type: "request-verification",

                    error: response.error ?? null,
                }

            default:
                return response satisfies never
        }
    }

    // - Request

    function sendRequest(request: DeepReadonly<ChatHook.Request>, keep?: boolean) {
        const apiRequest = hookRequestToRequest(request)

        sendJsonMessage(apiRequest, keep)
    }

    function hookRequestToRequest(request: DeepReadonly<ChatHook.Request>): ChatRequest {
        switch (request.type) {
            case "send-message":
                return {
                    type: "send-message",
                    id: request.id,
                    text: request.text,

                    documentIds: request.documentIds != null
                        ? [...request.documentIds]
                        : null,

                    recipientId: request.recipientId,
                }

            case "mark-read":
                return {
                    type: "mark-read",
                    id: request.id,
                    messageIds: [...request.messageIds],
                }

            case "delete":
                return {
                    type: "delete",
                    id: request.id,
                    messageIds: [...request.messageIds],
                }

            case "load-messages":
                return {
                    type: "load-messages",
                    id: request.id,
                    before: request.before.toISOString(),
                    limit: request.limit,
                    userId: request.userId,
                }

            case "get-info":
                return {
                    type: "get-info",
                    id: request.id,
                    userId: request.userId,
                }

            default:
                return request satisfies never
        }
    }
}
