import { getLang } from "i18n"

import { Copyable, collapseWhiteSpaceToNull, Nullish,
         Immutable, IsImmutable, removeWhiteSpaceToNull } from "my-util"

export namespace Bank {
    export interface OptionsBase extends Nullish<{
        ruName: string
        enName: string

        ruAddress: string
        enAddress: string

        swift: string
        itn: string
        bic: string
    }> {}

    export interface CreationOptions extends OptionsBase {}

    export interface CopyOptions extends OptionsBase {}
}

export class Bank
    extends
        Copyable<Bank.CopyOptions>

    implements
        Immutable
{
    static createOrPass(arg?: Bank | Readonly<Bank.CreationOptions> | null): Bank {
        return arg instanceof Bank
            ? arg
            : new Bank(arg ?? {})
    }

    // Fields

    readonly [IsImmutable] = true

    readonly itn: string | null

    readonly ruName: string | null
    readonly enName: string | null

    readonly ruAddress: string | null
    readonly enAddress: string | null

    readonly swift: string | null
    readonly bic: string | null

    // Constructor

    constructor(options: Readonly<Bank.CreationOptions> = {}) {
        super()

        this.itn = removeWhiteSpaceToNull(options.itn ?? "")

        this.ruName = collapseWhiteSpaceToNull(options.ruName ?? "")
        this.enName = collapseWhiteSpaceToNull(options.enName ?? "")

        this.ruAddress = collapseWhiteSpaceToNull(options.ruAddress ?? "")
        this.enAddress = collapseWhiteSpaceToNull(options.enAddress ?? "")

        this.swift = removeWhiteSpaceToNull(options.swift ?? "")?.toUpperCase() ?? null
        this.bic = removeWhiteSpaceToNull(options.bic ?? "")
    }

    // Name

    get anyName(): string | null {
        return getLang() === "ru"
            ? this.ruName ?? this.enName
            : this.enName ?? this.ruName
    }

    get name(): string | null {
        return getLang() === "ru"
            ? this.ruName
            : this.enName
    }

    // Address

    get anyAddress(): string | null {
        return getLang() === "ru"
            ? this.ruAddress ?? this.enAddress
            : this.enAddress ?? this.ruAddress
    }

    get address(): string | null {
        return getLang() === "ru"
            ? this.ruAddress
            : this.enAddress
    }

    // Copy

    protected override createCopy(options: Readonly<Bank.CopyOptions> = {}): Bank {
        return new Bank({
            // ITN

            itn: "itn" in options
                ? options.itn
                : this.itn,

            // Name

            ruName: "ruName" in options
                ? options.ruName
                : this.ruName,

            enName: "enName" in options
                ? options.enName
                : this.enName,

            // Address

            ruAddress: "ruAddress" in options
                ? options.ruAddress
                : this.ruAddress,

            enAddress: "enAddress" in options
                ? options.enAddress
                : this.enAddress,

            // SWIFT

            swift: "swift" in options
                ? options.swift
                : this.swift,

            // BIC

            bic: "bic" in options
                ? options.bic
                : this.bic,
        })
    }
}
