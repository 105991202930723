import { z } from "zod"
import { WithManyMessageTargetsResponseSchema, NamedResponseSchema } from "api/responses/common"
import { ModelObjectResponseSchema } from "../model-object"
import { WithCreatorIdResponseSchema } from "./with-creator-id"
import { WithCompanyNameResponseSchema } from "./with-company-name"

export const ProviderResponseSchema = ModelObjectResponseSchema
    .merge(WithCreatorIdResponseSchema)
    .merge(WithManyMessageTargetsResponseSchema)
    .merge(NamedResponseSchema)
    .merge(WithCompanyNameResponseSchema)

export type ProviderResponse = z.infer<typeof ProviderResponseSchema>
