import { Nullish, Optional } from "my-util"
import { NamedFields } from "./NamedFields"

export type MandatoryNamedFields = Optional<NamedFields, "enPatronymic" | "ruPatronymic">

export function allMandatoryNamedFieldsPresent(
    fields: Nullish<NamedFields>,
): fields is MandatoryNamedFields {
    return fields.enFirstname != null && fields.enLastname != null
        && fields.ruFirstname != null && fields.ruLastname != null
}
