import { AbstractModelObject } from "model/AbstractModelObject"
import { Copyable, DeepReadonly, Nullish, tryNormalizeNullableUuid } from "my-util"

export namespace SignableDocument {
    export interface OptionsBase extends Nullish<{
        initialId: string
        signedId: string
        locked: boolean
    }> {}

    export interface CreationOptions
        extends
            AbstractModelObject.CreationOptions,
            OptionsBase
    {}

    export interface CopyOptions
        extends
            AbstractModelObject.CopyOptions,
            OptionsBase
    {}
}

export class SignableDocument extends AbstractModelObject<SignableDocument.CopyOptions> {
    static createOrPass(
        arg?: SignableDocument
            | DeepReadonly<SignableDocument.CreationOptions>
            | null,
    ): SignableDocument {
        return arg instanceof SignableDocument
            ? arg
            : new SignableDocument(arg ?? {})
    }

    // Fields

    readonly initialId: string | null
    readonly signedId: string | null
    readonly locked: boolean

    // Constructor

    constructor(options: DeepReadonly<SignableDocument.CreationOptions> = {}) {
        super(options)

        this.initialId = tryNormalizeNullableUuid(options.initialId)
        this.signedId = tryNormalizeNullableUuid(options.signedId)
        this.locked = options.locked ?? false
    }

    // Copy

    protected override createCopy(
        options: DeepReadonly<SignableDocument.CopyOptions> = {},
    ): Copyable<SignableDocument.CopyOptions> {
        return new SignableDocument({
            initialId: "initialId" in options
                ? options.initialId
                : this.initialId,

            signedId: "signedId" in options
                ? options.signedId
                : this.signedId,

            locked: options.locked ?? this.locked,

            // Basic

            id: options.id ?? this.id,
            createdAt: options.createdAt ?? this.createdAt,
            modifiedAt: options.modifiedAt ?? this.modifiedAt,
        })
    }
}
