import { forwardRef, useContext } from "react"
import { useNavigate } from "react-router-dom"
import { exitIconUrl } from "images"
import { logout, USER_ID_STORAGE_KEY } from "api"
import { UserContext } from "ui/context"
import * as LoginPage from "ui/page/auth/LoginPage/path"
import * as MainPage from "ui/page/sections/main/MainPage/path"
import { Button } from "ui/ui"

export const LogoutButton = forwardRef(() => {
    const navigate = useNavigate()

    const [, setLocalUser] = useContext(UserContext)

    return <Button iconSrc={exitIconUrl}
                   buttonStyle="text"
                   onClick={onLogout}/>

    // Events

    function onLogout() {
        logout().catch(console.error)

        setLocalUser(null)

        const userId = localStorage.getItem(USER_ID_STORAGE_KEY)

        const newPath = userId != null
            ? LoginPage.createPath(userId)
            : MainPage.PATH

        navigate(newPath)
    }
})

LogoutButton.displayName = "LogoutButton"
