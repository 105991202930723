import { FormEvent, ForwardedRef, forwardRef, useMemo } from "react"
import { triangleDownIconUrl } from "images"
import { DeepReadonly } from "my-util"
import { useStateWithDeps } from "ui/hook"
import { Icon } from "ui/ui/icon"
import { Information, Label } from "ui/ui/output"
import style from "./style.module.css"

export namespace Select {
    export interface Props {
        onSelect?: (key: string) => void
        selected?: string
        options?: Options

        label?: string
        information?: string

        disabled?: boolean
        readonly?: boolean
        loading?: boolean

        autoFocus?: boolean
        width?: string
    }

    export interface Options {
        [key: string]: OptionValue | undefined
    }

    export type OptionValue =
        | [string, string]
        | string
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Select = forwardRef((
    {
        onSelect, selected, options,
        label, information,
        disabled, readonly, loading,
        autoFocus, width,
    }: DeepReadonly<Select.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    // State

    const innerOptions = useMemo(() => options ?? {}, [options])
    const innerOptionsEntries = useMemo(() => Object.entries(innerOptions), [innerOptions])

    const [innerSelected, setInnerSelected] = useStateWithDeps<string | undefined>(
        () => {
            if (selected != null)
                return selected

            if (innerOptionsEntries.length > 0)
                return innerOptionsEntries[0][0]

            return undefined
        },

        [selected, options],
    )

    const [displayValue, setDisplayValue] = useStateWithDeps(
        () => innerSelected != null
            ? selectedToDisplayValue(innerSelected)
            : "",

        [innerSelected, options],
    )

    // Render

    return <div className={renderClassName()}
                style={{ width }}
                ref={ref}>
        <Label text={label}/>

        <div className={style.htmlSelectContainer}>
            <select className={style.htmlSelect}
                    disabled={disabled || loading || readonly}
                    autoFocus={autoFocus}
                    value={innerSelected}
                    onInput={onInnerSelect}>
                {innerOptionsEntries.map(([key, value]) =>
                    value &&
                        <option value={key}
                                key={key}>
                            {value &&
                                optionValueToOptionText(value)
                            }
                        </option>
                )}
            </select>

            <div className={style.select}>
                {displayValue}
            </div>

            {!readonly &&
                <div className={style.icon}>
                    <Icon src={triangleDownIconUrl}
                          alt="Down arrow icon"
                          filter="brightness(.4)"/>
                </div>
            }
        </div>

        <Information text={information}/>
    </div>

    function renderClassName() {
        if (readonly)
            return style.ReadonlySelect

        if (loading)
            return style.LoadingSelect

        return style.Select
    }

    function onInnerSelect(event: FormEvent<HTMLSelectElement>) {
        const value = event.currentTarget.value

        setInnerSelected(value)
        setDisplayValue(selectedToDisplayValue(value))

        onSelect?.(value)
    }

    // Util

    function selectedToDisplayValue(selected: string): string {
        const value = innerOptions[selected]

        return value != null
            ? optionValueToDisplayValue(value)
            : ""
    }

    function optionValueToDisplayValue(value: Readonly<Select.OptionValue>): string {
        return Array.isArray(value)
            ? value[1]
            : value
    }

    function optionValueToOptionText(value: Readonly<Select.OptionValue>): string {
        return Array.isArray(value)
            ? value[0]
            : value
    }
})

Select.displayName = "Select"
