import { Document, Identifiable } from "model"
import { DeepReadonly } from "my-util"

// Util

export function copyDocumentStatus<T extends DocumentStatus>(status: DeepReadonly<T>): T {
    switch (status.type) {
        case READY_DOCUMENT_STATUS_TYPE:
            return {
                type: READY_DOCUMENT_STATUS_TYPE,
                document: status.document,
            } satisfies ReadyDocumentStatus as T

        case UPLOADING_DOCUMENT_STATUS_TYPE:
            return {
                type: UPLOADING_DOCUMENT_STATUS_TYPE,
                document: { id: status.document.id },
                loaded: status.loaded,
                total: status.total,
            } satisfies UploadingDocumentStatus as T

        case LOADING_FAILED_DOCUMENT_STATUS_TYPE:
        case UPLOADING_FAILED_DOCUMENT_STATUS_TYPE:
            return {
                type: status.type,
                document: { id: status.document.id },
                error: status.error,
            } satisfies LoadingFailedDocumentStatus | UploadingFailedDocumentStatus as T

        case LOADING_DOCUMENT_STATUS_TYPE:
        case DELETED_DOCUMENT_STATUS_TYPE:
        case ABORTED_DOCUMENT_STATUS_TYPE:
            return {
                type: status.type,
                document: { id: status.document.id },
            } satisfies LoadingDocumentStatus | DeletedDocumentStatus | AbortedDocumentStatus as T
    }
}

export function isDocumentStatusType(s: string): s is DocumentStatusType {
    switch (s) {
        case READY_DOCUMENT_STATUS_TYPE:
        case LOADING_DOCUMENT_STATUS_TYPE:
        case LOADING_FAILED_DOCUMENT_STATUS_TYPE:
        case UPLOADING_DOCUMENT_STATUS_TYPE:
        case UPLOADING_FAILED_DOCUMENT_STATUS_TYPE:
        case DELETED_DOCUMENT_STATUS_TYPE:
        case ABORTED_DOCUMENT_STATUS_TYPE:
            s satisfies DocumentStatusType
            return true

        default:
            return false
    }
}

export function isPhysicalDocumentStatusType(
    statusType: DocumentStatusType,
): statusType is PhysicalDocumentStatusType {
    return !isSyntheticDocumentStatusType(statusType)
}

export function isSyntheticDocumentStatusType(
    statusType: DocumentStatusType,
): statusType is SyntheticDocumentStatusType {
    switch (statusType) {
        case DELETED_DOCUMENT_STATUS_TYPE:
        case ABORTED_DOCUMENT_STATUS_TYPE:
            statusType satisfies SyntheticDocumentStatusType
            return true

        default:
            statusType satisfies PhysicalDocumentStatusType
            return false
    }
}

// Status

// - United

export type DocumentStatus =
    | PhysicalDocumentStatus
    | SyntheticDocumentStatus

// - Physical

export type PhysicalDocumentStatus =
    | ReadyDocumentStatus

    | LoadingDocumentStatus
    | LoadingFailedDocumentStatus

    | UploadingDocumentStatus
    | UploadingFailedDocumentStatus

// - Synthetic

export type SyntheticDocumentStatus =
    | DeletedDocumentStatus
    | AbortedDocumentStatus

// - Specific

// -- Ready

export interface ReadyDocumentStatus extends DocumentStatusBase {
    type: ReadyDocumentStatusType
    document: Document
}

// -- Loading

export interface LoadingDocumentStatus extends DocumentStatusBase {
    type: LoadingDocumentStatusType
}

export interface LoadingFailedDocumentStatus extends DocumentStatusBase {
    type: LoadingFailedDocumentStatusType
    error: unknown
}

// -- Uploading

export interface UploadingDocumentStatus extends DocumentStatusBase {
    type: UploadingDocumentStatusType
    loaded: number
    total: number
}

export interface UploadingFailedDocumentStatus extends DocumentStatusBase {
    type: UploadingFailedDocumentStatusType
    error: unknown
}

// -- Stopped

export interface DeletedDocumentStatus extends DocumentStatusBase {
    type: DeletedDocumentStatusType
}

export interface AbortedDocumentStatus extends DocumentStatusBase {
    type: AbortedDocumentStatusType
}

// - Base

export interface DocumentStatusBase {
    type: DocumentStatusType
    document: Identifiable
}

// - Type

// -- United

export type DocumentStatusType =
    | PhysicalDocumentStatusType
    | SyntheticDocumentStatusType

// -- Physical

export type PhysicalDocumentStatusType =
    | ReadyDocumentStatusType

    | LoadingDocumentStatusType
    | LoadingFailedDocumentStatusType

    | UploadingDocumentStatusType
    | UploadingFailedDocumentStatusType

// -- Synthetic

export type SyntheticDocumentStatusType =
    | DeletedDocumentStatusType
    | AbortedDocumentStatusType

// -- Specific

export type ReadyDocumentStatusType = typeof READY_DOCUMENT_STATUS_TYPE

export type LoadingDocumentStatusType = typeof LOADING_DOCUMENT_STATUS_TYPE
export type LoadingFailedDocumentStatusType = typeof LOADING_FAILED_DOCUMENT_STATUS_TYPE

export type UploadingDocumentStatusType = typeof UPLOADING_DOCUMENT_STATUS_TYPE
export type UploadingFailedDocumentStatusType = typeof UPLOADING_FAILED_DOCUMENT_STATUS_TYPE

export type DeletedDocumentStatusType = typeof DELETED_DOCUMENT_STATUS_TYPE
export type AbortedDocumentStatusType = typeof ABORTED_DOCUMENT_STATUS_TYPE

// - Consts

export const READY_DOCUMENT_STATUS_TYPE = "ready"

export const LOADING_DOCUMENT_STATUS_TYPE = "loading"
export const LOADING_FAILED_DOCUMENT_STATUS_TYPE = "loading-failed"

export const UPLOADING_DOCUMENT_STATUS_TYPE = "uploading"
export const UPLOADING_FAILED_DOCUMENT_STATUS_TYPE = "uploading-failed"

export const DELETED_DOCUMENT_STATUS_TYPE = "deleted"
export const ABORTED_DOCUMENT_STATUS_TYPE = "aborted"
