import { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useRef } from "react"
import { useTranslation } from "react-i18next"
import { fileIconUrl } from "images"
import { generateRandomUuid } from "my-util"
import { Icon } from "ui/ui"
import { UploadingUiDocument } from "../UiDocument"
import style from "./style.module.css"

export namespace DropZone {
    export interface Props {
        onDrop?: (documents: UploadingUiDocument[]) => void

        width?: string
        height?: string

        multiple?: boolean
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DropZone = forwardRef((
    {
        onDrop,
        width, height,
        multiple,
    }: Readonly<DropZone.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const [t] = useTranslation()

    // Refs

    const innerRef = useRef(null as HTMLDivElement | null)

    useImperativeHandle(ref, () => innerRef.current!, [])

    // Events handling

    useEffect(() => {
        const dropZone = innerRef.current

        if (dropZone == null)
            return

        dropZone.addEventListener("dragenter", dragEnterHandler)
        dropZone.addEventListener("dragleave", dragLeaveHandler)
        dropZone.addEventListener("dragover", dragOverHandler)
        dropZone.addEventListener("drop", dropHandler)

        return () => {
            dropZone.removeEventListener("dragenter", dragEnterHandler)
            dropZone.removeEventListener("dragleave", dragLeaveHandler)
            dropZone.removeEventListener("dragover", dragOverHandler)
            dropZone.removeEventListener("drop", dropHandler)
        }

        function dragEnterHandler(event: DragEvent) {
            dragOverHandler(event)

            dropZone?.classList.add(style.hover)
        }

        function dragLeaveHandler() {
            dropZone?.classList.remove(style.hover)
        }

        function dragOverHandler(event: DragEvent) {
            if (event.dataTransfer != null)
                event.dataTransfer.dropEffect = "copy"
        }

        function dropHandler(event: DragEvent) {
            if (onDrop == null ||
                event.dataTransfer == null ||
                event.dataTransfer.files.length === 0)
                return

            const files = multiple
                ? [...event.dataTransfer.files]
                : [event.dataTransfer.files[0]]

            const documents: UploadingUiDocument[] = files.map(file => ({
                id: generateRandomUuid(),
                status: "uploading",
                file,
            }))

            onDrop(documents)
        }
    }, [onDrop, multiple])

    // Render

    return <div style={{ width, height }}
                className={style.DropZone}
                ref={innerRef}>
        <Icon src={fileIconUrl}
              alt="File icon"
              filter="brightness(0) invert(50%)"
              width="32px"
              height="32px"/>

        {t("misc.labels.dropFilesHere")}
    </div>
})

DropZone.displayName = "DropZone"
