import { ForwardedRef, forwardRef, ReactNode } from "react"
import { dateToDateString, dateToDateTimeString, dateToTimeString, DeepReadonly } from "my-util"
import { Output } from "ui/ui/output"

export namespace DateTimeOutput {
    export interface Props extends Omit<Output.Props, "children"> {
        date: Date

        hideDate?: boolean
        hideTime?: boolean
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DateTimeOutput = forwardRef((
    props: DeepReadonly<DateTimeOutput.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    return <Output {...props as DateTimeOutput.Props}
                   ref={ref}>
        {renderChildren()}
    </Output>

    function renderChildren(): ReactNode {
        const { date, hideDate, hideTime } = props

        if (hideDate && hideTime)
            return null

        if (hideDate)
            return dateToTimeString(date)

        if (hideTime)
            return dateToDateString(date)

        return dateToDateTimeString(date)
    }
})

DateTimeOutput.displayName = "DateTimeOutput"
