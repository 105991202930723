import { IsImmutable, ReadonlyDate, DeepReadonly, generateRandomUuid,
         createOrCopyDate, tryNormalizeUuid, Nullish, Immutable, Copyable } from "my-util"

import { ModelObject } from "./interfaces"

export namespace AbstractModelObject {
    export interface CreationOptions extends Nullish<ModelObject> {}
    export interface CopyOptions extends Nullish<ModelObject> {}
}

export abstract class AbstractModelObject<CopyOptions extends AbstractModelObject.CopyOptions>
    extends
        Copyable<CopyOptions>

    implements
        DeepReadonly<ModelObject>,
        Immutable
{
    // Group

    static groupByIdOrPassOrCreate<T extends DeepReadonly<ModelObject>>(
        objects:
            | ReadonlyMap<string, T>
            | Iterable<T>
            | undefined
            | null
    ): Map<string, T> {
        return objects != null
            ? this.groupByIdOrPass(objects)
            : new Map()
    }

    static groupByIdOrPass<T extends DeepReadonly<ModelObject>>(
        objects:
            | ReadonlyMap<string, T>
            | Iterable<T>
    ): Map<string, T> {
        return objects instanceof Map
            ? objects
            : this.groupById(objects as readonly T[])
    }

    static groupById<T extends DeepReadonly<ModelObject>>(objects: Iterable<T>): Map<string, T> {
        const map = new Map<string, T>()

        for (const object of objects)
            map.set(object.id, object)

        return map
    }

    // Fields

    readonly [IsImmutable] = true

    readonly id: string
    readonly createdAt: ReadonlyDate
    readonly modifiedAt: ReadonlyDate

    // Constructor

    constructor(
        {
            id,
            createdAt,
            modifiedAt,
        }: DeepReadonly<AbstractModelObject.CreationOptions> = {},
    ) {
        super()

        this.id = id != null
            ? tryNormalizeUuid(id)
            : generateRandomUuid()

        this.createdAt = createOrCopyDate(createdAt)
        this.modifiedAt = createOrCopyDate(modifiedAt)
    }
}
