import formatDuration from "format-duration"
import { Immutable, IsImmutable, Nullish } from "my-util/type"
import { Copyable } from "my-util/Copyable"
import { parse as parseIso8601Duration } from "iso8601-duration"

import { MINUTE_MILLIS, DAY_MILLIS, HOUR_MILLIS, YEAR_MILLIS, WEEK_DAYS,
         HOUR_MINUTES, DAY_HOURS, MINUTE_SECONDS, SECOND_MILLIS, WEEK_MILLIS,
         YEAR_DAYS} from "./consts"

export namespace Duration {
    export interface CreationOptions extends Nullish<{
        years: number
        weeks: number
        days: number
        hours: number
        minutes: number
        seconds: number
        millis: number
    }> {}

    export interface CopyOptions extends CreationOptions {}
}

export class Duration
    extends
        Copyable<Duration.CopyOptions>

    implements
        Immutable
{
    static parse(duration: string): Duration {
        return new Duration(parseIso8601Duration(duration))
    }

    static ZERO = new Duration()

    // Fields

    readonly [IsImmutable] = true

    readonly years: number
    readonly weeks: number
    readonly days: number
    readonly hours: number
    readonly minutes: number
    readonly seconds: number
    readonly millis: number

    // Constructor

    constructor(options: Readonly<Duration.CreationOptions> = {}) {
        super()

        this.years = options.weeks ?? 0
        this.weeks = options.weeks ?? 0
        this.days = options.days ?? 0
        this.hours = options.hours ?? 0
        this.minutes = options.minutes ?? 0
        this.seconds = options.seconds ?? 0
        this.millis = options.millis ?? 0
    }

    // Normalization

    normalized(): Duration {
        const totalMillis = this.totalMillis

        const totalSeconds = totalMillis / SECOND_MILLIS
        const totalMinutes = totalSeconds / MINUTE_SECONDS
        const totalHours = totalMinutes / HOUR_MINUTES
        const totalDays = totalHours / DAY_HOURS
        const totalWeeks = totalDays / WEEK_DAYS
        const totalYears = totalDays / YEAR_DAYS

        const millis = totalMillis % SECOND_MILLIS
        const seconds = totalSeconds % MINUTE_SECONDS | 0
        const minutes = totalMinutes % HOUR_MINUTES | 0
        const hours = totalHours % DAY_HOURS | 0
        const days = totalDays % WEEK_DAYS | 0
        const weeks = totalWeeks | 0
        const years = totalYears | 0

        return new Duration({
            years,
            weeks,
            days,
            hours,
            minutes,
            seconds,
            millis,
        })
    }

    // Map

    rounded(): Duration {
        return this.map(Math.round)
    }

    floored(): Duration {
        return this.map(Math.floor)
    }

    ceiled(): Duration {
        return this.map(Math.ceil)
    }

    truncated(): Duration {
        return this.map(Math.trunc)
    }

    map(func: (part: number) => number): Duration {
        return new Duration({
            years: func(this.years),
            weeks: func(this.weeks),
            days: func(this.days),
            hours: func(this.hours),
            minutes: func(this.minutes),
            seconds: func(this.seconds),
            millis: func(this.millis),
        })
    }

    // Total

    get totalYears(): number {
        return this.totalMillis / YEAR_MILLIS
    }

    get totalWeeks(): number {
        return this.totalMillis / WEEK_MILLIS
    }

    get totalDays(): number {
        return this.totalMillis / DAY_MILLIS
    }

    get totalHours(): number {
        return this.totalMillis / HOUR_MILLIS
    }

    get totalMinutes(): number {
        return this.totalMillis / MINUTE_MILLIS
    }

    get totalSeconds(): number {
        return this.totalMillis / SECOND_MILLIS
    }

    get totalMillis(): number {
        const yearsMillis = this.years * YEAR_MILLIS
        const weeksMillis = this.weeks * WEEK_MILLIS
        const daysMillis = this.days * DAY_MILLIS
        const hoursMillis = this.hours * HOUR_MILLIS
        const minutesMillis = this.minutes * MINUTE_MILLIS
        const secondsMillis = this.seconds * SECOND_MILLIS

        return this.millis
            + secondsMillis
            + minutesMillis
            + hoursMillis
            + daysMillis
            + weeksMillis
            + yearsMillis
    }

    // Copy

    protected override createCopy(options: Readonly<Duration.CopyOptions> = {}): Duration {
        return new Duration({
            years: options.years ?? this.years,
            weeks: options.weeks ?? this.weeks,
            days: options.days ?? this.days,
            hours: options.hours ?? this.hours,
            minutes: options.minutes ?? this.minutes,
            seconds: options.seconds ?? this.seconds,
            millis: options.millis ?? this.millis,
        })
    }

    // To string conversion

    override toString(): string {
        return formatDuration(this.totalMillis)
    }
}
