import { ForwardedRef, forwardRef, Fragment, ReactNode, useEffect, useRef, useState } from "react"
import { plusIconUrl, trashCanIconUrl } from "images"
import { Product } from "model"
import { DeepReadonly, retainMapKeys, splicedArray } from "my-util"
import { useStateWithDeps } from "ui/hook"
import { Button, Flex } from "ui/ui"
import { ProductEditor, ProductViolations } from "../ProductEditor"
import { ProductListViolations } from "./ProductListViolations"

const BUTTON_WIDTH = "32px"
const BUTTONS_FLEX_WIDTH = `calc(2 * ${BUTTON_WIDTH} + ${Flex.DEFAULT_GAP})`

export namespace ProductListEditor {
    export interface Props {
        onValidate?: (violations: ProductListViolations) => void
        onChange?: (values: Product[]) => void
        values?: Iterable<Product>

        allowEmpty?: boolean

        loading?: boolean
        disabled?: boolean
        readonly?: boolean
        required?: boolean
        output?: boolean
        mobile?: boolean

        width?: string
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProductListEditor = forwardRef((
    {
        onValidate, onChange, values,
        allowEmpty,
        loading, disabled, readonly, required, output, mobile,
        width,
    }: DeepReadonly<ProductListEditor.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    // Refs

    const valuesChangedRef = useRef(true)
    const violationsChangedRef = useRef(true)

    // State

    const [innerValues, setInnerValues] = useStateWithDeps<Product[]>(
        oldValue => {
            if (valuesChangedRef.current && oldValue != null)
                return oldValue

            const newValues = [...values ?? []]

            if (!output && !allowEmpty && newValues.length === 0)
                newValues.push(new Product())

            return newValues
        },

        [values, output, allowEmpty],
    )

    const [violations, setViolations] = useState(new ProductListViolations())

    // Effects

    // - Violations clean-up

    useEffect(() => {
        const newViolationsByProductId = retainMapKeys(
            new Map(violations.violationsByProductId),
            innerValues.map(({ id }) => id),
        )

        if (newViolationsByProductId.size === violations.violationsByProductId.size)
            return

        setViolations(oldViolations => oldViolations.copy({
            violationsByProductId: newViolationsByProductId,
            deleteValid: true,
        }))

        violationsChangedRef.current = true
    }, [innerValues, violations.violationsByProductId])

    // - Values propagation

    useEffect(() => {
        if (!valuesChangedRef.current)
            return

        valuesChangedRef.current = false

        if (violations.isValid)
            onChange?.([...innerValues])
    }, [onChange, innerValues, violations.isValid])

    // - Violations propagation

    useEffect(() => {
        if (violationsChangedRef.current) {
            violationsChangedRef.current = false
            onValidate?.(violations)
        }
    }, [onValidate, violations])

    // Render

    return <Flex align="start"
                 width={width}
                 ref={ref}>
        {renderContent()}
    </Flex>

    function renderContent(): ReactNode {
        if (innerValues.length === 0)
            return renderAddButton()

        if (output || readonly)
            return renderAllProducts()

        return renderAllProductsWithEditingButtons()
    }

    // - Products

    function renderAllProducts(): ReactNode {
        return innerValues.map((product, index) =>
            <Fragment key={product.id ?? index}>
                {renderProduct(product, index)}
            </Fragment>
        )
    }

    function renderAllProductsWithEditingButtons(): ReactNode {
        const deleteButtonDisabled = !allowEmpty && innerValues.length === 1

        return innerValues.map((product, index) =>
            <Flex key={product.id ?? index}
                  direction="row">
                {renderProduct(product, index)}

                <div>
                    <Flex width={BUTTONS_FLEX_WIDTH}
                          direction="row">
                        {renderDeleteButton(index, deleteButtonDisabled)}

                        {index === innerValues.length - 1 &&
                            renderAddButton()
                        }
                    </Flex>
                </div>
            </Flex>
        )
    }

    function renderProduct(product: Product, index: number = -1): ReactNode {
        return <ProductEditor onValidate={productViolations => onValidateProduct(productViolations, product.id)}
                              onChange={newProduct => onProductChange(newProduct, index)}
                              value={product}

                              loading={loading}
                              disabled={disabled}
                              readonly={readonly}
                              required={required}
                              output={output}
                              mobile={mobile}/>
    }

    // - Buttons

    function renderAddButton(): ReactNode {
        return <Button onClick={onAdd}

                       iconSrc={plusIconUrl}
                       iconAlt="Plus icon"

                       width={BUTTON_WIDTH}/>
    }

    function renderDeleteButton(productIndex: number = -1, disabled: boolean = false): ReactNode {
        return <Button onClick={() => onDelete(productIndex)}

                       iconSrc={trashCanIconUrl}
                       iconAlt="Trash can icon"

                       buttonStyle="outline"
                       width={BUTTON_WIDTH}

                       disabled={disabled}
                       critical/>
    }

    // Events

    function onAdd() {
        setInnerValues(oldValues => [...oldValues, new Product()])

        valuesChangedRef.current = true
    }

    function onDelete(productIndex: number = -1) {
        if (productIndex < 0)
            return

        setViolations(oldViolations => {
            const product = innerValues[productIndex]

            if (product == null)
                return oldViolations

            return oldViolations.withDeleted(product.id)
        })

        setInnerValues(oldValues => splicedArray(oldValues, productIndex, 1))

        violationsChangedRef.current = true
        valuesChangedRef.current = true
    }

    function onProductChange(newValue: Product, index: number = -1) {
        if (index < 0)
            return

        setInnerValues(oldValues => splicedArray(oldValues, index, 1, newValue))

        valuesChangedRef.current = true
    }

    function onValidateProduct(productViolations: ProductViolations, productId: string) {
        setViolations(oldViolations =>
            oldViolations
                .withNew(productId, productViolations)
                .withoutValid()
        )

        violationsChangedRef.current = true
    }
})

ProductListEditor.displayName = "ProductListEditor"
