import { ApiError } from "./ApiError"

export namespace NotFoundApiError {
    export interface CreationOptions extends Omit<ApiError.CreationOptions, "statusCode"> {}
}

export class NotFoundApiError extends ApiError {
    static readonly STATUS_CODE = 404

    constructor(options: Readonly<NotFoundApiError.CreationOptions> = {}) {
        super({
            ...options,
            statusCode: NotFoundApiError.STATUS_CODE,
        })
    }
}
