import { t } from "i18next"

import { isTransferDirection, getTransferDirectionName,
         IMPORT_TRANSFER_DIRECTION, EXPORT_TRANSFER_DIRECTION } from "./TransferDirection"

// Types

export type TransferDirectionFilter =
    | ImportTransferDirectionFilter
    | ExportTransferDirectionFilter
    | AllTransferDirectionFilter

export type ImportTransferDirectionFilter = typeof IMPORT_TRANSFER_DIRECTION_FILTER
export type ExportTransferDirectionFilter = typeof EXPORT_TRANSFER_DIRECTION_FILTER
export type AllTransferDirectionFilter = typeof ALL_TRANSFER_DIRECTION_FILTER

// Consts

export const IMPORT_TRANSFER_DIRECTION_FILTER = IMPORT_TRANSFER_DIRECTION
export const EXPORT_TRANSFER_DIRECTION_FILTER = EXPORT_TRANSFER_DIRECTION
export const ALL_TRANSFER_DIRECTION_FILTER = "all"

// Name

export function getTransferDirectionFilterName(filter: TransferDirectionFilter): string {
    return filter !== ALL_TRANSFER_DIRECTION_FILTER
        ? getTransferDirectionName(filter)
        : t("misc.words.all")
}

// Is-check

export function isTransferDirectionFilter(filter: string): filter is TransferDirectionFilter {
    return isTransferDirection(filter)
        || filter === ALL_TRANSFER_DIRECTION_FILTER satisfies TransferDirectionFilter
}
