import { ForwardedRef, forwardRef } from "react"
import { Flex } from "ui/ui/layout"
import { Count } from "../Count"
import style from "./style.module.css"

export namespace CountDataRow {
    export interface Props {
        label: string
        value?: number

        importance?: Importance
        underline?: boolean

        width?: string
    }

    export type Importance =
        | "low"
        | "medium"
        | "high"
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CountDataRow = forwardRef((
    {
        label, value,
        importance, underline,
        width,
    }: Readonly<CountDataRow.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const innerValue = value ?? 0
    const innerImportance = importance ?? "low"

    return <div className={style.CountDataRow}
                style={{ width }}
                ref={ref}>
        <Flex justify="space-between"
              direction="row">
            <div
                style={{
                    textDecoration: underline ? "underline" : undefined,
                    color: innerImportance === "high" && innerValue > 0 ? "red" : "black",
                }}
                className={style.label}
            >
                {label}
            </div>

            <Count important={innerImportance !== "low"}
                   value={innerValue}/>
        </Flex>
    </div>
})

CountDataRow.displayName = "CountDataRow"
