import { useTranslation } from "react-i18next"

import { MfaResponse, startRegistration, StartRegistrationRequest } from "api"

import { messageTargetToObject, MessageTargetType } from "model"
import { MessageTargetMfaForm } from "ui/component"
import { Limit, Pane } from "ui/ui"

export namespace StartPane {
    export interface Props {
        onSuccess?: MessageTargetMfaForm.OnSuccess

        inviteId: string
    }
}

export function StartPane({ onSuccess, inviteId }: Readonly<StartPane.Props>) {
    const [t] = useTranslation()

    const messageTargetPlaceholder = t("messageTargets.placeholders.your.messageTarget")

    return <Pane header={t("auth.reg.headers.main")}>
        <Limit maxWidth="300px">
            <MessageTargetMfaForm messageTargetPlaceholder={messageTargetPlaceholder}
                                  performMfa={performMfa}
                                  onSuccess={onSuccess}/>
        </Limit>
    </Pane>

    async function performMfa(
        messageTarget: string,
        messageTargetType: MessageTargetType,
    ): Promise<MfaResponse> {
        const request: StartRegistrationRequest = {
            ...messageTargetToObject(messageTarget, messageTargetType),
            inviteId,
        }

        return startRegistration(request)
    }
}
