import { ReactNode, useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { getAllDocuments, getAllMyDocuments, getAllUsersByIds } from "api"
import { Document, User } from "model"
import { uniqueArray } from "my-util"
import { useUsers } from "ui/hook"
import { UserContext } from "ui/context"
import { SessionExpiredErrorPage } from "ui/page/error"
import { DocumentTable, Page } from "ui/component"
import { ErrorDisplay, Loading, Padding } from "ui/ui"

export function Component() {
    const [t] = useTranslation()

    const [localUser] = useContext(UserContext)

    const storedUsers = useUsers()

    // State

    const [documents, setDocuments] = useState(new Array<Document>())
    const [loadingDocuments, setLoadingDocuments] = useState(localUser != null)

    const [creators, setCreators] = useState(new Map<string, User>())
    const [loadingCreators, setLoadingCreators] = useState(false)

    const loading = loadingDocuments || loadingCreators
    const [error, setError] = useState(undefined as unknown)

    // Effects

    // - Documents loading

    useEffect(() => {
        if (!loadingDocuments)
            return

        const get = localUser?.isManager
            ? getAllDocuments
            : getAllMyDocuments

        const controller = new AbortController()

        get(controller.signal)
            .then(documents => {
                setDocuments(documents)
                setLoadingCreators(localUser?.isManager ?? false)
            })
            .catch(error => {
                if (!controller.signal.aborted)
                    setError(error)
            })
            .finally(() => {
                if (!controller.signal.aborted)
                    setLoadingDocuments(false)
            })

        return () => controller.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingDocuments])

    // - Creators loading

    useEffect(() => {
        if (!loadingCreators)
            return

        const creatorIds = uniqueArray(
            documents
                .map(({ creatorId }) => creatorId)
                .filter(id => id != null) as string[]
        )

        if (creatorIds.length === 0) {
            setLoadingCreators(false)
            return
        }

        const controller = new AbortController()

        getAllUsersByIds({ ids: creatorIds }, controller.signal)
            .then(creators => {
                storedUsers.addAll(creators)
                setCreators(User.groupById(creators))
            })
            .catch(error => {
                if (!controller.signal.aborted)
                    setError(error)
            })
            .finally(() => {
                if (!controller.signal.aborted)
                    setLoadingCreators(false)
            })

        return () => controller.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingCreators])

    // Render

    if (localUser == null)
        return <SessionExpiredErrorPage/>

    return <Page title={t("sections.documents.header").toUpperCase()}
                 type="main">
        {renderContent()}
    </Page>

    function renderContent(): ReactNode {
        if (loading)
            return <Loading/>

        if (error)
            return <ErrorDisplay error={error}/>

        if (documents.length === 0)
            return <Padding paddingTop="22px">
                {t("sections.documents.messages.no")}
            </Padding>

        return <DocumentTable documents={documents}
                              users={creators}
                              showCreators={localUser?.isManager}/>
    }
}
