import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { plusIconUrl } from "images"
import { UserContext } from "ui/context"
import { Button, Flex } from "ui/ui"
import * as InviteCreationPage from "../InviteCreationPage/path"

export function CompactTitle() {
    const [t] = useTranslation()
    const [localUser] = useContext(UserContext)

    const navigate = useNavigate()

    return <Flex direction="row"
                 width="fit-content"
                 gap="8px">
        {localUser?.hasRightToSendInvites &&
            <Button onClick={() => navigate(InviteCreationPage.PATH)}
                    iconSrc={plusIconUrl}
                    iconAlt="Plus icon"
                    width="32px"/>
        }

        <div style={{ height: "1em" }}>
            {t("sections.clients.invites.header").toUpperCase()}
        </div>
    </Flex>
}
