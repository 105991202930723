import { CSSProperties, ForwardedRef, ReactNode } from "react"
import { DeepReadonly, forwardRefAndSetProperties, satAs } from "my-util"
import style from "./style.module.css"

export namespace Flex {
    export interface Props {
        direction?: Direction
        justify?: Justify
        align?: Align
        gap?: CSSProperties["gap"]
        wrap?: boolean

        children?: ReactNode

        width?: CSSProperties["width"]
        height?: CSSProperties["height"]
    }

    export type Direction = CSSProperties["flexDirection"]
    export type Justify = CSSProperties["justifyContent"]
    export type Align = CSSProperties["alignItems"]
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Flex = forwardRefAndSetProperties(
    {
        DEFAULT_DIRECTION: satAs<Flex.Direction>("column"),
        DEFAULT_JUSTIFY: satAs<Flex.Justify>("start"),
        DEFAULT_ALIGN: satAs<Flex.Align>("center"),
        DEFAULT_GAP: "16px" as string,
    } as const,

    (
        {
            direction, justify, align, gap, wrap,
            children,
            width, height,
        }: DeepReadonly<Flex.Props>,

        ref: ForwardedRef<HTMLDivElement>,
    ) => {
        const innerDirection = direction ?? Flex.DEFAULT_DIRECTION
        const innerJustify = justify ?? Flex.DEFAULT_JUSTIFY
        const innerAlign = align ?? Flex.DEFAULT_ALIGN
        const innerGap = gap ?? Flex.DEFAULT_GAP

        return <div
            className={style.Flex}
            style={{
                flexDirection: innerDirection,
                justifyContent: innerJustify,
                alignItems: innerAlign,
                gap: innerGap,

                flexWrap: wrap ? "wrap" : "nowrap",

                width, height,
            }}
            ref={ref}
        >
            {children}
        </div>
    },
)

Flex.displayName = "Flex"
